import { SubscriptionType } from 'district-ui-client/domain/subscription-type'

/**
 * Used as the product slug in a reporting route, or for older student-events reporting requests
 */
export type ReportingProductSlug = 're' | 'rex' | 'fast-phonics' | 'maths' | 'mathseeds-prime' | 'writing-legends'

/**
 * Full product names used for newer gravity-based reporting requests, or any kind of need for product keys
 */
export enum Product {
  RE = 'reading-eggs',
  REX = 'reading-eggspress',
  FP = 'fast-phonics',
  MS = 'mathseeds',
  MSP = 'mathseeds-prime',
  WL = 'writing-legends',
}

export function isProduct(maybeProduct: unknown): maybeProduct is Product {
  const valid = Object.values<string>(Product)
  return typeof maybeProduct === 'string' && valid.includes(maybeProduct)
}

/**
 * Used as the product slug in a reporting route, or for older student-events reporting requests
 */
export function toReportingProductSlug(product: Product): ReportingProductSlug {
  switch (product) {
    case Product.RE:
      return 're'
    case Product.REX:
      return 'rex'
    case Product.FP:
      return 'fast-phonics'
    case Product.MS:
      return 'maths'
    case Product.MSP:
      return 'mathseeds-prime'
    case Product.WL:
      return 'writing-legends'
  }
}

/**
 * Obtain the product for the given reporting route name.
 */
export function getReportingProduct(routeName: Nullable<string>): Product | undefined {
  if (routeName?.includes('reporting.ui-scope.re.')) return Product.RE
  if (routeName?.includes('reporting.ui-scope.rex.')) return Product.REX
  if (routeName?.includes('reporting.ui-scope.fast-phonics.')) return Product.FP
  if (routeName?.includes('reporting.ui-scope.maths.')) return Product.MS
  if (routeName?.includes('reporting.ui-scope.mathseeds-prime.')) return Product.MSP
  if (routeName?.includes('reporting.ui-scope.writing-legends.')) return Product.WL
}

export const productsForSubscriptionType = (subscriptionType: SubscriptionType): Product[] => {
  switch (subscriptionType) {
    case SubscriptionType.Maths:
      return [Product.MS]
    case SubscriptionType.Reading:
      return [Product.RE, Product.REX, Product.FP]
    case SubscriptionType.Writing:
      return [Product.WL]
  }
}

export const isProductInSubscriptionType = (product: Product, subscriptionType: SubscriptionType): boolean => {
  return productsForSubscriptionType(subscriptionType).includes(product)
}
