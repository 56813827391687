import Service, { service } from '@ember/service'
import type AuthToken from '@blakeelearning/auth/services/auth-token'
import type Store from '@ember-data/store'
import config from 'district-ui-client/config/environment'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type School from 'district-ui-client/models/school'
import type Teacher from 'district-ui-client/models/teacher'
import { join } from 'district-ui-client/utils/uri'

const endpoint = join(config.readingEggsV1Url, 'district_ui', 'teacher_operations')

export default class TeacherOperationsService extends Service {
  @service authToken!: AuthToken

  @service store!: Store

  passwordReset(teachers: Teacher[]) {
    const data = {
      command: 'send_password_reset',
      data: { ids: teachers.map((teacher) => teacher.id) },
    }

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: this.authToken.token ?? '',
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }

  sendLoginDetails(teachers: Teacher[], subscriptionType: SubscriptionType) {
    const data = {
      command: 'send_login_details',
      product: subscriptionType,
      data: { ids: teachers.map((teacher) => teacher.id) },
    }

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: this.authToken.token ?? '',
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }

  exportTeachers(subscriptionType: SubscriptionType, school?: School) {
    const data = {
      command: 'export_teacher_details',
      product: subscriptionType,
      ...(school ? { data: { school_id: school.id } } : {}),
    }

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: this.authToken.token ?? '',
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }

  async moveTeachersToSchool(teachers: Teacher[], newSchool: School, subscriptionType: SubscriptionType) {
    const teacherIds = teachers.map((teacher) => teacher.id)
    const data = {
      command: 'move_teachers_between_schools',
      product: subscriptionType,
      data: {
        ids: teacherIds,
        destination_school_id: newSchool.id,
      },
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: this.authToken.token ?? '',
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    // reload these teachers to ensure we have up to date relationships for their school in the store
    if (response.ok) {
      await this.store.query('teacher', {
        scope: `schools/${newSchool.id}`,
        filter: { ids: teacherIds },
        include: 'school',
      })
    }
    return response
  }
}

declare module '@ember/service' {
  interface Registry {
    'teacher-operations': TeacherOperationsService
  }
}
