import Helper from '@ember/component/helper'
import { service } from '@ember/service'
import type FeaturesService from 'district-ui-client/services/features'

interface Signature {
  Args: {
    Positional: [string]
  }
  Return: boolean
}

export class featureFlag extends Helper<Signature> {
  @service features!: FeaturesService

  compute([flag]: Signature['Args']['Positional']) {
    return this.features.isEnabled(flag)
  }
}

export default featureFlag
