import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type Store from '@ember-data/store'
import type SessionService from 'district-ui-client/services/session'

export default class ManageSchoolsRoute extends Route {
  @service store!: Store

  @service session!: SessionService

  async model() {
    return {
      schools: await this.store.query('school', {
        scope: `districts/${this.session.currentDistrict.id}`,
        include: [
          'contact-detail', // used on both schools.index and schools.edit pages
          'school-subscriptions', // edit page lists school subscriptions table
          'school-subscription-utilisations', // used on both schools.index and schools.edit pages
          // subco record w/ teacher name shown on both schools.index and schools.edit pages
          'subscription-coordinators',
          'subscription-coordinators.teacher',
        ].join(','),
      }),
    }
  }
}
