import {
  AbortError as EmberDataAbortError,
  UnauthorizedError as EmberDataUnauthorizedError,
  ForbiddenError as EmberDataForbiddenError,
  NotFoundError as EmberDataNotFoundError,
} from '@ember-data/adapter/error'

export function shouldReportError(error: unknown) {
  return !isAbortError(error) && !isUnauthorizedError(error) && !isForbiddenError(error)
}

/**
 * Request aborted by user action, eg closed tab, stop button, network outage. Usually you want to ignore these.
 */
export function isAbortError(error: unknown) {
  const isGenericAbortError = error instanceof DOMException && error.name === 'AbortError'
  const isEmberDataAbortError = error instanceof EmberDataAbortError
  return isGenericAbortError || isEmberDataAbortError
}

/**
 * Checks the myriad of ways an error might be an unauthorized error
 */
export function isUnauthorizedError(error: unknown) {
  if (error && typeof error === 'object') {
    if (error instanceof EmberDataUnauthorizedError) {
      return true
    } else if (error instanceof Response) {
      return error.status === 401
    } else {
      return (
        String((error as { code?: unknown })?.code) === '401' ||
        String((error as { status?: unknown })?.status) === '401' ||
        String((error as { response?: { status?: unknown } })?.response?.status) === '401'
      )
    }
  }
}

/**
 * Checks the myriad of ways an error might be a forbidden error
 */
export function isForbiddenError(error: unknown) {
  if (error && typeof error === 'object') {
    if (error instanceof EmberDataForbiddenError) {
      return true
    } else if (error instanceof Response) {
      return error.status === 403
    } else {
      return (
        String((error as { code?: unknown })?.code) === '403' ||
        String((error as { status?: unknown })?.status) === '403' ||
        String((error as { response?: { status?: unknown } })?.response?.status) === '403'
      )
    }
  }
}

/**
 * Checks the myriad of ways an error might be a not found error
 */
export function isNotFoundError(error: unknown): boolean {
  if (error && typeof error === 'object') {
    if (error instanceof EmberDataNotFoundError) {
      return true
    } else if (error instanceof Response) {
      return error.status === 404
    } else {
      return (
        String((error as { code?: unknown })?.code) === '404' ||
        String((error as { status?: unknown })?.status) === '404' ||
        String((error as { response?: { status?: unknown } })?.response?.status) === '404'
      )
    }
  }
  return false
}
