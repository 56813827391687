import { computed, set } from '@ember/object'
import type ComputedProperty from '@ember/object/computed'

type DeserializeOptions = Partial<{
  validationFunction: (str: string) => boolean
}>

/**
 * Converts between an array of T (usually strings or numbers), and a string for URL query params
 *
 * non-digit items will be filtered out by default - use the validationFunction to override it
 *
 * Specify a mapper function to deserialize the query params to the type of array you need (usually String or Number)
 *
 * deserialize('123,456', String) -> ['123','456']
 * deserialize('123,456', Number) -> [123, 456]
 * deserialize('123abc,456', String) -> ['456']
 * const isAlphaNumeric = (str) => /^[a-z0-9]+$/i.test(str)
 * deserialize('123abc,456', String, { validationFunction: isAlphaNumeric }) -> ['123abc', '456']
 *
 * serialize(['123', '456']) -> '123,456'
 * serialize([123, 456]) -> '123,456'
 */
export function deserialize<T>(
  selectedIdsString: string,
  mapperFunction: (arg0: string) => T,
  options: DeserializeOptions = {}
): Array<T> {
  // Default to allowing only digits
  const isDigits = (str: string) => /^\d+$/.test(str) // ensure string is a positive integer (and checks for empty str '')
  const { validationFunction = isDigits } = options

  return selectedIdsString
    .split(',')
    .filter(validationFunction)
    .map(mapperFunction)
}
export function serialize(newSelectedIds: Array<unknown>): string {
  return newSelectedIds.join(',')
}

/**
 * Define this in a controller to act as serialize/deserialze layer for selected ID query param, converting between a
 * comma-delimited string of digit-based ID's from the URL to an array of those items mapped by the mapper function.
 *
 * Usage: selectedIdsComputed('selected', String) - ?selected=1,2,3 <-> ['1','2','3']
 * Usage: selectedIdsComputed('selected', Number) - ?selected=1,2,3 <-> [1,2,3]
 */
export function selectedIdsComputed<T>(
  selectedIdsQueryParamKey: string,
  mapperFunction: (arg0: string) => T
): ComputedProperty<T[], T[]> {
  return computed(selectedIdsQueryParamKey, {
    get(_key): Array<T> {
      return deserialize(this[selectedIdsQueryParamKey], mapperFunction)
    },
    set(_key, value: Array<T>): Array<T> {
      const newSelectedIdsString = serialize(value)
      set(this, selectedIdsQueryParamKey, newSelectedIdsString)
      return value
    },
  })
}
