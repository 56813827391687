import type { SortDirection } from '@blakeelearning/data-tables/utils/sorting'
import { get } from '@ember/object'

/**
 * Compares two values based on date
 *
 * should be negative if first is before second and compareForAscending is true
 * therefore, say first = 1975, second = 1976 should result in a negative number
 * so we want (first - second === -1) which will put first before second
 *
 * @param    compareForAscending Whether to compare for ascending sort, or not
 * @param    first  The first date
 * @param    second The second date
 * @return          The Comparison result
 * @instance
 * @memberOf DataTableOperationsComponent
 */
export function compareByDate(
  compareForAscending: boolean,
  first?: Date | null,
  second?: Date | null,
) {
  const firstDate = first ?? new Date()
  const secondDate = second ?? new Date()

  if (compareForAscending) {
    return Math.sign(firstDate.getTime() - secondDate.getTime())
  }
  return Math.sign(secondDate.getTime() - firstDate.getTime())
}

/**
 * Compares values by number
 *
 * if first is less than second and compareForAscending is true, should return a negative value
 * therefore first - second for compareForAscending
 *
 * @param    compareForAscending Whether to compare for ascending sort, or not
 * @param    first  First number
 * @param    second Second number
 * @return          Comparison result
 * @instance
 * @memberOf DataTableOperationsComponent
 */
export function compareByNumber(
  compareForAscending: boolean,
  first?: string | number,
  second?: string | number,
): number {
  let firstNumber = parseFloat(String(first))
  if (Number.isNaN(firstNumber)) firstNumber = -Infinity
  let secondNumber = parseFloat(String(second))
  if (Number.isNaN(secondNumber)) secondNumber = -Infinity
  const firstBeforeSecond = firstNumber < secondNumber
  const secondBeforeFirst = firstNumber > secondNumber

  if (compareForAscending) {
    if (firstBeforeSecond) return -1
    if (secondBeforeFirst) return 1
    return 0
  }

  // otherwise sorting descending
  if (firstBeforeSecond) return 1
  if (secondBeforeFirst) return -1
  return 0
}

/**
 * Compares values alphabetically after converting them to strings
 * @param    compareForAscending Whether to compare for ascending sort, or not
 * @param    first  First value
 * @param    second Second value
 * @return          Comparison result
 * @instance
 * @memberOf SortableTableComponent
 */
export function compareByString(
  compareForAscending: boolean,
  first?: any,
  second?: any,
): number {
  const firstString = (first || '').toString()
  const secondString = (second || '').toString()
  const firstBeforeSecond = firstString < secondString
  const secondBeforeFirst = firstString > secondString

  if (compareForAscending) {
    if (firstBeforeSecond) return -1
    if (secondBeforeFirst) return 1
    return 0
  }

  // otherwise sorting descending
  if (firstBeforeSecond) return 1
  if (secondBeforeFirst) return -1
  return 0
}

/**
 * Compares values alphabetically, then numerically (with strings before numbers)
 * @param    compareForAscending Whether to compare for ascending sort, or not
 * @param    first  First value - can be String or Number
 * @param    second Second value - can be String or Number
 * @return          Comparison result
 * @instance
 * @memberOf SortableTableComponent
 */
export function compareByValue(
  compareForAscending: boolean,
  first?: string | number,
  second?: string | number,
): number {
  const firstType = typeof first
  const secondType = typeof second
  if (firstType === secondType && firstType === 'number') {
    return compareByNumber(compareForAscending, first, second)
  }
  if (firstType === 'number' && secondType === 'string') {
    if (compareForAscending) {
      return 1
    }
    return -1
  }
  if (firstType === 'string' && secondType === 'number') {
    if (compareForAscending) {
      return -1
    }
    return 1
  }
  const firstString = (first || '').toString().toLowerCase()
  const secondString = (second || '').toString().toLowerCase()
  return compareByString(compareForAscending, firstString, secondString)
}

/**
 * Compares values by grade
 * @param    compareForAscending Whether to compare for ascending sort, or not
 * @param    first  First grade - can be String or Number
 * @param    second Second grade - can be String or Number
 * @return          Comparison result
 * @instance
 * @memberOf SortableTableComponent
 */
export function compareByGrade(
  compareForAscending: boolean,
  first?: string | number,
  second?: string | number,
): number {
  const gradeOrderArray = ['Pre-K', 'K']
  const firstType = typeof first
  const secondType = typeof second
  if (firstType === secondType && firstType === 'number') {
    return compareByNumber(compareForAscending, first, second)
  }
  if (firstType === 'number' && secondType === 'string') {
    if (compareForAscending) {
      return 1
    }
    return -1
  }
  if (firstType === 'string' && secondType === 'number') {
    if (compareForAscending) {
      return -1
    }
    return 1
  }
  const firstString = (first || '').toString()
  const secondString = (second || '').toString()
  // get the indexes, and sort by those
  let firstIndex = gradeOrderArray.indexOf(firstString)
  if (firstIndex < 0) firstIndex = Infinity
  let secondIndex = gradeOrderArray.indexOf(secondString)
  if (secondIndex < 0) secondIndex = Infinity
  // when not found, put the items at the end, then compareByString
  if (firstIndex === Infinity && firstIndex === secondIndex) {
    return compareByString(
      compareForAscending,
      firstString.toLowerCase(),
      secondString.toLowerCase(),
    )
  }
  // sort by the index
  return compareByNumber(compareForAscending, firstIndex, secondIndex)
}

interface Comparable {
  key: string
  sortDirection?: SortDirection
  compareFunction: (
    compareForAscending: boolean,
    first?: any,
    second?: any,
  ) => number
}

/**
 * Function to take an Array of objects each of which have at least the following props:
 * key, sortDirection, compareFunction, and builds a two-argument function.
 * The compareFunctions are functions of three arguments, for sorting
 * @returns a function of two arguments for sort comparison.
 */
export function buildComposedComparerFromComparerObjects(
  objects: Comparable[],
): (first?: any, second?: any) => number {
  return objects.reduce(
    (composed, object) => (itemA, itemB) => {
      const valueSoFar = composed(itemA, itemB)
      if (valueSoFar === 0) {
        const { key } = object
        const sortDirection = object.sortDirection || 'asc'
        const isSortingAscending = sortDirection === 'asc'
        const first = get(itemA, key)
        const second = get(itemB, key)
        return object.compareFunction(isSortingAscending, first, second)
      }
      return valueSoFar
    },
    (_first, _second) => 0,
  )
}
