import Service from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { runTask } from 'ember-lifeline'

export class ImpressionService extends Service {
  localStorage = window.localStorage

  /**
   * A rudimentary way to allow for some level of tracking updates when local storage changes
   */
  @tracked updateCount = 0

  protected readonly prefix = 'district-ui-client:impression'

  protected fullKey(id: string): string {
    return `${this.prefix}:${id}`
  }

  make = (id: string) => {
    if (!this.wasMade(id)) {
      this.localStorage.setItem(this.fullKey(id), 'true')
      // update in next render, to avoid it updating this value & reading it in the same computation
      runTask(this, () => (this.updateCount += 1))
    }
  }

  wasMade = (id: string): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.updateCount // ensure this function is called again for updates to storage (if in tracking context)
    return this.localStorage.getItem(this.fullKey(id)) === 'true'
  }

  notMade = (id: string): boolean => {
    return !this.wasMade(id)
  }
}

export default ImpressionService

declare module '@ember/service' {
  interface Registry {
    impression: ImpressionService
  }
}
