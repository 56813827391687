import type BulkManager from 'district-ui-client/utils/bulk-manager'
// This currently seems to be the best way to import some ember-data types
// https://docs.ember-cli-typescript.com/ember-data/models
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data'

type SerializedSnapshot = Partial<Record<string | number | symbol, unknown>> & {
  data?: unknown
}

export function createRecordBulk(snapshot: DS.Snapshot, bulkManager: BulkManager) {
  // the last line of the JSONApiSerializer.serialize() function will wrap the serialized model in a `data` key,
  // we are batching the create request hence the only `data` key in the payload should be the top level one e.g:
  //
  // {
  //   data: [serializedRecord, serializedRecord, ...]
  // }
  //
  // Use destructuring to unwrap each serialized model from the `data` key.
  //
  const serialized: SerializedSnapshot = snapshot.serialize({})

  return bulkManager.callBulkAction.perform('POST', serialized.data ? serialized.data : serialized)
}

export function updateRecordBulk(_store: unknown, _type: unknown, snapshot: DS.Snapshot, bulkManager: BulkManager) {
  const { data }: SerializedSnapshot = snapshot.serialize({})
  if (data && typeof data === 'object') (data as Record<string, unknown>).id = snapshot.id

  return bulkManager.callBulkAction.perform('PATCH', data)
}

export function deleteRecordBulk(_store: unknown, _type: unknown, snapshot: DS.Snapshot, bulkManager: BulkManager) {
  // Build an object with id and model name
  const data = {
    id: snapshot.id,
    type: snapshot.modelName,
  }

  const additionalBulkData = snapshot.adapterOptions?.additionalBulkData

  if (additionalBulkData && typeof additionalBulkData === 'object') {
    return bulkManager.callBulkAction.perform('DELETE', data, additionalBulkData as Record<string, unknown>)
  } else {
    return bulkManager.callBulkAction.perform('DELETE', data)
  }
}
