import { hasJsonApiErrorMatching, type JsonApiError } from 'district-ui-client/errors/json-api-errors'

export const EmailExistsError = {
  title: 'has already been taken',
  status: 422,
  code: 'unique',
  detail: 'email has already been taken',
} as const satisfies JsonApiError

const isEmailExistsError = (maybeLoginError: JsonApiError): maybeLoginError is typeof EmailExistsError =>
  Boolean(
    String(maybeLoginError.status) === String(EmailExistsError.status) &&
      maybeLoginError.detail === EmailExistsError.detail &&
      maybeLoginError.code === EmailExistsError.code,
  )

export const hasEmailExistsError = (errorPayload: unknown) => hasJsonApiErrorMatching(errorPayload, isEmailExistsError)
