import { template as template_38a3027a705b4b6fa133cdcbc2add06c } from "@ember/template-compiler";
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { ChartsStoriesWrittenTotals } from 'district-ui-client/components/charts/stories-written/totals/component';
import { t } from 'ember-intl';
import { not } from 'ember-truth-helpers';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import type ReStoriesWrittenRoute from 'district-ui-client/routes/reporting/ui-scope/re/stories-written';
import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<ReStoriesWrittenRoute>;
    };
}
export const ReportingReStoriesWrittenRouteTemplate: TOC<Signature> = template_38a3027a705b4b6fa133cdcbc2add06c(`
  <DetailedPageWrapper @empty={{not @model.storiesWrittenTotals}}>
    {{#each @model.storiesWrittenTotals.stories_written_totals as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsStoriesWrittenTotals
          class="h-full"
          @data={{gradeData}}
          @title={{t "reporting.chartTitles.storiesWrittenTotals"}}
          @tooltip={{t "reporting.tooltips.storiesWrittenTotals"}}
          @grade={{fullGradeName gradeData.grade_position}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReportingReStoriesWrittenRouteTemplate);
