import { template as template_5f8207af705146eeb8bcba95be65cc05 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
export const CleverPage: TOC<unknown> = template_5f8207af705146eeb8bcba95be65cc05(`
  <div class="mx-8 my-10">
    {{outlet}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(CleverPage);
