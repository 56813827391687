import { assert } from '@ember/debug'
import { getOwner } from '@ember/owner'
import type { Registry } from '@ember/controller'
import type Controller from '@ember/controller'
import type Owner from '@ember/owner'

function getController(context: Owner, controllerName: keyof Registry): Controller {
  const owner = getOwner(context)
  const controller = owner?.lookup(`controller:${controllerName}`)

  assert('Controller not found', controller)

  // @ts-expect-error - doesnt seem to work with ember-source types.
  return controller
}
/**
 * # Convenience decorator for query params
 *
 * By passing the name of the controller where the query params are declared,
 * we can allow access directly without needing to pass properties down through multiple
 * components.
 *
 * It also removes the need to pass both the property and a mutation function.
 *
 * It is *not* recommended to use this decorator with a dynamic controller name.
 *
 * *NOTE* query parameters still need to be declared in the Controller as well
 * (and the Route if you want model refreshing).
 *
 * *NOTE* The declared parameters should be marked as `@tracked` on the Controller.
 * ## Example
 *
 * ```ts
 * import { queryParam } from 'district-ui-client/decorators/query-param';
 *
 * class MyService extends Service {
 *   @queryParam('manage/students/import/error') someProperty: string;
 * }
 * ```
 */
export function queryParam(controllerName: keyof Registry) {
  return (_target: any, propertyKey: string): any => {
    assert('You must pass a fully qualified controller name, e.g. "manage/move-students"', controllerName)

    const result = {
      enumerable: true,
      get(this: Owner) {
        const controller = getController(this, controllerName) as Controller & { [key: string]: unknown }
        return controller[propertyKey]
      },
      set(this: Owner, value: unknown) {
        const controller = getController(this, controllerName) as Controller & { [key: string]: unknown }
        controller[propertyKey] = value
      },
    }
    return result
  }
}
