import Service, { service } from '@ember/service'
import { Product } from 'district-ui-client/domain/product'
import config from 'district-ui-client/config/environment'
import type AuthToken from '@blakeelearning/auth/services/auth-token'
import type { Log } from '@blakeelearning/log'
import { join, joinQueryParams, type QueryParams } from 'district-ui-client/utils/uri'
import { startOfDay, endOfDay, type NormalizedInterval, format } from 'date-fns'
import { type UIScope } from 'district-ui-client/domain/ui-scope'
import type DateFilterService from '@blakeelearning/dates/services/date-filter'
import type RouterService from '@ember/routing/router-service'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type ImpressionService from 'district-ui-client/services/impression'
import type { IntlService } from 'ember-intl'
import { isKeyOf } from 'district-ui-client/utils/type-guards'
import downloadjs from 'downloadjs'

export type RequestScopeName = 'district' | 'school'

/**
 * Represents the scope in which we request data. Similar to UIScope, but not representing the UI.
 */
export class RequestScope {
  id: string

  scope: RequestScopeName

  schoolIds?: string[]

  constructor(uiScope: UIScope) {
    this.id = uiScope.id
    this.scope = uiScope.scope
    this.schoolIds = uiScope.subScopes?.filter((s) => s.scope === 'school').map((s) => s.id)
  }

  get path() {
    return `${this.scope}/${this.id}`
  }

  get queryParams() {
    if (this.schoolIds?.length) return { filter: { 'school-ids': this.schoolIds } }
  }
}

interface ReportingBuildQueryArgs {
  /**
   * Provide if the request scope has any query params that need to go into the request
   */
  requestScope?: RequestScope
  sort?: ReportingQuerySort
  page?: ReportingQueryPage
  filter?: ReportingQueryFilter
  period?: NormalizedInterval
  studentGrade?: string
  contentLevel?: string
}

export type ReportingQuerySort = string

export type ReportingQueryPage =
  | {
      number: number
      size: number
    }
  | 'all'

export type ReportingQueryFilter = QueryParams

interface ReportingQuery extends QueryParams {
  sort?: ReportingQuerySort
  page?: ReportingQueryPage
  filter?: ReportingQueryFilter
}

export type BaseReport = object

export interface BaseReportMetaPaging {
  page_number: number
  page_size: number
  total_entries: number
  total_pages: number
}

export interface BaseReportMetaStandardsSet {
  standards_set_id: Nullable<string>
}

/**
 * A standard date formatter for the reporting area
 */
export function formatDate(date: Date) {
  const formatter = Intl.DateTimeFormat(window.navigator.language, { day: 'numeric', month: 'short', year: 'numeric' })
  return formatter.format(date)
}

/**
 * To help generate standard filenames for exports - file extension to be added based on the format you want.
 */
export function exportFilename(product: Product, userFriendlyReportName: string, ext: string) {
  const date = format(new Date(), 'yyyy-MM-dd_HH.mm.ss')
  return `${product}_${userFriendlyReportName}_${date}${ext}`
}

export default class ReportingService extends Service {
  @service protected authToken!: AuthToken

  @service protected log!: Log

  @service protected dateFilter!: DateFilterService

  @service protected router!: RouterService

  @service protected activeRoute!: ActiveRouteService

  @service protected intl!: IntlService

  @service protected impression!: ImpressionService

  get uiScope() {
    return this.activeRoute.reportingUiScope
  }

  /**
   * Provides the current product indicated by the URL, if within the reporting area.
   */
  get product() {
    return this.activeRoute.reportingProduct
  }

  /**
   * The grade of student to filter report results by (each student has a grade position that they are at, eg grade 1)
   */
  get studentGrade() {
    return this.activeRoute.reportingStudentGrade ?? 'all'
  }

  /**
   * The level of content to filter report results reports by (eg, lesson 10 might be intended as grade 3 knowledge,
   * but students of any grade can attempt it)
   */
  get contentLevel() {
    return this.activeRoute.reportingContentLevel ?? 'all'
  }

  /**
   * Each products' content has a set of grades it targets. This will be used for the content level filtering options.
   */
  get productContentLevels() {
    switch (this.product) {
      case Product.RE:
      case Product.FP:
        return [1, 2, 3]
      case Product.REX:
        return [2, 3, 4, 5, 6, 7]
      case Product.MS:
        return [1, 2, 3, 4]
      default:
        return []
    }
  }

  /**
   * Provides the selected date period for filtering
   */
  get period(): NormalizedInterval<Date> {
    return this.dateFilter.period
  }

  get periodDateRange(): string {
    return this.dateFilter.dateRange
  }

  get periodNameKey(): keyof this['periodNames'] | undefined {
    const [dateRangeType, ...rest] = this.dateFilter.dateRange.split(':')
    if (dateRangeType === 'named-period') {
      const [periodNameKey] = rest
      if (isKeyOf(this.periodNames, periodNameKey)) return periodNameKey
    }
  }

  get periodNames() {
    return {
      'this-week': this.intl.t('presetDateRangeLabels.thisWeek'),
      'last-7-days': this.intl.t('presetDateRangeLabels.last7Days'),
      'this-month': this.intl.t('presetDateRangeLabels.thisMonth'),
      'last-90-days': this.intl.t('presetDateRangeLabels.last90Days'),
      'this-year': this.intl.t('presetDateRangeLabels.thisYear'),
      'last-year': this.intl.t('presetDateRangeLabels.lastYear'),
    } as const
  }

  buildGravityUrl({
    requestScope,
    product,
    reportName,
  }: {
    requestScope: RequestScope
    product: Product
    reportName: string
  }): string {
    const productPath = String(product)

    const joined = join(config.gravityV3Url, 'district-ui/reporting', requestScope.path, productPath, reportName)
    const url = joined.href

    return url
  }

  /* Dates provided here for the period are only meant to be taken for their year/month/day values. The user only
   * selects _dates_ from the date picker (not times), and as such they should be inclusive for both start & end.
   * This should calculation should be based on the timezone the user is currently in (i.e. what the date already
   * has applied to it), _and then_ converted to an ISO string as the last step before handing to the backend to use
   * for searching events.
   *
   * For example;
   *  period.end = new Date(2014, 9, 10)  => Fri Oct 10 2014 00:00:00 GMT+1000
   *  endOfDay(period.end)                => Fri Oct 10 2014 23:59:59 GMT+1000
   *  endOfDay(period.end).toISOString()  => 2014-10-10T13:59:59.999Z
   */
  buildQueryPeriod({ start, end }: NormalizedInterval) {
    return {
      start: startOfDay(new Date(start)).toISOString(),
      end: endOfDay(new Date(end)).toISOString(),
    }
  }

  /**
   * Every arg is optional - if provided, it'll be set in the appropriate place in the query.
   */
  buildQuery(queryArgs: ReportingBuildQueryArgs): ReportingQuery {
    const { requestScope, sort, page, filter, period, studentGrade, contentLevel } = queryArgs

    const query: ReportingQuery = {}
    if (sort) query.sort = sort
    if (page) query.page = page
    if (filter) query.filter = filter
    if (requestScope?.queryParams?.filter) {
      query.filter = { ...(query.filter ?? {}), ...requestScope.queryParams.filter }
    }
    if (period) {
      query.filter = { ...(query.filter ?? {}), period: this.buildQueryPeriod(period) }
    }
    if (studentGrade && studentGrade !== 'all') {
      query.filter = { ...(query.filter ?? {}), 'student-grades': [studentGrade] }
    }
    if (contentLevel && contentLevel !== 'all') {
      query.filter = { ...(query.filter ?? {}), 'content-levels': [contentLevel] }
    }

    return query
  }

  get headers() {
    return { Authorization: this.authToken.token ?? '' }
  }

  async fetchReport<R>(url: string, query: ReportingQuery): Promise<R | undefined> {
    try {
      const response = await fetch(joinQueryParams(url, query), {
        method: 'GET',
        headers: {
          ...this.headers,
          Accept: 'application/json',
        },
      })

      if (response.ok) {
        return (await response.json()) as R
      } else {
        // Some unknown error. Log it.
        this.log.error(`Failed to fetch student events report; Reason: (${response.status})`, url, query)
      }
    } catch (e: any) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        // Fetch aborted by user action, eg closed tab, stop button, network outage. Do nothing.
      } else {
        this.log.error(`Failed to fetch student events report; Reason: (error thrown)`, e, url, query)
      }
    }
    // we've done our best by reporting the error(s),
    // the caller should handle an undefined result.
    return undefined
  }

  // settings as property on service allows for stubbing in tests
  downloadjs = downloadjs

  async exportReportCsv(url: string, query: ReportingQuery, filename = 'export.csv'): Promise<void> {
    try {
      const response = await fetch(joinQueryParams(url, query), {
        method: 'GET',
        headers: {
          ...this.headers,
          Accept: 'text/csv',
        },
      })

      if (response.ok) {
        const blob = await response.blob()
        this.downloadjs(blob, filename, 'text/csv')
        return
      } else {
        // Some unknown error. Log it.
        this.log.error(`Failed to export student events report; Reason: (${response.status})`, url, query)
      }
    } catch (e: any) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        // Fetch aborted by user action, eg closed tab, stop button, network outage. Do nothing.
      } else {
        this.log.error(`Failed to export student events report; Reason: (error thrown)`, e, url, query)
      }
    }
  }

  /**
   * Makes an impression for the current report path
   */
  makeImpression = () => {
    if (this.activeRoute.reportingPath) this.impression.make(this.activeRoute.reportingPath)
  }
}

declare module '@ember/service' {
  interface Registry {
    reporting: ReportingService
  }
}
