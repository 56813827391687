import Route from '@ember/routing/route'
import { service } from '@ember/service'

export default class CleverDashboardRoute extends Route {
  @service clever

  @service store

  async model() {
    const { store } = this
    const { subscriptionType } = this.modelFor('subscription-type')
    const { blakeDistrict } = this.modelFor('clever')
    const districtId = blakeDistrict.id
    // clever route ensures clever-district exists
    const { cleverDistrict } = blakeDistrict.cleverDistrictMatch

    const cleverQueries = [
      store.query('clever/school', {
        scope: `districts/${districtId}`,
        include: 'contact-detail,school-subscription-utilisations',
      }),
      store.query('clever/clever-school', { scope: `clever-districts/${cleverDistrict.id}` }),
    ]

    const promiseResult = await Promise.all(cleverQueries)
    const [_schools, cleverSchools] = promiseResult

    const cleverSchoolsForSubscriptionType = cleverSchools.filter((cleverSchool) => {
      return (
        (subscriptionType === 'reading' && cleverSchool.readingCleverApp) ||
        (subscriptionType === 'maths' && cleverSchool.mathsCleverApp)
      )
    })

    return {
      cleverSchools: cleverSchoolsForSubscriptionType,
      cleverDistrict,
      subscriptionType,
      blakeDistrict,
    }
  }
}
