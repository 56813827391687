import { helper } from '@ember/component/helper'
import { isSubscriptionType } from 'district-ui-client/domain/subscription-type'
import type { ThemeKeys } from 'district-ui-client/utils/themes'
import { subscriptionTypeToThemeKey } from 'district-ui-client/utils/themes'

interface Signature {
  Args: {
    Positional: [subscriptionType: unknown]
  }
  Return: ThemeKeys | undefined
}

/**
 * Provide a subscription type, get back a theme key. This theme key can be used to make color-based UI decisions in JS.
 *
 * {{theme-key "reading"}} -> "blue"
 * {{theme-key "maths"}} -> "green"
 */
export const themeKey = helper<Signature>(function ([subscriptionType]) {
  return isSubscriptionType(subscriptionType) ? subscriptionTypeToThemeKey(subscriptionType) : undefined
})

export default themeKey
