import type Store from '@ember-data/store'
import Controller from '@ember/controller'
import { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import type ManageTeachersIndexRoute from 'district-ui-client/routes/subscription-type/manage/teachers'
import { trackedFunction } from 'reactiveweb/function'
import type { SelectOption } from 'district-ui-client/components/form-components/form-select/option'
import type SessionService from 'district-ui-client/services/session'

export interface SchoolOption extends SelectOption {
  uid: string
  id: string
  text: string
  value: string
  schoolCode: string
}

export default class ManageTeachersIndexController extends Controller<ModelFor<ManageTeachersIndexRoute>> {
  @service store!: Store
  @service session!: SessionService

  queryParams = ['schoolId']

  @tracked schoolId?: string = undefined

  get teachersQuery() {
    if (this.schoolId) {
      return {
        scope: `schools/${this.schoolId}`,
        filter: { 'subscription-type': this.model.subscriptionType },
        include: [
          'school',
          'school.school-subscriptions', // for the active school filter below
          'school.school-subscriptions.subscription', // to ensure gravity has all data for school-subscription view
          'school-classes',
          'school-classes.students',
          'teacher-subscriptions',
        ].join(','),
      }
    }

    return {
      scope: `districts/${this.session.currentDistrict.id}`,
      filter: { 'subscription-type': this.model.subscriptionType }, // only teachers that have a school-class for this sub
      include: [
        'school',
        'school.school-subscriptions', // for the active school filter below
        'school.school-subscriptions.subscription', // to ensure gravity has all data for school-subscription view
        'school-classes',
        'school-classes.students',
        'teacher-subscriptions',
      ].join(','),
    }
  }

  queryTeachers = trackedFunction(this, async () => {
    return await this.store.query('teacher', this.teachersQuery)
  })

  get teacherQueryResult() {
    return (
      this.queryTeachers.value?.filter(
        (teacher) => !teacher.isDeleted && teacher.school?.hasActiveSchoolSubscriptionFor(this.model.subscriptionType),
      ) ?? []
    )
  }

  get teacherQueryPending() {
    return this.queryTeachers.isPending
  }

  get sortedSchoolsOptions() {
    const sortedSchools = this.model.schoolsForSubType.slice().sort((a, b) => a.name.localeCompare(b.name))

    return sortedSchools.map((school) => {
      const { id, name, schoolCode } = school
      return {
        uid: id,
        id,
        text: name,
        value: id,
        schoolCode,
      }
    })
  }

  get selectedSchoolOption(): SchoolOption | undefined {
    return this.sortedSchoolsOptions.find((opt) => opt.id === this.schoolId)
  }

  selectSchool = (option?: SchoolOption) => {
    this.schoolId = option?.id
  }
}
