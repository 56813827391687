import Ember from 'ember'
import type Rollbar from 'rollbar'
import { assert } from '@ember/debug'

let oldOnError = Ember.onerror

let rollbar: Rollbar | null = null

/**
 * Get the application's rollbar instance
 */
export function getRollbar(): Rollbar {
  assert('Rollbar has not been initialised', rollbar !== null)
  return rollbar
}

/**
 * Installs rollbar into Ember's error handling
 */
export function installRollbar(rollbarInstance: Rollbar): void {
  if (rollbar) {
    rollbar = rollbarInstance
    return
  }

  rollbar = rollbarInstance
  oldOnError = Ember.onerror

  Ember.onerror = (...args) => {
    const [error] = args

    rollbar?.error(error)

    if (typeof oldOnError === 'function') {
      oldOnError(...args)
    } else if (!rollbar?.options.enabled || Ember.testing) {
      throw error
    }
  }
}

/**
 * Uninstalls rollbar from Ember's error handling
 */
export function uninstallRollbar(): void {
  rollbar = null

  Ember.onerror = oldOnError
}
