import type { PositionalArgs } from 'ember-modifier'
import Modifier from 'ember-modifier'
import { type SelectOption } from '@blakeelearning/ember-select/index'
import type { MultiSelectState } from '@blakeelearning/ember-select/state/multi-select-state'

interface Signature<T extends SelectOption> {
  Args: {
    Positional: [state: MultiSelectState<T>, option?: T]
  }

  Element: HTMLElement
}

/**
 * Get the input element
 * - either the provided element if it is an input, or one within it (in the case of a <label><input></label>)
 */
function getInput(element: HTMLElement): HTMLInputElement | undefined {
  if (element instanceof HTMLInputElement) return element

  const search = element.querySelector('input')
  if (search instanceof HTMLInputElement) return search
}
/**
 * Modifier that makes an element behave like a checkbox.
 *
 * ## Usage
 *
 * When applied to an element makes it a an checkbox.
 *
 * Clicking on the element will select the option.
 *
 * It applies `aria-checked` if the option is checked.
 *
 * The aria attributes can also be targeted to style the element based on the state.
 *
 * ```hbs
 * import { MakeCheckboxOption } from '@blakeelearning/ember-select/modifiers/make-checkbox-option';
 *
 * <label {{MakeCheckboxOption state option}} class="focus:border focus:border-blue-600 focus:font-semibold aria-checked:border aria-checked:border-green-400">
 *  <span>{{option.label}}</span>
 *  <input type="checkbox" />
 * </label>
 * ```
 *
 * If not provided an option, will be treated as an ALL option toggle
 * ```hbs
 * <label {{MakeCheckboxOption state}} class="...">
 *  <span>Select All</span>
 *  <input type="checkbox" />
 * </label>
 * ```
 */

export class MakeCheckboxOption<T extends SelectOption> extends Modifier<Signature<T>> {
  hasSetup = false

  override modify(element: HTMLElement, positional: PositionalArgs<Signature<T>>) {
    const [state, option] = positional

    if (!this.hasSetup) {
      this.setup(element, state, option)
    }

    let checked = false
    if (option) {
      checked = state.args.selectedItems.includes(option.value)
    } else {
      checked = state.isAllSelected
    }
    element.ariaChecked = String(checked)

    const input = getInput(element)
    if (input) {
      input.checked = checked
    }
  }

  setup(element: HTMLElement, state: MultiSelectState<T>, option?: T) {
    element.role = element.role ?? 'option'
    element.tabIndex = 0

    element.addEventListener('click', (event: Event) => {
      if (option) {
        state.toggleOption(option)
      } else {
        state.toggleAll()
      }
      // prevent default, to avoid nested input (if present) from doing anything
      event.preventDefault()
    })
    element.addEventListener('keydown', (event) => {
      if (!(event instanceof KeyboardEvent)) return
      switch (event.key) {
        case 'Enter':
        case ' ':
          event.preventDefault() // have taken action, avoid the key triggering other actions
          if (option) {
            state.toggleOption(option)
          } else {
            state.toggleAll()
          }
          break
      }
    })

    /* If this element _contains_ an input (eg a <label><input></label> setup), remove the input from the focus tree and
     * don't let it be clickable either. It's only there to look the part.
     */
    const inputEl = element.querySelector('input[type="checkbox"]')
    if (inputEl && inputEl instanceof HTMLInputElement) {
      inputEl.tabIndex = -1
      inputEl.style.pointerEvents = 'none' // The surrounding element will capture the click
    }

    this.hasSetup = true
  }
}
