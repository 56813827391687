import Helper from '@ember/component/helper'
import { service } from '@ember/service'
import type FeatureControlService from 'district-ui-client/services/feature-control'
import type { FeatureAccessId } from 'district-ui-client/services/feature-control'

interface Signature {
  Args: {
    Positional: [FeatureAccessId]
  }
  Return: boolean
}

export class canAccess extends Helper<Signature> {
  @service featureControl!: FeatureControlService

  compute([featureAccessId]: Signature['Args']['Positional']) {
    return this.featureControl.canAccess(featureAccessId)
  }
}

export default canAccess
