import ApplicationJSONAPIAdapter from 'district-ui-client/adapters/application'
import { service } from '@ember/service'
import config from 'district-ui-client/config/environment'
import { adapterHostAndNamespace } from 'district-ui-client/utils/uri'

const url = new URL(config.gravityCsvUrl)
export const { host, namespace } = adapterHostAndNamespace(url)

export default class CsvApiAdapter extends ApplicationJSONAPIAdapter {
  host = host

  namespace = namespace

  @service authToken

  get headers() {
    return {
      Authorization: this.authToken.token,
    }
  }
}
