import Model, { attr } from '@ember-data/model'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'

export default class PartnerContactDetail extends Model {
  /* A partner can have different details per subscription type. This indicates which subtype these details are for,
   * for that partner. Note that a school often has multiple partners (potentially one per subtype as well)
   *
   * In order to choose the correct details you need for your subtype, first lookup the correct school-partner for your
   * subtype, then partner, then choose the correct contact details record for that partner for your subtype.
   *
   * eg, for maths:
   * school -> school-partner['maths'] -> partner -> partner-contact-details['maths']
   */
  @attr('string') subscriptionType!: SubscriptionType

  // School Support
  @attr('string') schoolSupportEmail!: string

  @attr('string') schoolSupportPhone!: string

  @attr('string') schoolSupportPhoneHours!: string

  @attr('boolean') schoolSupportPhoneTollFree!: boolean

  // School Sales

  @attr('string') schoolSalesEmail!: string

  @attr('string') schoolSalesPhone!: string

  @attr('string') schoolSalesPhoneHours!: string

  @attr('boolean') schoolSalesPhoneTollFree!: boolean

  // Note there are also parent contact fields available, but they are not needed for TUI.

  @attr('string') cleverSupportEmail!: string

  @attr('string') address!: string

  get schoolSupport() {
    return {
      email: this.schoolSupportEmail,
      phone: this.schoolSupportPhone,
      phoneHours: this.schoolSupportPhoneHours,
      phoneTollFree: this.schoolSupportPhoneTollFree,
      address: this.address,
    }
  }

  get schoolSales() {
    return {
      email: this.schoolSalesEmail,
      phone: this.schoolSalesPhone,
      phoneHours: this.schoolSalesPhoneHours,
      phoneTollFree: this.schoolSalesPhoneTollFree,
      address: this.address,
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'partner-contact-detail': PartnerContactDetail
  }
}
