// other error definitions can extend from this
export interface JsonApiError {
  title?: string
  status?: string | number
  code?: string | number
  detail?: string
}

export interface JsonApiErrorPayload {
  errors: JsonApiError[]
}

export function isJsonApiErrorPayload(maybeErrorPayload: unknown): maybeErrorPayload is JsonApiErrorPayload {
  return Boolean(
    maybeErrorPayload &&
      typeof maybeErrorPayload === 'object' &&
      'errors' in maybeErrorPayload &&
      Array.isArray(maybeErrorPayload.errors) &&
      maybeErrorPayload.errors.every((e) => e && typeof e === 'object'),
  )
}

export function hasJsonApiErrorMatching(maybeErrorPayload: unknown, predicate: (error: JsonApiError) => boolean) {
  return isJsonApiErrorPayload(maybeErrorPayload) && maybeErrorPayload.errors.some(predicate)
}
