import type Rollbar from 'rollbar'
import { getRollbar } from '../utils/rollbar.ts'
import type { LogArgument, LogResult, Person } from '../log-adapters/base.ts'
import BaseAdapter from '../log-adapters/base.ts'

export default class RollbarAdapter extends BaseAdapter {
  rollbar: Rollbar = getRollbar()

  override setPerson(person: Person): void {
    this.rollbar.configure({ payload: { person } })
  }

  override critical(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this.rollbar.critical(arg, ...args)
  }

  override error(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this.rollbar.error(arg, ...args)
  }

  override warning(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this.rollbar.warning(arg, ...args)
  }

  override warn(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this.rollbar.warn(arg, ...args)
  }

  override info(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this.rollbar.info(arg, ...args)
  }

  override debug(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this.rollbar.debug(arg, ...args)
  }

  override log(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this.rollbar.log(arg, ...args)
  }

  override willDestroy(): void {
    this.rollbar.configure({ payload: {} })
  }
}

declare module '@blakeelearning/log' {
  interface LogAdapterRegistry {
    rollbar: RollbarAdapter
  }
}
