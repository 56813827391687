import Model, { attr } from '@ember-data/model'

export default class ImportValidation extends Model {
  @attr('string') status!: string

  get isComplete() {
    return this.status === 'complete'
  }

  get hasFailed() {
    return this.status === 'failed'
  }

  get isScheduled() {
    return this.status === 'scheduled'
  }

  get isBusy() {
    return this.status === 'busy'
  }

  get shouldRetry() {
    return this.status === 'retry'
  }

  get isStillRunning() {
    return this.isScheduled || this.isBusy || this.shouldRetry
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'import-validation': ImportValidation
  }
}
