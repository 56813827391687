import { template as template_210e48683d3c492a865b296ced46b6d7 } from "@ember/template-compiler";
import { ChartsAssessmentScoresTotals } from 'district-ui-client/components/charts/assessment-scores/totals';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { not } from 'ember-truth-helpers';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import type MsQuizzesCompletedRoute from 'district-ui-client/routes/reporting/ui-scope/maths/quizzes-completed';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<MsQuizzesCompletedRoute>;
    };
}
export const MsQuizzesCompletedRouteTemplate: TOC<Signature> = template_210e48683d3c492a865b296ced46b6d7(`
  <DetailedPageWrapper @empty={{not @model.assessmentScoresTotals}}>
    {{#each @model.assessmentScoresTotals.assessment_scores_totals as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsAssessmentScoresTotals
          class="h-full"
          @data={{gradeData}}
          @product={{@model.product}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.assessmentScoresTotals"}}
          @title={{t "reporting.chartTitles.assessmentScoresTotals.maths"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(MsQuizzesCompletedRouteTemplate);
