import Route from '@ember/routing/route'
import { service } from '@ember/service'
import { didCancel } from 'ember-concurrency'

export default class CleverDashboardUnsyncedRoute extends Route {
  @service clever

  queryParams = {
    textFilter: {
      replace: true,
    },
    sort: {
      replace: true,
    },
    selected: {
      replace: true,
    },
  }

  model() {
    const { cleverDistrict, cleverSchools, subscriptionType } = this.modelFor('clever.dashboard')
    return { cleverDistrict, cleverSchools, subscriptionType }
  }

  afterModel(model, _transition) {
    const { cleverDistrict, cleverSchools } = model
    try {
      this.clever.pollForCleverSchools.perform(cleverDistrict.id, cleverSchools)
    } catch (e) {
      if (!didCancel(e)) throw e
    }
  }

  deactivate() {
    this.clever.pollForCleverSchools.cancelAll()
  }
}
