import { tracked } from '@glimmer/tracking'
import Service, { service } from '@ember/service'
import { isNone } from '@ember/utils'
import { assert } from '@ember/debug'
import type AuthToken from '@blakeelearning/auth/services/auth-token'
import config from 'district-ui-client/config/environment'

interface RawGrade {
  id: number
  name: string
  full_name: string
  position: number
}

export interface Grade {
  name: string
  fullName: string
  position: number
}

export interface GradeSetsResponse {
  grade_set: {
    name: string
    country_id: number
    grades: RawGrade[]
  }
}

export default class GradeSetsService extends Service {
  @service authToken!: AuthToken

  @tracked grades: Grade[] = []

  /**
   * This is the name of the loaded gradeset, for example "Australia - ACT,NSW,TAS - 0,K,1,2,3,4,5,6,7,8,9,10,11,12"
   */
  @tracked
  name: Nullable<string> = null

  get gradePositions() {
    return this.grades.map((g) => g.position)
  }

  get gradeNames() {
    return this.grades.map((g) => g.name)
  }

  async loadGradeSets() {
    const response = await fetch(`${config.readingEggsV1Url}grade_set`, {
      method: 'GET',
      headers: {
        Authorization: this.authToken.token ?? '',
      },
    })
    if (!response.ok) throw await response.text()

    const result: GradeSetsResponse | undefined = await response.json()
    assert('grade_set must return a response', result)
    if (!result) return result

    const {
      grade_set: { name, grades },
    } = result
    const mappedGrades = grades.map((grade) => ({
      position: grade.position,
      name: grade.name,
      fullName: grade.full_name,
    }))
    this.grades = mappedGrades
    this.name = name

    return response
  }

  /**
   * Find a grade by the grade name
   * Searches by both name and full name.
   * If grade isn't found returns an empty object as the result of
   * findByPosition is destructed in various use cases without checking for a
   * nullish value.
   *
   * @param  {String} name grade name to look for
   * @return {Grade}
   */
  findByName(name: string): Grade | Record<string, never> {
    let grade = this.grades.find((g) => g.name === name)

    if (isNone(grade)) {
      grade = this.grades.find((g) => g.fullName === name)
    }

    return grade ?? {}
  }

  /**
   * Find a grade by the grade position
   * If grade isn't found returns an empty object as the result of
   * findByPosition is destructed in various use cases without checking for a
   * nullish value.
   *
   * @param  {Number} position grade position to look for
   * @return {Grade}
   */
  findByPosition(position: number): Grade | Record<string, never> {
    const { grades } = this
    const grade = grades.find((g) => g.position === position)

    return grade ?? {}
  }

  nameFromGradeSetByPosition(position: number): string | undefined {
    return this.findByPosition(position)?.name
  }
}

declare module '@ember/service' {
  interface Registry {
    'grade-sets': GradeSetsService
  }
}
