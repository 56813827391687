import Service, { service } from '@ember/service'
import { action } from '@ember/object'
import { timeout, task } from 'ember-concurrency'
import ENV from 'district-ui-client/config/environment'
import { join } from 'district-ui-client/utils/uri'

const second = 1000

export const ErrorCodes = {
  EXISTS_IN_DIFFERENT_DISTRICT: 'exists-in-different-district',
  EXISTS_AS_PARENT: 'exists-as-parent',
  EXISTS_AS_PARENT_CONTACT: 'exists-as-parent-contact',
  ID_MISMATCH_ERROR: 'id-mismatch-error',
  STALE_ACCOUNT_ERROR: 'stale-account-error',
  INVALID_EMAIL_CHAR: 'invalid-email-char',
}

export default class TeacherMatchErrorService extends Service {
  @service store

  @service authToken

  @service router

  /**
   * @param {String} cleverSchoolId
   * @param {String} cleverTeacherId
   * @returns {String}
   * @example```js
   * _teacherMatchRemedyUrl('1', '2')
   * > clever.host/api/v1/clever-schools/1/clever-teachers/2/remedy`
   * ```
   */
  _teacherMatchRemedyUrl(cleverSchoolId, cleverTeacherId) {
    return join(ENV.cleverV1Url, `clever-schools/${cleverSchoolId}/clever-teachers/${cleverTeacherId}/remedy`).href
  }

  /**
   * Makes a remote request to remedy the teacher match error.
   * @param {CleverTeacherModel} cleverTeacher
   * @param {Object} cleverTeacher.matchError
   * @returns { ok: Boolean, error: Object | undefined }
   * { ok: true } if remedy successful.
   * { ok: false, error } if remedy unsuccessful.
   */
  @action
  async teacherMatchErrorRemedy(cleverTeacher) {
    const cleverSchoolId = cleverTeacher.cleverSchool.id
    const cleverTeacherId = cleverTeacher.id
    const url = this._teacherMatchRemedyUrl(cleverSchoolId, cleverTeacherId)

    const response = await fetch(url, {
      method: 'POST',
      headers: { Authorization: this.authToken.token, 'content-type': 'application/vnd.api+json' },
      body: JSON.stringify({ data: cleverTeacher.matchError }),
    })

    if (response.ok) return { ok: true }
    return { ok: false, error: await response.text() }
  }

  remedy = task(async (teacher, matchErrorCode, { onComplete, onError } = {}) => {
    try {
      const errorType = this.errorTypeForCode(matchErrorCode)
      if (errorType === undefined) {
        const errorMsg = `unhandled teacher match error code ${matchErrorCode}`
        this.log.error(errorMsg)
        throw Error(errorMsg)
      }
      this.assertTeacherHasErrorMetaProperties(teacher, errorType.code, errorType.metaProperties)
      if (errorType.remedy) {
        const { ok, error } = await errorType.remedy(teacher)
        if (ok) {
          await onComplete?.(teacher, matchErrorCode)
          return { ok: true }
        } else {
          throw Error(`remedy function for ${matchErrorCode} unsuccessful: ${error}`)
        }
      } else {
        throw Error(`no remedy function defined for ${matchErrorCode}`)
      }
    } catch (error) {
      await onError?.(teacher, matchErrorCode, error)
      return { ok: false, error }
    }
  })

  assertTeacherHasErrorMetaProperties(teacher, matchErrorCode, metaProperties) {
    metaProperties?.forEach((metaProperty) => {
      if (teacher.matchError[metaProperty] === undefined) {
        throw Error(
          `expected clever/teacher with teacher match error code '${matchErrorCode}' to have matchError property ${metaProperty}, instead matchError contains: ${JSON.stringify(
            teacher.matchError || {},
          )}`,
        )
      }
    })
  }

  /**
   * `metaProperties` are the properties we expect to find in the clever/teacher json meta object,
   * these properties contain error context information used when performing the error remedy.
   * `remedy` is the function called to remedy the clever teacher match error.
   */
  get errorTypes() {
    return [
      {
        code: ErrorCodes.EXISTS_IN_DIFFERENT_DISTRICT,
      },
      {
        code: ErrorCodes.EXISTS_AS_PARENT,
        remedy: this.teacherMatchErrorRemedy,
      },
      {
        code: ErrorCodes.EXISTS_AS_PARENT_CONTACT,
        remedy: this.teacherMatchErrorRemedy,
      },
      {
        code: ErrorCodes.ID_MISMATCH_ERROR,
        metaProperties: ['other-teacher-id'],
        remedy: this.teacherMatchErrorRemedy,
      },
      {
        code: ErrorCodes.STALE_ACCOUNT_ERROR,
        metaProperties: ['other-teacher-id'],
        remedy: this.teacherMatchErrorRemedy,
      },
      {
        code: ErrorCodes.INVALID_EMAIL_CHAR,
      },
    ]
  }

  errorTypeForCode(matchErrorCode) {
    return this.errorTypes.find((errorType) => errorType.code === matchErrorCode)
  }

  isValidErrorCode(matchErrorCode) {
    return this.errorTypeForCode(matchErrorCode) !== undefined
  }

  hasRemedyForCode(matchErrorCode) {
    return typeof this.errorTypeForCode(matchErrorCode)?.remedy === 'function'
  }

  // Make sure to stub this in any tests that do remedies]
  refreshIntervals = [second, 4 * second, 5 * second, 10 * second, 10 * second, 10 * second, 10 * second, 10 * second]

  /*
   * Will reload the school & teachers to ensure school sync state and all matches are up to date post-remedy.
   *
   * It will poll those records for about a minute at reduced intervals. Ideally this wouldn't be necessary, and it
   * could stop when the match error code is removed/changed on the teacher record, but unfortunately this error code
   * can be resolved before the remedy has actually finished. There isn't (currently) a way on the frontend to know when
   * a remedy starts or finishes.
   */
  postRemedyRefresh = task({ restartable: true }, async (teacher, matchErrorCode, refreshAction) => {
    const { refreshIntervals } = this

    // Kill it if user decides to leave whatever page this was started from.
    const { currentRouteName } = this.router
    const cancelRefresh = (transition) => {
      // ensure transition is more than a query param transition
      if (currentRouteName !== transition?.to?.name) {
        this.router.off('routeDidChange', cancelRefresh)
        this.postRemedyRefresh.cancelAll()
      }
    }

    try {
      this.router.on('routeDidChange', cancelRefresh)

      // Now poll for a while, backoff over time
      for (const interval of refreshIntervals) {
        await timeout(interval)
        await refreshAction()
      }
    } finally {
      this.router.off('routeDidChange', cancelRefresh)
    }
  })
}
