import Model, { belongsTo, attr } from '@ember-data/model'
import type GravitySchool from 'district-ui-client/models/clever/school'

export default class CleverContactDetail extends Model {
  @attr('string') state!: string

  @attr('string') town!: string

  @belongsTo('clever/school', { async: false, inverse: 'contactDetail' }) school?: GravitySchool
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'clever/contact-detail': CleverContactDetail
  }
}
