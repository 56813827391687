import { template as template_d7f081cf68e349b78c05f0c0e711b8e1 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { StandardsPicker } from 'district-ui-client/components/standards-picker';
import { PageTitle, SectionTitle } from 'district-ui-client/components/page-title';
import type SessionService from 'district-ui-client/services/session';
class DistrictSettingsPage extends Component<unknown> {
    @service
    session: SessionService;
    static{
        template_d7f081cf68e349b78c05f0c0e711b8e1(`
    <div class="mx-auto w-full max-w-screen-lg space-y-8">
      <PageTitle>{{t "districtSettings"}}</PageTitle>
      <section class="flex flex-col">
        <SectionTitle class="mb-6">
          {{t "stateAcademicStandards.title"}}
        </SectionTitle>
        <StandardsPicker
          class="w-full max-w-[600px] self-center"
          @district={{this.session.currentDistrict}}
          @countryCode={{this.session.currentDisco.countryCode}}
        />
      </section>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(DistrictSettingsPage);
