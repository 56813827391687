export type FilterDataItem = Record<string, unknown>
export type FilterPredicate<T> = (item: T) => boolean

export type FilterConfig<T = FilterDataItem> = {
  itemFilter: FilterPredicate<T>
}
import { get } from '@ember/object'

/**
 * This returns a filter function that returns true for items that have some text matching the text filter for some of
 * the given data keys of that item
 */
export function textFilterPredicate<T>(
  textFilter: string,
  dataKeysToFilterOn: string[],
): FilterPredicate<T> {
  // A function that takes a data item, gets data using the keys, returning all data from the item as a single data string
  // that can be matched against
  const getDataStringFromDataItem = (dataItem: T, dataKeys: string[]) => {
    const dataStringsToFilterOn = dataKeys.map((key) => {
      const dataFromItem = get(dataItem, key) ?? ''
      return `${dataFromItem}`
    })
    return dataStringsToFilterOn.join(' ').toLowerCase()
  }

  // For a given data item, this function returns true if all keywords are present in the data
  const textFilterMatches = (
    dataString: string,
    searchText: string,
  ): boolean => {
    const filterKeywords = searchText
      .toLowerCase()
      .split(' ')
      .filter((str) => str.length > 0)
    return filterKeywords.every((keyword) => dataString.match(keyword))
  }

  const filterPredicate = (dataItem: T): boolean => {
    const dataString = getDataStringFromDataItem(dataItem, dataKeysToFilterOn)
    const itemMatches = textFilterMatches(dataString, textFilter)
    return itemMatches
  }

  return filterPredicate
}
