import Service, { service } from '@ember/service'
import config from 'district-ui-client/config/environment'
import { join } from 'district-ui-client/utils/uri'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type School from 'district-ui-client/models/school'
import type AuthToken from '@blakeelearning/auth/services/auth-token'

const endpoint = join(config.readingEggsV1Url, 'district_ui', 'student_operations')

export default class StudentOperationsService extends Service {
  @service authToken!: AuthToken

  exportStudents(subscriptionType: SubscriptionType, school?: School) {
    const data = {
      command: 'export_student_details',
      product: subscriptionType,
      ...(school ? { data: { school_id: school.id } } : {}),
    }

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: this.authToken.token ?? '',
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }
}

declare module '@ember/service' {
  interface Registry {
    'student-operations': StudentOperationsService
  }
}
