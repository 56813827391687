import { SubscriptionType } from 'district-ui-client/domain/subscription-type'

export const THEME_KEYS = {
  blue: 'blue',
  green: 'green',
  default: 'default',
}

export const ThemeKeys = {
  blue: 'blue',
  green: 'green',
  default: 'default',
} as const

export type ThemeKeys = ValuesOf<typeof ThemeKeys>

/**
 * @deprecated - aim to apply tailwind classes rather than css classes
 */

export const THEME_CLASSES = {
  [THEME_KEYS.blue]: 'theme-blue',
  [THEME_KEYS.green]: 'theme-green',
  [THEME_KEYS.default]: 'theme-default',
}

/**
 * Provide a subscription type, get back a theme key. This theme key can be used to make color-based UI decisions in JS.
 *
 * subscriptionTypeToThemeKey("reading") -> "blue"
 * subscriptionTypeToThemeKey("maths") -> "green"
 */
export function subscriptionTypeToThemeKey(subscriptionType?: SubscriptionType) {
  switch (subscriptionType) {
    case SubscriptionType.Reading:
      return ThemeKeys.blue
    case SubscriptionType.Maths:
      return ThemeKeys.green
    default:
      return ThemeKeys.default
  }
}

/**
 * Type guard function to narrow type to ThemeKey
 */
export function isThemeKey(maybeThemeKey: unknown): maybeThemeKey is ThemeKeys {
  return Object.values(ThemeKeys).includes(maybeThemeKey as ThemeKeys)
}
