import { isPresent } from '@ember/utils'
import { isEmpty } from 'lodash'
import range from 'lodash/range'

const rex = {
  2: { min: 180, max: 339 },
  3: { min: 340, max: 599 },
  4: { min: 600, max: 731 },
  5: { min: 732, max: 841 },
  6: { min: 842, max: 924 },
  7: { min: 925, max: 1100 },
}

const re = {
  1: { min: 0, max: 114 },
  2: { min: 115, max: 263 },
  3: { min: 264, max: 320 },
}

const data: ProductMap = { rex, re }

export type ProductString = 'rex' | 're'

type GradePositionToMinMax = Record<string, { min: number; max: number }>

type ProductMap = Record<ProductString, GradePositionToMinMax>

/**
 * Lookup the grade position from a lexile value for a product.

 */
export function gradePositionFromLexile(product: ProductString, lexile: number): number {
  const { [product]: productData } = data
  const [gradePosition] = Object.keys(productData)
    .map((grade) => {
      const { min, max } = productData[grade]

      if (lexile >= min && lexile <= max) {
        return parseInt(grade, 10)
      }
      return null
    })
    .filter(isPresent)

  return gradePosition
}

export function gradePositionRangeFromLexiles(
  product: ProductString,
  lexiles: number[],
  positionPadding = 0,
): number[] {
  const gradePositions = lexiles
    .map((lexile) => {
      return gradePositionFromLexile(product, lexile)
    })
    .filter(isPresent)

  if (isEmpty(gradePositions)) return []

  const maxGradePosition = Math.max(...gradePositions) + positionPadding
  const minGradePosition = Math.min(...gradePositions)

  return range(minGradePosition, maxGradePosition + 1)
}

/**
 * Lookup the lexile range from a grade position for a product.

 */
export function lexileRangeFromGradePosition(
  product: ProductString,
  grade: number,
): Nullable<{ min: number; max: number }> {
  const { [product]: productData } = data

  return productData[grade] || null
}
