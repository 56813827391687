import Controller from '@ember/controller'
import { service } from '@ember/service'
import { isPresent } from '@ember/utils'

export default class CleverDashboardController extends Controller {
  @service intl

  @service store

  get subscriptionType() {
    return this.model.subscriptionType
  }

  get productName() {
    return this.intl.t(`subscriptions.${this.subscriptionType}`)
  }

  get cleverSchools() {
    return this.model.cleverSchools
  }

  get syncedSchools() {
    return this.cleverSchools.filter((school) => school.hasCompletedSync)
  }

  get unsyncedSchools() {
    return this.cleverSchools.filter((school) => !school.hasCompletedSync)
  }

  get tabCounts() {
    const unsynced = this.unsyncedSchools.length
    const synced = this.syncedSchools.length
    return { unsynced, synced }
  }

  get tabOptions() {
    const { unsynced, synced } = this.tabCounts
    const unsyncedTitle = this.intl.t('clever.dashboardTabs.unsynced', { count: unsynced })
    const syncedTitle = this.intl.t('clever.dashboardTabs.synced', { count: synced })
    return [
      { id: 'unsynced', title: unsyncedTitle, route: 'clever.dashboard.unsynced' },
      { id: 'synced', title: syncedTitle, route: 'clever.dashboard.synced' },
    ]
  }

  get hasUnsyncedSchools() {
    return this.unsyncedSchools.length !== 0
  }

  get overCapacitySchools() {
    return this.cleverSchools.filter((cleverSchool) => {
      const { blakeSchoolId } = cleverSchool
      if (isPresent(blakeSchoolId)) {
        return (
          this.store.peekRecord('clever/school', blakeSchoolId)?.utilisationFor(this.subscriptionType)
            ?.isOverCapacity ?? false
        )
      } else {
        // clever school not matched to a blake school yet, so we don't know if it's over capacity
        return false
      }
    })
  }

  get overCapacitySchoolCount() {
    return this.overCapacitySchools.length
  }
}
