import Component from '@glimmer/component'

/**
 * displays a block-level element that contains a series of five horizontal
 * bars that grow and shrink.  You can use this stand-alone to indicate that
 * something is loading.  It accepts the following arguments:

 * - `@small={Boolean}`: when set to `true`, the bars will be slightly
 *   smaller, and there will only be four of them.

 * - `@centered={Boolean}`: when set to `true` center-aligns the spinner's
 *   bars within the containing block-level element.
 *
 * @example
 *   {{#if currentlyLoading}}
 *     <Fidget::Spinner::Wave @small={{true}} @centered={{true}} />
 *   {{/if}}
 */
interface Args {
  small?: boolean
  centered?: boolean
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export class FidgetSpinnerWaveComponent extends Component<Signature> {}

export default FidgetSpinnerWaveComponent
