import { roundAccurately } from 'district-ui-client/utils/round'

/**
 * Takes a number, rounds it (accurately) and either pads or truncates to meet the given dp target.
 *
 * toFixed()'s rounding implementation is buggy. To avoid the issues, the value is accurately rounded first.
 *
 * (2.54).toFixed(1); // '2.5'
 * (2.55).toFixed(1); // '2.5' // should have rounded up to 2.6
 */
export function formatFixedDecimal(number: number, decimalPlaces: number) {
  return roundAccurately(number, decimalPlaces).toFixed(decimalPlaces)
}

/**
 * Formats a potentially large number into millions (M) and thousands (K).
 * When a non-integer value is encountered, it will round&truncate or pad the decimal by the given decimal places.
 *
 * Returns a string.
 */
export function formatNumber(number: number, decimalPlaces: number): string {
  const pow6 = 10 ** 6
  if (number > pow6) {
    const numberInMillion = number / pow6
    const decimals = Number.isInteger(numberInMillion) ? 0 : decimalPlaces
    return `${formatFixedDecimal(numberInMillion, decimals)}M`
  }

  const pow3 = 10 ** 3
  if (number > pow3) {
    const numberInThousand = number / pow3
    const decimals = Number.isInteger(numberInThousand) ? 0 : decimalPlaces
    return `${formatFixedDecimal(numberInThousand, decimals)}K`
  }

  if (Number.isInteger(number)) {
    return `${number}`
  }

  return formatFixedDecimal(number, decimalPlaces)
}
