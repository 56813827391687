import Service, { inject as service } from '@ember/service'
import { getConfig } from '@blakeelearning/get-config'
import type RefresherService from '../refresher/service'

/**
 * Allows tracking a count of events, triggered manually from the app. When the
 * maximum number has been reached, a refresh will be scheduled with the
 * refresher service.
 */
export default class EventCountCheckerService extends Service {
  @service
  declare refresher: RefresherService

  /**
   * The number of events recorded
   */
  eventCount = 0

  get _maxEventCount(): number {
    return getConfig(
      this,
      'appRefresher.eventCountChecker.maxEventCount',
      Infinity,
    )
  }

  get _enabled(): boolean {
    return getConfig(this, 'appRefresher.eventCountChecker.enabled', false)
  }

  /**
   * Increment the `eventCount`. This will schedule a refresh at the next safe
   * moment if the event count goes over the max event count.
   */
  increment(): void {
    this.eventCount += 1

    if (this._isMaxEventCountReached()) {
      this._scheduleRefresh()
    }
  }

  _scheduleRefresh(): void {
    if (this._enabled) {
      this.refresher.scheduleRefresh()
    }
  }

  _isMaxEventCountReached(): boolean {
    const maxEventCount = this._maxEventCount
    const { eventCount } = this

    return eventCount >= maxEventCount
  }
}

declare module '@ember/service' {
  interface Registry {
    'event-count-checker': EventCountCheckerService
  }
}
