import type { SyncHasMany } from '@ember-data/model'
import Model, { hasMany, belongsTo, attr } from '@ember-data/model'
import type ContactDetail from 'district-ui-client/models/contact-detail'
import type SchoolClass from 'district-ui-client/models/school-class'
import type SchoolSubscription from 'district-ui-client/models/school-subscription'
import type Teacher from 'district-ui-client/models/teacher'
import type SubscriptionCoordinator from 'district-ui-client/models/subscription-coordinator'
import type Student from 'district-ui-client/models/student'
import type SchoolSubscriptionUtilisation from 'district-ui-client/models/school-subscription-utilisation'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type SchoolRollover from 'district-ui-client/models/school-rollover'

export default class School extends Model {
  @attr('string') name!: string

  @attr('string') schoolCode!: string

  /**
   * AU, GB, US, ... ISO_3166-1 alpha-2 codes https://en.wikipedia.org/wiki/ISO_3166-1
   */
  @attr('string') countryCode!: string

  @belongsTo('contact-detail', { async: false, inverse: 'school' }) contactDetail?: ContactDetail

  @hasMany('school-subscription', { async: false, inverse: 'school' })
  schoolSubscriptions?: SyncHasMany<SchoolSubscription>

  @hasMany('school-subscription-utilisation', { async: false, inverse: 'school' })
  schoolSubscriptionUtilisations?: SyncHasMany<SchoolSubscriptionUtilisation>

  @hasMany('school-class', { async: false, inverse: 'school' }) schoolClasses?: SyncHasMany<SchoolClass>

  @hasMany('teacher', { async: false, inverse: 'school' }) teachers?: SyncHasMany<Teacher>

  @hasMany('student', { async: false, inverse: 'school' }) students?: SyncHasMany<Student>

  @hasMany('subscription-coordinator', { async: false, inverse: 'school' })
  subscriptionCoordinators?: SyncHasMany<SubscriptionCoordinator>

  @hasMany('school-rollover', { async: false, inverse: 'school' })
  schoolRollovers?: SyncHasMany<SchoolRollover>

  teachersForSubscriptionType(subscriptionType: SubscriptionType): Teacher[] {
    return this.teachers?.filter((t) => t.schoolClasses?.some((sc) => sc.subscriptionType === subscriptionType)) ?? []
  }

  utilisationFor(subscriptionType: SubscriptionType) {
    return this.schoolSubscriptionUtilisations?.find((utilisation) => utilisation.subscriptionType === subscriptionType)
  }

  // since utilisations are slow to request (due to the counts), this can be a cheaper way to determine active schools
  hasActiveSchoolSubscriptionFor(subscriptionType: SubscriptionType): boolean {
    return Boolean(
      this.schoolSubscriptions?.slice().some((sub) => sub.subscriptionType === subscriptionType && sub.isActive),
    )
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    school: School
  }
}
