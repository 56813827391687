import { hasJsonApiErrorMatching, type JsonApiError } from 'district-ui-client/errors/json-api-errors'

export const PasswordIncorrectError = {
  title: 'does not match existing password.',
  status: 422,
  code: 'error',
  detail: 'password current does not match existing password.',
} as const satisfies JsonApiError

const isPasswordIncorrectError = (
  maybePasswordError: JsonApiError,
): maybePasswordError is typeof PasswordIncorrectError =>
  Boolean(
    String(maybePasswordError.status) === String(PasswordIncorrectError.status) &&
      (maybePasswordError.detail === PasswordIncorrectError.detail ||
        maybePasswordError.code === PasswordIncorrectError.code),
  )

export const hasPasswordIncorrectError = (errorPayload: unknown) =>
  hasJsonApiErrorMatching(errorPayload, isPasswordIncorrectError)
