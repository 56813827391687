import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'

export default class CleverModalsResetConfirm extends Component {
  @tracked isResetChecked = false

  @action
  toggleResetChecked() {
    this.isResetChecked = !this.isResetChecked
  }

  @action
  setResetChecked(value) {
    this.isResetChecked = value
  }
}
