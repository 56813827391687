import Route from '@ember/routing/route'
import { service } from '@ember/service'

export default class CleverDashboardIndexRoute extends Route {
  @service router

  afterModel(resolvedModel, transition) {
    if (transition.to.name === this.routeName) {
      // want to redirect them to one of the dashboard tabs
      // if all schools are synced, default to the synced tab
      const allSynced = resolvedModel?.cleverSchools?.every((school) => school.hasCompletedSync)
      if (allSynced) {
        this.router.transitionTo('clever.dashboard.synced')
      } else {
        this.router.transitionTo('clever.dashboard.unsynced')
      }
    }
  }
}
