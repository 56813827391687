import ApplicationJSONAPIAdapter from 'district-ui-client/adapters/application'
import { service } from '@ember/service'
import config from 'district-ui-client/config/environment'
import { adapterHostAndNamespace } from 'district-ui-client/utils/uri'

const url = new URL(config.gravityV3Url)
export const { host, namespace } = adapterHostAndNamespace(url)

export default class GravityAdapter extends ApplicationJSONAPIAdapter {
  @service authToken

  host = host

  namespace = namespace

  get headers() {
    return {
      Authorization: this.authToken.token,
    }
  }

  urlForQuery(query, modelName) {
    // Prepend the scope if given
    if (query?.scope) {
      const scope = query?.scope // scope is like 'districts/123'
      delete query.scope

      const url = super.urlForQuery(query, modelName) // eg /api/v3/schools
      const pathForModelName = this.pathForType(modelName) // eg schools
      return url.replace(pathForModelName, `${scope}/${pathForModelName}`) // /api/v3/districts/123/schools - note: only replaces first occurrence
    }
    return super.urlForQuery(query, modelName)
  }
}
