import type { SortingConfig } from '@blakeelearning/data-tables/utils/sorting'

export type SortViewModel = {
  key: string
} & (
  | {
      icon: 'sort-up'
      ariaDirection: 'ascending'
    }
  | {
      icon: 'sort-down'
      ariaDirection: 'descending'
    }
  | {
      icon: 'sort'
      ariaDirection: undefined
    }
)

export function defaultSortViewModel(key: string): SortViewModel {
  return { key, icon: 'sort', ariaDirection: undefined }
}

/**
 * Map the sort config into values more useful for display.
 */
export function toSortViewModels(sortingConfig: SortingConfig): SortViewModel[] {
  return sortingConfig.columns.map((sortColumn) =>
    sortColumn.sortDirection === 'asc'
      ? {
          key: sortColumn.key,
          icon: 'sort-up',
          ariaDirection: 'ascending',
        }
      : {
          key: sortColumn.key,
          icon: 'sort-down',
          ariaDirection: 'descending',
        },
  )
}

interface Opts {
  /**
   * Set to true for single-column sort, or false for multi-column sort. The former will return default sort view models
   * for all but the primary sort.
   */
  firstOnly?: boolean
}

/**
 * Find the matching sort view model for the given key, if it is part of the sorting defined in the given sorting config
 */
export function getSortViewModel(sortingConfig: SortingConfig, sortKey: string, options: Opts = {}): SortViewModel {
  const { firstOnly = true } = options
  const sortViewModels = toSortViewModels(sortingConfig)
  const viewModels = firstOnly && sortViewModels[0] ? [sortViewModels[0]] : sortViewModels

  const found = viewModels.find(({ key }) => key === sortKey)
  return found ?? defaultSortViewModel(sortKey)
}
