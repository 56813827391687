import Model, { belongsTo, attr } from '@ember-data/model'
import { tracked } from '@glimmer/tracking'
import type CleverSchool from 'district-ui-client/models/clever/clever-school'
import type CleverStudentMatch from 'district-ui-client/models/clever/clever-student-match'

/**
 * Model class for clever data student
 *
 * @class CleverStudentModel
 * @property {string} firstName - first name of student
 * @property {string} lastName - last name of student
 * @property {string} grade - grade from clever for student
 * @property {string} sisId - unique clever id for the school
 * @property {boolean} matched - Flag to show if school is matched
 */
export default class CleverStudent extends Model {
  @attr('string') firstName!: string

  @attr('string') lastName!: string

  // From the Clever API, grades for clever records do come through as strings, but we want them as numbers.
  @attr('number') gradePosition!: number

  @attr('string') sisId!: string

  @attr('boolean') matched!: boolean

  @attr('boolean') mathsCleverApp!: boolean

  @attr('boolean') readingCleverApp!: boolean

  @belongsTo('clever/clever-school', { async: false, inverse: 'cleverStudents' })
  cleverSchool?: CleverSchool

  @belongsTo('clever/clever-student-match', { async: false, inverse: 'cleverStudent' })
  cleverStudentMatch?: CleverStudentMatch

  get blakeStudent() {
    return this.cleverStudentMatch?.student
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ') || '-'
  }

  @tracked isCreateBlakeStudentChecked = false
}
