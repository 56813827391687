import { modifier } from 'ember-modifier'
import type { Chart } from 'highcharts'

interface Signature {
  Args: {
    Named: unknown
    Positional: [chart?: Chart]
  }
  Element: HTMLElement
}

/**
 * Reflow chart when its div changes size. This can happen when the page layout changes (for example, sidenav closes)
 */
export default modifier<Signature>(function chartReflow(element, [chart]) {
  // resize chart when element changes size.
  const resizeObserver = new ResizeObserver(() => chart?.reflow())
  resizeObserver.observe(element)

  return () => {
    resizeObserver?.disconnect()
  }
})
