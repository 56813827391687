import cloneDeep from 'lodash/cloneDeep'
import { computed, set } from '@ember/object'
import type ComputedProperty from '@ember/object/computed'

export type SortDirection = 'asc' | 'desc'
export type SortComparerKey =
  | 'sortByNumber'
  | 'sortByDate'
  | 'sortByValue'
  | 'sortByGrade'

export type SortColumn = {
  key: string
  comparerKey?: SortComparerKey
  sortDirection: SortDirection
}

export type SortingConfig = {
  columns: Array<SortColumn>
}

export type SortComparerMap = {
  [sortKey: string]: SortComparerKey
}

/**
 * Converts between a SortingConfig object, and a string for URL query params or requests
 *
 * deserialized: {
 *   columns: [
 *     { sortKey: 'first_name', isAscending: true },
 *     { sortKey: 'last_name', isAscending: false },
 *   ]
 * }
 *
 * serialized: 'first_name,-last_name'
 */
export function deserialize(sortString = ''): SortingConfig {
  const sortArray = [] as Array<SortColumn>
  const sorts = sortString.split(',')
  sorts.forEach((sort) => {
    if (sort.length >= 2) {
      const sortDirChar = sort.charAt(0)
      if (sortDirChar === '-') {
        const key = sort.slice(1)
        sortArray.push({ key, sortDirection: 'desc' })
      } else {
        const key = sort
        sortArray.push({ key, sortDirection: 'asc' })
      }
    }
  })
  return { columns: sortArray }
}
export function serialize({ columns }: SortingConfig): string {
  const sortStringArray = columns.map(
    (sort) => `${sort.sortDirection === 'asc' ? '' : '-'}${sort.key}`
  )
  return sortStringArray.join(',')
}

/**
 * Define this in a controller to act as serialize/deserialze layer for a sorting query param, converting between a
 * string and a SortingConfig object
 */
export function sortingConfigComputed(
  sortQueryParamKey: string
): ComputedProperty<SortingConfig, SortingConfig> {
  return computed(sortQueryParamKey, {
    get(_key): SortingConfig {
      return deserialize(this[sortQueryParamKey])
    },
    set(_key, value: SortingConfig): SortingConfig {
      const newSort = serialize(value)
      set(this, sortQueryParamKey, newSort)
      return value
    },
  })
}

/**
 * Takes a string of either 'asc' or 'desc' and flips it to the other one.
 * Assumes the value was 'asc' if it was anything other than these two.
 * @argument {SortDirection} sortDirection - a string of either 'asc' or 'desc' representing the existing sort direction
 * @returns {SortDirection} - The adjusted (flipped) sort direction string
 * */
export function flipSortDirection(sortDirection: SortDirection): SortDirection {
  return sortDirection === 'desc' ? 'asc' : 'desc'
}

/**
 * Given a sorting config and a sort key to update, this will update the sort column array using the sort key. Intended
 * for use in a multi column sort. Does not mutate the original array.
 *
 * Optionally provide a sort direction, to be used in the new sort column if this column hasn't been sorted yet
 */
export function updateSortColumns(
  { columns }: SortingConfig,
  sortKey: string,
  newSortDirection: SortDirection = 'asc'
): SortingConfig {
  const sortColumns = cloneDeep(columns)
  const newColumn = {
    key: sortKey,
    sortDirection: newSortDirection,
  } as SortColumn

  const sortColumn = sortColumns.find((c) => c.key === sortKey) || newColumn
  const sortColumnIndex = sortColumns.indexOf(sortColumn)
  // If key already exists in sort columns, flip it & move to front
  if (sortColumnIndex !== -1) {
    sortColumn.sortDirection = flipSortDirection(sortColumn.sortDirection)
    // Remove from current location
    sortColumns.splice(sortColumnIndex, 1)
  }
  // Always place the new or modified sort item to the front
  sortColumns.unshift(sortColumn)

  return { columns: sortColumns }
}

type TableColumn = {
  sortKey?: string
  valuePath?: string
  comparerKey?: SortComparerKey
}

/**
 * Given an array of table columns, this function returns a mapping a mapping of each column's sortkey or valuepath to a
 * comparer key, if the column has such data.
 */
export function tableColumnsToComparerKeyMapping(
  tableColumns: Array<TableColumn>
): SortComparerMap {
  return tableColumns.reduce((map: SortComparerMap, column: TableColumn) => {
    const colComparerKey = column.comparerKey
    const colSortKey = column.sortKey || column.valuePath

    return colComparerKey && colSortKey
      ? { ...map, [colSortKey]: colComparerKey }
      : { ...map }
  }, {})
}

/**
 * Given a sorting config and a mapping from sort keys to comparer keys, this function will use the mapping to return a
 * new sorting config with comparer keys
 *
 * This is typically used with a sorting config created from a deserialized url sort array (which won't have comparer
 * keys) to formulate a new sorting config (with comparer keys) that can be given to the sort transformer, so that it
 * knows how to sort each column
 */
export function injectComparerKeys(
  { columns: sortColumns }: SortingConfig,
  comparerKeyMapping: SortComparerMap
): SortingConfig {
  const newSortColumns = sortColumns.map((sortCol) => {
    // Don't bother if the sort column already has a comparer key
    if (sortCol.comparerKey) return { ...sortCol }

    // Use the key from the sort column to find the comparer key in the map
    const comparerKey = comparerKeyMapping[sortCol.key]

    return comparerKey ? { comparerKey, ...sortCol } : { ...sortCol }
  })
  return { columns: newSortColumns }
}
