import Component from '@glimmer/component'
import { service } from '@ember/service'
/**
 * @class UnknownError
 * Unknown teacher match error, user is prompted to contact support.
 * @example```hbs
 * <CleverUi::Modals::TeacherMatcher::Unknown />
 * ```
 */
export default class UnknownError extends Component {
  @service
  router

  @service
  intl

  @service
  store

  translated(key, options = {}) {
    return this.intl.t(`clever.teacherMatcher.errors.unknown.${key}`, options)
  }

  get title() {
    return this.translated('summary')
  }

  get cleverTeacher() {
    return this.args.cleverTeacher
  }

  get teacherId() {
    return this.cleverTeacher?.id
  }

  get supportUrl() {
    return this.router.urlFor('subscription-type.support')
  }

  get explanationText() {
    const { teacherId } = this
    return this.translated('explanation', {
      htmlSafe: true,
      teacherId,
    })
  }

  get solutionText() {
    const { supportUrl } = this
    return this.translated('solution', {
      htmlSafe: true,
      supportUrl,
    })
  }
}
