import { template as template_c871ec636a3a41fdacf204dc460a8fa1 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const ModalDefaultFooter: TOC<Signature> = template_c871ec636a3a41fdacf204dc460a8fa1(`
  <div ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalDefaultFooter;
