import { gradePositionFromLexile, lexileRangeFromGradePosition } from './lexile-grade-map'
import { isPresent } from '@ember/utils'

/**
 * Return the min and max lexile to be used as the yAxis Range for charts.
 * When given a range of lexiles this util should return a min or max such
 * that it contains the min max of at least one grade range. The min or max
 * will either end on a min/max of a grade (from lexileGradeMap)
 * or be to the nearest hundred of lowest/highest lexile in the given range.

 */
export default function lexileYRange(product: 're' | 'rex', lexiles: number[]): [number, number] {
  const lexilesWithNullRemoved = lexiles.filter(isPresent)
  if (lexilesWithNullRemoved.length === 0) return [-Infinity, Infinity]
  const minLexile = Math.min(...lexilesWithNullRemoved)
  const maxLexile = Math.max(...lexilesWithNullRemoved)
  const minLexileWithPadding = floorToNearestHundred(minLexile)
  const maxLexileWithPadding = ceilingToNearestHundred(maxLexile)
  const lowestGrade = gradePositionFromLexile(product, minLexile)
  const highestGrade = gradePositionFromLexile(product, maxLexile)
  const minLexileFromLowestGrade = lowestGrade ? lexileRangeFromGradePosition(product, lowestGrade)?.min : null
  const maxLexileFromHighestGrade = highestGrade ? lexileRangeFromGradePosition(product, highestGrade)?.max : null
  const mins = [minLexileWithPadding, minLexileFromLowestGrade].filter(isPresent)
  const maxes = [maxLexileWithPadding, maxLexileFromHighestGrade].filter(isPresent)
  const absoluteMin = Math.min(...mins)
  const absoluteMax = Math.max(...maxes)
  return [absoluteMin, absoluteMax]
}

function ceilingToNearestHundred(number: number) {
  // adding one to guarantee min and mx are not the same
  return Math.ceil((number + 1) / 100) * 100
}

function floorToNearestHundred(number: number) {
  return Math.floor(number / 100) * 100
}
