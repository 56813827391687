type DisplayAverage = number | '<1'

function isSmallAverage(passedAverage: number): boolean {
  return passedAverage < 0.5 && passedAverage >= -0.5
}

function average(numerator = 0, denominator = 0): number {
  if (denominator === 0) {
    return 0
  }
  const calculatedAverage = numerator / denominator

  if (isSmallAverage(calculatedAverage)) {
    return calculatedAverage
  }
  return Math.round(calculatedAverage)
}

export function basicAverage(numerator?: number, denominator?: number): number {
  return average(numerator, denominator)
}

export function displayAverage(numerator?: number, denominator?: number): DisplayAverage {
  const baseAverage = average(numerator, denominator)
  if (isSmallAverage(baseAverage)) {
    return '<1'
  }
  return baseAverage
}
