import Model, { attr } from '@ember-data/model'
import { SubscriptionType } from 'district-ui-client/domain/subscription-type'

/**
 * Model class for blake school statistics (gravity data)
 */
export default class SchoolStatistic extends Model {
  @attr('number') readingTeacherCount!: number

  @attr('number') mathsTeacherCount!: number

  @attr('number') readingStudentCount!: number

  @attr('number') mathsStudentCount!: number

  teacherCountFor(subscriptionType: SubscriptionType): number {
    switch (subscriptionType) {
      case SubscriptionType.Reading:
        return this.readingTeacherCount
      case SubscriptionType.Maths:
        return this.mathsTeacherCount
      default:
        return 0
    }
  }

  studentCountFor(subscriptionType: SubscriptionType): number {
    switch (subscriptionType) {
      case SubscriptionType.Reading:
        return this.readingStudentCount
      case SubscriptionType.Maths:
        return this.mathsStudentCount
      default:
        return 0
    }
  }

  get studentCounts() {
    return { reading: this.readingStudentCount, maths: this.mathsStudentCount }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'school-statistic': SchoolStatistic
  }
}
