import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import getAcademicYear from '../../utils/get-academic-year.ts'
import getNamedDateRangeObject from '../../utils/named-date-ranges.ts'
import { action } from '@ember/object'
import { assert } from '@ember/debug'
import { add, isSameDay, formatISO, parseISO } from 'date-fns'

/**
 * @class RangeDatePickerComponent
 * @property {String} countryCode - required, used for the academic yearly date ranges, which is country-dependent.
 * @property {String} selectedDateRange The selected date range in string format
 * @property {Function} onDateRangeSelected The action called when a valid date range has been selected
 * @property {String} inputClass - optional, classnames to style the input trigger
 * @property {String} contentClass - optional, classnames to style the content box
 *
 */
export default class RangeDatePicker extends Component {
  constructor(...args) {
    super(...args)
    assert(
      'You must provide Dates::RangeDatePicker with a country code',
      this.args.countryCode,
    )
  }

  @tracked _center = null

  /**
   * This is the internal selected date range that we mutate internally and pass to ember-power-calendar.
   *
   * This is needed because ember-power-calendar's start date and end date are optional arguments.
   * And we don't want to pass up a date range via a parent action until we know we have both.
   *
   * @see #didReceiveAttrs
   * @see #selectedDateRangeReadOnly
   * @see actions#onDateRangeSelected
   *
   * @typedef DateRange
   * @property {Date} start - The start of the date range
   * @property {Date|null} end - The end of the date range
   * @type {DateRange | null }
   */
  @tracked _selectedDateRangeInternal = null

  /**
   * The month rendered for the left-most calendar.
   *
   * Defaults to today's month
   *
   * @see #didReceiveAttrs
   *
   * @type {Date}
   */
  get center() {
    const { start, end } = this._selectedDateRangeInternal ?? {}
    if (start && end) {
      return start
    }

    return this._center ?? new Date()
  }

  get centerFormatted() {
    const dateFormatter = new Intl.DateTimeFormat(window.navigator.language, {
      month: 'short',
      year: 'numeric',
    })
    return dateFormatter.format(this.center)
  }

  /**
   * This is the date range that gets passed to the component
   *
   * For incumbent reasons, it is passed as a string within our consuming apps.
   *
   * It can either be a named-period or a custom-range, as shown below in the example.
   *
   * @example
   *  "named-period:this-week"
   *  "custom-range:2020-02-01:2020-02-08"
   *
   * @type {String}
   */
  get selectedDateRange() {
    return this.args.selectedDateRange ?? ''
  }

  placeholder = 'Select a date range'

  /**
   * The month rendered for the right-most calendar.
   *
   * The right calendar month is always one month after the left
   *
   * @type {Date}
   */
  get nextMonthsCenter() {
    return add(this.center, { months: 1 })
  }

  get nextMonthsCenterFormatted() {
    const dateFormatter = new Intl.DateTimeFormat(window.navigator.language, {
      month: 'short',
      year: 'numeric',
    })
    return dateFormatter.format(this.nextMonthsCenter)
  }

  /**
   * The selected date range that ember-power-calendar reads from
   *
   * @typedef DateRange
   * @property {Date} start - The start of the date range
   * @property {Date|null} end - The end of the date range
   * @type {DateRange | null }
   */
  get selectedDateRangeReadOnly() {
    const { selectedDateRange, _selectedDateRangeInternal } = this

    if (_selectedDateRangeInternal) {
      return _selectedDateRangeInternal
    }

    const isNamedRange = selectedDateRange.startsWith('named-period')
    const isCustomRange = selectedDateRange.startsWith('custom-range')

    if (isNamedRange) {
      const namedRange = selectedDateRange.split('named-period:')[1]
      return this._getNamedRange(namedRange)
    }
    if (isCustomRange) {
      const customRange = selectedDateRange.split('custom-range:')[1]
      return this._getCustomRange(customRange)
    }
    // If the string doesn't conform to any of the above, then reset the datepicker.
    return null
  }

  /**
   * The source of truth for named ranges.
   *
   * This utilizes the getSchoolYearPeriod and getNamedDateRangeObject utils
   * which are the canonical resources for interpreting named ranges across apps
   *
   * Also note that the getSchoolYearPeriod util relies on the country code of
   * the user to determine year-based periods.
   *
   * @see /packages/dates/addon/utils/named-date-ranges.js
   *
   * @typedef NamedRange
   * @property {Date} start - the start date of the range
   * @property {Date} end - tne end date of the range
   *
   * @typedef NamedRangesObject
   * @property {NamedRange} this-week - The range for this week
   * @property {NamedRange} last-7-days - The range for this week
   * @property {NamedRange} this-month - The range for this week
   * @property {NamedRange} last-90-days - The range for this week
   * @property {NamedRange} this-year - The range for the current school period according to their country code
   * @property {NamedRange} last-year - The range for the previous school period according to their country code
   *
   * @type {NamedRangesObject}
   *
   */
  get namedRanges() {
    const schoolYearPeriod = getAcademicYear(
      this.getNow(),
      this.args.countryCode,
    )
    const namedRanges = getNamedDateRangeObject(schoolYearPeriod, this.getNow())

    return Object.keys(namedRanges).reduce((acc, rangeName) => {
      acc[rangeName] = {
        start: namedRanges[rangeName].start,
        end: namedRanges[rangeName].end,
      }
      return acc
    }, {})
  }

  get isThisWeekSelected() {
    const selected = this.selectedDateRangeReadOnly || {}
    if (selected.start && selected.end) {
      const thisWeek = this.namedRanges['this-week']
      return (
        isSameDay(thisWeek.start, selected.start) &&
        isSameDay(thisWeek.end, selected.end)
      )
    }
    return false
  }

  get isLast7DaysSelected() {
    const selected = this.selectedDateRangeReadOnly || {}
    if (selected.start && selected.end) {
      const last7Days = this.namedRanges['last-7-days']
      return (
        isSameDay(last7Days.start, selected.start) &&
        isSameDay(last7Days.end, selected.end)
      )
    }
    return false
  }

  get isThisMonthSelected() {
    const selected = this.selectedDateRangeReadOnly || {}
    if (selected.start && selected.end) {
      const thisMonth = this.namedRanges['this-month']
      return (
        isSameDay(thisMonth.start, selected.start) &&
        isSameDay(thisMonth.end, selected.end)
      )
    }
    return false
  }

  get isLast90DaysSelected() {
    const selected = this.selectedDateRangeReadOnly || {}
    if (selected.start && selected.end) {
      const last90Days = this.namedRanges['last-90-days']
      return (
        isSameDay(last90Days.start, selected.start) &&
        isSameDay(last90Days.end, selected.end)
      )
    }
    return false
  }

  get isThisYearSelected() {
    const selected = this.selectedDateRangeReadOnly || {}
    if (selected.start && selected.end) {
      const thisYear = this.namedRanges['this-year']
      return (
        isSameDay(thisYear.start, selected.start) &&
        isSameDay(thisYear.end, selected.end)
      )
    }
    return false
  }

  get isLastYearSelected() {
    const selected = this.selectedDateRangeReadOnly || {}
    if (selected.start && selected.end) {
      const lastYear = this.namedRanges['last-year']
      return (
        isSameDay(lastYear.start, selected.start) &&
        isSameDay(lastYear.end, selected.end)
      )
    }
    return false
  }

  /**
   * A label for named ranges.
   *
   * If the selected range matches a named preset, then we display this in the input field.
   *
   * @example:
   *  "This week: "
   *  "Last 7 days: "
   */
  get inputValuePrefix() {
    const { presetDateRangeLabels } = this.args
    let prefix = ''
    if (this.isThisWeekSelected) {
      prefix = `${presetDateRangeLabels?.thisWeek}: `
    } else if (this.isLast7DaysSelected) {
      prefix = `${presetDateRangeLabels.last7Days}: `
    } else if (this.isThisMonthSelected) {
      prefix = `${presetDateRangeLabels.thisMonth}: `
    } else if (this.isLast90DaysSelected) {
      prefix = `${presetDateRangeLabels.last90Days}: `
    } else if (this.isThisYearSelected) {
      prefix = `${presetDateRangeLabels.thisYear}: `
    } else if (this.isLastYearSelected) {
      prefix = `${presetDateRangeLabels.lastYear}: `
    }
    return prefix
  }

  /**
   * The value that is displayed by the input element
   *
   * @type {String}
   *
   * @example
   *  "This week: 2020/01/01 - 2020/01/07"
   *  "2020/01/01 - 2020/01/02"
   */
  get inputValueFormattedReadOnly() {
    // If being provided a value to display here, just show that
    if (this.args.inputValueFormatted) return this.args.inputValueFormatted

    const range = this.selectedDateRangeReadOnly || {}
    const isStartAndEndDateSelected = range.start && range.end
    let dateRangeFormatted = ''

    if (isStartAndEndDateSelected) {
      const dateFormatter = new Intl.DateTimeFormat(window.navigator.language)
      const startDateFormatted = dateFormatter.format(range.start)
      const endDateFormatted = dateFormatter.format(range.end)
      dateRangeFormatted = `${startDateFormatted} - ${endDateFormatted}`
    }

    if (this.inputValuePrefix)
      dateRangeFormatted = `${this.inputValuePrefix}${dateRangeFormatted}`
    return dateRangeFormatted
  }

  /**
   * Convert the named date range string we receive from outside the component.
   *
   * If the named period is invalid, then we reset the date picker
   */
  _getNamedRange(namedRange) {
    const range = this.namedRanges[namedRange]
    if (range) {
      return { start: range.start, end: range.end }
    }
    return null
  }

  /**
   * Convert the custom date range string we receive from outside the component.
   *
   * If the strings are invalid, then we reset the date picker
   */
  _getCustomRange(customRange) {
    const [startString, endString] = customRange.split(':')
    if (startString && endString) {
      try {
        const start = parseISO(startString)
        const end = parseISO(endString)
        return { start, end }
      } catch {
        return null
      }
    } else {
      return null
    }
  }

  /**
   * Enables us to stub today's date in tests
   */
  @action
  getNow() {
    return this.args.getNow?.() ?? new Date()
  }

  @action
  onDateRangeSelected(closeAction, { date: { start, end } }) {
    // If we don't have both a start and end date, then mutate the internal
    // _selectedDateRangeInternal object to update the calendar
    if (!start || !end) {
      this._selectedDateRangeInternal = { start, end }
      return
    }

    // Otherwise if a proper range is selected then let's send it back up
    // to the parent component formatted as a string
    if (start && end) {
      const dateFormat = { representation: 'date' }
      this.args.onDateRangeSelected(
        `custom-range:${formatISO(start, dateFormat)}:${formatISO(
          end,
          dateFormat,
        )}`,
      )
      closeAction()
    }

    this._selectedDateRangeInternal = null
  }

  @action
  selectNamedRange(rangeName, closeAction) {
    const namedRange = this._getNamedRange(rangeName)
    if (namedRange?.start) this._center = namedRange.start
    this.args.onDateRangeSelected(`named-period:${rangeName}`)
    closeAction()
  }

  /**
   * Handle dropdown close event
   *
   * If the user closes the calendar dropdown before selecting both a start and end date
   * then we reset the datepicker to its initial state
   */
  @action
  onClose() {
    const range = this._selectedDateRangeInternal || {}
    const isNotACompleteDateRange = !range.start || !range.end
    if (isNotACompleteDateRange) this._selectedDateRangeInternal = null
  }

  @action
  onCenterChange({ date }) {
    this._center = date
  }
}
