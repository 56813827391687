import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type Store from '@ember-data/store'
import type SessionService from 'district-ui-client/services/session'
import type ManageStudentsIndexController from 'district-ui-client/controllers/subscription-type/manage/students/index'
import type SubscriptionTypeRoute from 'district-ui-client/routes/subscription-type'

export default class ManageStudentsIndexRoute extends Route {
  @service store!: Store

  @service session!: SessionService

  queryParams = {
    schoolId: {
      replace: false,
    },
    teacherId: {
      replace: false,
    },
    schoolClassId: {
      replace: false,
    },
    studentFirstName: {
      replace: false,
    },
    studentLastName: {
      replace: false,
    },
  }

  async model() {
    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>
    const [schools, teachersForSubType] = await Promise.all([
      this.store.query('school', {
        scope: `districts/${this.session.currentDistrict.id}`,
        include: 'school-subscriptions',
      }),
      // used to make teacher options for searching by
      this.store.query('teacher', {
        filter: { 'subscription-type': subscriptionType },
        scope: `districts/${this.session.currentDistrict.id}`,
        // need the school<->teachers relation present, for the change school modal
        include: 'school-classes,school',
      }),
    ])
    const schoolsForSubType = schools.filter((school) => school.hasActiveSchoolSubscriptionFor(subscriptionType))
    return { schoolsForSubType, teachersForSubType: teachersForSubType.slice() }
  }

  resetController(controller: ManageStudentsIndexController, isExiting: boolean) {
    if (isExiting) controller.updateSelectedIds?.([])
  }
}
