import { tracked } from '@glimmer/tracking'

export class BaseState {
  @tracked disabled = false
  @tracked searchTerm?: string

  onInput = (event: Event) => {
    if (event.target instanceof HTMLInputElement) {
      this.searchTerm = event.target.value
    }
  }
}

export default BaseState
