import Modifier from 'ember-modifier'
import { inject as service } from '@ember/service'
import type DeviceDetection from '../detection/service.ts'

/**
 * This is the list of identifier strings that a user of this modifier can provide.
 */
type ShortDeviceIdentifier =
  | 'ios'
  | 'iphone'
  | 'android'
  | 'native-ios'
  | 'native-android'
  | 'native-mobile'
  | 'ipados13'
  | 'ipad'
  | 'mobile'

/**
 * Maps identifier strings to getters on the DeviceDetection service
 */
const deviceDetectionGetters = new Map<
  ShortDeviceIdentifier,
  keyof DeviceDetection
>([
  ['ios', 'isIos'],
  ['iphone', 'isIPhone'],
  ['android', 'isAndroid'],
  ['native-ios', 'isNativeIos'],
  ['native-android', 'isNativeAndroid'],
  ['native-mobile', 'isNativeMobile'],
  ['ipados13', 'isIPadOs13'],
  ['ipad', 'isIPad'],
  ['mobile', 'isMobile'],
])

/**
 * This indicates that we allow positional device detection arguments and an
 * optional `@prefix` named argument
 */
interface Signature {
  Args: {
    Positional: ShortDeviceIdentifier[]
    Named: {
      prefix?: string
    }
  }
  Element: HTMLElement
}

export default class DeviceDetectionClasses extends Modifier<Signature> {
  @service('device/detection') declare deviceDetection: DeviceDetection

  override modify(
    element: Signature['Element'],
    positional: Signature['Args']['Positional'],
    named: Signature['Args']['Named'],
  ): void {
    const prefix = named.prefix ?? 'device-is'
    for (const identifier of positional) {
      this.addIdentifierClass(element, prefix, identifier)
    }
  }

  private addIdentifierClass(
    element: HTMLElement,
    prefix: string,
    identifier: ShortDeviceIdentifier,
  ): void {
    const deviceDetectionGetter = deviceDetectionGetters.get(identifier)
    if (deviceDetectionGetter && this.deviceDetection[deviceDetectionGetter]) {
      element.classList.add(`${prefix}-${identifier}`)
    }
  }
}
