import Service, { service } from '@ember/service'
import { join } from 'district-ui-client/utils/uri'
import config from 'district-ui-client/config/environment'
import { format } from 'date-fns'

import assessmentScores from 'district-ui-client/utils/student-events/fetchers/assessment-scores'
import assessmentScoresTotals from 'district-ui-client/utils/student-events/fetchers/assessment-scores-totals'
import awardsEarned from 'district-ui-client/utils/student-events/fetchers/awards-earned'
import awardsEarnedTotals from 'district-ui-client/utils/student-events/fetchers/awards-earned-totals'
import booksReadByGenre from 'district-ui-client/utils/student-events/fetchers/books-read-by-genre'
import booksReadByGenreTotals from 'district-ui-client/utils/student-events/fetchers/books-read-by-genre-totals'
import booksReadByLevel from 'district-ui-client/utils/student-events/fetchers/books-read-by-level'
import booksReadByLevelTotals from 'district-ui-client/utils/student-events/fetchers/books-read-by-level-totals'
import courseProgressAverages from 'district-ui-client/utils/student-events/fetchers/course-progress-averages'
import courseProgressByGrade from 'district-ui-client/utils/student-events/fetchers/course-progress-by-grade'
import courseProgressTotals from 'district-ui-client/utils/student-events/fetchers/course-progress-totals'
import drivingTests from 'district-ui-client/utils/student-events/fetchers/driving-tests'
import drivingTestsTotalsByGradeByMonth from 'district-ui-client/utils/student-events/fetchers/driving-tests-totals-by-grade-by-month'
import lexileDetailsAverages from 'district-ui-client/utils/student-events/fetchers/lexile-details-averages'
import lexileGrowth from 'district-ui-client/utils/student-events/fetchers/lexile-growth'
import mentalMinuteSchoolOverview from 'district-ui-client/utils/student-events/fetchers/mental-minute-school-overview'
import mentalMinuteSummary from 'district-ui-client/utils/student-events/fetchers/mental-minute-summary'
import quizScoresTotals from 'district-ui-client/utils/student-events/fetchers/quiz-scores-totals'
import simpleSummary from 'district-ui-client/utils/student-events/fetchers/simple-summary'
import skillsMastered from 'district-ui-client/utils/student-events/fetchers/skills-mastered'
import stadiumEvents from 'district-ui-client/utils/student-events/fetchers/stadium-events'
import storiesWrittenByGrade from 'district-ui-client/utils/student-events/fetchers/stories-written-by-grade'
import storiesWrittenTotals from 'district-ui-client/utils/student-events/fetchers/stories-written-totals-by-grade-by-month'
import storylandsTotalsByGradeByMonth from 'district-ui-client/utils/student-events/fetchers/storylands-totals-by-grade-by-month'
import summary from 'district-ui-client/utils/student-events/fetchers/summary'
import timeOnTaskHistory from 'district-ui-client/utils/student-events/fetchers/time-on-task-history'
import timeOnTaskUsage from 'district-ui-client/utils/student-events/fetchers/time-on-task-usage'
import totalUsageOverTime from 'district-ui-client/utils/student-events/fetchers/total-usage-over-time'
import workingAtGradeLevel from 'district-ui-client/utils/student-events/fetchers/working-at-grade-level'
import workingAtGradeLevelTotalsByGrade from 'district-ui-client/utils/student-events/fetchers/working-at-grade-level-totals-by-grade'

/**
 * Service for interacting with the student-events api.
 */
export default class StudentEventsService extends Service {
  @service authToken

  /**
   * Builds a url from a product, studentScope and endpoint.
   *
   * @private
   * @instance
   * @memberOf StudentEventsService
   *
   * @param {String} endpoint - The endpoint uri fragment
   * @param {String} product - The product, e.g., rex
   * @param {Object} studentScope - The studentScope object
   * @param {String} studentScope.scope - The scope's type, e.g. 'students', 'district'
   * @return {String} The url
   */
  buildUrl(endpoint, product, studentScope) {
    const joined = join(config.studentEventsV3Url, studentScope.scope, product, endpoint)
    return joined.href
  }

  /**
   * Builds a query object that is suitable for requests targetting the
   * student-events api from studentScope, period and base query objects.
   *
   * @private
   * @instance
   * @memberOf StudentEventsService
   *
   * @param {Object} studentScope - The studentScope object
   * @param {Array} studentScope.ids - A list of scope type ids to query
   * @param {Object} period - The period object
   * @param {Date} period.start - The start date for this query
   * @param {Date} period.end - The end date for this query
   * @param {Object} [base={}] - The base query object to start from
   * @return {Object} The new query object to use, that can be used as a fetch requestinit
   */
  buildQuery({ ids }, { start, end }, base = {}) {
    const fromTime = new Date(start)
    fromTime.setHours(0, 0, 0, 0) // start of day
    const fromTimeSeconds = Math.floor(fromTime.getTime() / 1000) // seconds since epoch

    const toTime = new Date(end)
    toTime.setHours(23, 59, 59, 999) // end of day
    const toTimeSeconds = Math.floor(toTime.getTime() / 1000) // seconds since epoch

    const tz = format(fromTime, 'xxx') // formats timezone offset like `+11:30`. For GMT, it's `+00:00`

    const data = {
      ...base,
      from_date: fromTimeSeconds,
      to_date: toTimeSeconds,
      tz,
      ids,
    }
    return {
      body: JSON.stringify(data),
      headers: { 'content-type': 'application/json' },
    }
  }

  /**
   * Fetches data from an API endpoint.
   *
   * @private
   * @instance
   * @memberOf StudentEventsService
   *
   * @param {RequestInfo | URL} input - The URL of the API endpoint to fetch data from.
   * @param {RequestInit | undefined} requestInit - options object for fetch
   * @return {Promise} An RSVP Promise that resolves with the returned data.
   */
  async fetchData(input, requestInit = {}) {
    try {
      // make sure to merge the headers. (note: ...spreading undefined is valid)
      const mergedRequestInit = {
        ...requestInit,
        headers: { Authorization: this.authToken.token, ...requestInit.headers },
      }
      const response = await fetch(input, {
        method: 'POST',
        ...mergedRequestInit,
      })

      if (response.ok) {
        return await response.json()
      }
      if (response.headers.get('content-type') === 'application/json') {
        throw await response.json()
      }
      throw await response.text()
    } catch (error) {
      if (error instanceof DOMException && error?.name === 'AbortError') {
        // Fetch aborted by user action, eg closed tab, stop button, network outage. Do nothing.
        return
      }
      console.error('Couldnt load the student events report', error)
      throw error
    }
  }

  assessmentScores = assessmentScores

  assessmentScoresTotals = assessmentScoresTotals

  awardsEarned = awardsEarned

  awardsEarnedTotals = awardsEarnedTotals

  booksReadByGenre = booksReadByGenre

  booksReadByGenreTotals = booksReadByGenreTotals

  booksReadByLevel = booksReadByLevel

  booksReadByLevelTotals = booksReadByLevelTotals

  courseProgressAverages = courseProgressAverages

  courseProgressByGrade = courseProgressByGrade

  courseProgressTotals = courseProgressTotals

  drivingTests = drivingTests

  drivingTestsTotalsByGradeByMonth = drivingTestsTotalsByGradeByMonth

  lexileDetailsAverages = lexileDetailsAverages

  lexileGrowth = lexileGrowth

  mentalMinuteSchoolOverview = mentalMinuteSchoolOverview

  mentalMinuteSummary = mentalMinuteSummary

  quizScoresTotals = quizScoresTotals

  simpleSummary = simpleSummary

  skillsMastered = skillsMastered

  stadiumEvents = stadiumEvents

  storiesWrittenByGrade = storiesWrittenByGrade

  storiesWrittenTotals = storiesWrittenTotals

  storylandsTotalsByGradeByMonth = storylandsTotalsByGradeByMonth

  summary = summary

  timeOnTaskHistory = timeOnTaskHistory

  timeOnTaskUsage = timeOnTaskUsage

  totalUsageOverTime = totalUsageOverTime

  workingAtGradeLevel = workingAtGradeLevel

  workingAtGradeLevelTotalsByGrade = workingAtGradeLevelTotalsByGrade
}
