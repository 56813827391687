import GravityAdapter from 'district-ui-client/adapters/gravity'
import { tracked } from '@glimmer/tracking'
import BulkManager from 'district-ui-client/utils/bulk-manager'
import { createRecordBulk, updateRecordBulk, deleteRecordBulk } from 'district-ui-client/utils/bulk-adapter-utils'

/**
 * Same as the gravity adapter, but with the ability to batch create / update / delete requests.
 *
 * Use 'bulk: true' in the adapter options when creating a record.
 */
export default class GravityBulkAdapter extends GravityAdapter {
  @tracked _bulkManagerCreate = null

  @tracked _bulkManagerUpdate = null

  @tracked _bulkManagerDelete = null

  buildBulkManager(snapshot) {
    // Note, this references the ember-data adapter "ajax" method, rather than an ajax service injection
    const { ajax } = this
    // use the same endpoint for creates, updates, deletes..
    const urlForBulkAction = this.urlForCreateRecord(snapshot.modelName, snapshot)
    const adapterAjaxFn = ajax.bind(this)
    // BulkManager uses ember-concurrency to support doing these in batches
    return new BulkManager(adapterAjaxFn, urlForBulkAction)
  }

  createRecord(store, type, snapshot, ...otherArgs) {
    if (snapshot?.adapterOptions?.bulk) {
      if (!this._bulkManagerCreate) this._bulkManagerCreate = this.buildBulkManager(snapshot)
      return createRecordBulk(store, type, snapshot, this._bulkManagerCreate)
    } else {
      return super.createRecord(store, type, snapshot, ...otherArgs)
    }
  }

  updateRecord(store, type, snapshot, ...otherArgs) {
    if (snapshot?.adapterOptions?.bulk) {
      if (!this._bulkManagerUpdate) this._bulkManagerUpdate = this.buildBulkManager(snapshot)
      return updateRecordBulk(store, type, snapshot, this._bulkManagerUpdate)
    } else {
      return super.updateRecord(store, type, snapshot, ...otherArgs)
    }
  }

  deleteRecord(store, type, snapshot, ...otherArgs) {
    if (snapshot?.adapterOptions?.bulk) {
      if (!this._bulkManagerDelete) this._bulkManagerDelete = this.buildBulkManager(snapshot)
      return deleteRecordBulk(store, type, snapshot, this._bulkManagerDelete)
    } else {
      return super.deleteRecord(store, type, snapshot, ...otherArgs)
    }
  }
}
