import type { NormalizedInterval } from 'date-fns'
import { isBefore, isDate, parseISO, startOfDay, isEqual } from 'date-fns'
import getNamedDateRangeObject from './named-date-ranges.ts'

/**
 * Takes date range from params and converts it into an Interval
 */
export function customRange(
  _schoolYearPeriod: NormalizedInterval,
  from: string,
  to: string,
): NormalizedInterval {
  const start = startOfDay(parseISO(from))
  const end = startOfDay(parseISO(to))
  if (validateCustomRange(start, end)) {
    return { start, end }
  }
  throw Error(
    `Error: "${from}:${to}" Date params must be valid dates and in the correct order for a custom-range.`,
  )
}

function validateCustomRange(fromDate: Date, toDate: Date): boolean {
  // check we have at least two dates in the range
  // check both dates are valid dates and fromDate is not after toDate
  // date.unix() will return NaN if not a valid date
  const validData = isDate(fromDate) && isDate(toDate)
  const correctOrder = isBefore(fromDate, toDate) || isEqual(fromDate, toDate)
  return validData && correctOrder
}

export function namedPeriod(
  schoolYearPeriod: NormalizedInterval,
  aNamedPeriod: string,
  now = new Date(),
): NormalizedInterval {
  // allows us to override the now function if need be
  // assumes this function will be called bound within an ember component
  const namedDateRanges = getNamedDateRangeObject(schoolYearPeriod, now)
  const namedDateRange = namedDateRanges[aNamedPeriod]
  if (namedDateRange) {
    return namedDateRange
  }
  throw Error(`Error: "${aNamedPeriod}" You must pass a valid named period.`)
}
