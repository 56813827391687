import Service, { service } from '@ember/service'
import type SessionService from './session'
import type Store from '@ember-data/store'
import uniqBy from 'lodash/uniqBy'

export const STATUS_ACTIVE = 'active'
export const STATUS_COMPLETE = 'complete'

export default class SchoolRolloverService extends Service {
  @service store!: Store

  @service session!: SessionService

  async fetchRollovers() {
    return this.store.query('school-rollover', {
      filter: { status: STATUS_ACTIVE },
      scope: `districts/${this.session.currentDistrict.id}`,
      include: 'school',
    })
  }

  get schoolsInRollover() {
    return uniqBy(
      this.store.peekAll('school-rollover').map((schoolRollover) => schoolRollover.school),
      (school) => school.id,
    )
  }
}

declare module '@ember/service' {
  interface Registry {
    'school-rollover': SchoolRolloverService
  }
}
