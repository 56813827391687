import { template as template_3c6ee7475ed043fc84c152e6298744a9 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { use } from 'ember-resources';
import { trackedFunction } from 'reactiveweb/function';
import { keepLatest } from 'reactiveweb/keep-latest';
import { task } from 'ember-concurrency';
import { and } from 'ember-truth-helpers';
import { array } from '@ember/helper';
import type FpLessonPerformanceController from 'district-ui-client/controllers/reporting/ui-scope/fast-phonics/lesson-performance';
import { ReportingPageHeader } from 'district-ui-client/components/reporting/page-header';
import { ReportingScopeHeader } from 'district-ui-client/components/reporting/scope-header';
import type ReportingService from 'district-ui-client/services/reporting';
import { RequestScope, exportFilename } from 'district-ui-client/services/reporting';
import { NoReportValue } from 'district-ui-client/components/reporting/no-report-value';
import { LessonPerformanceOverview, type LessonPerformanceOverviewReportData } from 'district-ui-client/components/reporting/lesson-performance/overview';
import { LessonPerformanceResults, type LessonPerformanceResultsReportData } from 'district-ui-client/components/reporting/lesson-performance/results';
import type { BandKey } from 'district-ui-client/components/reporting/lesson-performance/overview';
import type { QueryParams } from 'district-ui-client/utils/uri';
import type SessionService from 'district-ui-client/services/session';
import { NoStandardsMessage } from 'district-ui-client/components/no-standards-message';
import { StandardsSetUpdatedAlert } from 'district-ui-client/components/standards-set/updated-alert';
interface FpLessonPerformancePageSignature {
    Args: {
        controller: FpLessonPerformanceController;
    };
}
interface BandFilter extends QueryParams {
    'average-score-min': number;
    'average-score-max': number;
}
const bandRangeToFilter = (min: number, max: number)=>({
        'average-score-min': min,
        'average-score-max': max
    });
const bandFilterLookup: Record<'all' | BandKey, undefined | BandFilter> = {
    all: undefined,
    '0-49': bandRangeToFilter(0, 49),
    '50-79': bandRangeToFilter(50, 79),
    '80-100': bandRangeToFilter(80, 100)
};
class FpLessonPerformancePage extends Component<FpLessonPerformancePageSignature> {
    @service
    reporting: ReportingService;
    @service
    session: SessionService;
    fetchOverviewReport = trackedFunction(this, async ()=>{
        if (!this.reporting.uiScope || !this.reporting.product) return;
        const requestScope = new RequestScope(this.reporting.uiScope);
        const url = this.reporting.buildGravityUrl({
            product: this.reporting.product,
            requestScope,
            reportName: 'lesson-performance/overview'
        });
        const query = this.reporting.buildQuery({
            requestScope,
            period: this.reporting.period,
            studentGrade: this.args.controller.studentGrade,
            contentLevel: this.args.controller.contentLevel
        });
        return await this.reporting.fetchReport<LessonPerformanceOverviewReportData>(url, query);
    });
    fetchResultsReport = trackedFunction(this, async ()=>{
        if (!this.reporting.uiScope || !this.reporting.product) return;
        const requestScope = new RequestScope(this.reporting.uiScope);
        const url = this.reporting.buildGravityUrl({
            product: this.reporting.product,
            requestScope,
            reportName: 'lesson-performance/results'
        });
        const filter = bandFilterLookup[this.args.controller.band];
        const query = this.reporting.buildQuery({
            requestScope,
            filter,
            sort: this.args.controller.sort,
            page: {
                number: this.args.controller.pageNumber,
                size: this.args.controller.perPage
            },
            period: this.reporting.period,
            studentGrade: this.args.controller.studentGrade,
            contentLevel: this.args.controller.contentLevel
        });
        const report = await this.reporting.fetchReport<LessonPerformanceResultsReportData>(url, query);
        return report?.results instanceof Array ? report : undefined;
    });
    exportResultsCsv = task({
        drop: true
    }, async ()=>{
        if (!this.reporting.uiScope || !this.reporting.product) return;
        const requestScope = new RequestScope(this.reporting.uiScope);
        const url = this.reporting.buildGravityUrl({
            product: this.reporting.product,
            requestScope,
            reportName: 'lesson-performance/results'
        });
        const query = this.reporting.buildQuery({
            requestScope,
            sort: this.args.controller.sort,
            period: this.reporting.period,
            studentGrade: this.args.controller.studentGrade,
            contentLevel: this.args.controller.contentLevel,
            filter: bandFilterLookup[this.args.controller.band]
        });
        const filename = exportFilename(this.reporting.product, 'lesson-performance', '.csv');
        await this.reporting.exportReportCsv(url, query, filename);
    });
    @use
    latestOverviewReport = keepLatest({
        value: ()=>this.fetchOverviewReport.value,
        when: ()=>this.fetchOverviewReport.isPending
    });
    get latestOverviewReportData() {
        return this.latestOverviewReport?.overview.total_students ? this.latestOverviewReport : undefined;
    }
    @use
    latestResultsReport = keepLatest({
        value: ()=>this.fetchResultsReport.value,
        when: ()=>this.fetchResultsReport.isPending
    });
    static{
        template_3c6ee7475ed043fc84c152e6298744a9(`
    <ReportingPageHeader class="mb-8" />
    {{#if this.session.currentDistrict.standardsSet}}
      <div class="space-y-6">
        <ReportingScopeHeader />
        <StandardsSetUpdatedAlert
          @reportStandardsSetId={{this.latestOverviewReport.meta.standards_set_id}}
          @currentStandardsSet={{this.session.currentDistrict.standardsSet}}
        />
        {{#if (and this.latestOverviewReportData this.latestResultsReport)}}
          {{#if this.latestOverviewReportData}}
            <LessonPerformanceOverview
              @reportData={{this.latestOverviewReportData}}
              @isReloading={{this.fetchOverviewReport.isPending}}
            />
          {{/if}}
          {{#if this.latestResultsReport}}
            <LessonPerformanceResults
              @reportData={{this.latestResultsReport}}
              @isReloading={{this.fetchResultsReport.isPending}}
              @exportCsv={{this.exportResultsCsv}}
              @band={{@controller.band}}
              @setBand={{@controller.setBand}}
            />
          {{/if}}
        {{else}}
          <NoReportValue @reportStates={{array this.fetchOverviewReport this.fetchResultsReport}} />
        {{/if}}
      </div>
    {{else}}
      <NoStandardsMessage />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(FpLessonPerformancePage);
