import Model, { belongsTo, attr } from '@ember-data/model'
import type CleverDistrictMatch from 'district-ui-client/models/clever/clever-district-match'
import type StandardsSet from 'district-ui-client/models/standards-set'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'

export default class District extends Model {
  @attr('string') name!: string

  @attr('string') code!: string

  /**
   * This record is created in the backend when a clever-id is defined for a district in admin console. It does not
   * necessarily mean there is a clever-district yet - that is done as part of the "clever download" process.
   */
  @belongsTo('clever/clever-district-match', { async: false, inverse: 'district' })
  cleverDistrictMatch?: CleverDistrictMatch

  @belongsTo('standards-set', { async: false, inverse: null })
  standardsSet?: StandardsSet

  cleverDisabledManagementFor = (subscriptionType: SubscriptionType): boolean => {
    return Boolean(this.cleverDistrictMatch?.getCleverSharedByType(subscriptionType))
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    district: District
  }
}

export function isDistrictCleverShared(district: District, subscriptionType: SubscriptionType): boolean {
  return Boolean(district.cleverDistrictMatch?.getCleverSharedByType(subscriptionType))
}
