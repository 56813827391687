import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'
import type StudentEventsService from 'district-ui-client/services/student-events'
import type UiScopeRoute from 'district-ui-client/routes/reporting/ui-scope'
import type {
  SchoolDataItem,
  Aggregate,
} from 'district-ui-client/components/tables/fluency-sprints/school-overview/component'
import type { StatsScope } from 'district-ui-client/utils/student-events/stats-scope'
import type { NormalizedInterval } from 'date-fns'

interface StatsResponse {
  mentalMinuteSchoolOverview: Nullable<{ schools: SchoolDataItem[]; aggregate: Aggregate }>
}

export default class ReportingMsFluencySprintsTableRoute extends Route {
  queryParams = {
    sort: {
      replace: true,
    },
  }

  @service studentEvents!: StudentEventsService

  async model() {
    const product: LegacyProductMs = 'maths'
    const {
      uiScope: scope,
      statsScope,
      period,
      scopedData,
    } = this.modelFor('reporting.ui-scope') as ModelFor<UiScopeRoute>

    const stats = await this.makeStatsRequest(product, statsScope, period)

    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product: string, scope: StatsScope, period: NormalizedInterval): Promise<StatsResponse> {
    const { studentEvents } = this

    return RSVP.hash({
      mentalMinuteSchoolOverview: studentEvents.mentalMinuteSchoolOverview(product, scope, period),
    })
  }
}
