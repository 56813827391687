import mergeSort from '@blakeelearning/data-tables/utils/merge-sort'
import type { PaginationConfig } from '@blakeelearning/data-tables/utils/paging'
import type { FilterConfig } from '@blakeelearning/data-tables/utils/filtering'

import {
  compareByNumber,
  compareByDate,
  compareByValue,
  compareByGrade,
  buildComposedComparerFromComparerObjects,
} from '@blakeelearning/data-tables/utils/comparisons'

import { isEmpty } from '@ember/utils'

import chunk from 'lodash/chunk'

import type { SortingConfig } from '@blakeelearning/data-tables/utils/sorting'

/**
 * Types that the data transformer uses to do its work
 */

/**
 * The structure of data that can get moved between operations in the pipeline
 */
export type PipelineData<T> = {
  items: Array<T>
  [key: string]: any
}

/**
 * A pipeline operation is a function that transforms some
 * PipelineData to some other PipelineData.
 */
export type PipelineOperation<TInput, TResult> = (
  pipelineData: PipelineData<TInput>,
) => Promise<PipelineData<TResult>> | PipelineData<TResult>

export type PipelineDescriptor =
  | SortingPipelineDescriptor
  | PaginationPipelineDescriptor
  | FilterPipelineDescriptor
  | CustomPipelineDescriptor

export type SortingPipelineDescriptor = {
  type: 'sort'
  config: SortingConfig
}

export type PaginationPipelineDescriptor = {
  type: 'paginate'
  config: PaginationConfig
}

export type FilterPipelineDescriptor = {
  type: 'filter'
  config: FilterConfig
}

export type CustomPipelineDescriptor = {
  type: 'custom'
  transformer: PipelineOperation<any, any>
}

/**
 * Build a pipeline operation for sorting from a SortConfig.
 * The resultant pipeline operation can be plugged into a pipeline which
 * sorts the items using a sorting priority queue.
 */
export function sort<T>({ columns }: SortingConfig): PipelineOperation<T, T> {
  return ({ items, ...rest }) => {
    if (isEmpty(items)) return { ...rest, items: [] }

    let sortingPriorityQueue = columns
    if (isEmpty(sortingPriorityQueue)) {
      sortingPriorityQueue = []
    }
    const defaultColumnData = {
      sortDirection: 'asc',
      comparerKey: 'sortByValue',
    }
    const compareFunctionsFromSortName = {
      sortByNumber: compareByNumber,
      sortByDate: compareByDate,
      sortByValue: compareByValue,
      sortByGrade: compareByGrade,
    }
    const comparerObjects = sortingPriorityQueue.map((column) => {
      const comparerKey = column.comparerKey || 'sortByValue'
      const compareFunction =
        compareFunctionsFromSortName[comparerKey] ?? compareByValue
      return { ...defaultColumnData, ...column, compareFunction }
    })
    // builds a single function out of the comparerObjects that takes two arguments
    // and gives -1, 0 or 1 (ie a standard comparer function for sorting functions)
    // the difference is that this one sorts on multiple columns
    const composedComparer =
      buildComposedComparerFromComparerObjects(comparerObjects)
    // sort a copy rather than mutate the original
    const result = mergeSort([...items], composedComparer)
    return { items: result, ...rest }
  }
}

/**
 * Build a pagination pipeline operation from a PaginationConfig.
 * The resultant pipeline operation can be plugged into a pipeline
 * which paginates its items according to that config.
 * @param {number} perPage
 * @param {number} pageNumber
 * @returns {PipelineData}
 */
export function paginate<T>({
  perPage,
  pageNumber,
}: PaginationConfig): PipelineOperation<T, T> {
  return ({ items, ...rest }) => {
    if (isEmpty(items))
      return { ...rest, items: [], pageCount: 0, paginated: [] }

    const paginated = chunk(items, perPage)
    const pageCount = paginated.length || 1
    const maxPageIndex = pageCount - 1
    let pageIndex = (pageNumber || 1) - 1
    if (pageIndex < 0) {
      pageIndex = 0
    }
    if (pageIndex > maxPageIndex) {
      pageIndex = maxPageIndex
    }

    return { ...rest, items: paginated[pageIndex] ?? [], pageCount, paginated }
  }
}

/**
 * Build a pipeline operation for filtering from a FilterConfig.
 * The resultant pipeline operation can be plugged into a pipeline which
 * filters the items using a functon defined in the config.
 */
export function filter<T>({
  itemFilter,
}: FilterConfig<T>): PipelineOperation<T, T> {
  return ({ items, ...rest }) => {
    if (isEmpty(items)) return { ...rest, items: [] }
    return { ...rest, items: items.filter(itemFilter) }
  }
}
