import Service, { service } from '@ember/service'
import { AMER } from 'district-ui-client/domain/marketing-regions'
import type SessionService from 'district-ui-client/services/session'
import type FeaturesService from 'district-ui-client/services/features'

/**
 * A directory of feature access names / ids.
 *
 * Intentionally separate to products, as a feature access may be restricted to part of a product, or extend across
 * multiple.
 * Intentionally separate to feature flag names as we use them in conjunction with other logic to determine access. Also
 * feature access may be solely determined by logic other than flags.
 *
 * They're named to describe the feature being restricted. These are intended to be longer-lived than feature flags.
 */
export const FeatureAccess = {
  Standards: 'standards',
} as const

export type FeatureAccessId = (typeof FeatureAccess)[keyof typeof FeatureAccess]

/**
 * A service to help handle feature access. Intended to handle feature access logic regarding;
 * - countries/regions
 * - partners
 * - feature flags (runtime toggling of behaviour)
 *
 * Works similarly to feature flags service but takes into account other aspects that might impact whether a feature is
 * accessible to a user, allowing us to;
 * - maintain access logic on partially released features (eg a region-based rollout)
 * - expand to more regions by toggling a feature flag (eg released to AU, but FF toggle gives worldwide access)
 * - use a single feature access lock across multiple feature flags (where it makes sense to do so)
 * - have clearly defined & tested rules for access
 *
 * With this service, we keep feature flags as a simple, short-lived, runtime behaviour toggle.
 *
 * There is also a companion helper available {{#if (can-access "standards")}} ....
 *
 * @example <caption>partially released feature to single region</caption>
 * ```
 * // canAccess logic
 * case 'standards':
 *   return AMER.includes(countryCode)
 *
 * // usage
 * get showStandardsPerformanceReport() {
 *   return this.featureControl.canAccess('standards')
 * }
 * ```
 *
 * @example <caption>expand access to more regions with a feature flag</caption>
 * // canAccess logic
 * case 'standards':
 *   if (this.features.isEnabled('duiStandardsAnzFF')) {
 *     return AMER.includes(countryCode) || ANZ.includes(countryCode)
 *   }
 *   return AMER.includes(countryCode)
 *
 * // usage
 * get showStandardsPerformanceReport() {
 *   return this.featureControl.canAccess('standards')
 * }
 */
export default class FeatureControlService extends Service {
  @service session!: SessionService

  @service features!: FeaturesService

  canAccess(featureId: FeatureAccessId): boolean {
    const countryCode = this.session.currentDisco.countryCode
    switch (featureId) {
      case FeatureAccess.Standards:
        return this.features.isEnabled('duiGlobalStandardsFF') ? true : AMER.includes(countryCode)
      default:
        return false
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'feature-control': FeatureControlService
  }
}
