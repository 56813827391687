import Route from '@ember/routing/route'
import { service } from '@ember/service'
import { hash } from 'rsvp'

export default class TeachersRoute extends Route {
  @service clever

  @service store

  queryParams = {
    currentPage: {
      replace: true,
    },
    filter: {
      replace: true,
    },
    tab: {
      replace: true,
    },
  }

  model() {
    const { store } = this
    const { cleverDistrictId, cleverSchoolId, cleverSchool, subscriptionType } = this.modelFor('clever.match.schools')
    const blakeSchoolId = cleverSchool.get('blakeSchool.id')

    const cleverTeachers = store.query('clever/clever-teacher', { scope: `clever-schools/${cleverSchoolId}` })
    // Ensure the latest blake teachers & matches. The controller constructs blakeTeachers from a filtered live array
    const blakeTeachers = store.query('clever/teacher', {
      scope: `schools/${blakeSchoolId}`,
      include: 'clever-teacher-match,school',
    })

    return hash({
      cleverDistrictId,
      cleverSchoolId,
      blakeSchoolId,
      cleverSchool,
      cleverTeachers,
      blakeTeachers,
      subscriptionType,
    })
  }
}
