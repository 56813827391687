import { modifier } from 'ember-modifier'

interface Signature {
  Args: {
    Named: unknown
    Positional: [selector?: string]
  }
  Element: HTMLElement
}

/**
 * Focus an element when it is rendered on the page. This shouldn't be used for anything that appears on a page by
 * default, we don't want multiple instances of this modifier fighting for focus. Rather, it should be used for an
 * element that appears when an action is taken (such as clicking a button to reveal an input)
 *
 * Example usage:
 * {{#if this.showInput}}
 *   <input type="text" {{auto-focus}} />
 * {{else}}
 *   <button type="button" {{on "click" (fn this.setShowInput true)}}>
 *     click me to show input!
 *   </button>
 * {{/if}}
 *
 * An optional "selector" arg can be provided, in case you want to auto focus a child element.
 * <div {{auto-focus "input"}}>
 *   <input />
 * </div>
 */

export const autoFocus = modifier<Signature>(function autoFocus(element, params, _hash) {
  const [selector] = params
  const elementToFocus = selector ? element.querySelector<HTMLElement>(selector) : element
  elementToFocus?.focus()
})

export default autoFocus
