import Service, { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import { assert } from '@ember/debug'
import { waitFor } from '@ember/test-waiters'
import ky, { TimeoutError } from 'ky'
import isNetworkError from 'is-network-error'
import { getConfig } from '@blakeelearning/get-config'
import type { Log } from '@blakeelearning/log'
import type NetworkService from '@blakeelearning/device/device/network/service'
import type RefresherService from '../refresher/service'

const TWO_HOURS = 1000 * 60 * 60 * 2
const FIFTEEN_MINUTES = 1000 * 60 * 15

export const DEFAULT_MAX_RETRIES = 5
export const DEFAULT_INTERVAL = FIFTEEN_MINUTES
export const HARD_REFRESH_DELAY = TWO_HOURS

/**
 * Checks the backend for the latest release sha, compares with current sha
 * and schedules a refresh if they do not match.
 */
export default class ReleaseChecker extends Service {
  @service('device/network') declare network: NetworkService

  @service() declare refresher: RefresherService

  @service() declare router: RouterService

  @service() declare log: Log

  _nextReleaseCheck = 0

  _nextHardRefresh = Infinity

  get _upUrl(): string | undefined {
    return getConfig(this, 'appRefresher.releaseChecker.upUrl')
  }

  get _loadedSha(): string | undefined {
    return getConfig(this, 'APP.emberGitSha')
  }

  get _interval(): number {
    return getConfig(
      this,
      'appRefresher.releaseChecker.interval',
      DEFAULT_INTERVAL,
    )
  }

  get _maxRetries(): number {
    return getConfig(
      this,
      'appRefresher.releaseChecker.maxRetries',
      DEFAULT_MAX_RETRIES,
    )
  }

  private removeVisibilityChange = onVisibilityChange((isVisible) => {
    const started = this._nextReleaseCheck !== 0

    if (started && isVisible) {
      this._attemptReleaseCheck()
    }
  })

  /**
   * Sets the nextReleaseCheck time and listens for routeWillChange,
   * online, and focus event
   */
  start(): void {
    const notStarted = this._nextReleaseCheck === 0

    if (notStarted) {
      this._nextReleaseCheck = this._interval + Date.now()
      this.router.on('routeWillChange', () => {
        this._attemptReleaseCheck()
      })

      this.network.on('online', () => {
        this._attemptReleaseCheck()
      })
    }
  }

  override willDestroy(): void {
    this.removeVisibilityChange()
  }

  /**
   * Attempts a release check by comparing nextReleaseCheck time
   *
   * Also performs a hardRefresh if nextHardRefresh has been set
   */
  _attemptReleaseCheck(): void {
    const {
      _nextReleaseCheck: nextReleaseCheck,
      _interval: interval,
      _nextHardRefresh: nextHardRefresh,
    } = this
    const now = Date.now()
    const releaseCheckTimeout = now >= nextReleaseCheck
    const hardRefreshTimeout = now >= nextHardRefresh

    // Do not start a new release check if we are scheduling a hard refresh
    if (hardRefreshTimeout) {
      this.refresher.hardRefresh()
    } else if (releaseCheckTimeout) {
      this._nextReleaseCheck = now + interval
      void this._checkForNewRelease()
    }
  }

  _handleReleaseCheckError(error: unknown): void {
    if (typeof error === 'object' && error !== null) {
      console.log(error)
      this.log.error(
        `app refresher new release check failed after ${this._maxRetries.toFixed()} retries`,
        error,
      )
    }
  }

  @waitFor
  async _checkForNewRelease(): Promise<void> {
    const upUrl = this._upUrl
    const loadedSha = this._loadedSha
    const { isOffline } = this.network.status
    assert(
      'Configuration for appRefresher.releaseChecker.upUrl must be provided',
      typeof upUrl === 'string',
    )

    if (isOffline) {
      return
    }

    try {
      const response = await ky
        .get(upUrl, {
          retry: this._maxRetries,
        })
        .json<Record<'sha', 'string' | undefined>>()

      const releaseSha = response.sha
      if (releaseSha === undefined)
        throw Error(
          `Could not extract release sha from ${upUrl} response: ${JSON.stringify(
            response,
          )}`,
        )

      const appNeedsUpdate = releaseSha !== loadedSha

      if (appNeedsUpdate) {
        this._scheduleRefresh()
      }
    } catch (error) {
      if (isNetworkError(error) || error instanceof TimeoutError) {
        // Ignore network and timeout errors
        return
      }

      this._handleReleaseCheckError(error)
    }
  }

  _scheduleRefresh(): void {
    this.refresher.scheduleRefresh()
    // We schedule a hard refresh in case they never get to a safe point.
    this._nextHardRefresh = Date.now() + HARD_REFRESH_DELAY
  }
}

export function onVisibilityChange(callback: (isVisible: boolean) => void) {
  const handleVisibilityChange = () => {
    callback(document.visibilityState === 'visible')
  }

  window.addEventListener('visibilitychange', handleVisibilityChange)

  return () => {
    window.removeEventListener('visibilitychange', handleVisibilityChange)
  }
}

declare module '@ember/service' {
  interface Registry {
    'release-checker': ReleaseChecker
  }
}
