import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import {
  serialize as serializeSort,
  deserialize as deserializeSort,
  type SortingConfig,
} from '@blakeelearning/data-tables/utils/sorting'

export default class ReportingMsFluencySprintsTableController extends Controller {
  queryParams = ['sort']

  @tracked sort = 'schoolName' // default sort

  get sortingConfig() {
    return deserializeSort(this.sort)
  }

  set sortingConfig(newSortConfig) {
    this.sort = serializeSort(newSortConfig)
  }

  updateSorts = (sortingConfig: SortingConfig) => {
    this.sortingConfig = sortingConfig
  }
}
