/**
 * A type guard for checking if a key exists in an object.
 *
 * Ideally, you could just do "if (key in obj) return obj[key]" and TS would type narrow at build-time, but due to
 * reasons, it must be determined at run-time.
 * https://github.com/microsoft/TypeScript/issues/12892
 */
export function isKeyOf<T extends Record<PropertyKey, unknown>>(obj: T, maybeKey: PropertyKey): maybeKey is keyof T {
  return maybeKey in obj
}

/**
 * Check if a variable is an object containing a key.
 *
 * Useful for other type guard functions to check nested properties, especially chaining together.
 */
export function isObjectWithKey(obj: unknown, key: string): obj is { [key: string]: unknown } {
  return typeof obj === 'object' && obj !== null && key in obj
}
