import type { Result, ResultAsync } from 'neverthrow'
import { ok, err } from 'neverthrow'

export type TokenResult = Result<string, Error>

export type TokenResultAsync = ResultAsync<string, Error>

interface Input {
  value?: string
  reason?: string
  error?: Error
}

export function tokenResult({
  value,
  reason = 'Token was not provided',
  error = new Error(reason),
}: Input = {}): TokenResult {
  return value ? ok(value) : err(error)
}
