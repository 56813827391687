import Route from '@ember/routing/route'
import { action } from '@ember/object'
import { service } from '@ember/service'
import config from 'district-ui-client/config/environment'
import { timeout, task } from 'ember-concurrency'

const POLLINGTIMEOUT = 5000

/**
 * The initial-sync-in-progress route tries to call the clever district as long as the
 * sync state is not `done` We will be showing a loading icon and redirect to the
 * dashboard once the download has finished.
 */
export default class CleverIdInitialSyncInProgressRoute extends Route {
  @service router

  async model() {
    const { subscriptionType } = this.modelFor('subscription-type')
    const { cleverDistrictMatch } = this.modelFor('clever-id')
    this.fetchCleverDistrict.perform(cleverDistrictMatch)
    return { subscriptionType }
  }

  /**
   * A task to load the clever-district model until we reach the sync done state.
   */
  fetchCleverDistrict = task({ restartable: true, cancelOn: 'deactivate' }, async (cleverDistrictMatch) => {
    if (config.environment === 'test') return

    let cleverDistrict = null

    try {
      while (!cleverDistrict?.isDataInitialized) {
        // Load the cleverDistrict until it returns and ignore any 404s in the process.
        cleverDistrict = await cleverDistrictMatch.loadCleverDistrict({ ignore404: true })
        await timeout(POLLINGTIMEOUT)
      }
    } finally {
      this.router.replaceWith('clever.dashboard')
    }
  })

  /**
   * Never show the loading route because we're polling and refreshing the model
   * when it's loading (ie it already has its own progress indicator)
   * @returns {boolean}
   */
  @action
  loading() {
    return false
  }
}
