import Component from '@glimmer/component'
import { service } from '@ember/service'
/**
 * Given Clever ID or SIS ID already in use, user can re-assign the in-use
 * Clever ID or SIS ID, unmatching the teacher account for other teacher.
 * @example```hbs
 * <CleverUi::Modals::TeacherMatcher::IdMismatchError
 *   @cleverTeacher={{teacherRecord}}
 * >/
 * ```
 */
export default class IdMismatchError extends Component {
  @service
  intl

  @service
  store

  translated(key, options = {}) {
    return this.intl.t(`clever.teacherMatcher.errors.idMismatchError.${key}`, options)
  }

  get cleverTeacher() {
    return this.args.cleverTeacher
  }

  get otherTeacherId() {
    return this.cleverTeacher.matchError?.['other-teacher-id']
  }

  get otherTeacher() {
    return this.otherTeacherId ? this.store.peekRecord('clever/teacher', this.otherTeacherId) : {}
  }

  get title() {
    return this.translated('summary')
  }

  get teacherFirstName() {
    return this.cleverTeacher?.firstName
  }

  get teacherLastName() {
    return this.cleverTeacher?.lastName
  }

  get otherTeacherFirstName() {
    return this.otherTeacher?.firstName
  }

  get otherTeacherLastName() {
    return this.otherTeacher?.lastName
  }

  get explanationText() {
    const { otherTeacherFirstName, otherTeacherLastName } = this
    return this.translated('explanation', {
      htmlSafe: true,
      otherTeacherFirstName,
      otherTeacherLastName,
    })
  }

  get solutionText() {
    const { teacherFirstName, teacherLastName, otherTeacherFirstName, otherTeacherLastName } = this
    return this.translated('solution', {
      htmlSafe: true,
      teacherFirstName,
      teacherLastName,
      otherTeacherFirstName,
      otherTeacherLastName,
    })
  }

  get confirmLabel() {
    return this.translated('confirmLabel')
  }

  get positiveButtonText() {
    return this.translated('positiveButtonText')
  }
}
