import ApplicationJSONAPISerializer, { hasAttributes } from 'district-ui-client/serializers/application'

export default class DistrictCoordinatorSerializer extends ApplicationJSONAPISerializer {
  serialize(snapshot: ModelSnapshot, options: SerializerOptions) {
    const json = super.serialize(snapshot, options)

    if (hasAttributes(json)) {
      // additional options that might be provided with a teacher.save() call
      const { passwordCurrent, password, passwordConfirmation } = snapshot.adapterOptions ?? {}
      if (passwordCurrent && password && passwordConfirmation) {
        json.data.attributes['password-current'] = passwordCurrent
        json.data.attributes.password = password
        json.data.attributes['password-confirmation'] = passwordConfirmation
      }
    }

    return json
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'district-coordinator': DistrictCoordinatorSerializer
  }
}
