import Model, { belongsTo, attr } from '@ember-data/model'
import { service } from '@ember/service'
import type { IntlService } from 'ember-intl'
import type School from 'district-ui-client/models/school'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'

export const LICENCE_COUNT_UNLIMITED = 0

/**
 * Unlike school subscriptions, these sub utilisations are virtual records, they indicate the usage of a subscription
 * type (reading, maths or writing).
 *
 * If a utilisation record is present for a subscription type, it means that school has active access to that product.
 * The school may have multiple school-subscriptions overlapping, and the utilisation represents a summation of those
 * capacities.
 */
export default class SchoolSubscriptionUtilisation extends Model {
  @service intl!: IntlService

  @attr('date') startDate!: Date

  @attr('date') endDate!: Date

  /** the total licence capacity of the subscription (0 if unlimited) */
  @attr('number') licenceCount!: number
  /** the number of active licences - ie active students */
  @attr('number') studentCount!: number

  @attr('string') subscriptionType!: SubscriptionType

  @belongsTo('school', { async: false, inverse: 'schoolSubscriptionUtilisations' }) school!: School

  get isUnlimitedCapacity() {
    // Ensure both - If it's unlimited, it shouldn't ever be one or the other.
    return this.licenceCount === LICENCE_COUNT_UNLIMITED
  }

  get isOverCapacity() {
    return !this.isUnlimitedCapacity && this.studentCount > this.licenceCount
  }

  get formattedCapacity() {
    const licenceCount = this.isUnlimitedCapacity ? this.intl.t('unlimited') : this.licenceCount
    return `${this.studentCount} / ${licenceCount}`
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'school-subscription-utilisation': SchoolSubscriptionUtilisation
  }
}
