import Service, { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import type { Log } from '@blakeelearning/log'
import type DistrictCoordinator from 'district-ui-client/models/district-coordinator'
import type District from 'district-ui-client/models/district'

interface AuthUser {
  id: number
  districtId: number
  roles?: string[]
  type?: string
}

/**
 * Note, ids provided by the auth token are numbers.
 */
export interface DecodedAuthToken {
  data: {
    id: number
    district_id: number
    roles: string[]
    type: string
  }
}

export default class SessionService extends Service {
  @service log!: Log

  @tracked authUser?: AuthUser

  /**
   * The currently logged in disco.
   * This is set very early in the app lifecycle, so we declare it as ! to save complexity in the majority of its usage.
   */
  @tracked currentDisco!: DistrictCoordinator

  /**
   * The district of the currently logged in disco.
   * This is set very early in the app lifecycle, so we declare it as ! to save complexity in the majority of its usage.
   */
  @tracked currentDistrict!: District

  /**
   * Uses the decoded token from authtoken, if it exists, to create and set the AuthUser object for the current user and
   * subscription.
   */
  setAuthUser(decodedToken: DecodedAuthToken) {
    if (decodedToken) {
      const {
        data: { id, roles, district_id, type },
      } = decodedToken

      const authUser = {
        id,
        roles,
        districtId: district_id,
        type,
      }

      this.log.setPerson({ id: String(authUser.id) })
      this.authUser = authUser
    }
  }

  setDistrictCoordinator(districtCoordinator: DistrictCoordinator) {
    this.currentDisco = districtCoordinator
  }

  /**
   * District is not a relationship to district-coordinator, due to the different adapter types currently in use. For
   * the time being, we store it as state on the session service.
   */
  setDistrict(district: District) {
    this.currentDistrict = district
  }
}

declare module '@ember/service' {
  interface Registry {
    session: SessionService
  }
}
