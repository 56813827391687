import { template as template_f5c7ad03cd5e4816ae0dbf9c8d4a1e5d } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { fn, concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { assert } from '@ember/debug';
import { htmlSafe } from '@ember/template';
import { LinkTo } from '@ember/routing';
import type Owner from '@ember/owner';
import type Store from '@ember-data/store';
import type { SelectOption } from '@blakeelearning/ember-select';
import { task, type Task } from 'ember-concurrency';
import { formatDate, t, type IntlService } from 'ember-intl';
import { not, or } from 'ember-truth-helpers';
import type ActiveRouteService from 'district-ui-client/services/active-route';
import type ManageSchoolsEditRoute from 'district-ui-client/routes/subscription-type/manage/schools/edit';
import { PageTitle } from 'district-ui-client/components/page-title';
import { Panel, PanelHeader, PanelTitle, PanelBody } from 'district-ui-client/components/section';
import { ThemedButton, ThemedLink } from 'district-ui-client/components/themed-button';
import type FlashQueueService from 'district-ui-client/services/flash-queue';
import type School from 'district-ui-client/models/school';
import type ContactDetail from 'district-ui-client/models/contact-detail';
import type Teacher from 'district-ui-client/models/teacher';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { ModalBody, ModalFooter, ModalHeader, ModalHeading, ThemedModal } from 'district-ui-client/components/themed-modal';
import { RolloverAlert } from 'district-ui-client/components/rollover-alert';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { FormItemHorizontal, TextField } from 'district-ui-client/components/base/form';
import { eventValue } from 'district-ui-client/helpers/event-value';
import { formatValue } from 'district-ui-client/utils/format-value';
import { TableContainer, Table, TRHead, TBody, TH, TD } from 'district-ui-client/components/table';
import type { Log } from '@blakeelearning/log';
import { InlineMessage, AlertType, InlineTitle, InlineSubtitle } from 'district-ui-client/components/inline-alert';
import type SchoolSubscription from 'district-ui-client/models/school-subscription';
import { SelectBox } from 'district-ui-client/components/select-box';
import type SchoolSubscriptionUtilisation from 'district-ui-client/models/school-subscription-utilisation';
import { DividerHorizontal } from 'district-ui-client/components/divider-horizontal';
import type { State } from 'reactiveweb/function';
import { trackedFunction } from 'reactiveweb/function';
interface Signature {
    Args: {
        model: ModelFor<ManageSchoolsEditRoute>;
    };
}
class ManageSchoolsEditTemplate extends Component<Signature> {
    @service
    activeRoute: ActiveRouteService;
    get subscriptionType() {
        return this.activeRoute.subscriptionType;
    }
    static{
        template_f5c7ad03cd5e4816ae0dbf9c8d4a1e5d(`
    <LinkTo
      @route="subscription-type.manage.schools"
      class="text-neutral-250 mb-1 inline-block py-3 text-xs font-medium uppercase"
    >
      {{t "subscriptionType.manageSchools.index.manageSchools"}}
    </LinkTo>
    <PageTitle>{{@model.school.name}}</PageTitle>

    <div class="mt-8 space-y-6">
      <RolloverAlert />

      <Panel>
        <PanelHeader>
          <PanelTitle>{{t "subscriptionType.manageSchools.edit.schoolDetails"}}</PanelTitle>
        </PanelHeader>
        <PanelBody>
          <EditSchoolDetailsForm @school={{@model.school}} @subscriptionType={{this.subscriptionType}} />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader>
          <PanelTitle>{{t "subscriptionType.manageSchools.edit.subscriptionDetails"}}</PanelTitle>
        </PanelHeader>
        <PanelBody>
          <SubscriptionDetails @school={{@model.school}} @subscriptionType={{this.subscriptionType}} />
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader>
          <PanelTitle>
            {{t "subscriptionType.manageSchools.edit.subscriptionCapacity"}}
          </PanelTitle>
        </PanelHeader>
        <PanelBody @tight={{true}}>
          <div class="px-6">
            <CapacityBar @school={{@model.school}} @subscriptionType={{this.subscriptionType}} />
            <DividerHorizontal class="my-6" />
            <h3 class="mb-4 font-medium">
              {{t "subscriptionType.manageSchools.edit.capacityPanel.tableHeading"}}
            </h3>
          </div>
          <SchoolSubscriptionsTable @school={{@model.school}} @subscriptionType={{this.subscriptionType}} />
        </PanelBody>
      </Panel>

    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
/**
 * We wrap in a changeset class (rather than editing the contact-detail record directly) so that we don't need to
 * rollback when leaving.
 */ class ContactDetailChangeset {
    contactDetail: ContactDetail;
    keys = [
        'town',
        'address1',
        'phoneNumber',
        'faxNumber'
    ] as const;
    @tracked
    town = '';
    @tracked
    address1 = '';
    @tracked
    phoneNumber = '';
    @tracked
    faxNumber = '';
    constructor(contactDetail: ContactDetail){
        this.contactDetail = contactDetail;
        this.keys.forEach((key)=>(this[key] = this.contactDetail[key]));
    }
    save(saveOptions?: Record<string, unknown>) {
        this.keys.forEach((key)=>(this.contactDetail[key] = this[key]));
        return this.contactDetail.save(saveOptions);
    }
}
interface EditSchoolDetailsFormSignature {
    Element: HTMLFormElement;
    Args: {
        school: School;
        subscriptionType: SubscriptionType;
    };
}
class EditSchoolDetailsForm extends Component<EditSchoolDetailsFormSignature> {
    @service
    flashQueue: FlashQueueService;
    @service
    intl: IntlService;
    @service
    log: Log;
    @tracked
    changeset: ContactDetailChangeset;
    @tracked
    isEditing = false;
    constructor(owner: Owner, args: EditSchoolDetailsFormSignature['Args']){
        super(owner, args);
        this.resetChangeset();
    }
    setEditMode = (value: boolean)=>{
        this.isEditing = value;
    };
    save = task({
        drop: true
    }, async (event: Event)=>{
        event.preventDefault();
        try {
            await this.changeset.save({
                adapterOptions: {
                    schoolId: this.args.school.id
                }
            });
            this.setEditMode(false);
            this.flashQueue.addSuccess({
                title: this.intl.t('subscriptionType.manageSchools.edit.editSchoolSuccessMessage')
            });
        } catch (e) {
            this.log.error('failed to save school details', e ?? undefined);
            this.flashQueue.addFail({
                title: this.intl.t('subscriptionType.manageSchools.edit.editSchoolFailedMessage')
            });
        }
    });
    reset = (event: Event)=>{
        event.preventDefault();
        this.setEditMode(false);
        this.resetChangeset();
    };
    resetChangeset = ()=>{
        assert('school must have a contact-detail record', this.args.school.contactDetail);
        this.changeset = new ContactDetailChangeset(this.args.school.contactDetail);
    };
    setContactDetail = (key: ContactDetailChangeset['keys'][number], value: string)=>{
        this.changeset[key] = value;
    };
    static{
        template_f5c7ad03cd5e4816ae0dbf9c8d4a1e5d(`
    <form data-test-edit-school-form {{on "submit" this.save.perform}} {{on "reset" this.reset}} ...attributes>
      <div class="space-y-2">
        <FormItemHorizontal @label={{t "subscriptionType.manageSchools.edit.schoolName"}}>
          <TextField data-test-school-name value={{@school.name}} @readOnly={{true}} />
        </FormItemHorizontal>

        <FormItemHorizontal @label={{t "subscriptionType.manageSchools.edit.schoolCode"}}>
          <TextField value={{@school.schoolCode}} @readOnly={{true}} />
        </FormItemHorizontal>

        <FormItemHorizontal @label={{t "subscriptionType.manageSchools.edit.city"}}>
          <TextField
            data-test-school-town
            value={{this.changeset.town}}
            @readOnly={{not this.isEditing}}
            {{on "input" (eventValue (fn this.setContactDetail "town"))}}
          />
        </FormItemHorizontal>

        <FormItemHorizontal @label={{t "subscriptionType.manageSchools.edit.address1"}}>
          <TextField
            value={{this.changeset.address1}}
            @readOnly={{not this.isEditing}}
            {{on "input" (eventValue (fn this.setContactDetail "address1"))}}
          />
        </FormItemHorizontal>

        <FormItemHorizontal @label={{t "subscriptionType.manageSchools.edit.phoneNumber"}}>
          <TextField
            value={{this.changeset.phoneNumber}}
            @readOnly={{not this.isEditing}}
            {{on "input" (eventValue (fn this.setContactDetail "phoneNumber"))}}
          />
        </FormItemHorizontal>

        <FormItemHorizontal @label={{t "subscriptionType.manageSchools.edit.faxNumber"}}>
          <TextField
            value={{this.changeset.faxNumber}}
            placeholder={{t "na"}}
            @readOnly={{not this.isEditing}}
            {{on "input" (eventValue (fn this.setContactDetail "faxNumber"))}}
          />
        </FormItemHorizontal>
      </div>

      <div class="mt-6 flex gap-2">
        {{#if this.isEditing}}
          <ThemedButton @style="neutral" type="reset">{{t "cancel"}}</ThemedButton>
          <ThemedButton @style="theme" type="submit">{{t "save"}}</ThemedButton>
        {{else}}
          <ThemedButton data-test-edit @style="neutral" {{on "click" (fn this.setEditMode true)}}>
            <FaIcon @icon="pencil" />
            {{t "editDetails"}}
          </ThemedButton>
        {{/if}}
      </div>
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface SubscriptionDetailsSignature {
    Args: {
        school: School;
        subscriptionType: SubscriptionType;
    };
    Element: HTMLDivElement;
}
class SubscriptionDetails extends Component<SubscriptionDetailsSignature> {
    @service
    flashQueue: FlashQueueService;
    @service
    intl: IntlService;
    @service
    store: Store;
    @service
    log: Log;
    @tracked
    showModal = false;
    fetchTeachers = trackedFunction(this, async ()=>{
        return (await this.store.query('teacher', {
            scope: `schools/${this.args.school.id}`,
            include: 'school'
        })).slice();
    });
    get subcoForSchoolAndProduct() {
        return this.args.school.subscriptionCoordinators?.find((subco)=>subco.isForSubscriptionType(this.args.subscriptionType));
    }
    get subcoTeacherForSchoolAndProduct() {
        return this.subcoForSchoolAndProduct?.teacher;
    }
    editSubcoTask = task({
        drop: true
    }, async (teacher: Teacher)=>{
        try {
            await this.subcoForSchoolAndProduct?.destroyRecord();
            const subcoRecord = this.store.createRecord('subscription-coordinator');
            subcoRecord.school = teacher.school;
            subcoRecord.teacher = teacher;
            subcoRecord.product = this.args.subscriptionType;
            await subcoRecord.save();
            this.flashQueue.addSuccess({
                title: this.intl.t('subscriptionType.manageSchools.edit.editSubcoSuccessMessage'),
                subtitle: this.intl.t('subscriptionType.manageSchools.edit.editSubcoSuccessMessageDetail', {
                    teacherName: teacher.fullName,
                    schoolName: this.args.school.name
                })
            });
        } catch (e) {
            this.log.error('failed to save changed subco', e ?? undefined);
            this.flashQueue.addFail({
                title: this.intl.t('subscriptionType.manageSchools.edit.editSubcoFailedMessage')
            });
        }
        this.showSubcoSelectModal(false);
    });
    showSubcoSelectModal = (value: boolean)=>{
        this.showModal = value;
    };
    static{
        template_f5c7ad03cd5e4816ae0dbf9c8d4a1e5d(`
    <FormItemHorizontal @label={{t "subscriptionType.manageSchools.edit.subscriptionCoordinator"}}>
      <TextField
        data-test-subco
        value={{formatValue this.subcoTeacherForSchoolAndProduct.fullName replacement=(t "na")}}
        @readOnly={{true}}
      />
    </FormItemHorizontal>

    <ThemedButton
      data-test-change-subco
      @style="neutral"
      {{on "click" (fn this.showSubcoSelectModal true)}}
      class="mt-6"
    >
      <FaIcon @icon="user-pen" />
      {{t "subscriptionType.manageSchools.edit.changeCoordinator"}}
    </ThemedButton>
    {{#if this.subcoTeacherForSchoolAndProduct.subcoLoginPath}}
      <ThemedLink
        data-test-subco-sso-link
        @style="neutral"
        class="mt-6"
        href={{this.subcoTeacherForSchoolAndProduct.subcoLoginPath}}
      >
        <FaIcon @icon="right-from-bracket" />
        {{t "subscriptionType.manageSchools.edit.loginAsSubscriptionCoordi"}}
      </ThemedLink>
    {{/if}}

    <EditSubcoModal
      @show={{this.showModal}}
      @currentSubcoId={{this.subcoTeacherForSchoolAndProduct.id}}
      @fetchTeachers={{this.fetchTeachers}}
      @editSubcoTask={{this.editSubcoTask}}
      @onCancel={{fn this.showSubcoSelectModal false}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface EditSubcoModalSignature {
    Args: {
        fetchTeachers: State<Promise<Teacher[]>>;
        show?: boolean;
        currentSubcoId?: string;
        editSubcoTask: Task<unknown, [Teacher]>;
        onCancel: () => unknown;
    };
}
export class EditSubcoModal extends Component<EditSubcoModalSignature> {
    @tracked
    selectedId?: string;
    get value() {
        return this.selectedId ?? this.args.currentSubcoId;
    }
    get teachers() {
        return this.args.fetchTeachers.value ?? [];
    }
    get options() {
        return this.teachers.map((teacher)=>({
                value: teacher.id,
                label: teacher.fullName
            }));
    }
    get selectedTeacher() {
        return this.teachers.find((teacher)=>teacher.id === this.selectedId);
    }
    onSelect = ({ value }: SelectOption)=>{
        this.selectedId = value;
    };
    onSubmit = ()=>{
        if (this.selectedTeacher) void this.args.editSubcoTask.perform(this.selectedTeacher);
    };
    static{
        template_f5c7ad03cd5e4816ae0dbf9c8d4a1e5d(`
    <ThemedModal data-test-edit-subco-modal @show={{@show}} @close={{@onCancel}}>
      <ModalHeader>
        <ModalHeading>{{t "subscriptionType.manageSchools.edit.selectSubcoModalTitle"}}</ModalHeading>
      </ModalHeader>
      <ModalBody class="w-[600px]">
        <SelectBox
          data-test-select-teacher
          class="my-4"
          @style="neutral"
          @placeholder={{if
            @fetchTeachers.isPending
            (t "loading")
            (t "subscriptionType.manageSchools.edit.selectATeacher")
          }}
          @searchPlaceholder={{t "subscriptionType.manageSchools.edit.searchATeacher"}}
          @value={{this.value}}
          @options={{this.options}}
          @onSelect={{this.onSelect}}
          @matchTriggerWidth={{true}}
        />
      </ModalBody>
      <ModalFooter>
        <ThemedButton
          data-test-cancel
          class="min-w-24"
          @disabled={{@editSubcoTask.isRunning}}
          @style="text"
          {{on "click" @onCancel}}
        >{{t "cancel"}}</ThemedButton>
        <ThemedButton
          data-test-submit
          class="min-w-24"
          @disabled={{or @editSubcoTask.isRunning (not this.selectedId)}}
          @style="theme"
          {{on "click" this.onSubmit}}
        >
          {{#if @editSubcoTask.isRunning}}
            <FaIcon @icon="circle-notch" @spin={{true}} />
          {{else}}
            {{t "save"}}
          {{/if}}
        </ThemedButton>
      </ModalFooter>
    </ThemedModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface CapacityBarSignature {
    Element: HTMLDivElement;
    Args: {
        school: School;
        subscriptionType: SubscriptionType;
    };
}
export class CapacityBar extends Component<CapacityBarSignature> {
    get utilisation() {
        return this.args.school.utilisationFor(this.args.subscriptionType);
    }
    get pendingSubscription(): SchoolSubscription | undefined {
        const pendingSub = this.args.school.schoolSubscriptions?.find((sub)=>sub.isPending && sub.subscriptionType === this.args.subscriptionType);
        return pendingSub;
    }
    get leftPercentage(): number {
        // no utilisation - mustn't have an active subscription
        if (!this.utilisation) return 0;
        // unlimited - licence count is 0
        if (this.utilisation.isUnlimitedCapacity) return 100 // just show 100% utilisation if they're unlimited
        ;
        // under - licence count should never be 0 (if it was, unlimited will have been true)
        if (!this.utilisation.isOverCapacity) return (this.utilisation.studentCount / this.utilisation.licenceCount) * 100;
        // must be over - in this case, we want the right side to show the exceeded amount moving back into the left part
        const exceededBy = this.utilisation.studentCount - this.utilisation.licenceCount;
        // Gets ratio of exceeded students to non-exceeded, - 1 gives us the left side non-exceeded-count ratio.
        // student count should always be greater than exceededBy so this ratio will always be <= 1
        const leftSide = 1 - exceededBy / this.utilisation.studentCount;
        const leftPercentage = leftSide * 100;
        return Math.min(leftPercentage, 100) // max out at 100.
        ;
    }
    get rightPercentage(): number {
        return 100 - this.leftPercentage;
    }
    get leftBasis() {
        return htmlSafe(`flex-basis: ${this.leftPercentage}%`);
    }
    get rightBasis() {
        return htmlSafe(`flex-basis: ${this.rightPercentage}%`);
    }
    get exceededByCount() {
        if (!this.utilisation) return 0;
        return this.utilisation.studentCount - this.utilisation.licenceCount;
    }
    static{
        template_f5c7ad03cd5e4816ae0dbf9c8d4a1e5d(`
    {{#if this.utilisation}}
      <div ...attributes>

        <CapacityNumbers @utilisation={{this.utilisation}} />

        <div
          class="my-4 flex h-2 overflow-hidden rounded-full bg-white {{if this.utilisation.isOverCapacity 'gap-0.5'}}"
        >
          <div
            style={{this.leftBasis}}
            class="{{if this.utilisation.isOverCapacity 'bg-juicy-orange-100' 'bg-green-300'}}"
          ></div>
          <div
            style={{this.rightBasis}}
            class="{{if this.utilisation.isOverCapacity 'bg-juicy-orange-350' 'bg-neutral-75'}}"
          ></div>
        </div>

        <div class="space-y-4">
          {{#if this.utilisation.isOverCapacity}}
            <InlineMessage data-test-inline-message-over-capacity @type={{AlertType.Warning}}>
              <InlineTitle>
                {{t
                  "subscriptionType.manageSchools.edit.capacityPanel.overCapacityMessageHeading"
                  count=this.exceededByCount
                }}
              </InlineTitle>
              <InlineSubtitle>
                <div class="mb-0">
                  <LinkTo
                    data-test-inline-message-contact-us
                    @route="subscription-type.support"
                    class="text-oceany-blue-325 hover:text-oceany-blue-350 focus:text-oceany-blue-350"
                  >
                    {{t "subscriptionType.manageSchools.edit.capacityPanel.supportLink"}}
                  </LinkTo>
                  {{t "subscriptionType.manageSchools.edit.capacityPanel.toIncreaseCapacity"}}
                </div>
                <div class="mb-0 text-sm">
                  {{t "subscriptionType.manageSchools.edit.capacityPanel.studentPrograms"}}
                </div>
              </InlineSubtitle>
            </InlineMessage>
          {{/if}}

          {{#if this.pendingSubscription}}
            <InlineMessage data-test-inline-message-pending-subscription @type={{AlertType.Information}}>
              <InlineTitle>
                {{t "subscriptionType.manageSchools.edit.capacityPanel.pendingSubMessageHeading"}}
              </InlineTitle>
              <InlineSubtitle>

                {{t "subscriptionType.manageSchools.edit.capacityPanel.pendingSubActiveDate"}}
                {{formatDate this.pendingSubscription.startDate day="numeric" month="long" year="numeric"}}

              </InlineSubtitle>

            </InlineMessage>
          {{/if}}
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface CapacityNumbersInterface {
    Args: {
        utilisation: SchoolSubscriptionUtilisation;
    };
}
export class CapacityNumbers extends Component<CapacityNumbersInterface> {
    get remainingLicenseCount() {
        return this.args.utilisation.licenceCount - this.args.utilisation.studentCount;
    }
    get exceededByCount() {
        return this.args.utilisation.studentCount - this.args.utilisation.licenceCount;
    }
    static{
        template_f5c7ad03cd5e4816ae0dbf9c8d4a1e5d(`
    <div class="flex justify-between font-medium">
      {{#if @utilisation.isUnlimitedCapacity}}
        <div data-test-capacity-bar-unlimited-count class="text-green-350">
          <span class="text-xl">
            {{@utilisation.studentCount}}
          </span>
          {{t "subscriptionType.manageSchools.edit.capacityPanel.students"}}
        </div>
        <div class="text-neutral-250">
          {{t "subscriptionType.manageSchools.edit.capacityPanel.unlimited"}}
        </div>

      {{else if @utilisation.isOverCapacity}}
        <div data-test-capacity-bar-exceeded-text class="text-orange-400">
          <span class="text-xl">
            {{@utilisation.studentCount}}
            /
            {{@utilisation.licenceCount}}
          </span>
          {{t "subscriptionType.manageSchools.edit.capacityPanel.studentsExceeded"}}
        </div>
        <div class="text-neutral-250">
          {{t "subscriptionType.manageSchools.edit.capacityPanel.exceededBy"}}
          <span class="text-xl">
            {{this.exceededByCount}}
          </span>
        </div>

      {{else}}
        <div data-test-capacity-bar-count-text class="text-green-350">
          <span class="text-xl">
            {{@utilisation.studentCount}}
            /
            {{@utilisation.licenceCount}}
          </span>
          {{t "subscriptionType.manageSchools.edit.capacityPanel.students"}}
        </div>
        <div data-test-capacity-bar-remaining class="text-neutral-250">
          <span class="text-xl">
            {{this.remainingLicenseCount}}
          </span>
          {{t "subscriptionType.manageSchools.edit.capacityPanel.remaining"}}
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface TableSignature {
    Element: HTMLDivElement;
    Args: {
        school: School;
        subscriptionType: SubscriptionType;
    };
}
export class SchoolSubscriptionsTable extends Component<TableSignature> {
    get schoolSubscriptions() {
        return (this.args.school.schoolSubscriptions?.filter((sub)=>sub.subscriptionType === this.args.subscriptionType) ?? []);
    }
    get arrangedContent() {
        return this.schoolSubscriptions.sort((a, b)=>b.endDate.getTime() - a.endDate.getTime());
    }
    static{
        template_f5c7ad03cd5e4816ae0dbf9c8d4a1e5d(`
    <TableContainer>
      <Table data-test-subscriptions-table @minWidth="min-w-[36rem]" class="bg-white" ...attributes>
        <thead>
          <TRHead>
            <TH>{{t "components.schoolSubscriptions.name"}}</TH>
            <TH>{{t "components.schoolSubscriptions.startDate"}}</TH>
            <TH>{{t "components.schoolSubscriptions.endDate"}}</TH>
            <TH>{{t "components.schoolSubscriptions.status"}}</TH>
            <TH>{{t "components.schoolSubscriptions.studentLimit"}}</TH>
          </TRHead>
        </thead>
        <TBody>
          {{#each this.arrangedContent as |sub|}}
            <tr>
              <TD>
                {{t (concat "subscriptions." sub.subscriptionType)}}
              </TD>
              <TD>
                {{formatDate sub.startDate day="numeric" month="long" year="numeric"}}
              </TD>
              <TD>
                {{formatDate sub.endDate day="numeric" month="long" year="numeric"}}
              </TD>
              <TD>
                {{#if sub.isPending}}
                  <FaIcon @icon="clock" class="text-blue-325" />
                  {{t "subscriptionStatus.upcoming"}}
                {{else if sub.isExpired}}
                  <FaIcon @icon="circle-xmark" class="text-neutral-250" />
                  {{t "subscriptionStatus.inactive"}}
                {{else if sub.isActive}}
                  <FaIcon @icon="circle-check" class="text-green-300" />
                  {{t "subscriptionStatus.active"}}
                {{/if}}
              </TD>
              <TD>
                {{sub.formattedCapacity}}
              </TD>
            </tr>
          {{/each}}
        </TBody>
      </Table>
    </TableContainer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ManageSchoolsEditTemplate);
