/**
 * This file is an attempt to have a place for common highcharts css selectors, as sometimes highcharts will change the
 * classnames they use.
 *
 * Mostly used for tests
 */

export const containerSelector = '.highcharts-container'

export const chartTitleSelector = '.highcharts-title'
export const xAxisTitleSelector = '.highcharts-xaxis > .highcharts-axis-title'
export const yAxisTitleSelector = '.highcharts-yaxis > .highcharts-axis-title'
export const xAxisLabelsSelector = '.highcharts-xaxis-labels'
export const yAxisLabelsSelector = '.highcharts-yaxis-labels'

export const dataLabelsSelector = '.highcharts-data-labels'

export const seriesSelector = '.highcharts-series'
export const markersSelector = '.highcharts-markers'
export function seriesItemSelector(index: number): string {
  return `${seriesSelector}.highcharts-series-${index}`
}
export function markersItemSelector(index: number): string {
  return `${markersSelector}.highcharts-series-${index}`
}
