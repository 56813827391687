import { helper as buildHelper } from '@ember/component/helper'
import { isNone } from '@ember/utils'
import { formattedHumanizedDuration } from 'district-ui-client/utils/format-humanized-duration'

function formatHumanizedDuration([seconds, replacement]: unknown[]) {
  const secondsValue = typeof seconds === 'number' || isNone(seconds) ? seconds : null
  const replacementValue = typeof replacement === 'string' || isNone(replacement) ? replacement : null

  return formattedHumanizedDuration(secondsValue, replacementValue)
}
export default buildHelper(formatHumanizedDuration)
