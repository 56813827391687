import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import type SessionService from 'district-ui-client/services/session'

/**
 * Landing route handles what happens when user visits /
 * We want to redirect them to one of the subscription-type tabs
 */
export default class LandingRoute extends Route {
  @service router!: RouterService

  @service session!: SessionService

  beforeModel() {
    const firstSubscription = this.session.currentDisco.subscriptionTypes[0]
    if (firstSubscription) {
      void this.router.transitionTo('subscription-type', firstSubscription)
    } else {
      // let the application route error handler deal with this one. will log & go to application-error page
      throw new Error('invalid subscriptions')
    }
  }
}
