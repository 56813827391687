import { timeout } from 'ember-concurrency'
import { tracked } from '@glimmer/tracking'
import { get } from '@ember/object'
import Utils from 'district-ui-client/student-import/utils'
import config from 'district-ui-client/config/environment'
import ErrorCodes from 'district-ui-client/student-import/error-codes'

const {
  csvImport: { initialValidationPollTimeoutMs, validationPollTimeoutMs },
} = config
const { JOB_FAILED, CSV_INVALID, GET_JOB_RESULT_FAILED } = ErrorCodes

export default {
  job: tracked(),

  get jobId() {
    return this.job?.id
  },

  get isValid() {
    return Boolean(get(this, 'value.ok'))
  },

  get errorCode() {
    return get(this, 'value.errorCode')
  },

  get validationErrors() {
    return get(this, 'value.validationErrors')
  },

  /**
   * @param {Function} params.validateCsvPollFn
   * @param {Function} params.validationResultFn
   * @returns {Promise<Object>}
   */
  *perform({ validateCsvPollFn, validateCsvResultFn }, { intl }) {
    yield timeout(initialValidationPollTimeoutMs)
    while (true) {
      this.job = yield validateCsvPollFn()
      if (!this.job.isStillRunning) break
      yield timeout(validationPollTimeoutMs)
    }

    if (this.job?.isComplete) {
      const response = yield validateCsvResultFn()
      const responseBody = yield response.json()
      if (response?.ok) {
        return {
          ok: true,
          candidateStudents: Utils.normalizeValidateCsvResponse(responseBody),
        }
      } else if (response?.status === 422) {
        return {
          ok: false,
          errorCode: CSV_INVALID,
          validationErrors: Utils.formatValidationErrors(responseBody, { intl }),
        }
      } else {
        return {
          ok: false,
          errorCode: GET_JOB_RESULT_FAILED,
          jobId: this.job?.id,
          response,
          responseBody,
        }
      }
    } else {
      return {
        ok: false,
        errorCode: JOB_FAILED,
        jobId: this.job?.id,
        jobStatus: this.job?.status,
      }
    }
  },
}
