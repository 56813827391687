import { A } from '@ember/array'
import { Promise } from 'rsvp'
import { isEmpty } from '@ember/utils'

/**
 * Fetches the stories_written_by_grade endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function storiesWrittenByGrade(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('stories_written_by_grade', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const { stories: stories_written_by_grade, student_count } = data.stories_written_by_grade

    if (stories_written_by_grade.length === 0) {
      return null
    }

    const filteredStoriesWritten = stories_written_by_grade.filter((grade) => grade.grade_position !== null)

    return {
      stories_written_by_grade: A(filteredStoriesWritten).sortBy('grade_position'),
      student_count,
    }
  })
}
