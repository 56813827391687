import { modifier } from 'ember-modifier'
import type { Chart } from 'highcharts'

interface Signature {
  Args: {
    Named: unknown
    Positional: [chart?: Chart]
  }
  Element: HTMLElement
}

export default modifier<Signature>(function printReflow(_element, [chart]) {
  const reflowHandler = (mediaQueryEvent: MediaQueryListEvent) => {
    /* In both cases we just reflow the chart. But if you wanted to do something different here (eg set the chart to
     * black and white, or some other chart option), you could.
     */
    const isPrint = mediaQueryEvent.matches
    if (isPrint) {
      chart?.reflow()
    } else {
      chart?.reflow()
    }
  }

  /* Note: match media print does not fire on Firefox and IE
   * https://bugzilla.mozilla.org/show_bug.cgi?id=774398
   *
   * This still seems to be the best way to do it. beforeprint and afterprint events can be listened to, but beforeprint
   * fires before the document has been resized to the print media, so isn't useful to us.
   *
   * Highcharts have had this as an open issue since 2013, but there has been some recent work on it
   * https://github.com/highcharts/highcharts/issues/2284
   * https://github.com/highcharts/highcharts/pull/15112
   */
  const printMedia = window.matchMedia('print')
  printMedia?.addEventListener?.('change', reflowHandler)

  return () => {
    printMedia?.removeEventListener?.('change', reflowHandler)
  }
})
