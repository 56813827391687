import Route from '@ember/routing/route'

/**
 * The route gets used when we want to to start an initial download for a subscription type
 * You can only ever start one download at a time.
 *
 * @Class StartInitialSyncRoute
 */
export default class StartInitialSyncRoute extends Route {
  model() {
    const { subscriptionType } = this.modelFor('subscription-type')
    const { cleverDistrictMatch } = this.modelFor('clever-id')
    return { subscriptionType, cleverDistrictMatch }
  }
}
