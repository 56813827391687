import Component from '@glimmer/component'
import { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { didCancel } from 'ember-concurrency'
import { getOwner } from '@ember/owner'
import { ErrorCodes } from 'district-ui-client/services/clever/teacher-match-error'

const errorCodeIntlKeyPrefixes = {
  [ErrorCodes.EXISTS_IN_DIFFERENT_DISTRICT]: 'clever.teacherMatcher.errors.existsInDifferentDistrict',
  [ErrorCodes.EXISTS_AS_PARENT]: 'clever.teacherMatcher.errors.existsAsParent',
  [ErrorCodes.EXISTS_AS_PARENT_CONTACT]: 'clever.teacherMatcher.errors.existsAsParentContact',
  [ErrorCodes.ID_MISMATCH_ERROR]: 'clever.teacherMatcher.errors.idMismatchError',
  [ErrorCodes.STALE_ACCOUNT_ERROR]: 'clever.teacherMatcher.errors.staleAccountError',
  [ErrorCodes.INVALID_EMAIL_CHAR]: 'clever.teacherMatcher.errors.invalidEmailChar',
}
export default class TeacherMatchErrorComponent extends Component {
  @service
  flashQueue

  @service
  intl

  @service
  store

  @service('clever/teacher-match-error')
  teacherMatchError

  @tracked
  isModalVisible = false

  @tracked
  _remedyTaskInstance = null

  get matchErrorCode() {
    return this.args.teacher.matchErrorCode
  }

  get summaryText() {
    const defaultPrefix = 'clever.teacherMatcher.errors.unknown'
    const prefix = errorCodeIntlKeyPrefixes[this.matchErrorCode] ?? defaultPrefix // use default if no key defined

    const defaultSummaryKey = `${defaultPrefix}.summary`
    const summaryKey = `${prefix}.summary`
    // use default key if no translation defined for summary key
    return this.intl.exists(summaryKey) ? this.intl.t(summaryKey) : this.intl.t(defaultSummaryKey)
  }

  get modalComponentName() {
    const defaultModalComponentPath = 'clever-ui/modals/teacher-matcher/unknown'
    const modalComponentPaths = {
      [ErrorCodes.EXISTS_IN_DIFFERENT_DISTRICT]: 'clever-ui/modals/teacher-matcher/exists-in-different-district',
      [ErrorCodes.EXISTS_AS_PARENT]: 'clever-ui/modals/teacher-matcher/exists-as-parent',
      [ErrorCodes.EXISTS_AS_PARENT_CONTACT]: 'clever-ui/modals/teacher-matcher/exists-as-parent-contact',
      [ErrorCodes.ID_MISMATCH_ERROR]: 'clever-ui/modals/teacher-matcher/id-mismatch-error',
      [ErrorCodes.STALE_ACCOUNT_ERROR]: 'clever-ui/modals/teacher-matcher/stale-account-error',
      [ErrorCodes.INVALID_EMAIL_CHAR]: 'clever-ui/modals/teacher-matcher/invalid-email-char',
    }

    const componentPath = modalComponentPaths[this.matchErrorCode]
    // https://api.emberjs.com/ember/3.27/classes/ApplicationInstance/methods/hasRegistration?anchor=hasRegistration
    const componentExists = componentPath && Boolean(getOwner(this).hasRegistration(`component:${componentPath}`))

    return componentExists ? componentPath : defaultModalComponentPath
  }

  get _isPerformingRemedy() {
    return this._remedyTaskInstance?.isRunning
  }

  @action
  async showModal() {
    // If there's some extra data the modal's going to need, fetch it here.
    // In some scenarios, the other teacher may belong to a different school, so it may not be in the store
    const { matchErrorCode } = this
    const { matchError } = this.args.teacher
    const isStaleOrMismatch = [ErrorCodes.STALE_ACCOUNT_ERROR, ErrorCodes.ID_MISMATCH_ERROR].includes(matchErrorCode)
    const otherTeacherId = matchError?.['other-teacher-id']
    if (isStaleOrMismatch && otherTeacherId) await this.store.findRecord('clever/teacher', otherTeacherId)

    this.isModalVisible = true
  }

  @action
  hideModal() {
    this.isModalVisible = false
  }

  @action
  cancel() {
    this.hideModal()
  }

  @action
  submit() {
    const {
      args: { teacher },
      matchErrorCode,
    } = this
    if (this.teacherMatchError.hasRemedyForCode(matchErrorCode)) {
      this._remedyTaskInstance = this.teacherMatchError.remedy.perform(teacher, matchErrorCode, {
        onComplete: this.onRemedyComplete,
        onError: this.onRemedyError,
      })
    }
    this.hideModal()
  }

  @action
  async onRemedyComplete(teacher, matchErrorCode) {
    try {
      const { refreshAction } = this.args
      if (refreshAction) this.teacherMatchError.postRemedyRefresh.perform(teacher, matchErrorCode, refreshAction)
    } catch (e) {
      if (!didCancel(e)) throw e
    }

    this.flashQueue.addSuccess({ subtitle: this.intl.t('clever.teacherMatcher.remedySuccess') })
  }

  @action
  async onRemedyError(_teacher, _matchErrorCode, _remedyError) {
    this.flashQueue.addFail({ subtitle: this.intl.t('clever.teacherMatcher.remedyFail') })
  }
}
