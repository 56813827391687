import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type Store from '@ember-data/store'
import type Controller from '@ember/controller'
import type Transition from '@ember/routing/transition'

export default class ManageStudentsEditRoute extends Route {
  @service store!: Store

  async model(params: { id: string }) {
    const student = await this.store.findRecord('student', params.id)
    return { student }
  }

  resetController(
    controller: Controller<ModelFor<ManageStudentsEditRoute>>,
    isExiting: boolean,
    transition: Transition,
  ) {
    super.resetController(controller, isExiting, transition)
    if (isExiting && controller.model?.student.hasDirtyAttributes) controller.model?.student.rollbackAttributes()
  }
}
