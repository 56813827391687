import Model, { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model'
import type SchoolClass from 'district-ui-client/models/school-class'
import type School from 'district-ui-client/models/school'
import type SubscriptionCoordinator from 'district-ui-client/models/subscription-coordinator'
import type TeacherSubscription from 'district-ui-client/models/teacher-subscription'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import { join } from 'district-ui-client/utils/uri'
import config from 'district-ui-client/config/environment'

export default class Teacher extends Model {
  @attr('string') firstName!: string

  @attr('string') lastName!: string

  @attr('string') login!: string

  @attr('string') email!: string

  @belongsTo('school', { async: false, inverse: 'teachers' }) school?: School

  @hasMany('school-class', { async: false, inverse: 'teachers' }) schoolClasses?: SchoolClass[]

  @hasMany('subscription-coordinator', { async: false, inverse: 'teacher' })
  subscriptionCoordinators!: SyncHasMany<SubscriptionCoordinator>

  @hasMany('teacher-subscription', { async: false, inverse: 'teacher' })
  teacherSubscriptions!: SyncHasMany<TeacherSubscription>

  teacherSubscriptionsFor(subscriptionType: SubscriptionType) {
    return this.teacherSubscriptions.filter((sub) => sub.subscriptionType === subscriptionType)
  }

  get schoolName() {
    return this.school?.name ?? ''
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get fullNameReversed() {
    return `${this.lastName}, ${this.firstName}`
  }

  get possessiveFullName() {
    return `${this.firstName} ${this.lastName}'s`
  }

  // subco login path uses SSO login defined in readineggs/app/controllers/api/v1/district_ui/subco_logins_controller.rb
  get subcoLoginPath() {
    return join(config.readingEggsV1Url, 'district_ui', 'subco_logins', this.id).href
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    teacher: Teacher
  }
}
