import ApplicationJSONAPISerializer from 'district-ui-client/serializers/application'

export default class extends ApplicationJSONAPISerializer {
  modelNameFromPayloadKey(key) {
    const singularKey = super.modelNameFromPayloadKey(key)
    return `clever/${singularKey}`
  }

  payloadKeyFromModelName(modelName) {
    const name = super.payloadKeyFromModelName(modelName)
    return name.split('clever/').pop()
  }
}
