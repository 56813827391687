import { timeout, task } from 'ember-concurrency'
import type RSVP from 'rsvp'

const MAX_QUEUE_SIZE = 20

interface BulkManagerOptions {
  timeOut: number
}

interface BulkQueueItem {
  record: unknown
  resolver: {
    resolve: (value?: unknown) => void
    reject: (reason?: any) => void
  }
}

type AjaxFunction = (url: string, type: string, options?: object) => RSVP.Promise<unknown>

type AdditionalBulkData = Record<string, unknown>

/**
 * A small class to call an action which collects successive calls and sends them as collection
 * to the endpoint.
 *
 * @Class BulkManager
 */
export default class BulkManager {
  _bulkQueue: BulkQueueItem[] = []

  _ajax: AjaxFunction

  _url: string

  timeOut: number

  /**
   * Constructor to set defaults
   * @param {Function} ajax - It is very important to bind the adapter scope to this function with `.bind(adapter)`
   * @param {String} apiUrl - 'the url for the bulk action'
   */
  constructor(ajax: AjaxFunction, apiUrl: string, options?: Partial<BulkManagerOptions>) {
    const { timeOut = 50 } = options ?? {}

    this._url = apiUrl
    this._ajax = ajax
    this.timeOut = timeOut
  }

  /**
   * Adds a JSONAPI object to the queue, alongside associated resolve and reject functions. It calls a debounced task,
   * which will send that request after the event storm.
   *
   * The maxConcurrency for this task acts as a maximum queue size, allowing us to batch the bulk requests (say, if
   * there's 100 create requests to make, we can do 5 requests of size 20 each. Each request waiting for the one before
   * it to complete)
   *
   * The idea is that either the timeout in the debounced task is reached and the request goes out, OR we reach the
   * max batch size here, so no more records are added to the batch queue attempts, no more attempts are made to perform
   * the debounce task, the timeout is reached and the request goes out.
   *
   * When the request returns, those records will be have their resolve functions called, the tasks complete, the
   * concurrency of this task is freed up, and we add more items to the bulk queue...
   *
   * @param {String} method - Possible vlues are DELETE|PUT|...
   * @param {Object} record - a JSONAPI object to add to the queue
   * @param {Object} additionalBulkData - optional, use if there is extra data needed for the request. Last one wins.
   * @return {Promise}
   */
  callBulkAction = task(
    { enqueue: true, maxConcurrency: MAX_QUEUE_SIZE },
    async (method: string, record: unknown, additionalBulkData?: AdditionalBulkData) => {
      const bulkPromise = new Promise((resolve, reject) => {
        const resolver = { resolve, reject }
        this._bulkQueue = [...this._bulkQueue, { record, resolver }]
      })

      void this._bulkAction.perform(method, additionalBulkData)

      return bulkPromise
    },
  )

  /**
   * Task which executes an ajax request for a given method, emptying the current bulk queue. It's debounced by the
   * timeout given in the constructor.
   * @param {String} method - Possible values are DELETE|PUT|...
   */
  _bulkAction = task({ restartable: true }, async (method: string, additionalBulkData?: AdditionalBulkData) => {
    await timeout(this.timeOut)

    const bulkData = this._bulkQueue.map((queueItem) => queueItem.record)
    const bulkResolvers = this._bulkQueue.map((queueItem) => queueItem.resolver)

    this._bulkQueue = []

    const options = {
      data: {
        data: bulkData,
        ...additionalBulkData,
      },
    }

    const startTime = Date.now()
    this._ajax(this._url, method, options)
      .then((response: any) => {
        // sometimes response can be empty/null (no response.data) - ie for PATCH and DELETE's
        const data = response?.data
        if (Array.isArray(data) && data.length > 0) {
          const endTime = Date.now()
          return new Promise((resolve, _reject) => {
            // backpressure to add a delay between requests based on backend latency.
            setTimeout(resolve, endTime - startTime, data)
          }).then((data: any) => {
            /* for bulk create to work we rely on the order of the array of resources - response should contain data items
             * in the same order that they were in the request.
             */
            return bulkResolvers.forEach((p, index) => {
              p.resolve({ data: data[index] })
            })
          })
        }

        return bulkResolvers.forEach((p) => p.resolve())
      })
      // eslint-disable-next-line ember/no-array-prototype-extensions
      .catch((e) => bulkResolvers.forEach((p) => p.reject(e)))
  })
}
