import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { set, action } from '@ember/object'
import { service } from '@ember/service'

export const EXTERNAL_STATE_DEFAULT = 'default'
export const EXTERNAL_STATE_LOAD = 'load'
export const EXTERNAL_STATE_ERROR = 'error'
export const EXTERNAL_STATE_DONE = 'done'

export const INTERNAL_STATE_START = 'start'
export const INTERNAL_STATE_LOAD = 'load'
export const INTERNAL_STATE_COMPLETE = 'complete'
export const INTERNAL_STATE_READY = 'ready'

/**
 * This class creates a button which has 3 behaviours. Loading, Success, Error
 * The original idea of this button was to create a generic component which takes as state like
 * `load`, `error`, `done` or `default` to trigger a reaction on the button itself which can be used
 * to indicate if we are currently loading async data. You can see it as a fancy loading indicator.
 * One requirement was that each state can transition to a different state without becoming jerky experience,
 * where content simply get replaced without motion. The component reads the state input to animate from one
 * `state (load|done...)` to another.
 *
 * @Class SubmitButtonComponent
 *
 * @property {String} state - 'default' || 'load' || 'error' || 'done'
 * @property {Boolean} disabled - used to initially disable a component
 * @property {String} defaultText - The default text which is displayed which defaults to `submit`
 */
export default class UiButtonSubmitComponent extends Component {
  @service intl

  get type() {
    return this.args.type || 'submit'
  }

  get defaultText() {
    return this.args.text || this.args.defaultText || this.intl.t('submit')
  }

  get appearance() {
    return this.args.appearance || 'regular'
  }

  @action
  clickAction(...args) {
    if (this.args.clickAction) this.args.clickAction(...args)
  }

  /**
   * Can be one of 'default' 'load' 'complete' 'ready'
   * Each dictate what animations are played, how the content elements are moved.
   *
   * start - the first state, no animations
   * load - slides the text out, load spinner in
   * complete - slides load spinner out, status element in (check or a cross)
   * ready - same as start, but is animated into (slides status element out, text in)
   */
  @tracked
  internalState = INTERNAL_STATE_START

  get internalStateClass() {
    return {
      [INTERNAL_STATE_START]: 'state-start',
      [INTERNAL_STATE_LOAD]: 'state-load',
      [INTERNAL_STATE_COMPLETE]: 'state-complete',
      [INTERNAL_STATE_READY]: 'state-ready',
    }[this.internalState]
  }

  // Reads the external state to determine if the status element should display success or fail
  get isError() {
    return this.args.state === EXTERNAL_STATE_ERROR
  }

  get isAnimationPlaying() {
    return this.internalState === INTERNAL_STATE_LOAD || this.internalState === INTERNAL_STATE_COMPLETE
  }

  @action
  didUpdateState(_element, [externalState]) {
    // clear any timeouts
    clearTimeout(this.lastTimeout)

    const previousInternalState = this.internalState

    let newState = INTERNAL_STATE_START

    const isLoad = externalState === EXTERNAL_STATE_LOAD
    const isDoneOrError = externalState === EXTERNAL_STATE_DONE || externalState === EXTERNAL_STATE_ERROR

    if (isLoad) {
      newState = INTERNAL_STATE_LOAD
    } else if (previousInternalState === INTERNAL_STATE_LOAD && isDoneOrError) {
      newState = INTERNAL_STATE_COMPLETE
      // must have come from load state. want to make sure the state goes to a ready state after 3 seconds.
      const setReadyState = function () {
        if (this && !this.isDestroying && !this.isDestroyed) {
          set(this, 'internalState', INTERNAL_STATE_READY)
        }
      }.bind(this)
      const timeout = setTimeout(setReadyState, 3000)
      set(this, 'lastTimeout', timeout)
    }

    set(this, 'internalState', newState)
  }

  @action
  onDestroy() {
    // clear any timeouts
    clearTimeout(this?.lastTimeout)
  }
}
