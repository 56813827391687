import { template as template_49296ac308bf4cd488d39b6b62acf434 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLLIElement;
    Blocks: {
        default: [];
    };
}
export const ActionMenuItem: TOC<Signature> = template_49296ac308bf4cd488d39b6b62acf434(`
  <li
    role="menuitem"
    class="text-dusty-black-500 hover:bg-dusty-black-50 focus:bg-dusty-black-50 cursor-pointer whitespace-nowrap px-4 py-1"
    ...attributes
  >
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionMenuItem;
