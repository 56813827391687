import { template as template_def6868b02074a0da5849591fde4d697 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import LoadingSpinner from 'district-ui-client/components/loading-spinner';
import RouteTemplate from 'ember-route-template';
export const LoadingRouteTemplate: TOC<unknown> = template_def6868b02074a0da5849591fde4d697(`<LoadingSpinner />`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(LoadingRouteTemplate);
