import Route from '@ember/routing/route'
import { service } from '@ember/service'

export default class CleverRoute extends Route {
  @service clever

  @service session

  @service router

  @service store

  async model(_params) {
    const { subscriptionType } = this.modelFor('subscription-type')
    const { store, router, clever } = this

    const { id: districtId } = this.session.currentDistrict
    const blakeDistrict = await store.findRecord('district', districtId, {
      reload: true,
      include: 'clever-district-match',
    })

    // Ensure we have all the necessary district & sync records to proceed. Else redirect to clever-id.
    const shouldRedirect = await this.shouldRedirectToCleverId(blakeDistrict.cleverDistrictMatch, subscriptionType)
    if (shouldRedirect) return router.replaceWith('clever-id')

    // Go get the school data
    const cleverDistrictId = blakeDistrict.cleverDistrictMatch.cleverDistrict.id
    await Promise.all([
      store.query('clever/school', {
        scope: `districts/${districtId}`,
        include: 'clever-school-match,school-subscriptions,contact-detail',
      }),
      store.query('clever/clever-school', { scope: `clever-districts/${cleverDistrictId}` }),
    ])

    // Load the teacher and student matches and show notice if needed
    const urlToMissingFields = this.router.urlFor('clever.missing-fields', subscriptionType)
    await clever.checkUserMatchesAndShowMissingFieldNotice(urlToMissingFields)

    return {
      blakeDistrict,
      subscriptionType,
      cleverDistrictMatch: blakeDistrict.cleverDistrictMatch,
    }
  }

  /**
   * Make sure we have a clever-district-match record AND a clever-district record AND all other necessary records/state
   */
  async shouldRedirectToCleverId(cleverDistrictMatch, subscriptionType) {
    const cleverDistrict = await cleverDistrictMatch?.loadCleverDistrict({ ignore404: true })
    if (!cleverDistrict) return true

    // If no sync record and the current sub type is not managed, get clever-id to figure out where they need to go
    const isCurrentSubtypeCleverManaged = cleverDistrictMatch.getCleverManagedByType(subscriptionType)
    if (!cleverDistrict.isCleverUndergoingSetup && !isCurrentSubtypeCleverManaged) return true

    // If they need to finish matching on the other tab, send them to clever-id route to handle it
    const finishMatchingOther = cleverDistrict.isCleverUndergoingSetupForOther(subscriptionType)
    if (finishMatchingOther) return true

    // Data still downloading
    if (cleverDistrict.isDataInitializing) return true
  }
}
