import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { queryParam } from 'district-ui-client/decorators/query-param'

export default class FastPhonicsStandardsPerformanceController extends Controller {
  /* Implemented via controller, rather than using reporting.studentGrade directly, so that default value &
   * deserialization occurs, and so that tracked updates only occur when it changes */
  @queryParam('reporting/ui-scope/fast-phonics') studentGrade!: string
  @queryParam('reporting/ui-scope/fast-phonics') contentLevel!: string

  queryParams = ['sort', 'pageNumber', 'perPage']

  @tracked sort = 'lesson'
  @tracked pageNumber = 1
  @tracked perPage = 25
}

declare module '@ember/controller' {
  interface Registry {
    'reporting/ui-scope/fast-phonics/standards-performance': FastPhonicsStandardsPerformanceController
  }
}
