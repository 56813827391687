import BaseSerializer from 'district-ui-client/serializers/clever'

interface SerializedData {
  data: {
    meta?: Record<PropertyKey, unknown>
    [other: PropertyKey]: unknown
  }
}

function isRecord(maybeRecord: unknown): maybeRecord is Record<PropertyKey, unknown> {
  return maybeRecord !== null && typeof maybeRecord === 'object'
}

function hasData(maybeHasData: unknown): maybeHasData is SerializedData {
  return isRecord(maybeHasData) && 'data' in maybeHasData
}

export default class CleverStudentSerializer extends BaseSerializer {
  serialize(snapshot: ModelSnapshot, options: SerializerOptions) {
    const serialized = super.serialize(snapshot, options)

    // If meta is provided in adapterOptions, add it into the record data alongside root attrs, relationship, id, type
    const meta = snapshot?.adapterOptions?.meta
    if (hasData(serialized) && isRecord(meta)) {
      serialized.data.meta ??= {}
      serialized.data.meta = { ...serialized.data.meta, ...meta }
    }
    return serialized
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'clever/student': CleverStudentSerializer
  }
}
