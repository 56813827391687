/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable ember/no-runloop */
import Evented from '@ember/object/evented'
import Service, { inject as service } from '@ember/service'
import { debounce } from '@ember/runloop'
import type DeviceDetection from '../detection/service.ts'

// This is the number of milliseconds to wait/debounce before dispatching deviceResize events
const MAGIC_DELAY_ON_RESIZE = 20

/**
 * This service gives us the size of the **visible** viewport (so
 * excluding the address bar, etc.).  The calculations are based on those used
 * in the fullscreen-nav component, and are mainly of use on devices where
 * `100vh` is not a reliable indicator of the available height (e.g. iOS).
 */
export default class Viewport extends Service.extend(Evented) {
  _resizeHandler?: EventListener

  @service('device/detection') declare deviceDetection: DeviceDetection

  constructor(properties?: Record<string, unknown>) {
    super(properties)
    this._createResizeListener()
  }

  override willDestroy(): void {
    this._destroyResizeListener()
    super.willDestroy()
  }

  /**
   * Returns the visible amount of vertical space in pixels.  Android devices
   * are apparently alone in needing to use
   * `document.documentElement.clientHeight` for this.  All other devices can
   * use `window.innerHeight`.
   */
  get height(): number {
    if (this.deviceDetection.isAndroid) {
      return document.documentElement.clientHeight
    }
    return window.innerHeight
  }

  /**
   * Returns the visible amount of horizontal space in pixels.  Android devices
   * are apparently alone in needing to use
   * `document.documentElement.clientWidth` for this.  All other devices can
   * use `window.innerWidth`.
   */
  get width(): number {
    if (this.deviceDetection.isAndroid) {
      return document.documentElement.clientWidth
    }
    return window.innerWidth
  }

  _createResizeListener(): void {
    // Guard against memory leaks, in case we ever create a new listener
    // without removing the old one.
    if (this._resizeHandler) {
      this._destroyResizeListener()
    }

    this._resizeHandler = (): void => {
      debounce(this, this._onResize, MAGIC_DELAY_ON_RESIZE)
    }

    window.addEventListener('resize', this._resizeHandler)
  }

  _destroyResizeListener(): void {
    if (this._resizeHandler) {
      window.removeEventListener('resize', this._resizeHandler)
      delete this._resizeHandler
    }
  }

  /**
   * Triggers three events some unspecified time after a browser `resize` event.
   * These can be listened to in order to resize any DOM elements related to
   * content-loader.  The stages are as follows:
   *
   * 1. beforeDeviceResize: at this stage the browser has resized, but no
   *    changes have been made to the DOM in response.  You can listen to this
   *    event if you need to do measurement, but it is important that no DOM
   *    mutation should take place at this stage.
   *
   * 2. deviceResize: at this stage any DOM changes that need to be done should
   *    be performed.  If for example, you need to programmatically resize the
   *    container that an interactive is in, you should listen to this event.
   *
   * 3. afterDeviceResize: at this stage all changes to the DOM *except* for
   *    any changes that need to take place to the actual interactive itself.  You
   *    can listen to this event if you want to see what changes were made by
   *    listeners to the `deviceResize` event.
   */
  _onResize(): void {
    if (this.isDestroying || this.isDestroyed) return
    this.trigger('beforeDeviceResize')
    this.trigger('deviceResize')
    this.trigger('afterDeviceResize')
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'device/viewport': Viewport
  }
}
