import { assert } from '@ember/debug'

/**
 *
 * Rounds a value to given decimal points. Will only round where necessary, returns a number (does not pad with zeros).
 *
 * roundAccurately(1.3, 2) -> 1.3
 * roundAccurately(1.333, 2) -> 1.33
 *
 * Rounding via exponential notation avoids rounding errors.
 * https://medium.com/swlh/how-to-round-to-a-certain-number-of-decimal-places-in-javascript-ed74c471c1b8
 * https://www.jacklmoore.com/notes/rounding-in-javascript/
 *
 * Only implemented for positive values - does not round as expected for negative values.
 *
 * @param value a positive number
 * @param dp decimal points to round to, an optional positive whole number
 */
export function roundAccurately(value: number, dp?: number): number {
  if (!dp) return Math.round(value)

  assert('negative value not allowed for this util', value >= 0)
  assert('negative decimal points not allowed for this util', dp > 0)

  const asExp = Number(value + 'e' + dp)
  return Number(Math.round(asExp) + 'e-' + dp)
}
