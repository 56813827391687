import { on } from '@ember-decorators/object'
import { readOnly } from '@ember/object/computed'
import { task } from 'ember-concurrency'
import Service, { service } from '@ember/service'
import studentImportTask from 'district-ui-client/student-import/task'
import { set } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import Utils from 'district-ui-client/student-import/utils'
import Evented from '@ember/object/evented'

/**
 * @class StudentImportService
 */
export default class StudentImportService extends Service.extend(Evented) {
  @service
  session

  @service
  intl

  @service
  store

  @service
  authToken

  @service activeRoute

  @readOnly('authToken.token')
  token

  @readOnly('session.currentDistrict.id')
  districtId

  @tracked
  readingLastTaskInstance

  @tracked
  mathsLastTaskInstance

  /**
   * @property {Task|StudentImportTask} studentImportTask an ember-concurrency
   * task composed of sequential sub-tasks for uploading, remotely validating
   * and submitting a student csv.
   */
  @task({ evented: true })
  studentImportTask = studentImportTask

  /**
   * @listens studentImportTask:canceled
   * When an import task for a given subscription type is cancelled,
   * remove that import task from the service's "lastForSubscriptionType" variable.
   */
  @on('studentImportTask:canceled')
  importCanceled(taskInstance) {
    this.setLastForSubscriptionType(taskInstance.subscriptionType, null)
  }

  /**
   * Get the last performed StudentImportTask's TaskInstance for a given subscription type.
   * @param {String} subscriptionType
   * @returns {TaskInstance|undefined}
   */
  getLastForSubscriptionType(subscriptionType) {
    return this?.[`${subscriptionType}LastTaskInstance`]
  }

  /**
   * Set the last performed StudentImportTask's TaskInstance for a given subscription type.
   * @param {String} subscriptionType
   * @param {TaskInstance} taskInstance
   * @returns {TaskInstance|undefined}
   */
  setLastForSubscriptionType(subscriptionType, taskInstance) {
    set(this, `${subscriptionType}LastTaskInstance`, taskInstance)
  }

  markAsCompleteIfNotRunning() {
    const taskInstance = this.getLastForSubscriptionType(this.activeRoute.subscriptionType)
    Utils.markAsCompleteIfNotRunning(taskInstance)
  }

  /**
   * Calls perform() on the StudentImportTask.
   * @param {File} file
   * @returns {TaskInstance}
   */
  perform(file) {
    const { districtId, intl, token, store } = this
    const { subscriptionType } = this.activeRoute

    const lastTaskInstance = this.getLastForSubscriptionType(subscriptionType)
    if (lastTaskInstance?.isRunning) return

    const subscriptionTypeLocal = intl.t(subscriptionType)
    const taskInstance = this.studentImportTask.perform(
      file,
      { districtId, subscriptionType, subscriptionTypeLocal },
      { token, store, intl },
    )
    // keep track of the performed task instances so we can get the last run
    // task instance for a given subscription type
    this.setLastForSubscriptionType(subscriptionType, taskInstance)
    return taskInstance
  }
}
