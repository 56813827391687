import { helper } from '@ember/component/helper'
import { formatValue as formatValueUtil } from 'district-ui-client/utils/format-value'

/**
 * Passes value and options directly into the format-value util
 *
 * Usage:
 * {{format-value value}}
 * {{format-value value append="%"}}
 * {{format-value value replacement="-" append="%"}}
 */

interface Signature {
  Args: {
    Positional: [Parameters<typeof formatValueUtil>[0]]
    Named: Parameters<typeof formatValueUtil>[1]
  }
  Return: ReturnType<typeof formatValueUtil>
}

export const formatValue = helper<Signature>(function ([value], options) {
  return formatValueUtil(value, options)
})

export default formatValue
