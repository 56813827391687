import { template as template_4733e18086a94d02b76d607e7621f252 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { eq } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import type { Tone } from 'district-ui-client/services/alert';
interface Args {
    closeAlertAction?: () => void;
    tone?: Tone;
    role?: string;
    showIcon?: boolean;
}
interface Signature {
    Element: HTMLDivElement;
    Args: Args;
    Blocks: {
        default: [];
    };
}
export class Alert extends Component<Signature> {
    get tone() {
        return this.args.tone ?? 'info';
    }
    get role() {
        return this.args.role ?? 'alert';
    }
    get showIcon() {
        return this.args.showIcon ?? true;
    }
    readonly faIconSize = 'lg';
    get faIcon(): IconName {
        return this.faIconForTone(this.tone);
    }
    faIconForTone(tone: Tone): IconName {
        const toneMap: Record<Tone, IconName> = {
            info: 'circle-info',
            caution: 'triangle-exclamation',
            critical: 'triangle-exclamation',
            positive: 'circle-check'
        };
        return toneMap[tone] || 'circle-info';
    }
    static{
        template_4733e18086a94d02b76d607e7621f252(`
    <div
      data-test-alert={{this.tone}}
      role="{{this.role}}"
      class="__alert__baa2f
        {{this.tone}}
        text-dusty-black-300 flex items-center justify-between rounded border p-5 leading-normal"
      ...attributes
    >
      <div data-test-alert-outer class="flex items-center">
        {{#if (eq this.showIcon true)}}
          <FaIcon @icon={{this.faIcon}} @size={{this.faIconSize}} @fixedWidth={{true}} class="icon mx-1 mr-3" />
        {{/if}}
        <div data-test-alert-inner>
          {{yield}}
        </div>
      </div>

      {{#if @closeAlertAction}}
        <button
          data-test-close-alert
          type="button"
          class="close"
          aria-label="{{t 'components.alertMessage.close'}}"
          {{on "click" @closeAlertAction}}
        >
          <span aria-hidden="true">{{t "components.alertMessage.closeX"}}</span>
        </button>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default Alert;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Alert: typeof Alert;
    }
}
