import { template as template_f318ba9e85764e7ea2752c6aae1ed1bf } from "@ember/template-compiler";
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ManageHeading } from 'district-ui-client/components/manage-heading';
import { RolloverAlert } from 'district-ui-client/components/rollover-alert';
import { TeacherTable } from 'district-ui-client/components/teacher-table/component';
import type AddExistingTeachersRoute from 'district-ui-client/routes/subscription-type/manage/teachers/add-existing-teachers';
import type ActiveRouteService from 'district-ui-client/services/active-route';
import type AddExistingTeachersService from 'district-ui-client/services/add-existing-teachers';
import type SessionService from 'district-ui-client/services/session';
import { t } from 'ember-intl';
import UiButtonLinkTo from 'district-ui-client/components/ui-button/link-to';
import UiButton from 'district-ui-client/components/ui-button';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { not } from 'ember-truth-helpers';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<AddExistingTeachersRoute>;
    };
}
export class ManageTeachersAddExistingTeachers extends Component<Signature> {
    @service
    session: SessionService;
    @service
    addExistingTeachers: AddExistingTeachersService;
    @service
    activeRoute: ActiveRouteService;
    @service
    router: RouterService;
    @tracked
    selectedIds: string[] = [];
    get isCleverDisabled() {
        return this.session.currentDistrict.cleverDisabledManagementFor(this.activeRoute.subscriptionType);
    }
    get selectedTeachers() {
        return this.notProductTeachers.filter((t)=>this.selectedIds.includes(t.id));
    }
    get notProductTeachers() {
        return this.args.model.teachersWithoutSubType;
    }
    updateSelectedIds = (selectedIds: string[])=>{
        this.selectedIds = selectedIds;
    };
    addTeachers = async (isTrial: boolean)=>{
        if (isEmpty(this.selectedTeachers)) return;
        const teachers = this.selectedTeachers;
        const result = await this.addExistingTeachers.execute(teachers, this.activeRoute.subscriptionType, isTrial);
        if (result) {
            this.updateSelectedIds([]);
            this.router.transitionTo('subscription-type.manage.teachers');
        }
    };
    static{
        template_f318ba9e85764e7ea2752c6aae1ed1bf(`
    <ManageHeading>{{t "subscriptionType.manageTeachers.addExistingTeachers.addExistingTeachers"}}</ManageHeading>
    <RolloverAlert />

    <div class="mt-6">
      <TeacherTable
        @teachers={{this.notProductTeachers}}
        @selectedIds={{this.selectedIds}}
        @updateSelectedIds={{this.updateSelectedIds}}
        @isCleverDisabled={{this.isCleverDisabled}}
      >
        <div class="mx-3 mb-3 inline-block align-middle">
          <UiButtonLinkTo @route="subscription-type.manage.teachers" class="muted" data-test-manage-teachers-link>
            <FaIcon @icon="users" class="mr-1" />{{t
              "subscriptionType.manageTeachers.addExistingTeachers.manageTeachers"
            }}</UiButtonLinkTo>
        </div>
        <div class="mx-3 mb-3 inline-flex align-middle">
          <UiButton
            class="muted"
            @corners="rounded rounded-r-none"
            {{on "click" (fn this.addTeachers true)}}
            disabled={{not this.selectedTeachers}}
          >
            <FaIcon @icon="download" class="mr-1" />{{t
              "subscriptionType.manageTeachers.addExistingTeachers.addWithATrial"
            }}</UiButton>

          <UiButton
            class="muted"
            @corners="rounded rounded-l-none"
            {{on "click" (fn this.addTeachers false)}}
            disabled={{not this.selectedTeachers}}
          >
            <FaIcon @icon="download" class="mr-1" />{{t
              "subscriptionType.manageTeachers.addExistingTeachers.addUsingSchoolSubscriptio"
            }}</UiButton>
        </div>
      </TeacherTable>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ManageTeachersAddExistingTeachers);
