import { helper } from '@ember/component/helper'
import { THEME_CLASSES } from 'district-ui-client/utils/themes'

interface Signature {
  Args: {
    Positional: [themeKey?: string]
  }
  Return: string
}

/**
 * Provide a theme key, and get back a theme class name. This can be applied to an element to make color-based UI
 * decisions in CSS.
 *
 * {{theme-class "blue"}} -> "theme-blue"
 * {{theme-class "green"}} -> "theme-green"
 */

/**
 * @deprecated - aim to apply tailwind classes rather than css classes
 */

export const themeClass = helper<Signature>(function ([themeKey = '']) {
  return THEME_CLASSES[themeKey] || ''
})

export default themeClass
