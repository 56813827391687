import { tracked } from '@glimmer/tracking'
import { get } from '@ember/object'
import { timeout } from 'ember-concurrency'
import config from 'district-ui-client/config/environment'
import ErrorCodes from '../error-codes'

const {
  csvImport: { importPollTimeoutMs, initialImportPollTimeoutMs },
} = config

const { JOB_FAILED } = ErrorCodes

export default {
  job: tracked(),

  get jobId() {
    return this.job?.id
  },

  get errorCode() {
    return get(this, 'value.errorCode')
  },

  /**
   * @param {Function} params.submitCsvPollFn
   * @returns {Promise<Object>}
   */
  *perform({ submitCsvPollFn }) {
    yield timeout(initialImportPollTimeoutMs)
    while (true) {
      this.job = yield submitCsvPollFn()
      if (!this.job.isStillRunning) break
      yield timeout(importPollTimeoutMs)
    }

    if (this.job.isComplete) {
      return {
        ok: true,
      }
    } else {
      return {
        ok: false,
        errorCode: JOB_FAILED,
        jobId: this.job?.id,
        jobStatus: this.job?.status,
      }
    }
  },
}
