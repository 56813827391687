import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { service } from '@ember/service'
import CleverServiceRunner from 'district-ui-client/utils/clever-service-runner'

/**
 * This component renders a match user table row.
 * I kept it intentionally unscoped (teacher|student) so that we can use this component
 * in both places.
 *
 * @class MatchTableRowComponent
 * @property {CleverTeacherModel|CleverStudentModel} data - the cleverRecord
 * @property {Array} blakeUserOptions - the data needed to populate the dropdown
 * @property {function} matchAction - action to match a user
 * @property {function} resetAction - action to reset a user match
 */
export default class MatchTableRowComponent extends Component {
  @service clever

  @service flashQueue

  @service log

  @service intl

  @tracked
  highlight = false

  // 'student' or 'teacher'
  userType = null

  get cleverRecord() {
    return this.args.data
  }

  get matchAction() {
    return this.args.matchAction
  }

  get resetAction() {
    return this.args.resetAction
  }

  @tracked
  submitState = 'default'

  @tracked
  resetState = 'default'

  get buttonText() {
    if (this.isCreateOptionSelected) {
      return this.intl.t('clever.createWord')
    } else {
      return this.intl.t('clever.matchWord')
    }
  }

  _getFlashMessagesForBlakeUserValue(messageType, cleverUser, blakeUser) {
    const { intl } = this
    const copyPrefix = `clever.flashMessages.${this.userType}.${messageType}`
    const cleverUserName = cleverUser.fullName
    const blakeUserName = blakeUser?.fullName || ''

    return {
      success: intl.t(`${copyPrefix}.success`, { cleverUserName, blakeUserName }),
      fail: intl.t(`${copyPrefix}.fail`, { cleverUserName, blakeUserName }),
    }
  }

  // The option selected in the match dropdown
  @tracked _selectedOption = null

  get selectedOption() {
    const { allOptions, _selectedOption } = this

    /* A previously selected option may no longer be a valid option anymore, if it was matched in a dropdown on a
     * different row. So the find() call is to check that the option selected is within the available options.
     */
    return allOptions.find((option) => option.value === _selectedOption?.value)
  }

  set selectedOption(value) {
    this._selectedOption = value
  }

  get isCreateOptionSelected() {
    return this.selectedOption?.value === 'create'
  }

  get selectedBlakeUserId() {
    if (this.isCreateOptionSelected) return null
    return this.selectedOption?.value ?? null
  }

  @action
  optionClick(option) {
    this.selectedOption = option
  }

  /* This holds the option that is being currently matched, after the match process has started for this row.
   * We save this, so that the dropdown can continue to display the teacher being matched to, even though its (now) no
   * longer an available option for rows to match to - it stays this way until the match row is removed from the table.
   */
  @tracked matchInProgressOption = null

  @action
  match() {
    const { clever, flashQueue, intl, log, matchAction, cleverRecord, isCreateOptionSelected, selectedBlakeUserId } =
      this

    let selectedBlakeUser
    if (this.userType === 'teacher') {
      selectedBlakeUser = this.clever.getBlakeTeacherById(this.selectedBlakeUserId)
    } else {
      selectedBlakeUser = this.clever.getBlakeStudentById(this.selectedBlakeUserId)
    }

    const messageType = isCreateOptionSelected ? 'create' : 'match'
    const flashMessages = this._getFlashMessagesForBlakeUserValue(messageType, cleverRecord, selectedBlakeUser)
    // Remember the option being matched, so that it can stay in the row dropdown until the row goes away, even if its
    // no longer a valid option to be matched to.
    this.matchInProgressOption = this.selectedOption

    new CleverServiceRunner(this, 'submitState', { mapErrorToString: false })
      .fail((error) => {
        const firstError = error?.errors?.[0]
        if (firstError?.code === 'unique') {
          const { detail } = firstError
          const key = `clever.flashMessages.${this.userType}.create.failDetail`
          const cleverUserName = cleverRecord.fullName
          const msg = intl.t(key, { cleverUserName, detail })
          flashQueue.addFail({ subtitle: msg })
        } else {
          flashQueue.addFail({ subtitle: flashMessages.fail })
          log.error(flashMessages.fail, { backendError: error })
        }
      })
      .success(() => {
        flashQueue.addSuccess({ subtitle: flashMessages.success })
        clever.setCleverUserMatchedState(cleverRecord, true)
      })
      .run(() => matchAction(cleverRecord, isCreateOptionSelected ? 'create' : selectedBlakeUserId))
  }

  @action
  reset() {
    const { clever, flashQueue, log, resetAction, cleverRecord } = this
    const flashMessages = this._getFlashMessagesForBlakeUserValue('reset', cleverRecord)

    new CleverServiceRunner(this, 'resetState')
      .fail((error) => {
        flashQueue.addFail({ subtitle: flashMessages.fail })
        log.error(flashMessages.fail, { backendError: error })
      })
      .success(() => {
        flashQueue.addSuccess({ subtitle: flashMessages.success })
        clever.setCleverUserMatchedState(cleverRecord, false)
      })
      .run(() => resetAction(cleverRecord))
  }

  /**
   * set the background colour of the row when we open the dropdown to keep the attention
   * on the selected row
   * @returns void
   */
  @action
  dropdownOpen() {
    this.highlight = true
  }

  /**
   * remove the background colour of the row when we open the dropdown
   * @returns void
   */
  @action
  dropdownClose() {
    this.highlight = false
  }
}
