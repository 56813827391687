import Service from '@ember/service'
import type { NormalizedInterval } from 'date-fns'
import { tracked } from '@glimmer/tracking'
import getAcademicYear from '../utils/get-academic-year.ts'
import { namedPeriod, customRange } from '../utils/named-date-range-parsing.ts'

/**
 * @class DateFilterService
 *
 * A service for storing and accessing a date range across an app
 */
export default class DateFilterService extends Service {
  @tracked
  countryCode?: string | undefined

  /**
   * The date range reperesented as a string
   *
   * @example
   * 'named-period:last-3-months', 'custom-range:2020-01-01:2020-01-31'
   */
  @tracked
  dateRange = 'named-period:last-7-days'

  /**
   * Allows us to override the getNow function if need be.
   * This is needed for tests and cases where we need to adjust the reference for what 'now' means.
   */
  getNow(): Date {
    return new Date()
  }

  /**
   * The from date for the period represented by the set date range.
   */
  get fromDate(): Date {
    return new Date(this.period.start)
  }

  /**
   * The to date for the period represented by the set date range.
   */
  get toDate(): Date {
    return new Date(this.period.end)
  }

  /**
   * The period represented by the set date range.
   */
  get period(): NormalizedInterval {
    const { dateRange, schoolYearPeriod } = this

    const [dateRangeType, rangeNameOrFromDate, toDate] = dateRange.split(':')

    switch (dateRangeType) {
      case 'named-period':
        // @ts-expect-error date assert rangeNameOrFromDate is correct
        return namedPeriod(schoolYearPeriod, rangeNameOrFromDate, this.getNow())
      case 'custom-range':
        // @ts-expect-error date assert rangeNameOrFromDate is correct
        return customRange(schoolYearPeriod, rangeNameOrFromDate, toDate)
      default:
        throw Error(
          `Error: "${String(dateRangeType)}" is not a valid date range type.`,
        )
    }
  }

  get schoolYearPeriod(): NormalizedInterval {
    return getAcademicYear(this.getNow(), this.countryCode)
  }

  /**
   * Sets the country code to that specified.
   */
  setCountryCode(countryCode: string | undefined): void {
    this.countryCode = countryCode
  }

  /**
   * Sets the date range, e.g.'named-period:last-3-months', 'custom-range:2020-01-01:2020-01-31'
   */
  setDateRange(dateRange = 'named-period:this-year'): void {
    this.dateRange = dateRange
  }
}
