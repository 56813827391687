import { assert } from '@ember/debug'
import GravityAdapter from 'district-ui-client/adapters/gravity'
import type ModelRegistry from 'ember-data/types/registries/model'

/**
 * We GET this model via gravity school includes.
 */
export default class ContactDetailAdapter extends GravityAdapter {
  urlForUpdateRecord(id: string, modelName: keyof ModelRegistry, snapshot: ModelSnapshot) {
    const schoolId = snapshot.adapterOptions?.schoolId
    assert('schoolId is required to PATCH contact-detail', schoolId)

    const url = super.urlForUpdateRecord(id, modelName, snapshot) // api/v3/contact-details/:id

    // prepend with school id to make api/v3/schools/:school-id/contact-details/:id
    const scope = `schools/${schoolId}`
    const pathForModelName = super.pathForType(modelName) // eg contact-details
    return url.replace(pathForModelName, `${scope}/${pathForModelName}`) // /api/v3/schools/123/contact-details/456 - note: only replaces first occurrence
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'contact-detail': ContactDetailAdapter
  }
}
