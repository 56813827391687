export default function (data, summaryKey, dataKey, dataType = 'months') {
  const byGrade = groupByGradePosition(data, summaryKey, dataKey)
  return pullKeyOutOfGradePositionData(byGrade, dataKey, dataType)
}

function groupByGradePosition(data, summaryKey, dataKey) {
  const summaries = (data[summaryKey] ?? [])
    .filter((el) => el.grade_position !== null)
    .sort((a, b) => a.grade_position - b.grade_position)
  const dataItems = (data[dataKey] ?? [])
    .filter((el) => el.grade_position !== null)
    .sort((a, b) => a.grade_position - b.grade_position)
  return summaries.map((summary, i) => ({
    grade_position: summary.grade_position,
    summary: summary.data,
    [dataKey]: dataItems[i],
  }))
}

function pullKeyOutOfGradePositionData(grades, dataKey, keyToPull) {
  return grades.map((grade) => ({
    grade_position: grade.grade_position,
    summary: grade.summary,
    [keyToPull]: grade[dataKey][keyToPull],
  }))
}
