const colours = {
  "transparent": "transparent",
  "current": "currentColor",
  "inherit": "inherit",
  "black": "#000000",
  "white": "#ffffff",
  "chargebeeGrey": "#F7F8FB",
  "medalBronze300": "#aa6e29",
  "medalBronze400": "#a26927",
  "medalBronze500": "#956124",
  "medalBronze": "#aa6e29",
  "medalSilver300": "#9cb0b5",
  "medalSilver400": "#96abb1",
  "medalSilver500": "#8da4aa",
  "medalSilver": "#9cb0b5",
  "medalGold300": "#f0ca3c",
  "medalGold400": "#e7c018",
  "medalGold500": "#ddb817",
  "medalGold": "#f0ca3c",
  "dustyBlack50": "#f3f3f2",
  "dustyBlack100": "#d1d0d0",
  "dustyBlack200": "#a4a1a0",
  "dustyBlack250": "#79726d",
  "dustyBlack300": "#484441",
  "dustyBlack400": "#242221",
  "dustyBlack500": "#121110",
  "oceanyBlue50": "#e5f4fb",
  "oceanyBlue100": "#cdeaf8",
  "oceanyBlue150": "#b3e0f3",
  "oceanyBlue200": "#9bd6f0",
  "oceanyBlue300": "#36ade1",
  "oceanyBlue325": "#1e98ce",
  "oceanyBlue350": "#197ba9",
  "oceanyBlue400": "#206787",
  "oceanyBlue500": "#0e2b38",
  "stormBlue100": "#c1ddf5",
  "stormBlue200": "#83bbec",
  "stormBlue300": "#1d77c5",
  "stormBlue350": "#175f9d",
  "stormBlue400": "#0e3b62",
  "stormBlue500": "#071d31",
  "cornflowerBlue100": "#DDE9FC",
  "cornflowerBlue325": "#1769ee",
  "cornflowerBlue350": "#0f5ad6",
  "cornflowerBlue400": "#0B45A2",
  "grapeyGreen100": "#ebf2cd",
  "grapeyGreen200": "#d6e59b",
  "grapeyGreen300": "#aeca36",
  "grapeyGreen400": "#57651b",
  "grapeyGreen500": "#2b330e",
  "msGreen50": "#ecf7e2",
  "msGreen100": "#d9f0c6",
  "msGreen150": "#c6e8a9",
  "msGreen200": "#b4e18d",
  "msGreen300": "#6bb32e",
  "msGreen325": "#60A129",
  "msGreen350": "#508622",
  "msGreen400": "#355917",
  "msGreen500": "#1a2c0b",
  "mspGreen300": "#00A45F",
  "forestGreen50": "#eafaea",
  "forestGreen100": "#cbf4cc",
  "forestGreen150": "#b1efb2",
  "forestGreen300": "#22a524",
  "forestGreen350": "#197b1b",
  "fpPurple300": "#7b62ae",
  "purplyPink100": "#f2cde2",
  "purplyPink200": "#e59bc5",
  "purplyPink300": "#cb378c",
  "purplyPink350": "#a32a70",
  "purplyPink400": "#661c46",
  "purplyPink500": "#330e23",
  "yolkyYellow100": "#fff2c0",
  "yolkyYellow200": "#ffe581",
  "yolkyYellow300": "#ffcC04",
  "yolkyYellow400": "#806602",
  "watermelonyRed50": "#feeef2",
  "watermelonyRed100": "#fec1cf",
  "watermelonyRed150": "#fca1b7",
  "watermelonyRed200": "#fc839f",
  "watermelonyRed250": "#fa446f",
  "watermelonyRed300": "#f9063f",
  "watermelonyRed350": "#d10535",
  "watermelonyRed400": "#7d031f",
  "watermelonyRed500": "#3e0210",
  "rexyPurple100": "#d8cbe3",
  "rexyPurple200": "#b296c8",
  "rexyPurple300": "#652d90",
  "rexyPurple400": "#321748",
  "rexyPurple500": "#190b25",
  "juicyOrange50": "#fef3e3",
  "juicyOrange100": "#fee7c7",
  "juicyOrange200": "#fccf90",
  "juicyOrange300": "#f99f20",
  "juicyOrange350": "#e88a06",
  "juicyOrange400": "#7d4f10",
  "midOrange100": "#ffe5d6",
  "midOrange300": "#fb721d",
  "deepOrange50": "#fff3f0",
  "deepOrange350": "#d13100",
  "appleGreen300": "#70cb59",
  "appleGreen500": "#53b839",
  "wlBlue50": "#EFF3F9",
  "wlBlue100": "#D1DFF4",
  "wlBlue150": "#A6BDE0",
  "wlBlue250": "#4676BE",
  "wlBlue300": "#365F9D",
  "wlBlue400": "#2A4979",
  "wlBlue500": "#1B2F4E",
  "brightBlue300": "#55C3FE",
  "brightBlue500": "#0192DF",
  "glowyOrange300": "#F99303",
  "rustyRed300": "#D96233"
}

export default colours