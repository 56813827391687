import Component from '@glimmer/component'

/**
 * Tagless component to show or hide a loading indicator.  By default the
 * indicator will fill the current containing block.  Arguments that can
 * be provided are:
 *
 * - `@show={Boolean}`: when set to `false`, the loading indicator will be
 *    removed from the DOM.  Note that this is not the same as the overlays
 *   `@visible` argument.
 *
 * - `@overlay={Boolean}`: when `true`, spinner and text will be displayed
 *   within an overlay with vertical and horizontally centering.
 *
 * - `@appearance={String}`: when set to `"small"`, will set the wave's `@small`
 *   argument to `true`.
 *
 * - `@centered={Boolean}`: when true will center-align the wave and text.  Only
 *   meaningful if `@overlay` is false.
 *
 * - `@loadingText={String}`: when provided will display the given string below
 *   the spinner.
 *
 * @example
 *  actions: {
 *    loading(transition) {
 *      const controller = this.controllerFor('application')
 *      controller.set('currentlyLoading', true)
 *      transition.promise.finally(function() {
 *        controller.set('currentlyLoading', false)
 *      })
 *      return true
 *    },
 *  },
 *
 *  // template
 *  <Fidget::Spinner::LoadingIndicator @show={{currentlyLoading}} @loadingText="Loading" />
 *
 * @class LoadingIndicatorComponent
 */
interface Args {
  show?: boolean
  overlay?: boolean
  appearance?: string
  centered?: boolean
  loadingText?: string
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
  Blocks: {
    default: []
  }
}

export class FidgetLoadingIndicatorComponent extends Component<Signature> {
  /**
   * Defines if we want to add an overlay or not
   * @type {Boolean}
   * @default true
   */
  get overlay() {
    return this.args.overlay ?? true
  }
}

export default FidgetLoadingIndicatorComponent
