import RSVP from 'rsvp'
import config from 'district-ui-client/config/environment'

export function initialize(appInstance) {
  if (config.environment !== 'test') {
    RSVP.on('error', function (reason) {
      if (reason.name !== 'TransitionAborted') {
        const message = `RSVP error: ${String(reason)}`

        /**
         * The log service notifier takes 2 argument where the 2. is optional
         * 1. argument is the message
         * 2. argument, we can pass additional data. e.g. the exception object
         */
        appInstance.lookup('service:log').error(message, reason)
      }
    })
  }
}

export default {
  name: 'rsvp-error-handler',
  initialize,
}
