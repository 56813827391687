import { tracked } from '@glimmer/tracking'
import Service, { inject as service } from '@ember/service'
import { dependentKeyCompat } from '@ember/object/compat'
import { getConfig } from '@blakeelearning/get-config'
import type { Log } from '@blakeelearning/log'
import type { TokenResult, TokenResultAsync } from '../utils/token-result.ts'
import { tokenResult } from '../utils/token-result.ts'
import { jwtDecode } from 'jwt-decode'
import type { JwtPayload } from 'jwt-decode'
import { fromPromise, fromThrowable } from 'neverthrow'

import type Fetcher from './fetcher.ts'
import type DeviceDetection from '@blakeelearning/device/device/detection/service'

interface JwtToken extends JwtPayload {
  data: Record<string, unknown>
}

type TokenUserType = 'parent' | 'teacher' | 'student' | 'disco'

export const safeJwtDecode = fromThrowable(
  (token: string) => jwtDecode<JwtToken>(token),
  () => null,
)

/**
 * Service for loading and setting JWT from dogfood. To get a token for a user, call this.getTokenFor()
 *
 * @class  AuthTokenService
 * @extends Ember.Service
 */
export default class AuthToken extends Service {
  @service('device/detection') declare deviceDetection: DeviceDetection

  @service() declare fetcher: Fetcher

  @service() declare log: Log

  get jwtURL(): string {
    return getConfig(this, 'jwtURL', '/api/v2/jwt')
  }

  get loginUrl(): string {
    // The iOS app needs to be sent to the readingeggs login page specifically
    // for the login catch-and-send-to-native-page process to work.
    if (this.deviceDetection.isNativeIos) {
      return getConfig(
        this,
        'iosAppLoginUrl',
        'https://app.readingeggs.com/login',
      )
    }

    return getConfig(this, 'loginUrl', '/login')
  }

  @tracked tokenResult: TokenResult = tokenResult({
    reason: 'Token has not been initialised',
  })

  @dependentKeyCompat
  get token(): string | undefined {
    return this.tokenResult.unwrapOr(undefined)
  }

  /**
   * Gets the first part of the (base64) jwt string, decodes it, and returns the parsed json
   * @property {Object} decodedToken   The decoded token details
   */
  get decodedToken(): JwtToken | null {
    return this.tokenResult
      .andThen((token) => safeJwtDecode(token))
      .unwrapOr(null)
  }

  /**
   * Retrieves userId from decoded token
   * @memberOf  AuthTokenService
   */
  get userId(): string | number | undefined {
    const userId = this.decodedToken?.data['id']

    if (typeof userId === 'string' || typeof userId === 'number') {
      return userId
    }

    return undefined
  }

  /**
   * Fetches the jwt for the specified user - either from memory or the server (if the
   * stored token is not found or expired)
   *
   * @return                  Resolves with the token result
   */
  getTokenFor(): TokenResultAsync {
    return this.retrieveOrRequestToken()
  }

  /**
   * Fetches the jwt for the given user type - either from memory or the server (if the
   * stored token is not found or expired). Only returns a successful token reuslt if it matches the given type,
   * else a failed token result
   *
   * @param  userType user type token must match to ('teacher', 'student', ...)
   * @return                  Resolves with the token, or null
   */
  getTokenForUserType(userType: TokenUserType): TokenResultAsync {
    const result = this.getTokenFor()

    return result.andThen(() => {
      const tokenType = this.decodedToken?.data['type']

      if (typeof tokenType === 'string' && tokenType !== userType) {
        // Replace the successful token result with a failed one, since the token type does not match what was requested
        this.tokenResult = tokenResult({
          reason: `Token userType of ${tokenType} does not match ${userType}`,
        })
      }

      return this.tokenResult
    })
  }

  getTokenForParent(): TokenResultAsync {
    return this.getTokenForUserType('parent')
  }

  getTokenForTeacher(): TokenResultAsync {
    return this.getTokenForUserType('teacher')
  }

  getTokenForDisco(): TokenResultAsync {
    return this.getTokenForUserType('disco')
  }

  getTokenForStudent(): TokenResultAsync {
    return this.getTokenForUserType('student')
  }

  retrieveOrRequestToken(): TokenResultAsync {
    return this.tokenResult
      .asyncMap((value) => Promise.resolve(value))
      .orElse(() => this.requestToken())
  }

  /**
   * Returns true/false if the expiry date is before the current date
   *
   * @param    expiry A Unix timestamp
   * @return
   */
  isExpired(expiry: number): boolean {
    return expiry < Date.now()
  }

  /**
   * Decodes the first part of a jwt, extracts the expiry date and returns it
   *
   * @property {Number} tokenExpiry   The expiry date in milliseconds
   */
  get tokenExpiry(): number {
    if (typeof this.decodedToken?.exp === 'number') {
      return this.decodedToken.exp * 1000
    }

    return 0
  }

  /**
   * Requests a new token from the service, sets it on the service
   * and resolves with the token itself.
   */
  requestToken(): TokenResultAsync {
    const result = fromPromise(
      this.fetcher
        .request(this.jwtURL, {
          credentials: 'include',
        })
        .text(),
      (error) => {
        if (error instanceof Error) {
          return error
        }

        return new Error('Failed to fetch token')
      },
    )
      .map((value) => {
        this.tokenResult = tokenResult({ value })
        return value
      })
      .mapErr((error) => {
        this.tokenResult = tokenResult({ error })
        return error
      })

    return result
  }

  /**
   * Refresh the current token with a new token
   * Redirects to the login page if the request for a new token fails
   */
  refreshToken(): TokenResultAsync {
    let tokenType = 'unknown'
    const tokenData = this.decodedToken?.data ?? {}

    if (typeof tokenData['type'] === 'string') {
      tokenType = tokenData['type']
    }

    const result = this.requestToken()
    return result.mapErr((error) => {
      this.log.error(`Failed to refresh auth token for ${tokenType}`, error)
      this.redirectToLogin()
      return error
    })
  }

  redirectToLogin(): void {
    window.location.replace(this.loginUrl)
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'auth-token': AuthToken
  }
}
