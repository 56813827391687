import Route from '@ember/routing/route'
import { service } from '@ember/service'

export default class NewRoute extends Route {
  @service store

  async model(params) {
    const { subscriptionType } = this.modelFor('subscription-type')

    const school = this.store.peekRecord('school', params.school_id)
    if (!school) throw new Error('school not found for given id on manage/schools/students/new route')

    const [schoolClasses, students] = await Promise.all([
      this.store.query('school-class', {
        filter: { product: subscriptionType }, // schools scope only has product filter
        scope: `schools/${params.school_id}`,
        // used for the teacher options
        include: 'teachers',
      }),
      this.store.query('student', { scope: `schools/${school.id}`, include: 'school' }),
    ])
    return { schoolClasses, school, students }
  }
}
