import { helper } from '@ember/component/helper'

interface Signature {
  Args: {
    Positional: [unknown[], unknown]
  }
  Return: boolean
}

/**
 * @example
 * ```hbs
 * <input
 *  checked={{includes this.selectedIds teacher.id}}
 * >
 * ```
 */
export const includes = helper<Signature>(function ([array, item]) {
  return array.includes(item)
})

export default includes
