import { BaseState } from '@blakeelearning/ember-select/state/base-state'
import { defaultSearch, type SelectOption } from '@blakeelearning/ember-select/index'
import type { SelectArgs } from '@blakeelearning/ember-select/components/select-box'

export class SelectBoxState<T extends SelectOption> extends BaseState {
  args: SelectArgs<T>

  get selected() {
    return this.args.value
  }

  constructor(args: SelectArgs<T>) {
    super()
    this.args = args
  }

  get selectedLabel() {
    if (this.selected) {
      return this.args.options.find((option: T) => option.value === this.selected)?.label
    }
  }

  selectOption = (option: T) => {
    this.args.onSelect(option)
  }

  get options() {
    return defaultSearch(this.args.options, this.searchTerm)
  }
}

export default SelectBoxState
