import Modifier from 'ember-modifier'

function getOptionElements(element: HTMLElement): HTMLElement[] {
  const options = Array.from(element.querySelectorAll('[role="option"], [role="menuitem"]'))
  return options.filter((el): el is HTMLElement => el instanceof HTMLElement)
}

function isOptionElement(element?: Element | EventTarget | null): element is HTMLElement {
  return element instanceof HTMLElement && element.matches(':is([role="option"], [role="menuitem"])')
}

interface Signature {
  Element: HTMLElement
}

/**
 * Modifier that makes an element the listbox for a select or multiselect component, receiving for keyboard events to
 * navigate the options.
 *
 * ## Usage
 *
 * When applied to an element makes it a receiver for keyboard events.
 *
 * Keyboard events can now be used to navigate the options.
 * Up/Down arrows to navigate the options.
 *
 * On render, will focus the element and scroll it into view.
 *
 * ```hbs
 * import { MakeListbox } from '@blakeelearning/ember-select/modifiers/make-listbox';
 *
 * <div {{MakeListbox}}>
 * {{#each state.options as |option|}}
 *  <div role="option>{{option.label}}</div>
 * {{/each}
 * </div>
 * ```
 */

export class MakeListbox extends Modifier<Signature> {
  hasSetup = false

  override modify(element: HTMLElement) {
    if (!this.hasSetup) {
      this.setup(element)
    }
  }

  setup(element: HTMLElement) {
    element.role = element.role ?? 'listbox'
    /* focusing listbox in safari can cause the page to move if the listbox has enough options to overflow the page
     * content (even if rendered inside a scroll) */
    element.tabIndex = -1

    // Allow arrow keys to navigate through the menu
    element.addEventListener('keydown', (event: KeyboardEvent) => {
      // Don't consume arrow keys unless event is for an option. (Need to allow left/right cursor in input)
      if (!isOptionElement(event.target)) return

      const options = getOptionElements(element)
      const index = options.indexOf(event.target)

      // ensure we have options and the target is one of those options
      if (!options.length || index < 0) return

      const previousIndex = index - 1
      const nextIndex = index + 1

      // index may be outside of options, this will loop back around.
      // Array.at() only loops back around for negative indices
      const previousOption = options[(previousIndex + options.length) % options.length]
      const nextOption = options[(nextIndex + options.length) % options.length]

      switch (event.key) {
        case 'ArrowLeft':
        case 'ArrowUp':
          /*
           * When scrolling to an element, we need to use { block: 'nearest' } scroll option, because of a Safari
           * bug where if the option appears off screen and is scrolled to, it scrolls the whole page instead.
           *
           * This is a common use case, as a list box will often be presented as a large list of options in an
           * overflow scroll. In particular, using keyboard navigation to loop back around from the first to the
           * last element that is likely offscreen is a good way to replicate this issue.
           *
           * Ideally we would provide the block: nearest scroll option to the focus() call, but it currently does
           * not accept scroll options https://github.com/whatwg/html/issues/834
           *
           * So we end up doing a scroll before the focus call instead.
           */
          previousOption?.scrollIntoView({ block: 'nearest' })
          previousOption?.focus({ preventScroll: true })
          event.preventDefault()
          break
        case 'ArrowRight':
        case 'ArrowDown':
          nextOption?.scrollIntoView({ block: 'nearest' })
          nextOption?.focus({ preventScroll: true })
          event.preventDefault()
          break
      }
    })

    /**
     * If there is a text or search input within, focus it, else focus selected option, else focus first option.
     * Reasoning for scrollIntoView/focus calls above.
     */
    const textInput = element.querySelector('input:is([type="text"], [type="search"])')
    const selectedOption = element.querySelector(
      // all elements that are (option or menuitem) and (aria-checked or aria-selected)
      ':is([role="option"], [role="menuitem"]):is([aria-checked="true"], [aria-selected="true"])',
    )
    const firstOption = element.querySelector(':is([role="option"], [role="menuitem"]):first-of-type')
    if (textInput instanceof HTMLInputElement) {
      textInput.scrollIntoView({ block: 'nearest' })
      textInput.focus({ preventScroll: true })
    } else if (selectedOption instanceof HTMLElement) {
      selectedOption.scrollIntoView({ block: 'nearest' })
      selectedOption.focus({ preventScroll: true })
    } else if (firstOption instanceof HTMLElement) {
      firstOption.scrollIntoView({ block: 'nearest' })
      firstOption.focus({ preventScroll: true })
    }

    this.hasSetup = true
  }
}
