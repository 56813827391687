import type { PositionalArgs } from 'ember-modifier'
import Modifier from 'ember-modifier'
import type { SelectBoxState } from '@blakeelearning/ember-select/state/select-box-state'
import type { MultiSelectState } from '@blakeelearning/ember-select/state/multi-select-state'
import { type SelectOption } from '@blakeelearning/ember-select/index'

interface Signature<T extends SelectOption> {
  Args: {
    Positional: [state: MultiSelectState<T> | SelectBoxState<T>]
  }

  Element: HTMLInputElement
}

/**
 * Modifier that makes an element a search input for a select box.
 *
 * ## Usage
 *
 * When applied to an input element makes it a search input for a select box.
 *
 * Typing into the input will fuzzy filter the options.
 *
 * ```hbs
 * import { MakeSearchInput } from '@blakeelearning/ember-select/modifiers/make-search-input';
 *
 * <input type="text" placeholder="Search options" {{MakeSearchInput state}} />
 * ```
 */

export class MakeSearchInput<T extends SelectOption> extends Modifier<Signature<T>> {
  hasSetup = false

  override modify(element: HTMLInputElement, positional: PositionalArgs<Signature<T>>) {
    const [state] = positional

    if (!this.hasSetup) {
      this.setup(element, state)
    }

    element.value = state.searchTerm ?? ''
  }

  setup(element: HTMLInputElement, state: MultiSelectState<T> | SelectBoxState<T>) {
    element.tabIndex = 0

    element.addEventListener('input', (event) => {
      state.onInput(event)
    })

    // clear search term on each render
    state.searchTerm = ''

    this.hasSetup = true
  }
}
