import Controller from '@ember/controller'
import { service } from '@ember/service'
import { alias } from '@ember/object/computed'
import { later } from '@ember/runloop'
import { set, action, computed } from '@ember/object'
import { timeout, waitForProperty, task } from 'ember-concurrency'

export const WAIT_FOR_TASKS_TIMEOUT = 1000

export default class StudentsController extends Controller {
  @service
  clever

  @service
  flashQueue

  @service
  intl

  @service
  log

  @service
  store

  queryParams = ['tab', 'currentPage', 'filter']

  @alias('tableConfig.filtering.tabFilterValue')
  tab

  @alias('tableConfig.pagination.currentPage')
  currentPage

  @alias('tableConfig.filtering.filterFieldValue')
  filter

  get areStudentMatchesDone() {
    return this.model.cleverSchool.areStudentMatchesDone
  }

  get school() {
    return { name: 'school' }
  }

  /**
   * Filters all students by subscriptionType
   * @property
   * @returns {Ember.Array}
   */
  @computed('model.{cleverSchool.cleverStudents.[],subscriptionType}')
  get cleverStudents() {
    const { subscriptionType } = this.model
    const { cleverStudents } = this.model.cleverSchool

    return cleverStudents.filter((cleverStudent) => {
      return (
        (subscriptionType === 'reading' && cleverStudent.readingCleverApp) ||
        (subscriptionType === 'maths' && cleverStudent.mathsCleverApp)
      )
    })
  }

  @computed('tableConfig.tabCounts.{unmatched,matched}')
  get tabOptions() {
    const { intl } = this
    const { unmatched, matched } = this.tableConfig.tabCounts
    return [
      {
        id: 'unmatched',
        title: intl.t('clever.studentTabs.unmatched', { count: unmatched }),
        route: 'clever.match.schools.students',
        query: { tab: 'unmatched' },
      },
      {
        id: 'matched',
        title: intl.t('clever.studentTabs.matched', { count: matched }),
        route: 'clever.match.schools.students',
        query: { tab: 'matched' },
      },
    ]
  }

  get blakeStudents() {
    return this.store.peekAll('clever/student').filter((s) => s.get('school.id') === this.model.blakeSchoolId)
  }

  /**
   * Caution - Various properties of this object are aliased to query params in this file. This object is used by and
   * has properties set in by several components down the stack.
   */
  tableConfig = {
    tabCounts: {
      unmatched: 0,
      matched: 0,
    },
    pagination: {
      currentPage: 1,
      perPage: 20,
    },
    filtering: {
      tabFilterValue: 'unmatched',
      filterColumns: ['fullName'],
      filterFieldValue: '',
      filterFunctions: {
        doFilterForTab(unfilteredCleverStudents) {
          // because we want to get the counts, we need to do all filters.
          const activeTab = this.dtoConfig?.filtering?.tabFilterValue
          const unmatched = unfilteredCleverStudents.filter((student) => !student.matched)
          const matched = unfilteredCleverStudents.filter((student) => student.matched)
          const tabCounts = { unmatched: unmatched.length, matched: matched.length }
          const configSetter = this.setInConfigAction
          later(() => {
            configSetter('tabCounts', tabCounts)
          })
          if (activeTab === 'unmatched') return unmatched
          if (activeTab === 'matched') return matched
          return unmatched
        },
      },
    },
    sorting: { columns: [] },
  }

  /**
   * A replacement for ember-concurrency's deprecated "task groups".
   * It allows any of the individual match related tasks to be executed sequentially. This is important as
   * we specifically need to ensure that requests to create/delete records are not carried out at the same time as any
   * updates are requested for these records.
   */
  matchTask = task({ enqueue: true }, async ({ taskType, cleverStudent, blakeStudentId }) => {
    switch (taskType) {
      case 'resetMatch':
        await this.resetMatchTask.perform(cleverStudent)
        break
      case 'createMatch':
        await this.createMatchTask.perform(cleverStudent, blakeStudentId)
        break
      default:
        return
    }
    // Wait for a bit, to make sure there's time for further tasks to be added to queue, before requesting an update
    if (this.matchTask.numQueued === 0) await this.timeoutHandler(WAIT_FOR_TASKS_TIMEOUT)
    // If this is the last reset in this queue, fetch updated sync state for school & fetch new match records.
    // Match records need to be fetched in case gravity performed automatching
    if (this.matchTask.numQueued === 0) {
      await this.waitForSchoolUpdate.perform()
      await this.waitForMatchesUpdate.perform()
    }
  })

  // Allows for easy stubbing in tests
  timeoutHandler(ms) {
    return timeout(ms)
  }

  /**
   * Deletes a match record and performs the update tasks when things have settled
   */
  resetMatchTask = task(async (cleverStudent) => {
    if (cleverStudent?.matched) {
      const matchRecord = await cleverStudent.cleverStudentMatch
      await matchRecord?.destroyRecord()
    }
  })

  /**
   * Creates a match record (and a blake student if necessary) and performs the update tasks when settled
   */
  createMatchTask = task(async (cleverStudent, blakeStudentId) => {
    if (cleverStudent?.matched === false) {
      if (blakeStudentId) {
        await this.clever.matchCleverStudentToBlakeStudent(cleverStudent, blakeStudentId)
      } else {
        const { cleverSchool } = this.model
        await this.clever.createBlakeStudentFromCleverData(cleverStudent, cleverSchool)
      }
    }
  })

  /**
   * Fetches latest clever-school sync state, and polls until it is in any state that is not automatching students
   */
  waitForSchoolUpdate = task(async () => {
    const { cleverDistrictId, cleverSchoolId } = this.model
    // Retreive latest clever-school record, for latest/updated sync state
    const cleverSchool = await this.clever.fetchCleverSchool(cleverDistrictId, cleverSchoolId)

    // Kicks off the poll task if not yet started - but dont yield the task instance returned
    this.clever.pollForCleverSchool.perform(cleverSchool)
    // Instead just wait for the clever school to indicate it's done with student matching work.
    await waitForProperty(cleverSchool, 'isStudentMatchingInProgress', false)
  })

  /**
   * Fetches latest clever match records for a school. This should be called whenever the backend might have performed
   * any automatching (in this case, after any create record requests)
   */
  waitForMatchesUpdate = task(async () => {
    const { store } = this
    const { cleverSchoolId, blakeSchoolId } = this.model
    const cleverStudentRecords = store.query('clever/clever-student', { scope: `clever-schools/${cleverSchoolId}` })
    const studentMatchRecords = store.query('clever/student', {
      scope: `schools/${blakeSchoolId}`,
      include: 'clever-student-match',
    })
    await Promise.all([cleverStudentRecords, studentMatchRecords])
  })

  /**
   * A task to create & match multiple records at once. After which, it will perform the necessary update tasks,
   * followed by an onComplete callback (where the bulk modal could be closed, for example)
   */
  bulkCreateMatchTask = task({ drop: true }, async (checkedStudents, { onComplete, completeDelay } = {}) => {
    const { clever, flashQueue, log, intl } = this
    const { cleverSchool } = this.model

    const flashMessages = {
      success: intl.t('clever.flashMessages.student.bulkCreate.success'),
      complete: intl.t('clever.flashMessages.student.bulkCreate.complete'),
      fail: intl.t('clever.flashMessages.student.bulkCreate.fail'),
    }

    try {
      flashQueue.addSuccess({ subtitle: flashMessages.success })
      await clever.bulkCreateBlakeStudentsFromCleverData(cleverSchool, checkedStudents)
      flashQueue.addSuccess({ subtitle: flashMessages.complete })
    } catch (err) {
      flashQueue.addFail({ subtitle: flashMessages.fail })
      log.error(flashMessages.fail, { backendError: err })
    }
    await this.waitForSchoolUpdate.perform()
    await this.waitForMatchesUpdate.perform()

    if (completeDelay) await this.timeoutHandler(completeDelay)
    onComplete?.()
  })

  get disableMatchButtons() {
    // Disable match/reset buttons while any update tasks are running OR if a bulk match is happening OR we've queued up
    // a bunch of requests
    return (
      this.waitForSchoolUpdate.isRunning ||
      this.waitForMatchesUpdate.isRunning ||
      this.bulkCreateMatchTask.isRunning ||
      this.matchTask.numQueued >= 5 // capping at 5 allows any queued requests to flush out and prompt an update
    )
  }

  // Disable bulk matcher button while it is already running, or any task in the match tasks group is running, to avoid
  // situations where something already queued up for matching is then selected as part of a bulk match.
  get disableBulkButtons() {
    return this.bulkCreateMatchTask.isRunning || this.matchTask.isRunning
  }

  @action
  setInConfig(keyPath, newValue) {
    const config = this.tableConfig
    set(config, keyPath, newValue)
  }

  /**
   * Based on the selected option we can choose if we want to create a new student or
   * create a match.
   *
   * @param cleverStudent
   * @param blakeStudentId - the id, or 'create'
   */
  @action
  async matchStudent(cleverStudent, blakeStudentIdOrCreate) {
    const blakeStudentId = blakeStudentIdOrCreate === 'create' ? null : blakeStudentIdOrCreate
    await this.matchTask.perform({ taskType: 'createMatch', cleverStudent, blakeStudentId })
  }

  @action
  async resetStudent(cleverStudent) {
    await this.matchTask.perform({ taskType: 'resetMatch', cleverStudent })
  }
}
