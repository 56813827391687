import Model, { belongsTo, attr } from '@ember-data/model'
import { dependentKeyCompat } from '@ember/object/compat'
import type CleverStudentMatch from 'district-ui-client/models/clever/clever-student-match'
import type GravitySchool from 'district-ui-client/models/clever/school'

/**
 * Model class for students (gravity data)
 *
 * @class BlakeStudentModel
 * @property {string} firstName - first name of the student
 * @property {string} lastName - last name of the student
 * @property {CleverStudentMatchModel} cleverStudentMatch - HasOne relationship to access the sisId
 * @property {string} sisId - An alias to the sisId from the blakeCleverStudentMatch relationship
 * @property {string} fullName - Name like 'Klaus Dieter' or '-'
 */
export default class GravityStudent extends Model {
  @attr('string') firstName!: string

  @attr('string') lastName!: string

  @attr('number') gradePosition!: number

  @belongsTo('clever/school', { async: false, inverse: 'students' })
  school?: GravitySchool

  @belongsTo('clever/clever-student-match', { async: false, inverse: 'student' })
  cleverStudentMatch?: CleverStudentMatch

  get cleverStudent() {
    return this.cleverStudentMatch?.cleverStudent
  }

  // Used in a computed property in match teachers table, in order to update dropdown options to remove teacher from
  // other dropdowns. Might be removable when that is using native getters.
  @dependentKeyCompat
  get sisId() {
    return this.cleverStudentMatch?.sisId
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ') || '-'
  }
}
