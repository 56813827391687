import { helper } from '@ember/component/helper'

interface Signature {
  Args: {
    Positional: unknown[]
  }
  Return: (event: Event) => void
}

/**
 * Heavily based on pick helper from ember-composable-helpers:
 * https://github.com/DockYard/ember-composable-helpers/blob/master/LICENSE.md
 *
 * But instead it's focused on extracting value from events, since that is the most common use-case.
 * If the helpers' arg is not a function, or the arg passed to the action is not an input event, the action does nothing
 *
 * <input {{on 'change' (event-value @onChange)}} />
 *
 * @action
 * onChange(value) { ... }
 *
 * It can also be used in more advanced cases - it just needs to be the outermost helper that receives the event.
 *
 * <select
 *  {{on 'change' (event-value (fn @onChange "some-other-thing"))}}
 * >
 *
 * @action
 * onChange(otherThing, value) { ... }
 */
export const eventValue = helper<Signature>(function ([action]) {
  return function (event) {
    if (typeof action === 'function' && event.target && 'value' in event.target) {
      action(event.target.value)
    }
  }
})

export default eventValue
