export interface AcademicYear {
  start: {
    month: number
  }
  end: {
    month: number
  }
}

const northernHemisphereAcademicYear: AcademicYear = {
  start: { month: 7 },
  end: { month: 6 },
}
const southernHemisphereAcademicYear: AcademicYear = {
  start: { month: 0 },
  end: { month: 11 },
}

const septemberToAugust: AcademicYear = {
  start: { month: 8 },
  end: { month: 7 },
}
const mayToApril: AcademicYear = {
  start: { month: 4 },
  end: { month: 3 },
}

const marchToFebruary: AcademicYear = {
  start: { month: 2 },
  end: { month: 1 },
}

const southernHemisphereCountries = ['au', 'nz', 'za']
const northernHemisphereCountries = ['ca', 'gb', 'kr', 'uk', 'us']

const septemberToAugustCountries = ['cn']
const mayToAprilCountries = ['th']
const marchToFebruaryCountries = ['kr']

const makeCountryMap = (countries: string[], academicYear: AcademicYear) => {
  const result = new Map<string, AcademicYear>()

  for (const country of countries) {
    result.set(country, academicYear)
  }

  return result
}

const southernHemisphereObjects = makeCountryMap(
  southernHemisphereCountries,
  southernHemisphereAcademicYear,
)
const northernHemisphereObjects = makeCountryMap(
  northernHemisphereCountries,
  northernHemisphereAcademicYear,
)

const customSeptemberToAugustObjects = makeCountryMap(
  septemberToAugustCountries,
  septemberToAugust,
)
const customMayToAprilObjects = makeCountryMap(mayToAprilCountries, mayToApril)
const customMarchToFebruaryObjects = makeCountryMap(
  marchToFebruaryCountries,
  marchToFebruary,
)

const academicYears = new Map([
  ...southernHemisphereObjects,
  ...northernHemisphereObjects,
  ...customSeptemberToAugustObjects,
  ...customMayToAprilObjects,
  ...customMarchToFebruaryObjects,
])

/**
 * Returns the correct relative academic school year for that country.
 *
 * If the countryCode param is falsy it will return the nothern academic year,
 * which is August 1 to July 31 as most countries are in the northern hemisphere.
 *
 * @param {String} countryCode - country code
 * @return {Object} Object with start/and end properties detailing months
 */
export default function (countryCode?: string): AcademicYear {
  // We can't use a default param because null can be passed in
  const lookupCountryCode = (countryCode ?? 'xy').toLowerCase()
  return academicYears.get(lookupCountryCode) ?? northernHemisphereAcademicYear
}
