import { template as template_4ee408eb6ee3419c937edbc825f27c60 } from "@ember/template-compiler";
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { ChartsAwardsEarnedTrophiesTotals } from 'district-ui-client/components/charts/awards-earned/trophies/totals';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import type RexAwardsEarnedRoute from 'district-ui-client/routes/reporting/ui-scope/rex/awards-earned';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { Tooltip } from 'district-ui-client/components/tooltip';
import type { TOC } from '@ember/component/template-only';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<RexAwardsEarnedRoute>;
    };
}
export const RexAwardsEarnedRouteTemplate: TOC<Signature> = template_4ee408eb6ee3419c937edbc825f27c60(`
  <DetailedPageWrapper @empty={{not @model.awardsEarnedTotals}}>
    {{#each @model.awardsEarnedTotals.awards_earned_totals as |gradeData|}}
      <div class="detailed-chart-wrapper relative">
        <ChartsAwardsEarnedTrophiesTotals
          data-test-awards-earned-trophies-totals
          class="h-full"
          @data={{gradeData}}
          @product={{@model.product}}
          @grade={{fullGradeName gradeData.grade_position}}
          @title={{t "reporting.chartTitles.awardsEarnedTotals.rex"}}
          @yAxisTitle={{t "reporting.chartTitles.awardsEarnedTotals.rex"}}
        />
        <span class="absolute right-0.5 top-0 print:hidden">
          <FaIcon @icon="circle-info" @size="sm" class="text-dusty-black-500 cursor-pointer" />
          <Tooltip @text={{t "reporting.tooltips.awardsEarnedTotals.rex" htmlSafe=true}} />
        </span>
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(RexAwardsEarnedRouteTemplate);
