import Model, { attr, belongsTo } from '@ember-data/model'
import { service } from '@ember/service'
import type { IntlService } from 'ember-intl'
import type School from 'district-ui-client/models/school'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'

// the key the BE provides as a capacity string when it is unlimited
export const UNLIMITED_CAPACITY_KEY = 'unlimited'

export type SubscriptionStatus = 'pending' | 'expired' | 'active'

/**
 * A school may have any number of school subscriptions, expired, pending or active.
 */
export default class SchoolSubscription extends Model {
  @service intl!: IntlService

  // date transform expects ISO8601 format, backend provides as yyyy-MM-dd format which is technically still ISO8601
  @attr('date') endDate!: Date

  @attr('date') startDate!: Date

  @attr('string') status!: SubscriptionStatus

  // a number (as string) or 'unlimited'
  @attr('string') private softCapacity!: string

  @attr('string') subscriptionType!: SubscriptionType

  @belongsTo('school', { async: false, inverse: 'schoolSubscriptions' }) school?: School

  get isPending() {
    return this.status === 'pending'
  }

  get isExpired() {
    return this.status === 'expired'
  }

  get isActive() {
    return this.status === 'active'
  }

  get formattedCapacity(): string {
    return this.softCapacity === UNLIMITED_CAPACITY_KEY ? this.intl.t('unlimited') : this.softCapacity
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'school-subscription': SchoolSubscription
  }
}
