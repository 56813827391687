import { template as template_306662d8d9b447378d90ad026d51eab8 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { or } from 'ember-truth-helpers';
import FidgetSpinnerWave from '@blakeelearning/fidget/components/fidget/spinner/wave';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
interface Signature {
    Element: HTMLSpanElement;
    Args: {
        isLoading?: boolean;
        isError?: boolean;
        isSuccess?: boolean;
    };
    Blocks: {
        default: [];
    };
}
/**
 * Intended to be used as the content of a UiButton where you would like some basic state to be shown, like loading, in
 * place of the text. This is typical of submit buttons.
 *
 * The use of relative/absolute is to ensure the button is the width of the yielded block (intended to be text, but
 * doesn't have to be) even while the typically smaller loading spinner is shown. Avoids the button changing size when
 * the state changes.
 *
 * Usage:
 * <UiButton @disabled={{this.myTaskIsRunning}}>
 *   <UiButtonState @isLoading={{this.myTaskIsRunning}}>
 *     Submit
 *   </UiButtonState>
 * </UiButton>
 */ export const UiButtonState: TOC<Signature> = template_306662d8d9b447378d90ad026d51eab8(`
  <span class="relative" ...attributes>
    <span class={{if (or @isLoading @isError @isSuccess) "invisible"}} data-test-button-state-text>
      {{yield}}
    </span>
    <span class="absolute inset-0">
      {{#if @isLoading}}
        <FidgetSpinnerWave @small={{true}} @centered={{true}} data-test-button-state-loading />
      {{else if @isError}}
        <FaIcon @icon="xmark" class="text-watermelony-red-300" data-test-button-state-error />
      {{else if @isSuccess}}
        <FaIcon @icon="check" class="text-ms-green-300" data-test-button-state-success />
      {{/if}}
    </span>
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiButtonState;
