import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { type UiScopeRoute } from 'district-ui-client/routes/reporting/ui-scope'

export class ReportingUiScopeController extends Controller {
  queryParams = ['dateRange', 'schoolIds']

  @tracked dateRange = ''

  @tracked schoolIds: string[] = []

  declare model: ModelFor<UiScopeRoute>

  @tracked drawer = true

  toggleNav = () => {
    this.drawer = !this.drawer
  }
}

export default ReportingUiScopeController

declare module '@ember/controller' {
  interface Registry {
    'reporting/ui-scope': ReportingUiScopeController
  }
}
