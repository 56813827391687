import Helper from '@ember/component/helper'
import { service } from '@ember/service'
import type GradeSetsService from 'district-ui-client/services/grade-sets'

interface Signature {
  Args: {
    Positional: unknown[]
  }
  Return: string
}

export class gradeName extends Helper<Signature> {
  @service gradeSets!: GradeSetsService

  compute([gradePosition]: Signature['Args']['Positional']) {
    if (typeof gradePosition === 'number') {
      const { name } = this.gradeSets.findByPosition(gradePosition)
      return name ?? '-'
    }
    return '-'
  }
}

export default gradeName
