import Route from '@ember/routing/route'

export default class ReRoute extends Route {
  queryParams = {
    studentGrade: {
      refreshModel: true,
      replace: true,
    },
    contentLevel: {
      refreshModel: true,
      replace: true,
    },
  }
}
