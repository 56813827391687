import { helper } from '@ember/component/helper'
import { get } from '@ember/object'

interface Signature {
  Args: {
    Positional: [string, (value: any) => void]
  }
  Return: (object: unknown) => void
}

/**
 * pick'd from ember-composable-helpers:
 * https://github.com/DockYard/ember-composable-helpers/blob/master/LICENSE.md
 */

/**
 * @example
 * ```hbs
 * <select
 *  {{on 'change' (pick 'target.value' @onSelectValueChange)}}
 * >
 * ```
 */
export const pick = helper<Signature>(function ([path, action]) {
  return function (object) {
    const value = get(object, path)

    if (!action) return value

    action(value)
  }
})

export default pick
