/* eslint-disable-next-line no-unused-vars */
import type EmberArray from '@ember/array'

/**
 * Merge sort (http://en.wikipedia.org/wiki/Merge_sort)
 * @version 0.1.0 (2012/05/23)
 * adapted from https://github.com/millermedeiros/amd-utils/blob/master/src/array/sort.js
 */
export function mergeSort<A>(
  argArr: A[] | EmberArray<A>,
  compareFn?: (first: A, second: A) => number
): A[] {
  let arr: A[]

  // convert Ember.Array to native Array
  if (argArr instanceof Array) {
    arr = argArr
  } else {
    arr = argArr.toArray()
  }

  if (arr.length < 2) {
    return arr
  }

  if (compareFn == null) {
    compareFn = defaultCompare
  }

  const mid = Math.floor(arr.length / 2)
  const left = mergeSort(arr.slice(0, mid), compareFn)
  const right = mergeSort(arr.slice(mid, arr.length), compareFn)

  return merge(left, right, compareFn)
}

function defaultCompare<A>(a: A, b: A): number {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

/**
 *
 * @param left first array to merge/compare
 * @param right second array to merge/compare
 * @param compareFn - function that returns a number to use as default JS sort ordering
 */
function merge<A>(
  left: A[],
  right: A[],
  compareFn: (arg0: A, arg1: A) => number
): A[] {
  const result: A[] = []

  while (left.length && right.length) {
    if (compareFn(left[0] as A, right[0] as A) <= 0) {
      // if 0 it should preserve same order (stable)
      const l = left.shift()

      if (typeof l !== 'undefined') {
        result.push(l)
      }
    } else {
      const r = right.shift()

      if (typeof r !== 'undefined') {
        result.push(r)
      }
    }
  }

  if (left.length) {
    result.push(...left)
  }

  if (right.length) {
    result.push(...right)
  }

  return result
}

export default mergeSort
