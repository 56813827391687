import { template as template_3ee798c9593345ea8e249a9b15019514 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export const DividerHorizontal: TOC<{
    Element: HTMLHRElement;
}> = template_3ee798c9593345ea8e249a9b15019514(`
  <hr class="h-[2px] bg-neutral-50" ...attributes />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
