import { template as template_9d3bafe217d64ae291d3a4994f146092 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { Interval } from '@blakeelearning/stable-table/utils/st/split-duration';
import type { IntlService } from 'ember-intl';
import { BaseTableActions } from 'district-ui-client/components/base/table-actions';
import { t } from 'ember-intl';
import type { CellObject } from '@blakeelearning/stable-table/components/basic-table/cell';
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave';
import type { SortingConfig } from '@blakeelearning/data-tables/utils/sorting';
import type Store from '@ember-data/store';
import { assert } from '@ember/debug';
export interface Aggregate {
    students: {
        average: Nullable<number>;
        total: number;
    };
    time_on_task: {
        average: Nullable<number>;
        total: number;
    };
    sprints_mastered: {
        average: Nullable<number>;
        total: number;
    };
    sprints_attempted: {
        average: Nullable<number>;
    };
    correct_answers: {
        average: Nullable<number>;
        total: number;
    };
    improvement: {
        average: Nullable<number>;
    };
}
export interface SchoolDataItem {
    school_id: number;
    number_of_students: number;
    sprints_mastered: number;
    time_on_task: number;
    correct_answers: number;
    average_sprints_attempted: Nullable<number>;
    average_improvement: Nullable<number>;
}
interface Signature {
    schools?: SchoolDataItem[];
    aggregate?: Aggregate;
    sortingConfig?: SortingConfig;
    onSortClick?: (sortingConfig: SortingConfig) => void;
}
export class FluencySprintsSchoolOverview extends Component<Signature> {
    @service
    intl: IntlService;
    @service
    store: Store;
    /**
   * Defines how the durations should be formatted
   */ get durationConfig() {
        const splitDurationOptions = {
            max: Interval.Days,
            min: Interval.Minutes,
            maxIntervals: 2
        };
        const dataInterval = 'seconds';
        return {
            dataInterval,
            splitDurationOptions
        };
    }
    get columns() {
        const { durationConfig, intl } = this;
        const translationPrefix = 'tables.fluencySprints.schoolOverview.headers';
        const defaultColumn = {
            classNames: 'table-box table-box-header bg-ms-green-100',
            cellClassNames: 'table-box table-box-cell bg-ms-green-50 group-hover:bg-ms-green-100',
            component: 'basic-cells/sortable',
            comparerKey: 'sortByNumber'
        };
        const columns = [
            {
                ...defaultColumn,
                value: intl.t(`${translationPrefix}.schoolName`),
                valuePath: 'schoolName',
                comparerKey: 'sortByValue'
            },
            {
                ...defaultColumn,
                value: intl.t(`${translationPrefix}.numberOfStudents`),
                valuePath: 'number_of_students'
            },
            {
                ...defaultColumn,
                value: intl.t(`${translationPrefix}.sprintsMastered`),
                valuePath: 'sprints_mastered'
            },
            {
                ...defaultColumn,
                value: intl.t(`${translationPrefix}.timeOnTask`),
                valuePath: 'time_on_task',
                cellComponent: 'basic-cells/duration-unit',
                durationConfig
            },
            {
                ...defaultColumn,
                value: intl.t(`${translationPrefix}.questionsAnswered`),
                valuePath: 'correct_answers'
            },
            {
                ...defaultColumn,
                value: intl.t(`${translationPrefix}.averageSprintsAttempted`),
                valuePath: 'average_sprints_attempted'
            },
            {
                ...defaultColumn,
                value: intl.t(`${translationPrefix}.averageImprovement`),
                valuePath: 'average_improvement',
                cellComponent: 'basic-cells/percentage-unit'
            }
        ];
        return columns;
    }
    get schoolRows() {
        return (this.args.schools?.map((school)=>{
            const schoolName = this.store.peekRecord('school', String(school.school_id))?.name;
            assert('cannot find school', schoolName);
            return {
                ...school,
                schoolName,
                classNames: 'table-row-striped group'
            };
        }) ?? []);
    }
    get footerRows() {
        const { durationConfig, intl } = this;
        const { aggregate } = this.args;
        if (!aggregate) return [];
        // For the known empty cells in the footer, we want no text displayed
        const emptyCell = {
            formatEmptyConfig: {
                replacement: ''
            }
        };
        const averageRow: {
            cells: CellObject[];
        } = {
            cells: [
                {
                    value: intl.t('tables.fluencySprints.schoolOverview.footers.average')
                },
                emptyCell,
                {
                    value: aggregate.sprints_mastered.average
                },
                {
                    component: 'basic-cells/duration-unit',
                    durationConfig,
                    value: aggregate.time_on_task.average
                },
                {
                    value: aggregate.correct_answers.average
                },
                {
                    value: aggregate.sprints_attempted.average
                },
                {
                    component: 'basic-cells/percentage-unit',
                    value: aggregate.improvement.average
                }
            ]
        };
        const totalsRow: {
            cells: CellObject[];
        } = {
            cells: [
                {
                    value: intl.t('tables.fluencySprints.schoolOverview.footers.totals')
                },
                emptyCell,
                {
                    value: aggregate.sprints_mastered.total
                },
                {
                    component: 'basic-cells/duration-unit',
                    durationConfig,
                    value: aggregate.time_on_task.total
                },
                {
                    value: aggregate.correct_answers.total
                },
                emptyCell,
                emptyCell
            ]
        };
        const classNames = 'table-footer-cell border-t border-ms-green-100 print:border-black';
        averageRow.cells.forEach((cell)=>{
            cell.classNames = classNames;
        });
        totalsRow.cells.forEach((cell)=>{
            cell.classNames = classNames;
        });
        return [
            averageRow,
            totalsRow
        ];
    }
    static{
        template_9d3bafe217d64ae291d3a4994f146092(`
    <BaseTableActions
      @items={{this.schoolRows}}
      @columns={{this.columns}}
      @sortingConfig={{@sortingConfig}}
      @onSortClick={{@onSortClick}}
      as |Table state|
    >

      <div class="scrollable-table-body overflow-x-auto">
        <Table exportable data-test-summary-table @footerRows={{this.footerRows}} as |tbl|>
          <tbl.head as |h|>
            <tr data-test-grouped-header-row>
              <th class="table-box table-box-header bg-ms-green-150 text-center" colspan="5"></th>
              <th class="table-box table-box-header bg-ms-green-150 text-center" colspan="2">
                {{t "tables.fluencySprints.schoolOverview.headers.numberFactsPerMinute"}}
              </th>
            </tr>
            {{! Standard header }}
            <h.row />
          </tbl.head>
          {{#if state.isEmptyAndIdle}}
            <tbl.spanner class="p-3 text-center italic">
              {{t "tables.fluencySprints.emptyTableMessage"}}
            </tbl.spanner>
          {{else}}
            {{#if state.isTransforming}}
              <tbl.spanner
                class="table-box table-box-cell border-transparent
                  {{if this.schoolRows 'absolute left-1/2 -translate-x-1/2'}}"
              >
                <FidgetSpinnerWaveComponent @small={{true}} @centered={{true}} />
              </tbl.spanner>
            {{/if}}
            <tbl.body class={{if state.isTransforming "opacity-50"}} />
            <tbl.foot class="print:border-t-2 print:border-black" />
          {{/if}}
        </Table>
      </div>
    </BaseTableActions>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default FluencySprintsSchoolOverview;
