import CsvApiAdapter from 'district-ui-client/adapters/csv-api'
import { join } from 'district-ui-client/utils/uri'

export default class ImportValidationAdapter extends CsvApiAdapter {
  /**
   * @param {String} id
   * @param {String} modelName
   * @param {String|Number} snapshot.adapterOptions.districtId
   * @param {String} snapshot.adapterOptions.subscriptionType
   * @returns {String}
   * @example
   *
   * this.store.findRecord(
   *   'import-validation',
   *   id,
   *   {
   *     adapterOptions: {
   *       districtId: '1',
   *       subscriptionType: 'reading',
   *     }
   *   }
   * )
   */
  urlForFindRecord(id, modelName, snapshot) {
    const { districtId, subscriptionType } = snapshot.adapterOptions
    const baseUrl = this.buildURL('district', String(districtId))
    return join(baseUrl, subscriptionType, modelName, id).href
  }
}
