import Model, { attr, belongsTo } from '@ember-data/model'
import type School from 'district-ui-client/models/school'
import type Teacher from 'district-ui-client/models/teacher'

export default class SubscriptionCoordinator extends Model {
  @attr('string') product!: string

  @belongsTo('school', { async: false, inverse: 'subscriptionCoordinators' }) school?: School

  @belongsTo('teacher', { async: false, inverse: 'subscriptionCoordinators' }) teacher?: Teacher

  isForSubscriptionType(subscriptionType: string) {
    return this.product === subscriptionType
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscription-coordinator': SubscriptionCoordinator
  }
}
