import { template as template_90d3f87c784c475cb27311867aa56faf } from "@ember/template-compiler";
import { ManageHeading } from 'district-ui-client/components/manage-heading';
import { ManageStudentActions } from 'district-ui-client/components/manage-student-actions';
import { RolloverAlert } from 'district-ui-client/components/rollover-alert';
import PopoverStandardDropdown from '@blakeelearning/popovers/components/popover/dropdowns/standard';
import UiButton from 'district-ui-client/components/ui-button';
import { ActionMenu } from 'district-ui-client/components/base/action-menu';
import { ActionMenuItem } from 'district-ui-client/components/base/action-menu/item';
import { LinkTo } from '@ember/routing';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
import { ExportStudents } from 'district-ui-client/components/export-students';
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave';
import StudentTable from 'district-ui-client/components/student-table';
import RouteTemplate from 'ember-route-template';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type SessionService from 'district-ui-client/services/session';
import type ActiveRouteService from 'district-ui-client/services/active-route';
import type ManageSchoolsStudentsIndexRoute from 'district-ui-client/routes/subscription-type/manage/schools/students';
import { on } from '@ember/modifier';
import { eventValue } from 'district-ui-client/helpers/event-value';
import Component from '@glimmer/component';
interface Signature {
    Args: {
        model: ModelFor<ManageSchoolsStudentsIndexRoute>;
    };
}
export class ManageSchoolsStudentsIndexRouteTemplate extends Component<Signature> {
    @tracked
    selectedIds: string[] = [];
    @tracked
    search = '';
    @service
    session: SessionService;
    @service
    activeRoute: ActiveRouteService;
    get isCleverDisabled() {
        return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType);
    }
    get groupActionsDisabled() {
        return !this.selectedStudents?.length || this.isCleverDisabled;
    }
    get subscriptionType() {
        return this.activeRoute.subscriptionType;
    }
    get school() {
        return this.args.model.school;
    }
    get students() {
        return this.school.students?.slice() ?? [];
    }
    get selectedStudents() {
        return this.args.model.schoolDataTask.isRunning ? [] : this.students?.filter((s)=>this.selectedIds.includes(s.id));
    }
    updateSelectedIds = (selectedIds: string[])=>{
        this.selectedIds = selectedIds;
    };
    setSearch = (value: string)=>{
        this.search = value;
    };
    static{
        template_90d3f87c784c475cb27311867aa56faf(`
    <ManageHeading>{{this.school.name}}</ManageHeading>
    <RolloverAlert />
    <div class="mb-3 mt-6 text-center">
      {{! Search box }}
      <form class="form-inline mx-4 mb-3 inline-block align-middle">
        <div class="has-feedback">
          <input
            class="form-control"
            aria-label={{t "searchPlaceholder.student"}}
            placeholder={{t "searchPlaceholder.student"}}
            type="search"
            value={{this.search}}
            {{on "input" (eventValue this.setSearch)}}
          />
          <span class="form-control-feedback">
            <FaIcon @icon="magnifying-glass" />
          </span>
        </div>
      </form>

      <ManageStudentActions
        @selectedStudents={{this.selectedStudents}}
        @updateSelectedStudents={{this.updateSelectedIds}}
        @isDisabled={{this.groupActionsDisabled}}
        @currentSchool={{this.school}}
      />

      <div class="mx-3 mb-3 inline-flex align-middle">
        <PopoverStandardDropdown @disabled={{this.isCleverDisabled}} as |dropdown|>
          <UiButton
            data-test-add-students-menu
            class="muted"
            @corners="rounded-l"
            aria-haspopup="menu"
            disabled={{this.isCleverDisabled}}
            {{dropdown.makeTrigger}}
          >
            <FaIcon @icon="user" class="mr-1" />{{t "subscriptionType.manageSchools.students.index.addStudents"}}<FaIcon
              @icon="caret-down"
              class="ml-1"
            />
          </UiButton>
          <dropdown.content>
            <ActionMenu data-test-actions-menu>
              <ActionMenuItem>
                <LinkTo
                  class="text-dusty-black-500 hover:bg-dusty-black-50 focus:bg-dusty-black-50"
                  @route="subscription-type.manage.schools.students.new"
                  @models={{Array this.school.id}}
                  data-test-add-single-student-link
                >
                  <FaIcon @icon="user" @fixedWidth={{true}} />
                  {{t "subscriptionType.manageSchools.students.index.singleStudent"}}
                </LinkTo>
              </ActionMenuItem>
              <ActionMenuItem>
                <LinkTo
                  class="text-dusty-black-500 hover:bg-dusty-black-50 focus:bg-dusty-black-50"
                  @route="subscription-type.manage.students.import-students"
                  data-test-add-multiple-students-link
                >
                  <FaIcon @icon="users" @fixedWidth={{true}} />
                  {{t "subscriptionType.manageSchools.students.index.multipleStudents"}}
                </LinkTo>
              </ActionMenuItem>
            </ActionMenu>
          </dropdown.content>
        </PopoverStandardDropdown>
        <ExportStudents @school={{this.school}} @showAsButton={{true}} />
      </div>
    </div>
    <div class="text-center">
      {{#if @model.schoolDataTask.isRunning}}
        <FidgetSpinnerWaveComponent @centered={{true}} />
      {{else}}
        <StudentTable
          @students={{this.students}}
          @subscriptionType={{this.subscriptionType}}
          @search={{this.search}}
          @isCleverDisabled={{this.isCleverDisabled}}
          @selectedIds={{this.selectedIds}}
          @updateSelectedIds={{this.updateSelectedIds}}
        />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ManageSchoolsStudentsIndexRouteTemplate);
