import type { PositionalArgs } from 'ember-modifier'
import Modifier from 'ember-modifier'
import type { SelectBoxState } from '@blakeelearning/ember-select/state/select-box-state'
import { type SelectOption } from '@blakeelearning/ember-select/index'

interface Signature<T extends SelectOption> {
  Args: {
    Positional: [state: SelectBoxState<T>, option: T]
  }

  Element: HTMLElement
}

/**
 * Modifier that makes an element behave like a select option.
 *
 * ## Usage
 *
 * When applied to an element makes it a select option.
 *
 * Clicking on the element will select the option.
 *
 * It applies `aria-selected` if the option is selected.
 *
 * The aria attributes can also be targeted to style the element based on the state.
 *
 * ```hbs
 * import { MakeSelectOption } from '@blakeelearning/ember-select/modifiers/make-select-option';
 *
 * <li {{MakeSelectOption state option}} class="focus:border focus:border-blue-600 focus:font-semibold aria-selected:border aria-selected:border-green-400">
 *   {{option.label}}
 * </li>
 * ```
 */

export class MakeSelectOption<T extends SelectOption> extends Modifier<Signature<T>> {
  hasSetup = false

  override modify(element: HTMLElement, positional: PositionalArgs<Signature<T>>) {
    const [state, option] = positional

    element.ariaSelected = String(state.selected === option.value)

    if (!this.hasSetup) {
      element.role = element.role ?? 'option'
      element.tabIndex = 0

      element.addEventListener('click', () => {
        state.selectOption(option)
      })
      element.addEventListener('keydown', (event) => {
        if (!(event instanceof KeyboardEvent)) return
        switch (event.key) {
          case 'Enter':
          case ' ':
            event.preventDefault() // have taken action, avoid the key triggering other actions
            state.selectOption(option)
            break
        }
      })

      this.hasSetup = true
    }
  }
}
