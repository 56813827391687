import Model, { belongsTo, attr } from '@ember-data/model'
import type Teacher from 'district-ui-client/models/teacher'
import type { SubscriptionStatus } from 'district-ui-client/models/school-subscription'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'

export default class TeacherSubscription extends Model {
  // date transform expects ISO8601 format, backend provides as yyyy-MM-dd format which is technically still ISO8601
  @attr('date') startDate!: Date

  @attr('date') endDate!: Date

  @attr('string') status!: SubscriptionStatus

  @attr('string') subscriptionType!: SubscriptionType

  @belongsTo('teacher', { async: false, inverse: 'teacherSubscriptions' }) teacher?: Teacher

  get isActive() {
    return this.status === 'active'
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'teacher-subscription': TeacherSubscription
  }
}
