import GravityBulkAdapter from 'district-ui-client/adapters/clever/gravity-bulk'
import { joinQueryParams } from 'district-ui-client/utils/uri'

export default class CleverStudentAdapter extends GravityBulkAdapter {
  urlForCreateRecord(modelName: ModelKey, snapshot: ModelSnapshot) {
    const defaultUrl = super.urlForCreateRecord(modelName, snapshot)
    const include = snapshot?.adapterOptions?.queryParams?.include
    if (include && typeof include === 'string') {
      return joinQueryParams(defaultUrl, { include }).href
    }
    return defaultUrl
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'clever/student': CleverStudentAdapter
  }
}
