import { isEmpty } from '@ember/utils'
import { isHTMLSafe, htmlSafe } from '@ember/template'
import type { SafeString } from '@ember/template'

export type FormatValueOptions = Partial<{
  /**
   * The replacement character/string to use. Defaults to -
   */
  replacement: string | SafeString
  /**
   * Set to true if you would like 0 to be replaced with the replacement string.
   */
  replaceZero: boolean
  /**
   * A string to prepend to the start of the value. Will only be prepended if the value is not replaced.
   */
  prepend: string
  /**
   * A string to append to the end of the value. Will only be appended if the value is not replaced.
   */
  append: string
}>

/**
 * Returns a string, formatting for empty-ish values as given by options.
 *
 * If the provided value is a safe string, the amended string will also be safe.
 *
 * formatValue(123, { append: '%' }) -> 123%
 * formatValue(0, { append: '%' }) -> 0%
 * formatValue(null, { append: '%' }) -> -
 * formatValue(0, { append: '%', replaceZero: true }) -> -
 */
export function formatValue(value: unknown, options: FormatValueOptions = {}): string | SafeString {
  // Setup defaults for any missing options
  const { replacement = '-', replaceZero = false, prepend = '', append = '' } = options

  const isEmptyValue = isEmpty(value)
  const isZeroValue = value === 0 || value === '0'
  const doReplacement = isEmptyValue || (isZeroValue && replaceZero)

  if (doReplacement) {
    return replacement
  }
  const amended = `${prepend}${String(value)}${append}`
  return isHTMLSafe(value) ? htmlSafe(amended) : amended
}
