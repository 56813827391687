import Route from '@ember/routing/route'
import { service } from '@ember/service'

export default class IndexRoute extends Route {
  @service store

  async model({ school_id: schoolId }) {
    const { subscriptionType } = this.modelFor('subscription-type')
    const [school, schoolClassesForSubType] = await Promise.all([
      this.store.findRecord('school', schoolId),
      this.store.query('school-class', {
        filter: { product: subscriptionType }, // schools scope only has product filter
        scope: `schools/${schoolId}`,
        include: 'teachers,students,teachers.school,teachers.teacher-subscriptions',
      }),
    ])
    return { school, schoolClassesForSubType }
  }
}
