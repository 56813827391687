import { hasJsonApiErrorMatching, type JsonApiError } from 'district-ui-client/errors/json-api-errors'

export const LoginExistsError = {
  title: 'has already been taken',
  status: 422,
  code: 'unique',
  detail: 'login has already been taken',
} as const satisfies JsonApiError

const isLoginExistsError = (maybeLoginError: JsonApiError): maybeLoginError is typeof LoginExistsError =>
  Boolean(
    String(maybeLoginError.status) === String(LoginExistsError.status) &&
      maybeLoginError.detail === LoginExistsError.detail &&
      maybeLoginError.code === LoginExistsError.code,
  )

export const hasLoginExistsError = (errorPayload: unknown) => hasJsonApiErrorMatching(errorPayload, isLoginExistsError)
