import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'

/**
 * @class ConfirmWithCheckbox
 * Shows a modal with a confirm checkbox and cancel and submit buttons.
 */
export default class ConfirmWithCheckbox extends Component {
  @tracked
  positiveButtonEnabled = false

  @action
  toggleConfirm() {
    this.positiveButtonEnabled = !this.positiveButtonEnabled
  }
}
