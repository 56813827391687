import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'

export class ReController extends Controller {
  queryParams = ['studentGrade', 'contentLevel']

  @tracked studentGrade = 'all'

  @tracked contentLevel = 'all'
}

export default ReController

declare module '@ember/controller' {
  interface Registry {
    'reporting/ui-scope/re': ReController
  }
}
