import Route from '@ember/routing/route'
import type Store from '@ember-data/store'
import { service } from '@ember/service'
import { assert } from '@ember/debug'

export default class ManageSchoolsEditRoute extends Route {
  @service store!: Store

  model(params: { id: string }) {
    // should already be loaded from manage schools route
    const school = this.store.peekRecord('school', params.id)
    assert('missing school record for schools edit route', school)
    return { school }
  }
}
