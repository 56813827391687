import Model, { attr, belongsTo } from '@ember-data/model'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type School from 'district-ui-client/models/school'

export default class SchoolRollover extends Model {
  @attr('string') status!: string

  // Marked as private to avoid use outside the class. use subscriptionType getter below
  @attr('string') private product!: SubscriptionType

  @belongsTo('school', { async: false, inverse: 'schoolRollovers' })
  school!: School

  get subscriptionType() {
    return this.product
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'school-rollover': SchoolRollover
  }
}
