import Model, { belongsTo, attr } from '@ember-data/model'
import { tracked } from '@glimmer/tracking'
import type CleverSchool from 'district-ui-client/models/clever/clever-school'
import type CleverTeacherMatch from 'district-ui-client/models/clever/clever-teacher-match'

/**
 * Model class for clever data teacher
 *
 * @class CleverTeacherModel
 * @property {string} firstName - first name of teacher
 * @property {string} lastName - last name of teacher
 * @property {string} sisId - unique clever id for the teacher
 * @property {boolean} matched - Flag to show if teacher is matched
 * @property {CleverSchool} cleverSchool - HasOne relationship to the clever school
 */

interface MatchError {
  code: 'existsDifferentDistrict' | 'existsAsParent' | 'existsAsParentContact' | 'idMismatchError' | 'staleAccountError'
}

export default class CleverTeacher extends Model {
  @attr('string') firstName!: string

  @attr('string') lastName!: string

  @attr('string') email!: string

  @attr('string') sisId!: string

  @attr('boolean') matched!: boolean

  @attr('boolean') readingCleverApp!: boolean

  @attr('boolean') mathsCleverApp!: boolean

  @attr() matchError?: Nullable<MatchError>

  @belongsTo('clever/clever-school', { async: false, inverse: 'cleverTeachers' }) cleverSchool?: CleverSchool

  @belongsTo('clever/clever-teacher-match', { async: false, inverse: 'cleverTeacher' })
  cleverTeacherMatch?: CleverTeacherMatch

  get blakeTeacher() {
    return this.cleverTeacherMatch?.teacher
  }

  // undefined | "existsDifferentDistrict" | "existsAsParent" | "existsAsParentContact" | "idMismatchError" | "staleAccountError"
  get matchErrorCode() {
    return this.matchError?.code
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ') || '-'
  }

  @tracked isCreateBlakeTeacherChecked = false
}
