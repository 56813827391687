import Component from '@glimmer/component'
import { service } from '@ember/service'
/**
 * @class ExistsDifferentDistrict
 * Account exists in different district, user is prompted to contact support.
 * @example```hbs
 * <CleverUi::Modals::TeacherMatcher::ExistsInDifferentDistrict
 *   @cleverTeacher={{cleverTeacherRecord}}
 * >/
 * ```
 */
export default class ExistsInDifferentDistrict extends Component {
  @service
  router

  @service
  intl

  @service
  store

  translated(key, options = {}) {
    return this.intl.t(`clever.teacherMatcher.errors.existsInDifferentDistrict.${key}`, options)
  }

  get cleverTeacher() {
    return this.args.cleverTeacher
  }

  get firstName() {
    return this.cleverTeacher?.firstName
  }

  get lastName() {
    return this.cleverTeacher?.lastName
  }

  get title() {
    return this.translated('summary')
  }

  get supportUrl() {
    return this.router.urlFor('subscription-type.support')
  }

  get explanationText() {
    const { firstName, lastName } = this
    return this.translated('explanation', {
      htmlSafe: true,
      firstName,
      lastName,
    })
  }

  get solutionText() {
    const { supportUrl } = this
    return this.translated('solution', {
      htmlSafe: true,
      supportUrl,
    })
  }
}
