import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type District from 'district-ui-client/models/district'
import type School from 'district-ui-client/models/school'
import { sameContent } from 'district-ui-client/utils/same-content'

/**
 * Represents ui_scope_name dynamic segment in reporting URLs
 */
export type UIScopeName = 'district' | 'school'

/**
 * Represents ui_scope_name and ui_scope_id dynamic segments in reporting URLs.
 * Can be applied to the gravity data object below to "scope" it to return data only relevant to that scope.
 */
export class UIScope {
  id: string

  scope: UIScopeName

  /**
   * Allows for multiple schools to be selected within district scope. Represented in the URL as query params.
   * Empty array indicates the entire scope is available.
   */
  subScopes: UIScope[]

  constructor(scope: UIScopeName, id: string, subScopes?: UIScope[]) {
    this.id = id
    this.scope = scope
    this.subScopes = subScopes ?? []
  }

  isEqual(otherUiScope: UIScope) {
    return (
      otherUiScope.id === this.id &&
      otherUiScope.scope === this.scope &&
      sameContent(
        otherUiScope.subScopes.map((s) => s.id),
        this.subScopes.map((s) => s.id),
      )
    )
  }
}

/**
 * Returns an array of valid scopes that can be used. This will return a scope for the current district, as well as
 * scopes for schools within that district that have utilisations for the given subscription type.
 * [
 *   { scope: 'district', id: '1' },
 *   { scope: 'school', id: '1' },
 *   { scope: 'school', id: '2' },
 *   { scope: 'school', id: '3' },
 * ]
 */
export function validUiScopesFor(district: District, schools: School[], subscriptionType: SubscriptionType): UIScope[] {
  const schoolsForSubType = schools.filter((school) => isPastActiveSchool(school, subscriptionType))
  return [new UIScope('district', district.id), ...schoolsForSubType.map((school) => new UIScope('school', school.id))]
}

export function isUiScopeName(maybeUiScopeName: string): maybeUiScopeName is UIScopeName {
  return maybeUiScopeName === 'district' || maybeUiScopeName === 'school'
}

/**
 * Return true if the school is active or was active, based on its subscriptions.
 *
 * Usually, we could use a school's subscription utilisations to easily determine if the school is active. However, for
 * the school scopes in reporting we want to show schools that have ever had a subscription, even if now expired.
 *
 * This logic explicitly excludes subscriptions that are yet to start.
 */
function isPastActiveSchool(school: School, subscriptionType: SubscriptionType): boolean {
  return Boolean(
    school.schoolSubscriptions
      ?.slice()
      .some((sub) => sub.subscriptionType === subscriptionType && (sub.isActive || sub.isExpired)),
  )
}
