import Route from '@ember/routing/route'

export default class CleverDashboardSyncedRoute extends Route {
  queryParams = {
    textFilter: {
      replace: true,
    },
    sort: {
      replace: true,
    },
    selected: {
      replace: true,
    },
  }

  model() {
    const { cleverDistrict, cleverSchools, subscriptionType } = this.modelFor('clever.dashboard')
    return { cleverDistrict, cleverSchools, subscriptionType }
  }
}
