// @ts-expect-error - ember-data types are currently wrong
import { BooleanTransform } from '@ember-data/serializer/transform'

export default BooleanTransform

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    boolean: BooleanTransform
  }
}
