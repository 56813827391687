// Country codes in this file are ISO_3166-1 alpha-2 codes https://en.wikipedia.org/wiki/ISO_3166-1
// AU, GB, US, ...

// sourced from AMER entry of acronyms glossary
// https://3plearning.atlassian.net/wiki/spaces/MD/pages/2861532570/Acronyms+Glossary

export const AMER = [
  'AI', // Anguilla
  'AG', // Antigua and Barbuda
  'AR', // Argentina
  'AW', // Aruba
  'BS', // Bahamas
  'BB', // Barbados
  'BZ', // Belize
  'BM', // Bermuda
  'BO', // Bolivia
  'BR', // Brazil
  'CA', // Canada
  'KY', // Cayman Islands
  'CL', // Chile
  'CO', // Colombia
  'CR', // Costa Rica
  'CU', // Cuba
  'DM', // Dominica
  'DO', // Dominican Republic
  'EC', // Ecuador
  'SV', // El Salvador
  'FK', // Falkland Islands (Malvinas)
  'GF', // French Guiana
  'GD', // Grenada
  'GT', // Guatemala
  'GY', // Guyana
  'HT', // Haiti
  'HN', // Honduras
  'JM', // Jamaica
  'MQ', // Martinique
  'MX', // Mexico
  'MS', // Montserrat
  'NI', // Nicaragua
  'PA', // Panama
  'PY', // Paraguay
  'PE', // Peru
  'PN', // Pitcairn
  'PR', // Puerto Rico
  'BL', // Saint Barthelemy
  'KN', // Saint Kitts and Nevis
  'PM', // Saint Pierre and Miquelon
  'VC', // Saint Vincent and the Grenadines
  'GS', // South Georgia and the South Sandwich Islands
  'SR', // Suriname
  'TT', // Trinidad and Tobago
  'TC', // Turks and Caicos Islands
  'US', // United States
  'UM', // United States Minor Outlying Islands
  'UY', // Uruguay
  'VE', // Venezuela
  'VG', // Virgin Islands, British
  'VI', // Virgin Islands, U.S
]
