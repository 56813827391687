import Service, { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import { getConfig } from '@blakeelearning/get-config'
import type RefresherService from '../refresher/service'

const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24

/**
 * Starts a timer, and when the timer runs out schedules a refresh.
 *
 * If the timer runs out twice, trigger a hard refresh.
 */
export default class LongSessionKiller extends Service {
  @service() declare router: RouterService

  @service() declare refresher: RefresherService

  _started = 0

  get _timeout(): number {
    return getConfig(
      this,
      'appRefresher.longSessionKiller.timeout',
      TWENTY_FOUR_HOURS,
    )
  }

  get timeLeft(): number {
    const now = Date.now()
    return this._timeout - (now - this._started)
  }

  /**
   * Sets the started time and listens for routeWillChange events
   */
  start(): void {
    const notStarted = this._started === 0

    if (notStarted) {
      this._started = Date.now()

      this.router.on('routeWillChange', () => {
        this._attemptRefresh()
      })
    }
  }

  private removeVisibilityChange = onVisibilityChange((isVisible) => {
    if (this._started && isVisible) {
      this._attemptRefresh()
    }
  })

  private removeNetworkChange = onNetworkChange((isOnline) => {
    if (this._started && isOnline) {
      this._attemptRefresh()
    }
  })

  override willDestroy(): void {
    this.removeVisibilityChange()
    this.removeNetworkChange()
  }

  _attemptRefresh(): void {
    const { _started: started, _timeout: timeout } = this
    const now = Date.now()
    const firstTimeout = now - started >= timeout
    const secondTimeout = now - started >= 2 * timeout

    if (secondTimeout) {
      this.refresher.hardRefresh()
    } else if (firstTimeout) {
      this.refresher.scheduleRefresh()
    }
  }
}

function onVisibilityChange(callback: (isVisible: boolean) => void) {
  const handleVisibilityChange = () => {
    callback(document.visibilityState === 'visible')
  }

  window.addEventListener('visibilitychange', handleVisibilityChange)

  return () => {
    window.removeEventListener('visibilitychange', handleVisibilityChange)
  }
}

function onNetworkChange(callback: (isOnline: boolean) => void) {
  const handleNetworkChange = () => {
    callback(navigator.onLine)
  }

  window.addEventListener('online', handleNetworkChange)
  window.addEventListener('offline', handleNetworkChange)

  return () => {
    window.removeEventListener('online', handleNetworkChange)
    window.removeEventListener('offline', handleNetworkChange)
  }
}

declare module '@ember/service' {
  interface Registry {
    'long-session-killer': LongSessionKiller
  }
}
