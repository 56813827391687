const rex = {
  my_lessons: {
    2: { min: 1, max: 20 },
    3: { min: 21, max: 60 },
    4: { min: 61, max: 100 },
    5: { min: 101, max: 140 },
    6: { min: 141, max: 180 },
    7: { min: 181, max: 220 },
  },
  spelling: {
    2: { min: 1, max: 36 },
    3: { min: 37, max: 72 },
    4: { min: 73, max: 108 },
    5: { min: 109, max: 144 },
    6: { min: 145, max: 180 },
    7: { min: 181, max: 216 },
  },
}

const re = {
  reading: {
    1: { min: 1, max: 50 },
    2: { min: 51, max: 100 },
    3: { min: 101, max: 120 },
  },
  spelling: {
    1: { min: 1, max: 32 },
    2: { min: 33, max: 64 },
    3: { min: 65, max: 96 },
  },
  storylands: {
    2: { min: 1, max: 10 },
    3: { min: 11, max: 20 },
  },
}

const maths = {
  lessons: {
    1: { min: 1, max: 50 },
    2: { min: 51, max: 100 },
    3: { min: 101, max: 150 },
    4: { min: 151, max: 200 },
  },
  mental_minute: {
    1: { min: 1, max: 39 },
    2: { min: 40, max: 71 },
    3: { min: 72, max: 106 },
    4: { min: 107, max: 145 },
  },
}

const data: ProductMap = { rex, re, maths }

type ProductString = 'rex' | 're' | 'maths'

type PrecinctString = 'my_lessons' | 'spelling' | 'reading' | 'lessons' | 'storylands' | 'mental_minute'

type GradePositionToMinMax = Record<string, { min: number; max: number }>
type PrecinctMap = Record<string, GradePositionToMinMax>

type ProductMap = Record<ProductString, PrecinctMap>

function isProduct(product: string): product is ProductString {
  return Object.keys(data).indexOf(product) !== -1
}

function isPrecinctOf(product: string, precinct: string) {
  const productExists = isProduct(product)
  return productExists && Object.keys(data[product]).indexOf(precinct) !== -1
}

/**
 * Lookup the grade position from a lesson value for a product and precinct.

 */
export function gradePositionFromLesson(
  product: ProductString,
  precinct: PrecinctString,
  lesson: number,
): number | undefined {
  if (!isPrecinctOf(product, precinct)) return

  const precinctData = data[product][precinct]
  const [gradePos] =
    Object.entries(precinctData).find(([_gradePos, { min, max }]) => lesson >= min && lesson <= max) ?? []
  return typeof gradePos !== 'undefined' ? parseInt(gradePos, 10) : undefined
}

/**
 * Lookup the lesson range from a grade position for a product and precinct.
 *
 */
export function lessonRangeFromGradePosition(
  product: ProductString,
  precinct: PrecinctString,
  grade: number,
): { min: number; max: number } | Record<string, never> {
  if (!isPrecinctOf(product, precinct)) return {}

  const productData = data[product][precinct]
  return productData[grade] || {}
}
