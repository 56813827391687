import Helper from '@ember/component/helper'
import { service } from '@ember/service'
import type { FeatureNotificationId } from 'district-ui-client/services/feature-notification'
import type FeatureNotificationService from 'district-ui-client/services/feature-notification'

interface Signature {
  Args: {
    Positional: [FeatureNotificationId]
    Named: Record<string, unknown>
  }
  Return: boolean
}

export class FeatureNotification extends Helper<Signature> {
  @service featureNotification!: FeatureNotificationService

  compute([featureNotificationId]: Signature['Args']['Positional'], named: Record<string, unknown>) {
    // some feature notification classes need extra context when determining
    // whether to show a notification, this extra context is passed from the
    // template to the FeatureNotificationService#isVisible method via the
    // helper's named parameters.
    return this.featureNotification.isVisible(featureNotificationId, named)
  }
}

export default FeatureNotification
