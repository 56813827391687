import { assert } from '@ember/debug'
import Rollbar from 'rollbar'
import { installRollbar, uninstallRollbar } from './utils/rollbar.ts'
import type { LogAdapter } from './log-adapters/base.ts'

export { default as Log } from './services/log.ts'

export * from './utils/check-ignore.ts'

interface LogConfig {
  rollbar?: Rollbar.Configuration
}

/**
 * Setup and start the Logging Frameworks for an application
 *
 * @param config Application Configuration with configuration for Logging Frameworks
 */
export function startLog(config?: LogConfig): void {
  assert(
    'You called `startLog`, but did not provide a valid configuration object',
    typeof config?.rollbar !== 'undefined',
  )

  installRollbar(new Rollbar(config.rollbar))
}

export function stopLog(): void {
  uninstallRollbar()
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LogAdapterRegistry extends Record<string, LogAdapter> {}

declare module '@ember/owner' {
  interface DIRegistry {
    'log-adapter': LogAdapterRegistry
  }
}
