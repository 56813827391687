import Controller from '@ember/controller'
import { service } from '@ember/service'
import { action } from '@ember/object'
import { task } from 'ember-concurrency'

export default class CleverMatchSchoolController extends Controller {
  @service clever

  @service flashQueue

  @service log

  @service intl

  @service session

  @service activeRoute

  @service router

  get isSubmitLoading() {
    return this.confirmMatchTask.isRunning || this.resetMatchTask.isRunning || this.createMatchFromOptionTask.isRunning
  }

  get isResetLoading() {
    return this.resetMatchFromOptionTask.isRunning
  }

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get cleverSchool() {
    return this.model.cleverSchool
  }

  /**
   * The blake school matched to the clever school, if a match has already occurred previously. Will be nullish if
   * clever school is unmatched.
   */
  get matchedBlakeSchool() {
    return this.model.matchedBlakeSchool
  }

  /**
   * The list of blake schools for the school matching dropdown
   */
  get unmatchedBlakeSchools() {
    return this.model.unmatchedBlakeSchools
  }

  get productName() {
    return this.intl.t(`subscriptions.${this.subscriptionType}`)
  }

  get schoolDetails() {
    const translationParams = {
      name: this.cleverSchool.name,
      streetAddress: '',
      city: '',
      country: '',
      state: '',
      postCode: '',
      phone: '',
      type: '',
      grade: '',
    }
    return this.intl.t('clever.requestNewSchoolDetails', translationParams)
  }

  requestSchoolEmail = 'support.usa@3plearning.com'

  requestSchoolCC = ['cleversupport@mathseeds.com']

  get requestSchoolSubject() {
    const { productName } = this
    return this.intl.t('clever.requestNewSchoolSubject', { productName })
  }

  /**
   * Text for the body of an email to request a new school
   */
  get requestSchoolBody() {
    const { schoolDetails, cleverSchool } = this
    const cleverSchoolName = cleverSchool.name

    const disco = this.session.currentDisco

    const body = this.intl.t('clever.requestNewSchoolBody', {
      districtCode: disco?.district.code,
      districtName: disco?.district.name,
      districtCoordinatorEmail: disco?.email,
      districtCoordinatorFirstName: disco?.firstName,
      districtCoordinatorFullName: disco?.fullName,
      schoolDetails,
      cleverSchoolName,
    })
    return body
  }

  get requiresAutomatchConfirmation() {
    return this.cleverSchool.isAwaitingSchoolMatchConfirm
  }

  get isSchoolMatchDone() {
    return this.cleverSchool.isSchoolMatchDone
  }

  /**
   * Will return an object of flash message text depending on the type needed and school names.
   */
  getFlashMessages(type, cleverSchool, blakeSchool = {}) {
    const { intl } = this
    const copyPrefix = `clever.flashMessages.school.${type}`
    const cleverSchoolName = cleverSchool.name
    const blakeSchoolName = blakeSchool.name

    return {
      success: intl.t(`${copyPrefix}.success`, { cleverSchoolName, blakeSchoolName }),
      fail: intl.t(`${copyPrefix}.fail`, { cleverSchoolName, blakeSchoolName }),
    }
  }

  async resetCleverMatch(cleverSchool) {
    if (cleverSchool.matched) {
      const matchRecord = await cleverSchool.cleverSchoolMatch
      await matchRecord?.destroyRecord()
    }
  }

  confirmMatchTask = task({ drop: true }, async (blakeSchool) => {
    const { clever, cleverSchool, flashQueue, log } = this
    const flashMessages = this.getFlashMessages('confirmMatch', cleverSchool, blakeSchool)

    try {
      await clever.confirmAutoMatch(cleverSchool)
      flashQueue.addSuccess({ subtitle: flashMessages.success })
      await this.router.refresh('clever.match.schools')
    } catch (error) {
      flashQueue.addFail({ subtitle: flashMessages.fail })
      log.error(flashMessages.fail, { backendError: error })
    }
  })

  /**
   * Call to confirm an auto match
   */
  @action
  confirmMatch(blakeSchool) {
    this.confirmMatchTask.perform(blakeSchool)
  }

  resetMatchTask = task({ drop: true }, async () => {
    const { cleverSchool, flashQueue, log } = this
    const flashMessages = this.getFlashMessages('reset', cleverSchool)

    try {
      await this.resetCleverMatch(cleverSchool)
      flashQueue.addSuccess({ subtitle: flashMessages.success })
      await this.router.refresh('clever.match.schools')
    } catch (error) {
      flashQueue.addFail({ subtitle: flashMessages.fail })
      log.error(flashMessages.fail, { backendError: error })
    }
  })

  /**
   * Call to reset/unmatch a school
   */
  @action
  resetMatch() {
    this.resetMatchTask.perform()
  }

  resetMatchFromOptionTask = task({ drop: true }, async (blakeSchool) => {
    const { clever, cleverSchool, flashQueue, log } = this
    const flashMessages = this.getFlashMessages('resetAndCreateMatch', cleverSchool, blakeSchool)

    try {
      await this.resetCleverMatch(cleverSchool)
      await clever.matchCleverSchoolToBlakeSchool(cleverSchool, blakeSchool)
      flashQueue.addSuccess({ subtitle: flashMessages.success })
      await this.router.refresh('clever.match.schools')
    } catch (error) {
      flashQueue.addFail({ subtitle: flashMessages.fail })
      log.error(flashMessages.fail, { backendError: error })
    }
  })

  /**
   * Call to reset/unmatch an existing school match and match with a different blake school (selected by dropdown)
   */
  @action
  resetMatchFromOption(blakeSchool) {
    this.resetMatchFromOptionTask.perform(blakeSchool)
  }

  createMatchFromOptionTask = task({ drop: true }, async (blakeSchool) => {
    const { clever, cleverSchool, flashQueue, log } = this
    const flashMessages = this.getFlashMessages('createMatch', cleverSchool, blakeSchool)

    try {
      await clever.matchCleverSchoolToBlakeSchool(cleverSchool, blakeSchool)
      flashQueue.addSuccess({ subtitle: flashMessages.success })
      await this.router.refresh('clever.match.schools')
    } catch (error) {
      flashQueue.addFail({ subtitle: flashMessages.fail })
      log.error(flashMessages.fail, { backendError: error })
    }
  })

  /**
   * Call to match with a blake school (selected by dropdown)
   */
  @action
  createMatchFromOption(blakeSchool) {
    this.createMatchFromOptionTask.perform(blakeSchool)
  }

  /**
   * Call this for any actions that might require some kind of confirmation checks (for example, checking for capacity
   * issues, then opening a modal where the action can be confirmed before being executed)
   */
  @action
  attemptMatchAction(matchAction, blakeSchoolId) {
    if (typeof matchAction !== 'function') return

    const hasSchoolOption = blakeSchoolId && !(blakeSchoolId instanceof Event)
    const blakeSchool = hasSchoolOption ? this.clever.getBlakeSchoolById(blakeSchoolId) : this.matchedBlakeSchool

    matchAction(blakeSchool)
  }
}
