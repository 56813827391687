import type { NormalizedInterval } from 'date-fns'
import {
  startOfMonth,
  endOfMonth,
  setMonth,
  addYears,
  subYears,
  isBefore,
} from 'date-fns'
import findRelativeAcademicYear from './relative-academic-years.ts'

/**
 * Get the academic year for a given date
 *
 * For a given date and country code, return the start and end date of the corresponding
 * academic year in which the given date lies within.
 *
 * @example
 * const date = new Date(2016,5,4) // June 5, 2016
 * const resultForAu = getAcademicYear(date, 'au')
 * result.start // January 1, 2016
 * result.end // December 31, 2016
 *
 * const resultForUs = getAcademicYear(date, 'us')
 * result.start // Auguest 1, 2015
 * result.end // July 31, 2016
 */
export default function getAcademicYear(
  date: Date,
  countryCode?: string,
): NormalizedInterval {
  const { start, end } = findRelativeAcademicYear(countryCode)
  const isEndOfAcademicYearTheSameYearAsTheStartDate = start.month < end.month
  const prevYearDate = subYears(date, 1)

  // The given date can lie within the
  // academic year starting the same year
  const academicYearStartingTheSameYear = {
    start: startOfMonth(setMonth(date, start.month)),
    end: isEndOfAcademicYearTheSameYearAsTheStartDate
      ? endOfMonth(setMonth(date, end.month))
      : endOfMonth(setMonth(addYears(date, 1), end.month)),
  }

  // Or it can lie within the
  // academic year starting the previous year
  const academicYearStartingThePreviousYear = {
    start: startOfMonth(setMonth(prevYearDate, start.month)),
    end: isEndOfAcademicYearTheSameYearAsTheStartDate
      ? endOfMonth(setMonth(prevYearDate, end.month))
      : endOfMonth(setMonth(addYears(prevYearDate, 1), end.month)),
  }

  const dateIsWithinPreviousAcademicYear = isBefore(
    date,
    academicYearStartingTheSameYear.start,
  )

  return dateIsWithinPreviousAcademicYear
    ? academicYearStartingThePreviousYear
    : academicYearStartingTheSameYear
}
