import type Controller from '@ember/controller'
import Route from '@ember/routing/route'
import type SubscriptionTypeRoute from 'district-ui-client/routes/subscription-type'

export default class SubscriptionTypeErrorRoute extends Route {
  // model hooks not called for a substate

  // keep it simple
  setupController(controller: Controller, model: unknown) {
    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>
    const errorController = controller
    errorController.set('subscriptionType', subscriptionType)
    super.setupController(errorController, model)
  }
}
