import { BaseState } from '@blakeelearning/ember-select/state/base-state'
import { defaultSearch, type SelectOption } from '@blakeelearning/ember-select/index'
import type { MultiSelectArgs } from '@blakeelearning/ember-select/components/multi-select'

export class MultiSelectState<T extends SelectOption> extends BaseState {
  args: MultiSelectArgs<T>

  constructor(args: MultiSelectArgs<T>) {
    super()
    this.args = args
  }

  toggleOption = (option?: T) => {
    if (!option) return

    if (this.args.selectedItems.includes(option.value)) {
      this.args.onSelect(this.args.selectedItems.filter((value: T['value']) => value !== option.value))
    } else {
      this.args.onSelect([...(this.args.selectedItems ?? []), option.value])
    }
  }

  get isAllSelected() {
    // only have to check that all options are selected - not necessarily that all selected items are in options
    return this.args.options.every((option: T) => this.args.selectedItems.includes(option.value))
  }

  toggleAll = () => {
    if (this.isAllSelected) {
      // deselect all
      this.args.onSelect([])
    } else {
      // select all
      this.args.onSelect(this.args.options.map((opt: T) => opt.value))
    }
  }

  get options() {
    return defaultSearch(this.args.options, this.searchTerm)
  }
}

export default MultiSelectState
