import { set, get } from '@ember/object'
import { task, waitForProperty } from 'ember-concurrency'
import { tracked } from '@glimmer/tracking'
import Utils, {
  buildSubmitCsvPollFn,
  buildValidateCsvPollFn,
  buildValidateCsvResultFn,
  requestHeaders,
} from 'district-ui-client/student-import/utils'
import { buildStudentsCsvApiSubmitUrl, buildStudentsCsvApiValidateUrl } from 'district-ui-client/student-import/urls'
import ErrorCodes from 'district-ui-client/student-import/error-codes'
import validateUploadTask from './validate-upload/task'
import validateTask from './validate/task'
import importUploadTask from './import-upload/task'
import importTask from './import/task'

const { CSV_INVALID } = ErrorCodes

/**
 * @type {StudentImportTask}
 */
export default {
  get validateUploader() {
    return this.validateUploadTask.last
  },

  get validator() {
    return this.validateTask.last
  },

  get importUploader() {
    return this.importUploadTask.last
  },

  get importer() {
    return this.importTask.last
  },

  subscriptionType: tracked(),

  // csv validateUploader child task properties
  get uploadProgress() {
    return this.validateUploader?.progress
  },

  get isUploading() {
    return this.validateUploader?.isUploading
  },

  get uploadComplete() {
    return this.validateUploader?.value?.ok
  },

  get filename() {
    return this.validateUploader?.args?.[0]?.name
  },

  get validationErrors() {
    return get(this, 'value.validationErrors')
  },

  // csv validator child task properties
  get isValidating() {
    return this.validator?.isRunning
  },

  get isValid() {
    return this.validator?.isValid
  },

  get candidateStudents() {
    return this.validator?.value?.candidateStudents
  },

  // csv importer child task properties
  get isSubmitting() {
    return this.importUploader?.isRunning || this.importer?.isRunning
  },

  get isImportSuccessful() {
    return get(this, 'value.ok') === true
  },

  submitClicked: tracked({ value: false }),

  /**
   * Has the task been marked as complete?
   * @property {Boolean} isMarkedComplete
   */
  isMarkedComplete: tracked({ value: false }),

  get unexpectedError() {
    return get(this, 'value.ok') === false && get(this, 'value.errorCode') !== CSV_INVALID
  },

  get unexpectedErrorData() {
    return {
      code: get(this, 'value.errorCode'),
      status: get(this, 'value.response.status'),
      job_id: get(this, 'value.jobId'),
    }
  },

  /**
   * Cancel the student import task by cancelling the ember-concurrency
   * task and marking it as complete.
   */
  cancelTask() {
    this.cancel()
    this.markAsComplete()
  },

  /**
   * Unblock the task when it is waiting for 'submitClicked' to equal true.
   */
  submitCsv() {
    // Need to use set, due to bug with waitForProperty https://github.com/machty/ember-concurrency/issues/292
    set(this, 'submitClicked', true)
  },

  /**
   * Mark the import task as complete i.e. it is no longer needed.
   */
  markAsComplete() {
    set(this, 'isMarkedComplete', true)
  },

  /**
   * Creates a task which uploads the csv file to be validated.
   * @returns {Promise<Boolean>} resolves to true if the task can proceed to the next step.
   */
  validateUploadTask: task(validateUploadTask).drop(),

  /**
   * Creates a task which polls gravity for the status of a csv validation job.
   * @returns {Promise<Boolean>} resolves to true if the task can proceed to the next step.
   */
  validateTask: task(validateTask).drop(),

  /**
   * Creates a task which uploads the csv file to be imported.
   * @returns {Promise<Boolean>} resolves to true if the task can proceed to the next step.
   */
  importUploadTask: task(importUploadTask).drop(),

  /**
   * Creates a task which polls gravity for the status of the csv import job.
   * @returns {Promise<Boolean>} resolves to true if the task can proceed to the next step.
   */
  importTask: task(importTask).drop(),

  /**
   * Build the function that will poll gravity for the status of the csv
   * validation job.
   */
  buildValidateCsvPollFn,

  /**
   * Build the function that will request gravity for the result of the csv
   * validation job.
   */
  buildValidateCsvResultFn,

  /**
   * Build the function that will poll gravity for the status of the csv
   * submission job.
   */
  buildSubmitCsvPollFn,

  /**
   * Creates a {TaskInstance} which uses gravity to determine if a csv is
   * valid and can thus be imported.
   * @param {DS.store} store
   * @param {IntlService} intl
   * @param {String} token auth token for gravity requests
   * @param {File} file
   * @param {String} options.districtId
   * @param {String} options.subscriptionType
   * @param {String} options.subscriptionTypeLocal
   * @param {String|Number|undefined} validateJobId
   * @param {String|Number|undefined} importJobId
   * @returns {Promise<Object>} // returns a promise that resolves to an object w/csv metadata.
   */
  *perform(
    file,
    { districtId, subscriptionType, subscriptionTypeLocal, validateJobId, importJobId },
    { token, intl, store },
  ) {
    this.subscriptionType = subscriptionType
    const timestamp = Utils.generateImportTimestamp(intl)
    const headers = requestHeaders(token)
    const urlParams = { districtId, subscriptionType }

    // skip job creation if we already have a validateJobId
    if (!validateJobId) {
      const validateUrl = buildStudentsCsvApiValidateUrl(urlParams)
      const uploadResult = yield this.validateUploadTask.perform(
        file,
        validateUrl,
        headers,
        {
          timestamp: String(timestamp),
          subscriptionTypeLocal,
          'Content-Type': file.type,
          async: true,
        },
        { intl },
      )
      if (!uploadResult.ok) return uploadResult
      validateJobId = uploadResult.jobId
    }
    const validateCsvPollFn = this.buildValidateCsvPollFn(store, { districtId, jobId: validateJobId, subscriptionType })
    const validateCsvResultFn = this.buildValidateCsvResultFn(headers, {
      districtId,
      jobId: validateJobId,
      subscriptionType,
    })

    const validateResult = yield this.validateTask.perform(
      {
        validateCsvPollFn,
        validateCsvResultFn,
      },
      { intl },
    )

    if (!validateResult?.ok) return validateResult

    yield waitForProperty(this, 'submitClicked', true)

    // skip job creation if we already have a importJobId
    if (!importJobId) {
      const importUrl = buildStudentsCsvApiSubmitUrl(urlParams)
      const uploadResult = yield this.importUploadTask.perform(file, importUrl, headers, {
        timestamp: String(timestamp),
        subscriptionTypeLocal,
        'Content-Type': file.type,
        async: true,
      })
      if (!uploadResult.ok) return uploadResult
      importJobId = uploadResult.jobId
    }
    const submitCsvPollFn = this.buildSubmitCsvPollFn(store, { districtId, jobId: importJobId, subscriptionType })

    return this.importTask.perform({ submitCsvPollFn })
  },
}
