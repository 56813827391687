import Route from '@ember/routing/route'

export default class FpLessonPerformanceRoute extends Route {
  queryParams = {
    band: {
      replace: true,
    },
    sort: {
      replace: true,
    },
    pageNumber: {
      replace: true,
    },
    perPage: {
      replace: true,
    },
  }
}
