import Component from '@glimmer/component'

/**
 * This is used by the Loading Indicator component.  It is not designed to be
 * used independently.
 *
 * @class LoadingIndicatorInnerComponent
 * @private
 */

interface Args {
  appearance?: string
  centered?: boolean
  loadingText?: string
}

interface Signature {
  Args: Args
  Blocks: {
    default: []
  }
}

export class FidgetLoadingIndicatorInnerComponent extends Component<Signature> {
  /**
   * There is a small version of this loader where the wave is smaller and has
   * fewwer squares.  Based on the appearance we can tell the wave whether to
   * render small.
   *
   * @type {Boolean}
   */
  get isSmall() {
    return this.args.appearance === 'small'
  }
}

export default FidgetLoadingIndicatorInnerComponent
