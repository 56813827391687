import Service from '@ember/service'
import { tracked } from '@glimmer/tracking'

export type Tone = 'info' | 'caution' | 'critical' | 'positive'

interface AlertContentOptions {
  type?: Tone
  message: string
  dismissAfterMilliseconds?: number
}

export default class AlertService extends Service {
  @tracked alertContent: Nullable<AlertContentOptions> = null

  show(options: AlertContentOptions) {
    this.openAlert({
      type: options.type || 'positive',
      message: options.message,
    })
  }

  /**
   *
   * @param {String} options.type 'positive'
   * @param {String} options.message message to display to user
   * @param {Number} options.dismissAfterMilliseconds time that alert stays open
   */
  showWithDismiss(options: AlertContentOptions) {
    this.openAlert({
      type: options.type || 'positive',
      message: options.message,
      dismissAfterMilliseconds: options.dismissAfterMilliseconds,
    })
  }

  openAlert(alertContent: AlertContentOptions) {
    if (alertContent.dismissAfterMilliseconds) {
      setTimeout(() => {
        this.closeAlert()
      }, alertContent.dismissAfterMilliseconds)
    }
    this.alertContent = alertContent
  }

  closeAlert() {
    this.alertContent = null
  }
}
