import { template as template_a42a25ee9aca41f7a1fd21e2cc6a8519 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import type GradeSetsService from 'district-ui-client/services/grade-sets';
import type { IntlService } from 'ember-intl';
import BlakeColors from '@blakeelearning/blake-colours/colours';
import { t } from 'ember-intl';
import SeriesBar from 'district-ui-client/components/primitives/series-bar/component';
import type { SeriesBarOptions } from 'highcharts';
/**
 * Turns a student-events stories_written_by_grade response into a giraffe chart
 * using the dashboard-title and series-bar primitives.

 */ export interface StoriesWrittenData {
    stories_written_by_grade: {
        grade_position: number;
        student_count: number;
        story_count: number;
    }[];
    student_count: number;
}
interface Signature {
    Args: {
        data?: StoriesWrittenData;
    };
}
export class StoriesWrittenDashboardChart extends Component<Signature> {
    @service
    gradeSets: GradeSetsService;
    @service
    intl: IntlService;
    get colour() {
        return BlakeColors.oceanyBlue300;
    }
    get categories() {
        const chartData = this.args.data?.stories_written_by_grade;
        return chartData?.map((grade)=>this.gradeSets.findByPosition(grade.grade_position)?.name ?? '-');
    }
    get bars(): SeriesBarOptions[] {
        const color = this.colour;
        const byGrade = this.args.data?.stories_written_by_grade;
        return [
            {
                data: byGrade?.map((grade)=>{
                    if (grade.student_count === 0) {
                        return 0;
                    }
                    return Math.round(grade.story_count / grade.student_count);
                }),
                type: 'bar',
                color
            }
        ];
    }
    get subtitleKey() {
        if (this.isSingleGrade) return 'reporting.dashboardSubtitles.singleGrade.storiesWritten';
        return 'reporting.dashboardSubtitles.multiGrade';
    }
    get isSingleGrade() {
        return this.args.data?.stories_written_by_grade.length === 1;
    }
    get yAxisLabel() {
        return this.isSingleGrade ? 'Number of stories' : 'Stories Written';
    }
    get studentCount() {
        const count = this.args.data?.student_count ?? 0;
        return formatNumber(count, 2);
    }
    get singleGradeChartMargin() {
        return [
            55,
            null,
            104,
            null
        ];
    }
    static{
        template_a42a25ee9aca41f7a1fd21e2cc6a8519(`
    <div class="flex h-full flex-col" data-test-stories-written-chart>
      <div data-test-dashboard-subtitle class="flex justify-between gap-4 text-xs">
        <span>
          {{t this.subtitleKey}}
        </span>
        <span>
          {{t "reporting.dashboardSubtitles.studentCount" count=this.studentCount}}
        </span>
      </div>
      {{#if this.isSingleGrade}}
        <SeriesBar
          @data={{this.bars}}
          @categories={{this.categories}}
          @xAxisLabel={{t "grade.label"}}
          @yAxisLabel={{this.yAxisLabel}}
          @groupPadding={{0}}
          @barPadding={{0}}
          @chartMargin={{this.singleGradeChartMargin}}
          @yAxisOffset={{25}}
          @yAxisLineWidth={{1}}
          @yAxisGridLineWidth={{0}}
        />
      {{else}}
        <SeriesBar
          @data={{this.bars}}
          @categories={{this.categories}}
          @xAxisLabel={{t "grade.label"}}
          @yAxisLabel={{this.yAxisLabel}}
        />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default StoriesWrittenDashboardChart;
