import type { Asset, Entry, EntryFieldTypes, EntrySkeletonType } from 'contentful'

export type DiscoGuideSkeleton = EntrySkeletonType<{
  title: EntryFieldTypes.Text
  subscriptionType: EntryFieldTypes.Symbol<'Reading'> | EntryFieldTypes.Symbol<'Maths'>
  file: EntryFieldTypes.AssetLink
}>
export type DiscoGuideEntry = Entry<DiscoGuideSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

/**
 * The asset returned for an entry could be a resolved asset with url, or a link.
 *
 * Returns the assets' url if it was resolved
 */
export function getAssetUrl(asset: Asset<'WITHOUT_UNRESOLVABLE_LINKS'> | undefined): string | undefined {
  if (asset && 'fields' in asset && asset.fields.file && 'url' in asset.fields.file) {
    return asset.fields.file.url
  }
}
