import { template as template_713f3325ff5c40eca9bde5856ad50994 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import type School from 'district-ui-client/models/school';
import { AlertType, InlineAlert } from 'district-ui-client/components/inline-alert';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
import { ThemedLinkTo } from 'district-ui-client/components/themed-button';
interface OverCapacityAlertSignature {
    Element: HTMLElement;
    Args: {
        schools?: Nullable<School[]>;
        subscriptionType: SubscriptionType;
        /** if alert is shown on the home page, set to true to show link to manage schools page */ isHome?: boolean;
    };
}
export class OverCapacityAlert extends Component<OverCapacityAlertSignature> {
    get overCapacitySchoolCount() {
        return this.args.schools?.filter((school)=>{
            const utilisation = school.utilisationFor(this.args.subscriptionType);
            return utilisation?.isOverCapacity;
        }).length;
    }
    static{
        template_713f3325ff5c40eca9bde5856ad50994(`
    {{#if this.overCapacitySchoolCount}}
      <InlineAlert @type={{AlertType.Warning}} @showClose={{true}} data-test-over-capacity-alert ...attributes>
        <div class="space-y-2">
          <h2 class="m-0 text-sm font-medium">
            {{t "components.overCapacityAlert.title" schoolCount=this.overCapacitySchoolCount}}
            <TooltipInfo @text={{t "components.overCapacityAlert.tooltip"}} />
          </h2>
          {{#if @isHome}}
            <p class="m-0 text-sm">{{t "components.overCapacityAlert.homeDescription"}}</p>
            <ThemedLinkTo @style="theme" @route="subscription-type.manage.schools">
              <span>{{t "components.overCapacityAlert.manageSchools"}}</span>
              <FaIcon @icon="arrow-right" />
            </ThemedLinkTo>
          {{else}}
            <p class="m-0 text-sm">{{t "components.overCapacityAlert.schoolsDescription"}}</p>
            <ThemedLinkTo @style="theme" @route="subscription-type.support">
              <span>{{t "components.overCapacityAlert.contactSupport"}}</span>
              <FaIcon @icon="arrow-right" />
            </ThemedLinkTo>
          {{/if}}
        </div>
      </InlineAlert>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default OverCapacityAlert;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        OverCapacityAlert: typeof OverCapacityAlert;
    }
}
