import { helper } from '@ember/component/helper'
import { isEmpty } from '@ember/utils'

interface Signature {
  Args: {
    Positional: [number?: Nullable<number>]
  }
  Return: string
}

export const thousands = helper<Signature>(function ([number]) {
  if (isEmpty(number)) return '-'
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '0'
})

export default thousands
