import Model, { belongsTo, attr } from '@ember-data/model'
import type School from 'district-ui-client/models/school'

export default class ContactDetail extends Model {
  @attr('string') address1!: string

  @attr('string') faxNumber!: string

  @attr('string') phoneNumber!: string

  @attr('string') state!: string

  @attr('string') town!: string

  @belongsTo('school', { async: false, inverse: 'contactDetail' }) school?: School
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'contact-detail': ContactDetail
  }
}
