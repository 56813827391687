import type Store from '@ember-data/store'
import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type SubscriptionTypeRoute from 'district-ui-client/routes/subscription-type'
import type SessionService from 'district-ui-client/services/session'

export default class AddExistingTeachersRoute extends Route {
  @service store!: Store

  @service session!: SessionService

  async model() {
    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>
    // get all teachers where school has this product, but teacher does not have this product
    await this.store.query('school', {
      scope: `districts/${this.session.currentDistrict.id}`,
      // need ALL school-classes, in order to know exactly what sub-types a teacher has classes for.
      include: 'school-classes,teachers,teachers.school-classes,school-subscription-utilisations',
    })
    // get teachers for school of subtype that dont have classes for that subtype
    const teachersWithoutSubType = this.store.peekAll('teacher').filter((teacher) => {
      const schoolHasSubType = teacher.school?.utilisationFor(subscriptionType)
      const teacherHasSubType = teacher.schoolClasses?.some(
        (schoolClass) => schoolClass.subscriptionType === subscriptionType,
      )
      return schoolHasSubType && !teacherHasSubType
    })
    return { teachersWithoutSubType, subscriptionType }
  }
}
