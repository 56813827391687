import Service from '@ember/service'
import { assert } from '@ember/debug'
import {
  sort as dataTransformerSort,
  paginate as dataTransformerPaginate,
  filter as dataTransformerFilter,
} from '@blakeelearning/data-tables/utils/data-transformer'
import type {
  PipelineData,
  PipelineOperation,
  PipelineDescriptor,
} from '@blakeelearning/data-tables/utils/data-transformer'

/**
 * Provides functions to transform data using a composable pipeline.
 *
 * To be used anywhere: routes, controllers, components.
 */

export default class DataTransformer extends Service {
  /**
   * Takes an array of items and an array of (potentially async) operations, builds up an async pipeline
   * function by using forward function composition across the async operations, and then applies that
   * to an object where the items have been added to an object under an `items` key.
   *
   * Note that this function expects each of the functions' outputs to match the subsequent functions' inputs.
   * However, they don't have to all be the same.
   * The general expected convention is to take and pass an object that *at least* has the `items` key on it,
   * with an array of same-typed objects inside.
   *
   * @param {*} items array of items to be processed through the pipeline
   * @param {Array<Function>} operations an array of operation functions from { items, ... } to the same type.
   * `items` is assumed to be an array of objects of the same type as each other.
   * Each operation function should destructure `items` out, but also `...rest`, and pass back
   * and object with `...rest` plumbed back in, and the new value of `items` in place:
   * Will be used to filter, sort, and/or otherwise transform the data.
   * Note that they should not *mutate* the data, preferably, however they can set the data context.
   *
   * @example operation function:
   * ( { items, ...rest }) => { return { ...rest, items: items.map((i) => i + 1) } }
   */
  async transform<TInput, TResult>(
    items: Array<TInput>,
    operations: Array<PipelineOperation<any, any>>
  ): Promise<PipelineData<TResult>> {
    const pipeline = operations.reduce(
      (
        acc: PipelineOperation<any, any>,
        operation: PipelineOperation<any, any>,
        index: number
      ) => {
        assert(
          `The pipeline operation at index ${index} was not a function`,
          typeof operation === 'function'
        )
        const next: PipelineOperation<any, any> = async (data) => {
          const resultSoFar = await acc(data)
          const result = await operation({ ...data, ...resultSoFar })
          return { ...resultSoFar, ...result }
        }
        return next
      },
      (obj) => obj
    )
    return pipeline({ items })
  }

  /**
   * Build a pipeline operation that builds a new object with the passed in function
   * applied to the previous object's items values.
   *
   * @param {Function} itemsTransformer a function on items to items that this function
   * will use to thread through the ordinary transformation object that gets passed through
   * a pipeline operation function.
   *
   * @example this.itemsOperation((items) => items.filter(({ password }) => password.length < 15))
   */
  itemsOperation<TInput, TResult>(
    itemsTransformer: (
      items: Array<TInput>
    ) => Promise<Array<TResult>> | Array<TResult>
  ): PipelineOperation<TInput, TResult> {
    const nextFun: PipelineOperation<TInput, TResult> = async ({
      items: previousItems,
      ...rest
    }) => {
      const items = await itemsTransformer(previousItems)
      return { ...rest, items }
    }
    return nextFun
  }

  /**
   * Build a pipeline operation that yields a new object where the items have had the
   * passed in function mapped over each item.
   *
   * @param {Function} mapper a function on item to item that this function
   * will use to map across each item, threading through the ordinary transformation object
   * that gets passed through a pipeline operation function.
   *
   * @example this.mapItemsOperation(({ name, ...rest }) => { return { ...rest, name, capitalizedName: capitalize(name) }})
   * @returns {Function}
   * */
  mapItemsOperation<TInput, TResult>(
    mapper: (item: TInput) => TResult
  ): PipelineOperation<TInput, TResult> {
    return this.itemsOperation((items) => items.map(mapper))
  }

  buildPipelineOperations(
    pipelineDescriptors: Array<PipelineDescriptor>
  ): Array<PipelineOperation<any, any>> {
    const pipeline: Array<PipelineOperation<any, any>> = []
    pipelineDescriptors.forEach((descriptor) => {
      switch (descriptor.type) {
        case 'sort':
          if (descriptor.config)
            pipeline.push(dataTransformerSort(descriptor.config))
          break
        case 'paginate':
          if (descriptor.config)
            pipeline.push(dataTransformerPaginate(descriptor.config))
          break
        case 'filter':
          if (descriptor.config)
            pipeline.push(dataTransformerFilter(descriptor.config))
          break
        case 'custom':
          if (descriptor.transformer) pipeline.push(descriptor.transformer)
          break
        default:
          break
      }
    })
    return pipeline
  }

  buildAndTransform<TInput, TResult>(
    items: Array<TInput>,
    pipelineDescriptors: Array<PipelineDescriptor>
  ): Promise<PipelineData<TResult>> {
    const pipeline = this.buildPipelineOperations(pipelineDescriptors)
    return this.transform(items, pipeline)
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'data-transformer': DataTransformer
  }
}
