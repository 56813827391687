import ApplicationJSONAPISerializer from 'district-ui-client/serializers/application'

interface WithAttributes {
  data: { attributes: Record<string, unknown> }
}

function hasAttributes(json: unknown): json is WithAttributes {
  return Boolean(
    json &&
      typeof json === 'object' &&
      'data' in json &&
      json.data &&
      typeof json.data === 'object' &&
      'attributes' in json.data &&
      json.data.attributes &&
      typeof json.data.attributes === 'object',
  )
}

export default class TeacherSerializer extends ApplicationJSONAPISerializer {
  serialize(snapshot: ModelSnapshot, options: SerializerOptions) {
    const json = super.serialize(snapshot, options)

    if (hasAttributes(json)) {
      const { subscriptionType, accountType } = snapshot.adapterOptions ?? {}
      if (accountType) json.data.attributes['account-type'] = accountType
      if (subscriptionType) json.data.attributes['subscription-type'] = subscriptionType
    }

    return json
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    teacher: TeacherSerializer
  }
}
