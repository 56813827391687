import { service } from '@ember/service'
import Route from '@ember/routing/route'
import type SessionService from 'district-ui-client/services/session'
import type RouterService from '@ember/routing/router-service'
import type Store from '@ember-data/store'
import type Transition from '@ember/routing/transition'
import type SchoolRolloverService from 'district-ui-client/services/school-rollover'
import {
  SubscriptionType,
  isSubscriptionType,
  subscriptionTypeOrDefault,
} from 'district-ui-client/domain/subscription-type'

/**
 * Validates the subscriptionType param against available subscriptions.
 */
export default class SubscriptionTypeRoute extends Route {
  @service
  schoolRollover!: SchoolRolloverService

  @service
  session!: SessionService

  @service
  router!: RouterService

  @service
  store!: Store

  /**
   * In the case of an invalid or unavailable subscriptionType, it will transition to the landing page, which will
   * determine what to do about it.
   */
  beforeModel(transition: Transition) {
    const toRouteInfo = transition.to?.find((routeInfo) => Boolean(routeInfo.params?.subscription_type))
    const subscriptionType = toRouteInfo?.params?.subscription_type

    const isValid = isSubscriptionType(subscriptionType)
    if (!isValid) {
      void this.router.transitionTo('landing')
      return
    }

    if (!this.session.currentDisco.subscriptionTypes.includes(subscriptionType)) {
      void this.router.transitionTo('landing')
    }
  }

  model(params: { subscription_type: string }) {
    const subscriptionType = subscriptionTypeOrDefault(params.subscription_type, SubscriptionType.Reading)

    const { currentDisco, currentDistrict: district } = this.session

    // get any rollovers for district - no need to await, will fill in the live arrays that show the alert
    void this.schoolRollover.fetchRollovers()

    return { subscriptionType, district, countryCode: currentDisco.countryCode }
  }
}
