import config from 'district-ui-client/config/environment'
import { join } from 'district-ui-client/utils/uri'

/**
 * @param {String} options.districtId
 * @param {String} options.subscriptionType
 */
function buildStudentsCsvApiBaseUrl({ districtId, subscriptionType }) {
  return join(config.gravityCsvUrl, 'districts', districtId, subscriptionType).toString()
}

export function buildStudentsCsvApiValidateUrl({ districtId, subscriptionType, jobId }) {
  const baseUrl = buildStudentsCsvApiBaseUrl({ districtId, subscriptionType })
  if (jobId) {
    return join(baseUrl, 'import-validation', jobId).toString()
  } else {
    return join(baseUrl, 'import-validation').toString()
  }
}

export function buildStudentsCsvApiValidateResultUrl({ districtId, subscriptionType, jobId }) {
  const baseUrl = buildStudentsCsvApiBaseUrl({ districtId, subscriptionType })
  return join(baseUrl, 'import-validation-result', jobId).toString()
}

export function buildStudentsCsvApiSubmitUrl({ districtId, subscriptionType, jobId }) {
  const baseUrl = buildStudentsCsvApiBaseUrl({ districtId, subscriptionType })
  if (jobId) {
    return join(baseUrl, 'import-schedules', jobId).toString()
  } else {
    return join(baseUrl, 'import-schedules').toString()
  }
}

export function buildStudentsCsvApiSubmitResultUrl({ districtId, subscriptionType, jobId }) {
  const baseUrl = buildStudentsCsvApiBaseUrl({ districtId, subscriptionType })
  return join(baseUrl, 'import-schedules-result', jobId).toString()
}
