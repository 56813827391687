import Model, { belongsTo, hasMany, attr, type SyncHasMany } from '@ember-data/model'
import type SchoolClass from 'district-ui-client/models/school-class'
import type School from 'district-ui-client/models/school'
import type Teacher from 'district-ui-client/models/teacher'
import uniqBy from 'lodash/uniqBy'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'

function uniqTeachersFromSchoolClasses(schoolClasses: SchoolClass[]): Teacher[] {
  return uniqBy(
    schoolClasses.reduce<Teacher[]>((acc, schoolClass) => {
      const teachers = schoolClass.teachers?.slice()
      if (!teachers) return acc
      return [...acc, ...teachers]
    }, []),
    (teacher) => teacher.id,
  )
}

export default class Student extends Model {
  @attr('string') firstName!: string

  @attr('string') lastName!: string

  @attr('number') gradePosition!: number

  @attr('string') login!: string

  @attr('string') plainPassword!: string

  @attr('string') externalId!: string

  @belongsTo('school', { async: false, inverse: 'students' }) school?: School

  @hasMany('school-class', { async: false, inverse: 'students' }) schoolClasses!: SyncHasMany<SchoolClass>

  get schoolName() {
    return this.school?.name ?? ''
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  schoolClassesFor(subscriptionType: SubscriptionType) {
    return this.schoolClasses?.filter((item) => item.subscriptionType === subscriptionType) ?? []
  }

  teachersFor(subscriptionType: SubscriptionType) {
    return uniqTeachersFromSchoolClasses(this.schoolClassesFor(subscriptionType))
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    student: Student
  }
}
