import { template as template_d9a0ea1c5d014058b61e324c7719769f } from "@ember/template-compiler";
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import Component from '@glimmer/component';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { Tooltip } from 'district-ui-client/components/tooltip';
import { notEq } from 'ember-truth-helpers';
import type { SafeString } from '@ember/template';
export const themeKeys = {
    THEME_DEFAULT: 'default',
    THEME_MID_ORANGE: 'mid-orange',
    THEME_JUICY_ORANGE: 'juicy-orange',
    THEME_MS_GREEN: 'ms-green',
    THEME_GRAPEY_GREEN: 'grapey-green',
    THEME_FOREST_GREEN: 'forest-green',
    THEME_OCEANY_BLUE: 'oceany-blue',
    THEME_STORM_BLUE: 'storm-blue',
    THEME_PURPLY_PINK: 'purply-pink',
    THEME_REXY_PURPLE: 'rexy-purple'
} as const;
export const themes = {
    [themeKeys.THEME_DEFAULT]: {
        header: 'bg-dusty-black-500',
        stats: 'bg-dusty-black-200'
    },
    [themeKeys.THEME_MID_ORANGE]: {
        header: 'bg-mid-orange-300',
        stats: 'bg-mid-orange-100'
    },
    [themeKeys.THEME_JUICY_ORANGE]: {
        header: 'bg-juicy-orange-300',
        stats: 'bg-juicy-orange-100'
    },
    [themeKeys.THEME_MS_GREEN]: {
        header: 'bg-ms-green-300',
        stats: 'bg-ms-green-100'
    },
    [themeKeys.THEME_GRAPEY_GREEN]: {
        header: 'bg-grapey-green-300',
        stats: 'bg-grapey-green-100'
    },
    [themeKeys.THEME_FOREST_GREEN]: {
        header: 'bg-forest-green-300',
        stats: 'bg-forest-green-100'
    },
    [themeKeys.THEME_OCEANY_BLUE]: {
        header: 'bg-oceany-blue-300',
        stats: 'bg-oceany-blue-100'
    },
    [themeKeys.THEME_STORM_BLUE]: {
        header: 'bg-storm-blue-300',
        stats: 'bg-storm-blue-100'
    },
    [themeKeys.THEME_PURPLY_PINK]: {
        header: 'bg-purply-pink-300',
        stats: 'bg-purply-pink-100'
    },
    [themeKeys.THEME_REXY_PURPLE]: {
        header: 'bg-rexy-purple-300',
        stats: 'bg-rexy-purple-100'
    }
};
interface StatObject {
    name: string;
    value: number | string | SafeString;
}
interface Args {
    theme?: keyof typeof themes;
    titleIcon?: IconName;
    title: string;
    tooltipText?: string;
    stats: StatObject[][];
}
interface Signature {
    Args: Args;
    Element: HTMLDivElement;
}
/**
 * Pass this component an array of rows of stats. The array dictates the structure of the stat-box.
 * This example, would produce stat box with StatObject1 on the top row, and 2 evenly split stats on the bottom row.
 * {
 *   title: 'my title',
 *   theme: 'some-theme',
 *   stats: [ [StatObject1], [StatObject2, StatObject3] ],
 * }
 *
 * A StatObject is an object comprising of name and value keys
 * {
 *  name: string
 *  value: number | string
 * }
 */ export class StatBox extends Component<Signature> {
    get colorTheme() {
        const theme = this.args.theme ?? themeKeys.THEME_DEFAULT;
        return themes[theme];
    }
    static{
        template_d9a0ea1c5d014058b61e324c7719769f(`
    <div data-test-stat-box class="print:border-dusty-black-200 flex flex-col print:border" ...attributes>
      <header
        data-test-stat-box-header
        class="header print:border-dusty-black-200 print:bg-dusty-black-50 relative flex h-14 flex-shrink-0 flex-grow-0 items-center justify-center text-center text-white print:m-0.5 print:border-b print:border-solid
          {{this.colorTheme.header}}"
      >
        {{! Extra horizontal padding necessary for tooltip icon }}
        <p class="stat__title m-0 px-5 text-base font-bold print:font-bold print:text-black">
          {{#if @titleIcon}}
            <FaIcon @icon={{@titleIcon}} aria-hidden="true" class="mr-1" />
          {{/if}}
          {{@title}}
        </p>
        {{#if @tooltipText}}
          <span data-test-stat-box-tooltip class="absolute right-0.5 top-0 print:hidden">
            <FaIcon @icon="circle-info" @size="xs" class="text-dusty-black-500 cursor-pointer" />
            <Tooltip @text={{@tooltipText}} />
          </span>
        {{/if}}
      </header>

      <div
        data-test-stat-box-stats
        class="stats flex flex-grow flex-col p-1 text-black print:bg-transparent {{this.colorTheme.stats}}"
      >
        {{#each @stats as |statsRow statsRowIndex|}}
          {{#if (notEq statsRowIndex 0)}}
            <div class="print:border-dusty-black-200 my-1 border-t border-solid border-white"></div>
          {{/if}}
          <div class="stats-row flex flex-shrink flex-grow" data-test-stat-box-stats-row={{statsRowIndex}}>
            {{#each statsRow as |stat statIndex|}}
              {{#if (notEq statIndex 0)}}
                <div class="print:border-dusty-black-200 mx-1 border-l border-solid border-white"></div>
              {{/if}}
              <div class="stat w-full flex-shrink flex-grow py-1 text-center" data-test-stat-box-stat={{statIndex}}>
                <p class="stat__value m-0 !text-3xl leading-none" data-test-stat-box-stat-value>
                  {{stat.value}}
                </p>
                <p class="stat__name mb-0 mt-0.5 text-base leading-none" data-test-stat-box-stat-name>
                  {{stat.name}}
                </p>
              </div>
            {{/each}}
          </div>
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default StatBox;
