import ApplicationJSONAPISerializer from 'district-ui-client/serializers/application'

export default class SchoolStatisticSerializer extends ApplicationJSONAPISerializer {
  modelNameFromPayloadKey(payloadKey: string) {
    const modelName = {
      statistics: 'school-statistic',
    }[payloadKey]
    return modelName || payloadKey
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'school-statistic': SchoolStatisticSerializer
  }
}
