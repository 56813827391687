import { startLog } from '@blakeelearning/log'
import config from 'district-ui-client/config/environment'

export function initialize() {
  startLog({
    rollbar: {
      ...config.rollbar,
    },
  })
}

export default {
  name: 'start-blakeelearning-log',
  initialize,
}
