import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'
import { action } from '@ember/object'
import { isEmpty } from '@ember/utils'
import uniqBy from 'lodash/uniqBy'

export default class IndexController extends Controller {
  @service session

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType)
  }

  get groupActionsDisabled() {
    return isEmpty(this.selectedIds) || this.isCleverDisabled
  }

  @service activeRoute

  @service alert

  @service teacherOperations

  @service intl

  @service log

  @tracked selectedIds = []

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get productTeachers() {
    return uniqBy(
      this.model.schoolClassesForSubType.flatMap((sc) => sc.teachers),
      'id',
    )
  }

  _uncheckSelectedItems() {
    this.selectedIds = []
  }

  _getFilteredCollection() {
    return this.productTeachers.filter((t) => this.selectedIds.includes(t.id))
  }

  // here to be stubbed in test
  windowConfirm(confirmMessage) {
    return window.confirm(confirmMessage)
  }

  @action
  updateSelectedIds(selectedIds) {
    this.selectedIds = selectedIds
  }

  @action
  async deleteTeachers() {
    const teachers = this._getFilteredCollection()
    if (teachers.length > 0) {
      const confirmMessage = `Are you sure you wish to delete ${teachers.length} teacher(s)`
      const confirmResponse = this.windowConfirm(confirmMessage)
      if (!confirmResponse) return

      const successMessage = `Successfully deleted ${teachers.length} teachers`

      try {
        const promises = teachers.map((teacher) => teacher.destroyRecord({ adapterOptions: { bulk: true } }))
        await Promise.all(promises)

        this.alert.showWithDismiss({ message: successMessage })
        this._uncheckSelectedItems()
      } catch (e) {
        this.log.error('failed to delete teachers', e)
        this.alert.show({
          type: 'critical',
          message: this.intl.t('errorMessages.deleteTeachers'),
        })
      }
    }
  }

  @action
  async passwordReset() {
    const teachers = this._getFilteredCollection()
    if (teachers.length > 0) {
      const response = await this.teacherOperations.passwordReset(teachers)
      if (response.ok) {
        this._uncheckSelectedItems()
        this.alert.showWithDismiss({ message: this.intl.t('subscriptionType.manageTeachers.passwordResetSuccess') })
      } else {
        this.log.error('failed to request password reset', await response.text())
        this.alert.show({
          type: 'critical',
          message: this.intl.t('errorMessages.passwordReset'),
        })
      }
    }
  }

  @action
  async loginDetails() {
    const teachers = this._getFilteredCollection()
    if (teachers.length > 0) {
      const response = await this.teacherOperations.sendLoginDetails(teachers, this.subscriptionType)
      if (response.ok) {
        this._uncheckSelectedItems()
        this.alert.showWithDismiss({ message: this.intl.t('subscriptionType.manageTeachers.sendLoginDetailsSuccess') })
      } else {
        this.log.error('failed to send login details', await response.text())
        this.alert.show({
          type: 'critical',
          message: this.intl.t('errorMessages.loginDetails'),
        })
      }
    }
  }

  @action
  async exportTeachers(school) {
    const response = await this.teacherOperations.exportTeachers(this.subscriptionType, school)
    if (response.ok) {
      this.alert.showWithDismiss({
        message: this.intl.t('subscriptionType.manageTeachers.exportTeachers.exportCsvSuccess'),
      })
    } else {
      this.log.error('failed to export teachers', await response.text())
      this.alert.show({
        type: 'critical',
        message: this.intl.t('subscriptionType.manageTeachers.exportTeachers.exportCsvError'),
      })
    }
  }
}
