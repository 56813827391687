import BaseSerializer from 'district-ui-client/serializers/clever'

export default class CleverTeacherSerializer extends BaseSerializer {
  normalize(modelClass, resourceHash) {
    const normalized = super.normalize(modelClass, resourceHash)
    const attributes = normalized?.data?.attributes
    if (attributes && attributes.matchError === undefined) {
      // when a record is updated, an undefined match-error will not
      // clear out the record's match-error property, so explicitly
      // set it to null.
      attributes.matchError = null
    }
    return normalized
  }
}
