/**
 * Same content of arrays, ordering doesn't matter.
 */
export function sameContent(array1: (string | boolean | number)[], array2: (string | boolean | number)[]): boolean {
  if (array1.length !== array2.length) return false
  const sorted1 = [...array1].sort()
  const sorted2 = [...array2].sort()
  // arrays are now same length and sorted, can do a single iteration to verify contents
  return sorted1.every((item, index) => item === sorted2[index])
}
