import Service from '@ember/service'
import config from 'district-ui-client/config/environment'

export class FeaturesService extends Service {
  get featureFlags() {
    return { ...config.featureFlags }
  }

  isEnabled(flag: string): boolean {
    return this.featureFlags[flag] === true
  }

  get enabledFlags() {
    return Object.keys(this.featureFlags).reduce<string[]>((flags, flag) => {
      return this.isEnabled(flag) ? [...flags, flag] : flags
    }, [])
  }
}

export default FeaturesService

declare module '@ember/service' {
  interface Registry {
    features: FeaturesService
  }
}
