import { tracked } from '@glimmer/tracking'
import { get } from '@ember/object'
import {
  formatValidationErrors,
  getJobIdFromResponseBody,
  uploadFileWithProgress,
} from 'district-ui-client/student-import/utils'
import ErrorCodes from 'district-ui-client/student-import/error-codes'

const { CSV_INVALID, UPLOAD_FAILED } = ErrorCodes

/**
 * @param {File} file
 * @returns {Boolean}
 */
export function isValidFileType(file) {
  return String(file.name).toLowerCase().endsWith('csv')
}

export default {
  progress: tracked({ value: 0 }),

  setUploadProgress(progress) {
    this.progress = progress
  },

  get isValid() {
    return Boolean(get(this, 'value.ok'))
  },

  get errorCode() {
    return get(this, 'value.errorCode')
  },

  get validationErrors() {
    return get(this, 'value.validationErrors')
  },

  /**
   * Creates a {TaskInstance} which uploads a file to the given url,
   * updating the progress property (0-100) until the upload is complete.
   * @param {File} file
   * @param {String} url
   * @param {Object} headers
   * @param {Object} formData
   * @returns {Promise<Object>} // returns { ok: true, jobId } on success, { ok: false, response, responseBody } otherwise.
   */
  *perform(file, url, headers, formData, { intl }) {
    if (!isValidFileType(file)) {
      return {
        ok: false,
        errorCode: CSV_INVALID,
        validationErrors: [
          {
            detail: intl.t('manage.studentCsv.errors.invalid_file_type'),
          },
        ],
      }
    }
    const updateProgressFn = this.setUploadProgress.bind(this)
    const response = yield uploadFileWithProgress(file, url, headers, formData, updateProgressFn)
    const responseBody = yield response.json()
    if (!response.ok) {
      if (response?.status === 422) {
        // early validation failure before validation job added to queue
        return {
          ok: false,
          errorCode: CSV_INVALID,
          validationErrors: formatValidationErrors(responseBody, { intl }),
        }
      } else {
        return {
          ok: false,
          errorCode: UPLOAD_FAILED,
          response,
          responseBody,
        }
      }
    }
    const jobId = getJobIdFromResponseBody(responseBody)
    if (!jobId) {
      return {
        ok: false,
        errorCode: UPLOAD_FAILED,
        response,
        responseBody,
      }
    } else {
      return {
        ok: true,
        jobId,
      }
    }
  },
}
