import Service, { service } from '@ember/service'
import { Product, productsForSubscriptionType } from 'district-ui-client/domain/product'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type FeatureControlService from 'district-ui-client/services/feature-control'
import { FeatureAccess } from 'district-ui-client/services/feature-control'
import type FeaturesService from 'district-ui-client/services/features'
import type { IntlService } from 'ember-intl'

export class ReportRegistryService extends Service {
  @service intl!: IntlService

  @service features!: FeaturesService

  @service featureControl!: FeatureControlService

  /**
   * Reports in the registry are enabled by default.
   */
  isReportEnabled(reportPath: ReportPath) {
    const featureFlag = reportsRegistry[reportPath].featureFlag
    const requiresStandards = reportsRegistry[reportPath].requiresStandards
    const accessGranted = requiresStandards ? this.featureControl.canAccess(FeatureAccess.Standards) : true
    const featureEnabled = featureFlag === undefined ? true : this.features.isEnabled(featureFlag)
    return accessGranted && featureEnabled
  }

  reportPathsForProduct(product: Product): ReportPath[] {
    return this.allReportPathsForProduct(product).filter((reportPath) => this.isReportEnabled(reportPath))
  }

  private allReportPathsForProduct(product: Product): ReportPath[] {
    switch (product) {
      case Product.RE:
        return Object.values(ReadingEggsReportPath)
      case Product.REX:
        return Object.values(ReadingEggspressReportPath)
      case Product.MS:
        return Object.values(MathseedsReportPath)
      case Product.FP:
        return Object.values(FastPhonicsReportPath)
      default:
        return []
    }
  }

  reportName(reportPath: ReportPath) {
    return this.intl.t(reportsRegistry[reportPath].labelKey)
  }

  newReportsForSubscriptionType(subscriptionType: SubscriptionType) {
    return productsForSubscriptionType(subscriptionType)
      .flatMap((product) => this.reportPathsForProduct(product))
      .filter((reportPath) => hasNewReportForPath(reportPath))
  }

  newReportsForProduct(product: Product) {
    return this.reportPathsForProduct(product).filter((reportPath) => hasNewReportForPath(reportPath))
  }
}

export default ReportRegistryService

declare module '@ember/service' {
  interface Registry {
    'report-registry': ReportRegistryService
  }
}

export const isReportPath = (value?: Nullable<string>): value is ReportPath => {
  return typeof value === 'string' && value in reportsRegistry
}

export function hasNewReportForPath(reportPath: ReportPath) {
  return Boolean(reportsRegistry[reportPath].newReport)
}

/**
 * True if provided report uses the new standards system, requiring standards to be set
 */
export function isStandardsBasedReport(reportPath: ReportPath) {
  return Boolean(reportsRegistry[reportPath].requiresStandards)
}

export function isMultiSchoolReport(reportPath: ReportPath) {
  return Boolean(reportsRegistry[reportPath].multiSchoolSelection)
}

export enum ReadingEggsReportPath {
  StandardsPerformance = 'reporting.ui-scope.re.standards-performance',
  LessonPerformance = 'reporting.ui-scope.re.lesson-performance',
  Dashboard = 'reporting.ui-scope.re.dashboard.index',
  DashboardTable = 'reporting.ui-scope.re.dashboard.table',
  SummaryTable = 'reporting.ui-scope.re.summary.table',
  TimeOnTask = 'reporting.ui-scope.re.time-on-task',
  CourseProgressTotals = 'reporting.ui-scope.re.course-progress.totals',
  CourseProgressAverages = 'reporting.ui-scope.re.course-progress.averages',
  QuizzesCompleted = 'reporting.ui-scope.re.quizzes-completed',
  DrivingTests = 'reporting.ui-scope.re.driving-tests',
  LexileDetails = 'reporting.ui-scope.re.lexile-details',
  WorkingAtGradeLevel = 'reporting.ui-scope.re.working-at-grade-level',
  SpellingProgressTotals = 'reporting.ui-scope.re.spelling-progress.totals',
  SpellingProgressAverages = 'reporting.ui-scope.re.spelling-progress.averages',
  StoriesWritten = 'reporting.ui-scope.re.stories-written',
  Storylands = 'reporting.ui-scope.re.storylands',
  BooksRead = 'reporting.ui-scope.re.books-read',
  AwardsEarned = 'reporting.ui-scope.re.awards-earned',
}

export enum ReadingEggspressReportPath {
  Dashboard = 'reporting.ui-scope.rex.dashboard.index',
  DashboardTable = 'reporting.ui-scope.rex.dashboard.table',
  SummaryTable = 'reporting.ui-scope.rex.summary.table',
  TimeOnTask = 'reporting.ui-scope.rex.time-on-task',
  CourseProgressTotals = 'reporting.ui-scope.rex.course-progress.totals',
  CourseProgressAverages = 'reporting.ui-scope.rex.course-progress.averages',
  AssessmentScores = 'reporting.ui-scope.rex.assessment-scores',
  QuizScores = 'reporting.ui-scope.rex.quiz-scores',
  LexileDetails = 'reporting.ui-scope.rex.lexile-details',
  WorkingAtGradeLevel = 'reporting.ui-scope.rex.working-at-grade-level',
  SpellingProgressTotals = 'reporting.ui-scope.rex.spelling-progress.totals',
  SpellingProgressAverages = 'reporting.ui-scope.rex.spelling-progress.averages',
  BooksRead = 'reporting.ui-scope.rex.books-read.index',
  BooksReadByLevel = 'reporting.ui-scope.rex.books-read.by-level',
  AwardsEarned = 'reporting.ui-scope.rex.awards-earned',
}

export enum MathseedsReportPath {
  StandardsPerformance = 'reporting.ui-scope.maths.standards-performance',
  LessonPerformance = 'reporting.ui-scope.maths.lesson-performance',
  Dashboard = 'reporting.ui-scope.maths.dashboard.index',
  DashboardTable = 'reporting.ui-scope.maths.dashboard.table',
  SummaryTable = 'reporting.ui-scope.maths.summary.table',
  TimeOnTask = 'reporting.ui-scope.maths.time-on-task',
  CourseProgressTotals = 'reporting.ui-scope.maths.course-progress.totals',
  CourseProgressAverages = 'reporting.ui-scope.maths.course-progress.averages',
  DrivingTests = 'reporting.ui-scope.maths.driving-tests',
  QuizzesCompleted = 'reporting.ui-scope.maths.quizzes-completed',
  WorkingAtGradeLevel = 'reporting.ui-scope.maths.working-at-grade-level',
  AwardsEarned = 'reporting.ui-scope.maths.awards-earned',
  FluencySprints = 'reporting.ui-scope.maths.fluency-sprints.table',
}

export enum FastPhonicsReportPath {
  StandardsPerformance = 'reporting.ui-scope.fast-phonics.standards-performance',
  LessonPerformance = 'reporting.ui-scope.fast-phonics.lesson-performance',
}

export type ReportPath =
  | ReadingEggsReportPath
  | ReadingEggspressReportPath
  | MathseedsReportPath
  | FastPhonicsReportPath

type ReportRegistry = {
  [key in ReportPath]: {
    labelKey: string
    newReport?: boolean // reports that will show "new" notifications
    requiresStandards?: boolean // reports that use standards-based reporting system
    multiSchoolSelection?: boolean // reports that use new filters (eg multi-school selection, grades, content levels, larger default date range)
    featureFlag?: string // feature flag to enable/disabled the report
  }
}

// a mapping of report paths to their corresponding label keys in the side nav
const reportsRegistry: ReportRegistry = {
  // Product.RE
  [ReadingEggsReportPath.StandardsPerformance]: {
    labelKey: 'sideNav.reporting.re.standardsPerformance',
    newReport: true,
    requiresStandards: true,
    multiSchoolSelection: true,
  },
  [ReadingEggsReportPath.LessonPerformance]: {
    labelKey: 'sideNav.reporting.lessonPerformanceIndividualStudents',
    newReport: true,
    requiresStandards: true,
    multiSchoolSelection: true,
  },
  [ReadingEggsReportPath.Dashboard]: { labelKey: 'sideNav.reporting.re.dashboard' },
  [ReadingEggsReportPath.DashboardTable]: { labelKey: 'sideNav.reporting.re.dashboardTable' },
  [ReadingEggsReportPath.SummaryTable]: { labelKey: 'sideNav.reporting.re.summary' },
  [ReadingEggsReportPath.TimeOnTask]: { labelKey: 'sideNav.reporting.re.timeOnTask' },
  [ReadingEggsReportPath.CourseProgressTotals]: { labelKey: 'sideNav.reporting.re.courseProgressTotals' },
  [ReadingEggsReportPath.CourseProgressAverages]: { labelKey: 'sideNav.reporting.re.courseProgressAverages' },
  [ReadingEggsReportPath.QuizzesCompleted]: { labelKey: 'sideNav.reporting.re.quizzesCompleted' },
  [ReadingEggsReportPath.DrivingTests]: { labelKey: 'sideNav.reporting.re.drivingTests' },
  [ReadingEggsReportPath.LexileDetails]: { labelKey: 'sideNav.reporting.re.lexileDetails' },
  [ReadingEggsReportPath.WorkingAtGradeLevel]: { labelKey: 'sideNav.reporting.re.workingAtGradeLevel' },
  [ReadingEggsReportPath.SpellingProgressTotals]: { labelKey: 'sideNav.reporting.re.spellingProgressTotals' },
  [ReadingEggsReportPath.SpellingProgressAverages]: { labelKey: 'sideNav.reporting.re.spellingProgressAverages' },
  [ReadingEggsReportPath.StoriesWritten]: { labelKey: 'sideNav.reporting.re.storiesWritten' },
  [ReadingEggsReportPath.Storylands]: { labelKey: 'sideNav.reporting.re.storylands' },
  [ReadingEggsReportPath.BooksRead]: { labelKey: 'sideNav.reporting.re.booksRead' },
  [ReadingEggsReportPath.AwardsEarned]: { labelKey: 'sideNav.reporting.re.awardsEarned' },
  // Product.REX
  [ReadingEggspressReportPath.Dashboard]: { labelKey: 'sideNav.reporting.rex.dashboard' },
  [ReadingEggspressReportPath.DashboardTable]: { labelKey: 'sideNav.reporting.rex.dashboardTable' },
  [ReadingEggspressReportPath.SummaryTable]: { labelKey: 'sideNav.reporting.rex.summary' },
  [ReadingEggspressReportPath.TimeOnTask]: { labelKey: 'sideNav.reporting.rex.timeOnTask' },
  [ReadingEggspressReportPath.CourseProgressTotals]: { labelKey: 'sideNav.reporting.rex.courseProgressTotals' },
  [ReadingEggspressReportPath.CourseProgressAverages]: { labelKey: 'sideNav.reporting.rex.courseProgressAverages' },
  [ReadingEggspressReportPath.AssessmentScores]: { labelKey: 'sideNav.reporting.rex.assessmentScores' },
  [ReadingEggspressReportPath.QuizScores]: { labelKey: 'sideNav.reporting.rex.quizScores' },
  [ReadingEggspressReportPath.LexileDetails]: { labelKey: 'sideNav.reporting.rex.lexileDetails' },
  [ReadingEggspressReportPath.WorkingAtGradeLevel]: { labelKey: 'sideNav.reporting.rex.workingAtGradeLevel' },
  [ReadingEggspressReportPath.SpellingProgressTotals]: { labelKey: 'sideNav.reporting.rex.spellingProgressTotals' },
  [ReadingEggspressReportPath.SpellingProgressAverages]: { labelKey: 'sideNav.reporting.rex.spellingProgressAverages' },
  [ReadingEggspressReportPath.BooksRead]: { labelKey: 'sideNav.reporting.rex.booksRead' },
  [ReadingEggspressReportPath.BooksReadByLevel]: { labelKey: 'sideNav.reporting.rex.booksReadByLevel' },
  [ReadingEggspressReportPath.AwardsEarned]: { labelKey: 'sideNav.reporting.rex.awardsEarned' },
  // Product.FP
  [FastPhonicsReportPath.StandardsPerformance]: {
    labelKey: 'sideNav.reporting.fast-phonics.standardsPerformance',
    newReport: true,
    requiresStandards: true,
    multiSchoolSelection: true,
  },
  [FastPhonicsReportPath.LessonPerformance]: {
    labelKey: 'sideNav.reporting.lessonPerformanceIndividualStudents',
    newReport: true,
    requiresStandards: true,
    multiSchoolSelection: true,
  },
  // Product.MS
  [MathseedsReportPath.StandardsPerformance]: {
    labelKey: 'sideNav.reporting.maths.standardsPerformance',
    newReport: true,
    requiresStandards: true,
    multiSchoolSelection: true,
  },
  [MathseedsReportPath.LessonPerformance]: {
    labelKey: 'sideNav.reporting.lessonPerformanceIndividualStudents',
    newReport: true,
    requiresStandards: true,
    multiSchoolSelection: true,
  },
  [MathseedsReportPath.Dashboard]: { labelKey: 'sideNav.reporting.maths.dashboard' },
  [MathseedsReportPath.DashboardTable]: { labelKey: 'sideNav.reporting.maths.dashboardTable' },
  [MathseedsReportPath.SummaryTable]: { labelKey: 'sideNav.reporting.maths.summary' },
  [MathseedsReportPath.TimeOnTask]: { labelKey: 'sideNav.reporting.maths.timeOnTask' },
  [MathseedsReportPath.CourseProgressTotals]: { labelKey: 'sideNav.reporting.maths.courseProgressTotals' },
  [MathseedsReportPath.CourseProgressAverages]: { labelKey: 'sideNav.reporting.maths.courseProgressAverages' },
  [MathseedsReportPath.DrivingTests]: { labelKey: 'sideNav.reporting.maths.drivingTests' },
  [MathseedsReportPath.QuizzesCompleted]: { labelKey: 'sideNav.reporting.maths.quizzesCompleted' },
  [MathseedsReportPath.WorkingAtGradeLevel]: { labelKey: 'sideNav.reporting.maths.workingAtGradeLevel' },
  [MathseedsReportPath.AwardsEarned]: { labelKey: 'sideNav.reporting.maths.awardsEarned' },
  [MathseedsReportPath.FluencySprints]: { labelKey: 'sideNav.reporting.maths.fluencySprints' },
  // Product.MSP
  // Product.WL
}
