import Component from '@glimmer/component'
import { service } from '@ember/service'
/**
 * @class ExistsAsParentContact
 * Given Clever ID or SIS ID already in use, user can re-assign the in-use
 * Clever ID or SIS ID and delete the teacher account for other teacher.
 * @example```hbs
 * <CleverUi::Modals::TeacherMatcher::ExistsAsParentContact
 *   @cleverTeacher={{cleverTeacherRecord}}
 * >/
 * ```
 */
export default class ExistsAsParentContact extends Component {
  @service
  intl

  @service
  store

  translated(key, options = {}) {
    return this.intl.t(`clever.teacherMatcher.errors.existsAsParentContact.${key}`, options)
  }

  get cleverTeacher() {
    return this.args.cleverTeacher
  }

  get email() {
    return this.cleverTeacher.email
  }

  get title() {
    return this.translated('summary')
  }

  get explanationText() {
    const { email } = this
    return this.translated('explanation', {
      htmlSafe: true,
      email,
    })
  }

  get solutionText() {
    return this.translated('solution', {
      htmlSafe: true,
    })
  }

  get confirmLabel() {
    return this.translated('confirmLabel')
  }

  get positiveButtonText() {
    return this.translated('positiveButtonText')
  }
}
