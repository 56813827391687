import { template as template_d7adb7495dc449b2988312026ce054aa } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_d7adb7495dc449b2988312026ce054aa(`
    <div class="flex-shrink flex-grow overflow-y-auto px-8 py-10">
      {{outlet}}
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
