import Component from '@glimmer/component'

/**
 * Component that will display a fixed-position element that will fill the
 * current containing block.  Accepts a `@visible={Boolean}` argument.  When
 * set to `true`, the element will be given a very large `z-index`; when set to
 * `false`, the element will gets an `invisible` class, which by default gives
 * it negative `z-index`, and `hidden` `visibility`.  You can add
 * transitions/animations to the `invisible` class if needed.  There are some
 * examples in the dummy app.
 *
 * @example
 *   <Fidget::Overlay @visible={{currentLoading}}>
 *     Loading!
 *   </Fidget::Overlay>
 */
interface Args {
  visible?: boolean
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
  Blocks: {
    default: []
  }
}
export class FidgetOverlayComponent extends Component<Signature> {
  /**
   * Setting visible to false will mark the overlay as invisible and ignore
   * pointer events.  Can be used with opacity transitions for smooth fade
   * in/out - see examples in dummy app.
   *
   * @type {Boolean}
   * @default true
   */
  get visible() {
    return this.args.visible ?? true
  }

  get visibleClass() {
    return this.visible ? '' : 'invisible'
  }
}

export default FidgetOverlayComponent
