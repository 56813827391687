import Route from '@ember/routing/route'
import { service } from '@ember/service'
import { hash } from 'rsvp'

export default class StudentsRoute extends Route {
  @service clever

  @service store

  queryParams = {
    tab: {
      replace: true,
    },
    currentPage: {
      replace: true,
    },
    filter: {
      replace: true,
    },
  }

  model() {
    const { store } = this
    const { cleverDistrictId, cleverSchoolId, cleverSchool, subscriptionType } = this.modelFor('clever.match.schools')
    const blakeSchoolId = cleverSchool.get('blakeSchool.id')

    const cleverStudents = store.query('clever/clever-student', { scope: `clever-schools/${cleverSchoolId}` })
    // Ensure the latest blake students & matches. The controller constructs blakeStudents from a filtered live array
    const blakeStudents = store.query('clever/student', {
      scope: `schools/${blakeSchoolId}`,
      include: 'clever-student-match,school',
    })

    return hash({
      cleverDistrictId,
      cleverSchoolId,
      blakeSchoolId,
      cleverStudents,
      blakeStudents,
      cleverSchool,
      subscriptionType,
    })
  }
}
