import { modifier } from 'ember-modifier'

interface Signature {
  Args: {
    Named: Record<string, unknown>
    Positional: unknown[]
  }
  Element: HTMLElement
}

/**
 * This modifier is essentially a combination of did-insert & did-update render modifiers, it will call the function on
 * render or whenever the given args change.
 *
 * This is useful for table data, it allows us to watch data items, as well as pipeline descriptors, and call a
 * transform action when either change.
 *
 * // template.hbs
 * <div {{apply-transform this.transformAction this.items this.descriptors}} />
 *
 * // component.js
 * @action
 * transformAction(_element, [items, descriptors]) {
 *   this.transformItems.perform(items, descriptors)
 * }
 */

export default modifier<Signature>(function applyTransform(
  element,
  positional,
  named
) {
  const [fn, ...positionalWithoutFn] = positional
  if (typeof fn === 'function') {
    fn(element, positionalWithoutFn, named)
  }
})
