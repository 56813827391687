import MatchRowComponent from 'district-ui-client/components/clever-ui/match-table-row/component'
import { service } from '@ember/service'

export default class MatchStudentsTableRowComponent extends MatchRowComponent {
  @service clever

  @service intl

  userType = 'student'

  get searchFields() {
    return ['text', 'grade']
  }

  get allOptions() {
    return [...this.actionOptions, ...this.args.blakeUserOptions]
  }

  get actionOptions() {
    const createNewStudentText = this.intl.t('clever.createNewStudentText')
    const createStudentOption = { text: createNewStudentText, value: 'create' }
    return [createStudentOption]
  }
}
