import Route from '@ember/routing/route'
import { service } from '@ember/service'

export default class CleverMissingFieldsRoute extends Route {
  @service clever

  queryParams = {
    sortTeacher: {
      replace: true,
    },
    sortStudent: {
      replace: true,
    },
  }

  async model(_params) {
    const { subscriptionType } = this.modelFor('subscription-type')
    const { teacherMatches = [], studentMatches = [] } = await this.clever.getUserMatchesWithMissingFields(false)
    return {
      teacherMatches,
      studentMatches,
      subscriptionType,
    }
  }
}
