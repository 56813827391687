import type Store from '@ember-data/store'
import type Controller from '@ember/controller'
import Route from '@ember/routing/route'
import type Transition from '@ember/routing/transition'
import { service } from '@ember/service'

export default class ManageTeacherEditRoute extends Route {
  @service store!: Store

  async model({ id }: { id: string }) {
    const teacher = await this.store.findRecord('teacher', id)
    return { teacher }
  }

  resetController(
    controller: Controller<ModelFor<ManageTeacherEditRoute>>,
    isExiting: boolean,
    transition: Transition,
  ) {
    super.resetController(controller, isExiting, transition)
    if (isExiting && controller.model?.teacher.hasDirtyAttributes) controller.model?.teacher.rollbackAttributes()
  }
}
