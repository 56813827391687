import { isPresent } from '@ember/utils'
import { set } from '@ember/object'
import { later } from '@ember/runloop'

/**
 * Class create a unified way of execution clever service promises which either run a success or
 * fail function
 */
export default class CleverServiceRunner {
  /**
   * We need the scope object of the calling code which we need to set an
   * Ember var on it.
   *
   * @param {Object} emberScope
   * @param {String} submitState
   */
  constructor(emberScope, submitState, options) {
    this.submitState = submitState
    this.emberScope = emberScope
    this.timeoutForSuccess = 2000
    this.mapErrorToString = options?.mapErrorToString ?? true
  }

  /**
   * Set before success callback which gets called before the delayed success callback
   * @param cb
   * @returns {CleverServiceRunner}
   */
  beforeSuccess(cb) {
    this.beforeSuccessCallback = cb
    return this
  }

  /**
   * Set success callback
   * @param cb
   * @returns {CleverServiceRunner}
   */
  success(cb) {
    this.successCallback = cb
    return this
  }

  /**
   * Sets the fail callack
   * @param cb
   * @returns {CleverServiceRunner}
   */
  fail(cb) {
    this.errorCallback = cb
    return this
  }

  /**
   * This method runs a given function and deals with its response
   * It modifies the submitstate variable as well as calls several
   * callback in a specific order to work with the submit button.
   *
   * @param method
   * @param args
   */
  async run(method, ...args) {
    set(this.emberScope, this.submitState, 'load')

    try {
      await method(...args)
      if (!this.emberScope.isDestroyed) set(this.emberScope, this.submitState, 'done')
      this.beforeSuccessCallback?.()
      later(() => {
        this.successCallback?.()
      }, this.timeoutForSuccess)
    } catch (error) {
      if (!this.emberScope.isDestroyed) set(this.emberScope, this.submitState, 'error')
      if (this.mapErrorToString && isPresent(error.errors)) {
        const e = error.errors[0]
        this.errorCallback?.(`${e.title}: ${e.detail}`)
      } else {
        this.errorCallback?.(error)
      }
      console.log(error) // log the error to the console
    }
  }
}
