import Service from '@ember/service'
import { assert } from '@ember/debug'
import { getOwner } from '@ember/application'
import type {
  LogAdapter,
  LogArgument,
  LogResult,
  Person,
} from '../log-adapters/base.ts'

/**
 * Service for using the application log-adapter
 */
export default class Log extends Service implements LogAdapter {
  _adapter: LogAdapter

  constructor(properties: Record<string, unknown>) {
    super(properties)
    const owner = getOwner(this)

    const adapter = owner.lookup('log-adapter:application')

    assert(
      'Could not find a log adapter, have you created an application log-adapter?',
      typeof adapter !== 'undefined',
    )
    this._adapter = adapter
  }

  setPerson(person: Person): void {
    this._adapter.setPerson(person)
  }

  critical(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this._adapter.critical(arg, ...args)
  }

  error(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this._adapter.error(arg, ...args)
  }

  warning(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this._adapter.warning(arg, ...args)
  }

  warn(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this._adapter.warn(arg, ...args)
  }

  info(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this._adapter.info(arg, ...args)
  }

  debug(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this._adapter.debug(arg, ...args)
  }

  log(arg: Error | string, ...args: LogArgument[]): LogResult {
    return this._adapter.log(arg, ...args)
  }

  override willDestroy(): void {
    this._adapter.destroy()
  }
}

declare module '@ember/service' {
  interface Registry {
    log: Log
  }
}
