import Service, { service } from '@ember/service'
import type Store from '@ember-data/store'
import type Teacher from 'district-ui-client/models/teacher'
import type { Log } from '@blakeelearning/log'
import type FlashQueueService from 'district-ui-client/services/flash-queue'
import type IntlService from 'ember-intl/services/intl'

function isTrialExistsError(error: unknown) {
  const isObject = typeof error === 'object' && error !== null
  return (
    isObject &&
    'errors' in error &&
    Array.isArray(error.errors) &&
    error.errors
      .map((e) => e?.detail)
      .join(' ')
      .includes('has trial already')
  )
}

export default class AddExistingTeachersService extends Service {
  @service store!: Store

  @service log!: Log

  @service flashQueue!: FlashQueueService

  @service intl!: IntlService

  async execute(teachers: Teacher[], subscriptionType: string, isTrial: boolean): Promise<boolean> {
    let errors: unknown[] = []

    await Promise.all(
      teachers.map((t) =>
        this.createSchoolClassAndSub(t, subscriptionType, isTrial).catch((error) => {
          // continue past failed, but hold onto important errors
          if (!isTrialExistsError(error)) errors = [...errors, error]
        }),
      ),
    )

    if (!errors.length) {
      const successMessage = isTrial
        ? this.intl.t(`subscriptionType.manageTeachers.addExistingTeachers.flashMessage.success.addedWithTeacherSub`)
        : this.intl.t(`subscriptionType.manageTeachers.addExistingTeachers.flashMessage.success.addedWithSchoolSub`)
      this.flashQueue.addSuccess({ subtitle: successMessage })
      return true
    }

    const count = errors.length
    const failureMessage = isTrial
      ? this.intl.t('subscriptionType.manageTeachers.addExistingTeachers.flashMessage.failure.teacherSub', { count })
      : this.intl.t('subscriptionType.manageTeachers.addExistingTeachers.flashMessage.failure.schoolSub', { count })
    this.flashQueue.addFail({ subtitle: failureMessage })

    if (isTrial) {
      this.log.error('adding existing teacher with a trial subscription failed', errors)
    } else {
      this.log.error('adding existing teacher with a school subscription failed', errors)
    }

    return false
  }

  private async createSchoolClassAndSub(teacher: Teacher, subscriptionType: string, isTrial: boolean) {
    const className = this.intl.t('subscriptionType.manageTeachers.addExistingTeachers.defaultClassName', {
      possessiveName: teacher.possessiveFullName,
      subscriptionType: this.intl.t(subscriptionType),
    })

    await this.store
      .createRecord('school-class', {
        name: className,
        subscriptionType,
        teachers: [teacher],
        school: teacher.school,
        gamesAccess: true,
        playroomAccess: true,
        backgroundMusicDisabled: false,
        mathseedsPrimeAccess: subscriptionType === 'maths',
      })
      .save()

    if (!isTrial) return
    // if trial, create teacher sub
    return this.store
      .createRecord('teacher-subscription', {
        subscriptionType,
        teacher,
      })
      .save()
  }
}

declare module '@ember/service' {
  interface Registry {
    'add-existing-teachers': AddExistingTeachersService
  }
}
