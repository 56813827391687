import { setProperties } from '@ember/object'

/**
 * This helper toggles the sort icon on a sortable table
 *
 * Example usage in a template
 * <th role="button"
 *     {{action "statefulSort" tableAction "sortByValue || sortByNumber || sortByDate ||  sortByGrade || sort" "columnName"}}
 * >
 *   Column Name
 *   <FaIcon @icon={{if (eq currentSortColumn "columnName") currentSortIcon "sort"}} />
 * </th>
 */

export function statefulSort(tableAction, tableActionOperation, tableActionKey) {
  tableAction(tableActionOperation, tableActionKey)

  let newSortIcon = 'sort-up' // default sort direction 'asc'

  // If the column has already been sorted, toggle the direction
  if (this.currentSortColumn === tableActionKey) {
    if (this.currentSortIcon === 'sort-up') {
      newSortIcon = 'sort-down' // sort direction 'desc'
    } else {
      newSortIcon = 'sort-up' // sort direction 'asc'
    }
  }

  setProperties(this, {
    currentSortColumn: tableActionKey,
    currentSortIcon: newSortIcon,
  })
}
