import Service, { inject as service } from '@ember/service'
import { getConfig } from '@blakeelearning/get-config'
import type RefresherService from '../refresher/service'

export const SESSION_ID_KEY = 'sessionTracker.sessionId'

/**
 * Prevents multiple student sessions running in the same browser.
 *
 * This is to stop multiple students from being logged in at the same time,
 * removing a whole class of bugs that can occur.
 *
 * It achieves this by storing a session id in-memory and in local storage.
 *
 * The memory session id is local to this tab. The local storage id is shared
 * by the whole browser. If they diverge, then it means another student has
 * logged in without closing the original tab. Doing a hard refresh of the
 * current tab at this point will load as the new student.
 */
export default class SessionTrackerService extends Service {
  @service
  declare refresher: RefresherService

  _sessionId?: string

  get _enabled(): boolean {
    return getConfig(this, 'appRefresher.sessionTracker.enabled', false)
  }

  removeStorageListener = onStorage((event) => {
    const enabled = this._enabled
    const started = typeof this._sessionId !== 'undefined'

    if (enabled && started) {
      this._checkCurrentSession(event)
    }
  })

  /**
   * Starts periodically checking for other sessions than the
   * session id passed in.
   */
  start(sessionId: string | number): void {
    const enabled = this._enabled
    const started = typeof this._sessionId !== 'undefined'

    if (enabled && !started) {
      this._setupCurrentSession(sessionId)
    }
  }

  override willDestroy(): void {
    this.removeStorageListener()
  }

  /**
   * Checks the in-memory session id against the storage event session ids.
   *
   * If they don't match, trigger a hard refresh.
   */
  _checkCurrentSession(event: StorageEvent): void {
    if (event.key === SESSION_ID_KEY) {
      const inMemoryId = this._sessionId
      const newSessionId = event.newValue

      if (inMemoryId !== newSessionId) {
        this.refresher.hardRefresh()
      }
    }
  }

  /**
   * Sets the in-memory and local storage session ids
   */
  _setupCurrentSession(sessionId: string | number): void {
    this._sessionId = sessionId.toString()

    try {
      localStorage.setItem(SESSION_ID_KEY, this._sessionId)
    } catch (error) {
      console.log(error)
    }
  }
}

export function onStorage(callback: (event: StorageEvent) => void) {
  const handleStorage = (event: StorageEvent) => {
    callback(event)
  }

  window.addEventListener('storage', handleStorage)

  return () => {
    window.removeEventListener('storage', handleStorage)
  }
}

declare module '@ember/service' {
  interface Registry {
    'session-tracker': SessionTrackerService
  }
}
