import { template as template_8db1fb38f5464b66a06a4030fa6272ca } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import t from 'ember-intl/helpers/t';
import type RouterService from '@ember/routing/router-service';
import type Owner from '@ember/owner';
import { FloatingDrawer } from 'district-ui-client/components/floating-drawer';
import { ManagePane } from 'district-ui-client/components/pane/manage';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { array } from '@ember/helper';
import type SessionService from 'district-ui-client/services/session';
import { productsForSubscriptionType } from 'district-ui-client/domain/product';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { NotificationDot } from 'district-ui-client/components/notification-dot';
import { FeatureNotificationId } from 'district-ui-client/services/feature-notification';
import { FeatureNotification } from 'district-ui-client/helpers/feature-notification';
import type ReportRegistryService from 'district-ui-client/services/report-registry';
import { isReportPath } from 'district-ui-client/services/report-registry';
import type ActiveRouteService from 'district-ui-client/services/active-route';
import { type ReportingLink } from 'district-ui-client/services/active-route';
import { hash } from '@ember/helper';
import { featureFlag } from 'district-ui-client/helpers/feature-flag';
import config from 'district-ui-client/config/environment';
interface Signature {
    Element: HTMLElement;
    Args: {
        subscriptionType: SubscriptionType;
    };
    Blocks: {
        default: [];
    };
}
export class SideNav extends Component<Signature> {
    @service
    router: RouterService;
    @service
    session: SessionService;
    @service
    activeRoute: ActiveRouteService;
    @tracked
    isManageDrawerOpen = false;
    @service
    reportRegistry: ReportRegistryService;
    constructor(owner: Owner, args: Signature['Args']){
        super(owner, args);
        this.router.on('routeDidChange', this.onRouteDidChange);
    }
    willDestroy() {
        this.router.off('routeDidChange', this.onRouteDidChange);
        super.willDestroy();
    }
    onRouteDidChange = ()=>{
        this.closeManageDrawer();
    };
    get isCleverLinkVisible() {
        return this.session.currentDistrict.cleverDistrictMatch?.getCleverSharedByType(this.args.subscriptionType);
    }
    closeManageDrawer = ()=>{
        this.isManageDrawerOpen = false;
    };
    manageLinkClicked = (e: Event)=>{
        // avoid going to link
        e.stopImmediatePropagation();
        e.preventDefault();
        this.isManageDrawerOpen = !this.isManageDrawerOpen;
    };
    /**
   * Go to the last visited reporting route, otherwise default report for the subscription type
   */ get reportingLink(): ReportingLink {
        const reportingLastLink = this.activeRoute.reportingLastLink;
        const reportsForSub = productsForSubscriptionType(this.args.subscriptionType).flatMap((product)=>this.reportRegistry.reportPathsForProduct(product));
        if (reportingLastLink && isReportPath(reportingLastLink.route) && reportsForSub.includes(reportingLastLink.route)) {
            return reportingLastLink;
        }
        return {
            route: reportsForSub[0],
            models: [
                'district',
                this.session.currentDistrict.id
            ],
            query: {
                dateRange: undefined,
                studentGrade: undefined,
                contentLevel: undefined,
                schoolIds: undefined
            }
        };
    }
    static{
        template_8db1fb38f5464b66a06a4030fa6272ca(`
    <div class="flex items-stretch" ...attributes>
      {{! z-index 2 to render on top of the inline-drawer }}
      <nav
        class="border-neutral-75 relative z-[2] inline-flex flex-shrink-0 flex-col items-center gap-6 self-stretch overflow-y-auto overflow-x-clip border-r-2 border-solid bg-white pt-8 print:hidden"
      >
        <SideNavLink data-test-sidenav-home @route="subscription-type.index" @label={{t "sideNav.home"}}>
          <:icon>
            <FaIcon class="h-5 w-5" @icon="home" />
          </:icon>
        </SideNavLink>
        <SideNavLink
          data-test-sidenav-reports
          @route={{this.reportingLink.route}}
          @models={{this.reportingLink.models}}
          @query={{this.reportingLink.query}}
          @label={{t "sideNav.reports"}}
        >
          <:icon>
            <FaIcon class="h-5 w-5" @icon="chart-simple" />
            {{! show notification dot if there's a new report for the current subscription type }}
            {{#if
              (FeatureNotification
                FeatureNotificationId.NewReportForSubscriptionType reportSubscriptionType=@subscriptionType
              )
            }}
              <NotificationDot class="absolute right-0 top-0" />
            {{/if}}
          </:icon>
        </SideNavLink>
        {{#if this.isCleverLinkVisible}}
          {{#if (featureFlag "duiCleverLiveViewFF")}}
            <SideNavLink @href={{config.cleverAppUrl}} @label={{t "sideNav.clever"}}>
              <:icon>
                <FaIcon class="h-5 w-5" @icon="link" />
              </:icon>
            </SideNavLink>
          {{else}}
            <SideNavLink
              @route="clever.dashboard"
              @models={{array @subscriptionType}}
              @current-when="clever clever-id"
              @label={{t "sideNav.clever"}}
            >
              <:icon>
                <FaIcon class="h-5 w-5" @icon="link" />
              </:icon>
            </SideNavLink>
          {{/if}}
        {{/if}}
        <SideNavLink
          @route="subscription-type.manage"
          @label={{t "sideNav.manage"}}
          {{on "click" this.manageLinkClicked}}
        >
          <:icon>
            <FaIcon class="h-5 w-5" @icon="list-check" />
          </:icon>
        </SideNavLink>
        <SideNavLink @route="subscription-type.support" @label={{t "sideNav.support"}}>
          <:icon>
            <FaIcon class="h-5 w-5" @icon="headset" />
          </:icon>
        </SideNavLink>
      </nav>
      <div class="relative min-w-0 flex-grow">
        <div class="flex h-full items-stretch">
          {{! z-index 1 to render on top of the content - important for the expand/collapse button that sticks out }}
          {{! w-fit is for safari. Otherwise, on re-opening the drawer it ends up going on top of the report content,
          because it still thinks it is zero width. This is because the content of this element goes from absolute to
          relative, this element doesn't know to recalculate its width. }}
          <div id="inline-drawer" class="relative z-[1] w-fit" />
          <main class="relative h-full flex-grow overflow-y-auto overflow-x-clip print:overflow-visible">
            {{yield}}
          </main>
        </div>
        {{! z-index 2 so that it appears on top of the inline drawer when opened }}
        <FloatingDrawer
          class="absolute inset-0 z-[2]"
          @isOpen={{this.isManageDrawerOpen}}
          @onClose={{this.closeManageDrawer}}
        >
          <ManagePane />
        </FloatingDrawer>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface SideNavLinkSignature {
    Element: HTMLAnchorElement;
    Args: {
        href?: string;
        route?: string;
        label: string;
        'current-when'?: boolean | string;
        models?: unknown[];
        query?: Record<string, unknown>;
    };
    Blocks: {
        icon: [];
    };
}
/**
 * These side-nav links contain some classes using the linkto transition classnames. This is to ensure they are "eager"
 * when transitioning to a page with an async model hook, keeping the page feeling snappy.
 */ const SideNavLink: TOC<SideNavLinkSignature> = template_8db1fb38f5464b66a06a4030fa6272ca(`
  {{#if @href}}
    {{! Same classes as LinkTo but without current/active/transitioning etc. }}
    <a
      href={{@href}}
      class="text-neutral-250 hover:text-blue-325 group relative w-[6.5rem] space-y-1 p-2"
      ...attributes
    >
      <div class="flex items-center justify-center">
        <div
          class="group-hover:bg-blue-325 absolute left-0 h-4 w-2 rounded-r-lg bg-transparent transition-all duration-200 ease-in-out"
        />
        <div class="relative flex h-10 w-10 items-center justify-center rounded-lg bg-transparent">
          {{yield to="icon"}}
        </div>
      </div>
      <div class="whitespace-nowrap text-center text-sm font-medium">
        {{@label}}
      </div>
    </a>
  {{else if @route}}
    {{! prettier-ignore }}
    <LinkTo
      @route={{@route}}
      @current-when={{@current-when}}
      @models={{if @models @models (array)}}
      @query={{if @query @query (hash)}}
      @activeClass="current"
      class="group relative w-[6.5rem] space-y-1 p-2
      text-neutral-250
      [&.current]:text-blue-325 [&.ember-transitioning-in]:text-blue-325 hover:text-blue-325
      [&.current.ember-transitioning-out]:text-neutral-250"
      ...attributes
    >
      <div class="flex items-center justify-center">
        <div
          class="absolute left-0 w-2 rounded-r-lg transition-all duration-200 ease-in-out
          bg-transparent
          group-[&.current]:bg-blue-325 group-[&.ember-transitioning-in]:bg-blue-325 group-hover:bg-blue-325
          group-[&.current.ember-transitioning-out]:bg-transparent
          h-4
          group-[&.current]:h-10 group-[&.ember-transitioning-in]:h-10
          group-[&.current.ember-transitioning-out]:h-4"
        />
        <div
          class="relative flex h-10 w-10 items-center justify-center rounded-lg
          bg-transparent
          group-[&.current]:bg-blue-100 group-[&.ember-transitioning-in]:bg-blue-100
          group-[&.current.ember-transitioning-out]:bg-transparent"
        >
          {{yield to="icon"}}
        </div>
      </div>
      <div class="whitespace-nowrap text-center text-sm font-medium">
        {{@label}}
      </div>
    </LinkTo>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SideNav;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        SideNav: typeof SideNav;
    }
}
