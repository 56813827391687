import Service, { inject as service } from '@ember/service'
import type AuthToken from './auth-token.ts'
import type { Options, ResponsePromise } from 'ky'
import ky from 'ky'

export * from 'ky'

/**
 * RequestOptions replaces the header option from ky with the default headers option from fetch
 */
export type RequestOptions = Omit<Options, 'headers'> &
  Pick<RequestInit, 'headers'> & {
    /**
     * Include authorization header in the request.
     * Uses the token from service:auth-token
     *
     * @default true
     */
    authorization?: boolean
  }

/**
 * Service for making ember fetch requests with the token attached
 * in the header. json() and text() functions have also been added to the
 * fetch response which will enable us to get the parsed data.
 *
 * @class Fetcher
 * @example await this.fetcher.request(url, options).json()
 * @example await this.fetcher.post(url, { json })
 */
export default class Fetcher extends Service {
  @service() declare authToken: AuthToken

  request(
    input: RequestInfo | URL,
    options: RequestOptions = {},
  ): ResponsePromise {
    const opts = { ...options }
    const headers = new Headers(opts.headers)
    const { tokenResult } = this.authToken
    const authorization = opts.authorization ?? true

    const includeAuthorization = !(
      headers.has('authorization') || !authorization
    )

    opts.headers = headers

    if (includeAuthorization && tokenResult.isOk()) {
      headers.set('authorization', tokenResult.value)
    }

    return ky(input, opts)
  }

  delete(
    input: RequestInfo | URL,
    options: RequestOptions = {},
  ): ResponsePromise {
    return this.request(input, {
      ...options,
      method: 'DELETE',
    })
  }

  head(
    input: RequestInfo | URL,
    options: RequestOptions = {},
  ): ResponsePromise {
    return this.request(input, {
      ...options,
      method: 'HEAD',
    })
  }

  patch(
    input: RequestInfo | URL,
    options: RequestOptions = {},
  ): ResponsePromise {
    return this.request(input, {
      ...options,
      method: 'PATCH',
    })
  }

  post(
    input: RequestInfo | URL,
    options: RequestOptions = {},
  ): ResponsePromise {
    return this.request(input, {
      ...options,
      method: 'POST',
    })
  }

  put(input: RequestInfo | URL, options: RequestOptions = {}): ResponsePromise {
    return this.request(input, {
      ...options,
      method: 'PUT',
    })
  }
}

declare module '@ember/service' {
  interface Registry {
    fetcher: Fetcher
  }
}
