import urlJoin from 'url-join'
import mapKeys from 'lodash/mapKeys'

const startsWithProtocol = /^[a-z]*:?\/\//
function isRelativeUrl(string: string): boolean {
  return !startsWithProtocol.test(string)
}

function isProtocolRelativeUrl(string: string): boolean {
  return string.slice(0, 2) === '//'
}

export function adapterHostAndNamespace(url: URL) {
  // ember-data adapter namespace does not want leading or trailing slash
  return { host: url.origin, namespace: url.pathname.replace(/^\//, '').replace(/\/$/, '') }
}

/**
 * Turn a string into a URL, using the window location or a given base string. Safe to use with URL if you like, to make
 * a copy.
 */
export function parse(stringOrURL: URL | string, base?: URL | string): URL {
  if (isRelativeUrl(stringOrURL.toString()) || isProtocolRelativeUrl(stringOrURL.toString())) {
    return new URL(stringOrURL, base ?? window.location.href)
  }
  return new URL(stringOrURL)
}

/**
 * A util to join strings or URLs safely together. Ensures no slashes are missed or doubled, and handles query params.
 */
export function join(stringOrURL: URL | string, ...parts: Array<string | number>) {
  const url = parse(stringOrURL)

  const parsedParts = parts.map((part) => part.toString())

  url.pathname = urlJoin(url.pathname, ...parsedParts)

  return url
}

interface JoinQueryParamsOptions {
  arrayFormat?: 'comma' | 'bracket'
}

export interface QueryParams {
  [key: string]: boolean | string | number | undefined | Array<string | number> | Partial<QueryParams>
}

/**
 * This util joins query params onto a given URL or string.
 *
 * Very straightforward for simple query params, but for arrays and objects it's less so.
 *
 * Arrays:
 * As of writing there's no real standard for this. Turns out there's a bunch of ways to do this that nobody agrees on.
 * In TUI, we only really have comma and bracket formats.
 * By default, native URL and URLSearchParams use the comma format.
 *
 * Objects:
 * Appends each subkey in square brackets, like[this]=123
 */
export function joinQueryParams(
  stringOrURL: URL | string,
  queryParams: QueryParams,
  { arrayFormat = 'comma' }: JoinQueryParamsOptions = {},
) {
  const url = parse(stringOrURL)
  const flatQuery = toFlatQuery(queryParams, arrayFormat)
  return appendToUrl(url, flatQuery)
}

/**
 * Turns a deeply nested object into a flat object with primitive values.
 *
 * before
 * { test: 123, filter: { period: { start: 'abc', end: 'def' } } }
 * after
 * { test: '123', filter[period][start]: 'abc', filter[period][end]: 'def' }
 *
 * Arrays are handled based on the given array format.
 * { test: [1, 2, 3] }
 * into
 * { test: '1,2,3' }      (comma format)
 * { test[]: [1, 2, 3] }  (bracket format, to be handled when appending to url)
 */
function toFlatQuery(
  queryParams: Partial<QueryParams>,
  arrayFormat: 'comma' | 'bracket',
): Record<string, string | string[]> {
  return Object.entries(queryParams).reduce((result, [qp, value]) => {
    if (!value) return result
    if (Array.isArray(value) && arrayFormat === 'bracket') {
      // ?baz[]=2&baz[]=3
      return {
        ...result,
        /* this array value will be handled in appendToUrl. url search params can handle the multiple keys of the same
         * name eg "baz[]: 2" "baz[]: 3" whereas a javascript object cannot. */
        [`${qp}[]`]: value.map((v) => v.toString()), // at least make sure its values are string (not number)
      }
    }
    if (!Array.isArray(value) && typeof value === 'object') {
      // ?foo[bar][baz]=1&foo[bar][biz]=2
      return {
        ...result,
        ...mapKeys(toFlatQuery(value, arrayFormat), (_value, key) => {
          // reconstruct the key to only wrap the outerkey, prepend the qp
          return [
            qp,
            ...key
              .replace(/\]/g, '')
              .split('[')
              .map((k) => `[${k}]`),
          ].join('')
        }),
      }
    }
    // this handles simple values and arrays to comma format
    // ?foo=1&baz=2,3
    return {
      ...result,
      [qp]: value.toString(),
    }
  }, {})
}

/**
 * Appends a flat query onto a URL's searchParams.
 *
 * If any array values are found, it will use the same param name for each
 * { test[]: [1, 2, 3] }
 * becomes
 * ?test[]=1&test[]=2&test=3
 */
function appendToUrl(url: URL, flatQuery: Record<string, string | string[]>) {
  const cloneUrl = parse(url)
  Object.entries(flatQuery).forEach(([qp, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        cloneUrl.searchParams.append(qp, val)
      })
    } else {
      cloneUrl.searchParams.append(qp, value)
    }
  })
  return cloneUrl
}
