import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { assert } from '@ember/debug'
import type Owner from '@ember/owner'

interface Args {
  date: Date
  /** When a date is selected, this function will be called with that date */
  onDateSelect?: (date: Date) => unknown
  /** After a date has been selected, this function will be called. Useful for actions like closing a dropdown. */
  afterUpdate?: () => unknown
}

export default class TwoDatePickerCalendarComponent extends Component<Args> {
  @tracked center?: Date

  constructor(owner: Owner, args: Args) {
    super(owner, args)
    assert('you must pass a "date" to this component', args.date)

    this.center = args.date
  }

  @action
  onDateSelect({ date }: { date: Date }) {
    this.args.onDateSelect?.(date)
    this.args.afterUpdate?.()
  }

  @action
  onCenterChange({ date }: { date: Date }) {
    this.center = date
  }
}
