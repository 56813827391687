import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type SessionService from 'district-ui-client/services/session'
import type Store from '@ember-data/store'

export default class ReportingRoute extends Route {
  @service session!: SessionService

  @service store!: Store

  async model() {
    // Load schools with utilisations into store, so they can be peeked and filtered by subscription type
    await this.store.query('school', {
      scope: `districts/${this.session.currentDistrict.id}`,
      include: 'school-subscription-utilisations,school-subscriptions',
    })
    return {}
  }
}
