import { A } from '@ember/array'
import { isEmpty } from '@ember/utils'
import { Promise } from 'rsvp'

/**
 * Fetches the books_read_by_genre endpoint from the student-events API
 *
 * @param {String} product - The product to query
 * @param {Object} studentScope - The studentScope for this query
 * @param {String} studentScope.scope - The scope of the records to query, e.g. students, district
 * @param {Array} studentScope.ids - A list of ids to be filtered on within the scope
 * @param {Object} period - The period object
 * @param {Date} period.start - Start of period to query
 * @param {Date} period.end - End of period to query
 * @return {RSVP.Promise} Resolves with the data, or `null` if data is empty
 */
export default function booksReadByLevel(product, studentScope, period) {
  if (isEmpty(studentScope.ids)) return Promise.resolve(null)

  const url = this.buildUrl('books_read_by_level', product, studentScope)
  const query = this.buildQuery(studentScope, period)

  return this.fetchData(url, query).then((data) => {
    const { books_read_by_level } = data
    const hasNoEntries = isEmpty(books_read_by_level)
    const hasNoCounts = A(books_read_by_level).isEvery('count', 0)
    if (hasNoEntries || hasNoCounts) return null

    return data
  })
}
