import { helper as buildHelper } from '@ember/component/helper'
import { isNone } from '@ember/utils'
import { formattedDuration } from 'district-ui-client/utils/format-duration'

function formatDuration([seconds, replacement]: unknown[]) {
  const secondsValue = typeof seconds === 'number' || isNone(seconds) ? seconds : null
  const replacementValue = typeof replacement === 'string' || isNone(replacement) ? replacement : null

  return formattedDuration(secondsValue, replacementValue)
}
export default buildHelper(formatDuration)
