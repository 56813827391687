import Service, { service } from '@ember/service'
import type Transition from '@ember/routing/transition'
import type RouteInfo from '@ember/routing/route-info'
import { tracked } from '@glimmer/tracking'
import type Owner from '@ember/owner'
import { join, parse } from 'district-ui-client/utils/uri'
import type RouterService from '@ember/routing/router-service'
import { SubscriptionType, extractSubscriptionTypeFromRouteInfo } from 'district-ui-client/domain/subscription-type'
import type { Product } from 'district-ui-client/domain/product'
import { getReportingProduct } from 'district-ui-client/domain/product'
import { UIScope, isUiScopeName } from 'district-ui-client/domain/ui-scope'
import { isReportPath, type ReportPath } from 'district-ui-client/services/report-registry'
import { assert } from '@ember/debug'

export interface ReportingLink {
  route: ReportPath
  models: string[]
  // don't necessarily need to save the query, it should be remembered when revisiting the same route & models.
  query?: Record<string, unknown>
}

/**
 * A service for easy access to the current subscription type, based on the current route, and related properties.
 *
 * DO NOT use this service in routes. In particular in the model hooks. If transitioning between subscription types (eg
 * clicking a subtype tab at the top of the page), during transition, the subtype will be the prior subtype.
 *
 * Note that this affects all routes / pages, since the user can visit any page then click the browser Back button.
 */
export default class ActiveRouteService extends Service {
  @service router!: RouterService

  private params: TrackedParams

  /**
   * Remember which report route & models was last visited, so we can go back there
   */
  @tracked private reportingLink?: ReportingLink

  constructor(owner: Owner) {
    super(owner)

    this.params = new TrackedParams()
    // initialize tracked params
    if (this.router.currentRoute) this.params.updateParams(this.router.currentRoute)
    this.router.on('routeDidChange', this.routeDidChange)
  }

  willDestroy(): void {
    super.willDestroy()
    this.router.off('routeDidChange', this.routeDidChange)
  }

  private routeDidChange = (transition: Transition) => {
    if (!transition.to) return
    this.params.updateParams(transition.to)
    this.saveReportingLink()
  }

  /**
   * Whenever the user navigates to a reporting path, save a copy of it and the scope
   */
  private saveReportingLink = () => {
    const { uiScope } = this.params
    if (this.reportingPath && uiScope) {
      this.reportingLink = {
        route: this.reportingPath,
        models: [uiScope.scope, uiScope.id],
      }
    }
  }

  /**
   * Use this when a component is potentially rendered outside of the subscription-type area, and needs to deal with the
   * edge case
   *
   * Will return undefined if current route does not have the subscription-type route as an ancestor
   * e.g. settings, application_loading
   */
  get maybeSubscriptionType(): SubscriptionType | undefined {
    // When refreshing a page, during the loading substate, if a model hook accesses this property, currentRoute can be
    // null. In that case, use the URL to recognize the current route and infer subscription type from that.
    const currentURLWithoutQueryParams = parse(this.router.currentURL || '/').pathname
    const currentURLWithRoot = join(this.router.rootURL, currentURLWithoutQueryParams).pathname
    return (
      extractSubscriptionTypeFromRouteInfo(this.router.currentRoute) ??
      extractSubscriptionTypeFromRouteInfo(this.router.recognize(currentURLWithRoot))
    )
  }

  /**
   * Returns current subscription type. Only use this when inside the subscription-type area and you don't want to deal
   * with the edge case.
   */
  get subscriptionType(): SubscriptionType {
    assert('has defined subscription type', this.maybeSubscriptionType)
    return this.maybeSubscriptionType
  }

  get isCurrentReading() {
    return this.subscriptionType === SubscriptionType.Reading
  }

  get isCurrentMaths() {
    return this.subscriptionType === SubscriptionType.Maths
  }

  get isCurrentWriting() {
    return this.subscriptionType === SubscriptionType.Writing
  }

  get otherSubscriptionTypes(): SubscriptionType[] | undefined {
    if (!this.subscriptionType) return
    return Object.values(SubscriptionType).filter((subType) => subType !== this.subscriptionType)
  }

  get isReporting() {
    return this.router.currentRouteName?.includes('reporting')
  }

  get isSettings() {
    return this.router.currentRouteName?.startsWith('settings')
  }

  get reportingProduct(): Product | undefined {
    return getReportingProduct(this.router.currentRouteName)
  }

  get reportingPath(): ReportPath | undefined {
    const { currentRouteName } = this.router
    return isReportPath(currentRouteName) ? currentRouteName : undefined
  }

  /**
   * Provides the current ui-scope from the URL
   */
  get reportingUiScope(): UIScope | undefined {
    return this.params.uiScope
  }

  /** This will be updated on all route updates, not necessarily just when student grade changes. Avoid using this for
   * requests or other expensive operations. */
  get reportingStudentGrade(): string | undefined {
    return getStudentGrade(this.router.currentRoute)
  }

  /** This will be updated on all route updates, not necessarily just when student grade changes. Avoid using this for
   * requests or other expensive operations. */
  get reportingContentLevel(): string | undefined {
    return getContentLevel(this.router.currentRoute)
  }

  /**
   * The last visited reporting route & models, allowing the user to go back to the same reporting page after leaving
   */
  get reportingLastLink() {
    return this.reportingLink
  }
}

declare module '@ember/service' {
  interface Registry {
    'active-route': ActiveRouteService
  }
}

/**
 * Sets the uiScope (and potentially other params) from the URL when items in the URL that affect it change.
 *
 * Ideally, we would read currentRoute or currentRouteName from the routing service in a getter & respond to
 * tracking updates, however;
 *
 * - currentRoute updates _on all route transitions_ - this is problematic when query params are updated that do
 * not affect the uiScope (like sort), for example, causing unneeded autotracking updates (resulting in requests being
 * made that don't need to be)
 * https://api.emberjs.com/ember/5.8/classes/RouterService/properties/currentRoute?anchor=currentRoute
 *
 * - currentRouteName returns a dot-separated route name, like reporting.ui-scope.re.standards-performace
 * This does not update on ui-scope change (like from district -> school), so it does not update enough.
 *
 * Instead of relying on tracked updates from the router service, we instead take direct control of exactly when
 * to update uiScope. Each time the route changes, we check to see if UiScope has actually changed.
 *
 * There are disadvantages to obtaining query params via the router service (instead of the conventional way via the
 * controller) - values are serialized and do not have the default value specified in the controller. Query params from
 * here are often "undefined", like after a refresh.
 * UIScope is a dynamic segment param and so does not have those specific issues.
 */
class TrackedParams {
  @tracked uiScope?: UIScope

  updateParams = (routeInfo: RouteInfo) => {
    const newUiScope = getUiScope(routeInfo)
    if (!isEqualUiScope(this.uiScope, newUiScope)) this.uiScope = newUiScope
  }
}

function isEqualUiScope(uiScope1?: UIScope, uiScope2?: UIScope): boolean {
  // If they're different types, then they are different
  if (typeof uiScope1 !== typeof uiScope2) return false
  // Now they are either both undefined or both items
  if (uiScope1 === undefined) return uiScope2 === undefined
  if (uiScope2 === undefined) return uiScope1 === undefined
  // Both are defined, lets use their isEqual method
  return uiScope1.isEqual(uiScope2)
}

function getUiScope(routeInfo: RouteInfo): UIScope | undefined {
  const uiScopeRouteInfo = routeInfo.find((rInfo) => rInfo.name === 'reporting.ui-scope')
  if (!uiScopeRouteInfo?.params) return

  const { ui_scope_name: name, ui_scope_id: id } = uiScopeRouteInfo.params
  if (typeof name === 'string' && typeof id === 'string' && isUiScopeName(name)) {
    const { schoolIds } = uiScopeRouteInfo.queryParams
    if (name === 'district' && typeof schoolIds === 'string') {
      const parsed = JSON.parse(schoolIds)
      if (Array.isArray(parsed)) {
        return new UIScope(
          name,
          id,
          parsed.map((schoolId: string) => new UIScope('school', schoolId)),
        )
      }
    }
    return new UIScope(name, id)
  }
}

function getStudentGrade(routeInfo: Nullable<RouteInfo>): string | undefined {
  if (!isReportPath(routeInfo?.name)) return
  const studentGrade = routeInfo.find((rInfo) => Boolean(rInfo.queryParams.studentGrade))?.queryParams.studentGrade
  // can be undefined, since router doesn't have the default value - that is done by the controller
  if (!studentGrade || typeof studentGrade !== 'string') return
  // items retreived directly from the router are serialized
  return studentGrade
}

function getContentLevel(routeInfo: Nullable<RouteInfo>): string | undefined {
  if (!isReportPath(routeInfo?.name)) return
  const contentLevel = routeInfo.find((rInfo) => Boolean(rInfo.queryParams.contentLevel))?.queryParams.contentLevel
  // can be undefined, since router doesn't have the default value - that is done by the controller
  if (!contentLevel || typeof contentLevel !== 'string') return
  // items retreived directly from the router are serialized
  return contentLevel
}
