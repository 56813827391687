import type { SyncHasMany } from '@ember-data/model'
import Model, { belongsTo, attr, hasMany } from '@ember-data/model'
import type School from 'district-ui-client/models/school'
import type Teacher from 'district-ui-client/models/teacher'
import type Student from 'district-ui-client/models/student'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'

export default class SchoolClass extends Model {
  @attr('string') subscriptionType!: SubscriptionType

  @attr('string') name!: string

  @attr('boolean') mathseedsPrimeAccess!: boolean

  @attr('boolean') playroomAccess!: boolean

  @attr('boolean') gamesAccess!: boolean

  @attr('boolean') backgroundMusicDisabled!: boolean

  @belongsTo('school', { async: false, inverse: 'schoolClasses' }) school?: School

  @hasMany('teacher', { async: false, inverse: 'schoolClasses' }) teachers?: SyncHasMany<Teacher>

  @hasMany('student', { async: false, inverse: 'schoolClasses' }) students?: SyncHasMany<Student>

  get studentIds() {
    return (this as SchoolClass).hasMany('students').ids()
  }

  get studentCount() {
    return this.studentIds.length
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'school-class': SchoolClass
  }
}
