import Service from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { addListener, removeListener, sendEvent } from '@ember/object/events'
import { waitFor } from '@ember/test-waiters'
import { getConfig } from '@blakeelearning/get-config'
import ky from 'ky'

type NetworkEvent = 'online' | 'offline'

/**
 * Status helper class which tracks the online or offline state
 * independently from the browsers state
 */
class Status {
  @tracked recovered = false

  @tracked _status = 'online'

  get isOnline(): boolean {
    return this._status === 'online'
  }

  get isOffline(): boolean {
    return this._status === 'offline'
  }

  setOffline(): void {
    this.recovered = false
    this._status = 'offline'
  }

  setOnline(): void {
    this.recovered = true
    this._status = 'online'
  }
}

/**
 * Tracks the online status of the browser,
 * along with event handlers for listening for changes to the online status.
 *
 * We manage our own online/offline state independently from the browser,
 * so we can make a network request to verify there is an internet connection
 * See https://developer.mozilla.org/en-US/docs/Web/API/Document/ononline
 */
export default class DeviceNetwork extends Service {
  /**
   * online/offline status of the browser
   */
  status = new Status()

  /**
   * Can abort any pending network requests checking for an internet connection
   */
  controller?: AbortController | undefined

  /**
   * Sets up 2 event listeners which set the isOnline property when triggered.
   */
  removeNetworkChange = onNetworkChange((isOnline) => {
    if (isOnline) {
      void this._onlineHandler()
    } else {
      this._offlineHandler()
    }
  })

  /**
   * Subscribe to network online and offline events with the given function
   *
   * @param eventName The name of the event
   * @param method Function to be called
   * @return this
   */
  on(eventName: NetworkEvent, method: () => unknown) {
    addListener(this, eventName, null, method)
    return this
  }

  /**
   * Subscribe to the next network event with the given function
   *
   * @param eventName The name of the event
   * @param method Function to be called
   * @return this
   */
  one(eventName: NetworkEvent, method: () => unknown) {
    addListener(this, eventName, null, method, true)
    return this
  }

  /**
   * Cancels the subscription for the given event and function
   *
   * @param eventName The name of the event
   * @param method Function to be removed
   * @returns this
   */
  off(eventName: NetworkEvent, method: () => unknown): this {
    removeListener(this, eventName, method)
    return this
  }

  override willDestroy(): void {
    this.removeNetworkChange()
  }

  /**
   * Checks that there is an internet connection before
   * setting the status to online
   *
   * @private
   */
  @action
  @waitFor
  async _onlineHandler(): Promise<void> {
    const isOnline = await this._checkOnline()

    if (isOnline) {
      this.status.setOnline()
      sendEvent(this, 'online')
    }
  }

  /**
   * Aborts any pending network requests checking for an internet connection
   * and sets the status to offline
   * @private
   */
  @action
  _offlineHandler(): void {
    this._abortCheckOnline()
    this.status.setOffline()
    sendEvent(this, 'offline')
  }

  /**
   * Aborts any pending network requests before checking for an internet connection by sending a HEAD request to a URL
   *
   *
   * @private
   */
  async _checkOnline(): Promise<boolean> {
    this._abortCheckOnline()
    const url = getConfig(this, 'device.network.checkOnlineUrl')
    let isOnline

    if (typeof url === 'string') {
      // Check AbortController is avaliable before creating it
      if (typeof AbortController === 'function') {
        this.controller = new AbortController()
      }

      try {
        const result = await ky.head(url, {
          // don't cache the request
          cache: 'no-cache',
          // retry the request, this only retries server errors, not networking errors
          retry: 2,
          // allows the fetch request to be cancelled
          signal: this.controller?.signal ?? null,
          // disabling timeout means the browser will manage when the request should timeout
          timeout: false,
        })
        isOnline = result.ok
      } catch {
        // Ignore any errors and assume there is no internet connection
        isOnline = false
      }
    } else {
      // Assume there is an internet connection if checkOnlineUrl is not defined
      isOnline = true
    }

    return isOnline
  }

  _abortCheckOnline() {
    this.controller?.abort()
    this.controller = undefined
  }
}

function onNetworkChange(callback: (isOnline: boolean) => void) {
  const handleNetworkChange = () => {
    callback(navigator.onLine)
  }

  window.addEventListener('online', handleNetworkChange)
  window.addEventListener('offline', handleNetworkChange)

  return () => {
    window.removeEventListener('online', handleNetworkChange)
    window.removeEventListener('offline', handleNetworkChange)
  }
}

declare module '@ember/service' {
  interface Registry {
    'device/network': DeviceNetwork
  }
}
