import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'
import type UiScopeRoute from 'district-ui-client/routes/reporting/ui-scope'
import type { StatsScope } from 'district-ui-client/utils/student-events/stats-scope'
import type { NormalizedInterval } from 'date-fns'
import type StudentEventsService from 'district-ui-client/services/student-events'
import type { CourseProgressData } from 'district-ui-client/components/charts/dashboard/course-progress/component'
import type { DrivingTestsData } from 'district-ui-client/components/charts/dashboard/driving-tests/component'
import type { WorkingAtGradeLevelData } from 'district-ui-client/components/charts/dashboard/working-at-grade-level/component'
import type { SkillsMasteredData } from 'district-ui-client/components/charts/dashboard/skills-mastered/component'
import type { MentalMinuteData } from 'district-ui-client/components/charts/dashboard/mental-minute/component'
import type { AwardsEarnedData } from 'district-ui-client/components/charts/dashboard/awards-earned/ribbons'
import { type TotalUsageOverTimeData } from 'district-ui-client/components/charts/dashboard/total-usage-over-time/component'

interface StatsResponse {
  totalUsageOverTime: Nullable<TotalUsageOverTimeData>
  courseProgressByGrade: Nullable<CourseProgressData>
  drivingTests: Nullable<DrivingTestsData>
  workingAtGradeLevel: Nullable<WorkingAtGradeLevelData>
  skillsMastered: Nullable<SkillsMasteredData>
  mentalMinuteSummary: Nullable<MentalMinuteData>
  awardsEarned: Nullable<AwardsEarnedData>
}

export class ReportingMathsDashboardRoute extends Route {
  @service studentEvents!: StudentEventsService

  async model() {
    const product: LegacyProductMs = 'maths'
    const {
      uiScope: scope,
      statsScope,
      period,
      scopedData,
    } = this.modelFor('reporting.ui-scope') as ModelFor<UiScopeRoute>

    const stats = await this.makeStatsRequest(product, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product: string, scope: StatsScope, period: NormalizedInterval): Promise<StatsResponse> {
    const { studentEvents } = this

    return RSVP.hash({
      totalUsageOverTime: studentEvents.totalUsageOverTime(product, scope, period),
      courseProgressByGrade: studentEvents.courseProgressByGrade(product, 'lessons', scope, period),
      workingAtGradeLevel: studentEvents.workingAtGradeLevel(product, scope, period),
      drivingTests: studentEvents.drivingTests(product, scope, period),
      mentalMinuteSummary: studentEvents.mentalMinuteSummary(product, scope, period),
      awardsEarned: studentEvents.awardsEarned(product, scope, period),
      skillsMastered: studentEvents.skillsMastered(product, scope, period),
    })
  }
}
export default ReportingMathsDashboardRoute
