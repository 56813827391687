import CleverAdapter from 'district-ui-client/adapters/clever'

export default class CleverPrefixedCleverAdapter extends CleverAdapter {
  // Strip any "clever/" prefix from the beginning of the modelname then pass to the super, which will pluralize it
  pathForType(modelName) {
    return super.pathForType(modelName.replace(/^clever\//, ''))
  }

  urlForQuery(query, modelName) {
    // Prepend the scope if given
    if (query?.scope) {
      const scope = query?.scope // scope is like 'districts/123'
      delete query.scope

      const url = super.urlForQuery(query, modelName) // eg /api/v3/schools
      const pathForModelName = this.pathForType(modelName) // eg schools
      return url.replace(pathForModelName, `${scope}/${pathForModelName}`) // /api/v3/districts/123/schools - note: only replaces first occurrence
    }
    return super.urlForQuery(query, modelName)
  }
}
