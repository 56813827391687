import { isNone } from '@ember/utils'

/*
 * Takes one value in seconds, converts it to minutes
 * and returns it with either 'm' or 'h' after it.
 * @param {seconds} number of seconds
 * @param {replacement} string of custom fallback text
 */
export function formattedDuration(seconds?: number | null, replacement?: string | null): string {
  let duration = replacement ?? ''

  if (isNone(seconds)) return duration

  const minutesFromSeconds = seconds / 60

  if (minutesFromSeconds < 1) {
    duration = '<1m'
  } else if (minutesFromSeconds < 60) {
    duration = `${Math.round(minutesFromSeconds)}m`
  } else {
    duration = `${(minutesFromSeconds / 60).toFixed(1)}h`
  }

  return duration
}
