import GravityAdapter from 'district-ui-client/adapters/clever/gravity'
import { tracked } from '@glimmer/tracking'
import BulkManager from 'district-ui-client/utils/bulk-manager'
import { createRecordBulk } from 'district-ui-client/utils/bulk-adapter-utils'

/**
 * Same as the clever gravity adapter, but with the ability to batch create requests together
 *
 * Use 'bulk: true' in the adapter options when creating a record.
 */
export default class GravityBulkAdapter extends GravityAdapter {
  @tracked
  _bulkManager = null

  buildBulkManager(modelName, snapshot) {
    const { ajax } = this
    const urlForBulkAction = this.urlForCreateRecord(modelName, snapshot)

    return new BulkManager(ajax.bind(this), urlForBulkAction)
  }

  createRecord(store, type, snapshot) {
    if (snapshot?.adapterOptions?.bulk) {
      if (!this._bulkManager) this._bulkManager = this.buildBulkManager(snapshot.modelName, snapshot)
      return createRecordBulk(snapshot, this._bulkManager)
    } else {
      return super.createRecord(store, type, snapshot)
    }
  }
}
