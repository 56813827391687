import ApplicationJSONAPISerializer from 'district-ui-client/serializers/application'

export default class extends ApplicationJSONAPISerializer {
  /* Apply 'clever/' prefix to payloadKey for _some_ model names, to match the format that ember-data uses internally to
   * lookup the modelname.
   *
   * Must also singularize.
   *
   * Must handle every relationship that can appear in the payload, and the model itself.
   */
  modelNameFromPayloadKey(payloadKey) {
    const modelName = {
      // payload-key: model-name,
      'clever-districts': 'clever/clever-district',
      'clever-district-matches': 'clever/clever-district-match',
    }[payloadKey]
    return modelName ?? super.modelNameFromPayloadKey(payloadKey) // handles districts -> district
  }

  /*
   * Safely remove 'clever/' prefix from modelName to get the payloadKey used for serializing outbound records
   */
  payloadKeyFromModelName(modelName) {
    const name = super.payloadKeyFromModelName(modelName)
    return name.split('clever/').pop() // if clever/ not present, leaves the modelname unmodified
  }
}
