import type { NormalizedInterval } from 'date-fns'
import {
  endOfMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  subDays,
  subWeeks,
  subYears,
} from 'date-fns'

export type IntervalWithDescription = NormalizedInterval & {
  description: string
}

export default function (
  schoolYearPeriod?: NormalizedInterval,
  now = new Date(),
): Record<string, IntervalWithDescription> {
  const { start, end } = schoolYearPeriod ?? {
    start: subYears(now, 1),
    end: now,
  }
  const namedDateRangeObject = {
    'this-week': {
      start: startOfWeek(now),
      // adding 6 days is 7 days duration
      end: endOfWeek(now),
      description: 'This week',
    },
    'last-7-days': {
      // subtracting 6 days is 7 days duration
      start: startOfDay(subDays(now, 6)),
      end: startOfDay(now),
      description: 'Last 7 days',
    },
    'this-month': {
      start: startOfMonth(now),
      end: endOfMonth(now),
      description: 'This month',
    },
    'last-90-days': {
      start: startOfDay(subDays(now, 89)),
      end: startOfDay(now),
      description: 'Last 90 days',
    },
    'this-year': {
      start: startOfDay(start),
      end: startOfDay(end),
      description: 'This year',
    },
    'last-year': {
      start: startOfDay(subYears(start, 1)),
      end: startOfDay(subYears(end, 1)),
      description: 'Last year',
    },
    // WF Teacher named date ranges
    'last-2-weeks': {
      start: startOfDay(subWeeks(now, 2)),
      end: startOfDay(now),
      description: 'Last 14 days',
    },
    'last-30-days': {
      start: startOfDay(subDays(now, 30)),
      end: startOfDay(now),
      description: 'Last 30 days',
    },
  }
  return namedDateRangeObject
}
