import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { service } from '@ember/service'

export default class NewController extends Controller {
  @service teacherOperations

  @service activeRoute

  @service store

  @service alert

  @service log

  @service intl

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  @tracked firstName = ''

  @tracked lastName = ''

  @tracked email = ''

  @tracked emailConfirmation = ''

  @tracked accountType = 'teacher-subscription'

  get school() {
    return this.model
  }

  get accountTypeOptions() {
    return [
      { label: 'Trial', value: 'teacher-subscription' },
      { label: 'School Subscription', value: 'school-subscription' },
    ]
  }

  @action
  cancel() {
    this._clearNewTeacherFields()
    this._backToPrevious()
  }

  @action
  setAccountType(accountType) {
    this.accountType = accountType
  }

  @action
  async createTeacher(event) {
    event.preventDefault()

    if (this.email !== this.emailConfirmation) {
      this.alert.showWithDismiss({ type: 'critical', message: 'Email and confirmation fields do not match' })
      return
    }
    try {
      const { subscriptionType, accountType, school } = this
      const newTeacher = this.store.createRecord('teacher', {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        login: this.email,
        school,
      })
      await newTeacher.save({
        adapterOptions: { subscriptionType, accountType },
      })
      const newClass = this.store.createRecord('school-class', {
        name: `${newTeacher.firstName} ${newTeacher.lastName}'s ${this.intl.t(subscriptionType)} Class`,
        subscriptionType,
        school,
        teachers: [newTeacher],
        gamesAccess: true,
        playroomAccess: true,
        backgroundMusicDisabled: false,
        mathseedsPrimeAccess: subscriptionType === 'maths',
      })
      await newClass.save()

      this._clearNewTeacherFields()
      this._backToPrevious()
      this.alert.showWithDismiss({
        message: 'Successfully created a teacher',
      })
    } catch (err) {
      const errors = err?.payload?.errors || [{ detail: 'Something went wrong' }]
      const errorMessages = errors.map((e) => e?.detail)
      this.log.error('failed to create teacher', err)
      this.alert.showWithDismiss({
        type: 'critical',
        message: errorMessages.join(' , '),
      })
    }
  }

  _clearNewTeacherFields() {
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.emailConfirmation = ''
    this.accountType = 'teacher-subscription'
  }

  _backToPrevious() {
    window.history.back()
  }
}
