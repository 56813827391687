import JSONAPISerializer from '@ember-data/serializer/json-api'

interface WithAttributes {
  data: { attributes: Record<string, unknown> }
}

export function hasAttributes(json: unknown): json is WithAttributes {
  return Boolean(
    json &&
      typeof json === 'object' &&
      'data' in json &&
      json.data &&
      typeof json.data === 'object' &&
      'attributes' in json.data &&
      json.data.attributes &&
      typeof json.data.attributes === 'object',
  )
}

export default class ApplicationJSONAPISerializer extends JSONAPISerializer {
  /**
   * Skip the extractErrors processing. From ember-data 4.7.3+ the error handling was rewritten and it no longer "leaks"
   * the extra jsonapi error members. A lot of our error handling relies on those properties to know how to handle those
   * errors.
   *
   * By setting this to "not a function" we skip this if block, which overwrites the errors array.
   * https://github.com/emberjs/data/blob/3bc74cb488ee5be1f06ba5266ab34bdece4b5344/packages/store/addon/-private/store-service.ts#L2695-L2697
   *
   * This workaround is very much relying on behaviour we shouldn't know about. This PR should solve it correctly
   * https://github.com/emberjs/data/pull/8669
   */
  // @ts-expect-error boolean is not a function
  extractErrors = false
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    application: ApplicationJSONAPISerializer
  }
}
