import Model, { hasMany, belongsTo, attr, type SyncHasMany } from '@ember-data/model'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type CleverSchoolMatch from 'district-ui-client/models/clever/clever-school-match'
import type ContactDetail from 'district-ui-client/models/clever/contact-detail'
import type CleverSchoolSubscriptionUtilisation from 'district-ui-client/models/clever/school-subscription-utilisation'
import type GravityStudent from 'district-ui-client/models/clever/student'
import type GravityTeacher from 'district-ui-client/models/clever/teacher'

/**
 * Model class for schools (a blake school model - gravity data, with clever relationships)
 */
export default class GravitySchool extends Model {
  @attr('string') name!: string

  @belongsTo('clever/contact-detail', { async: false, inverse: 'school' }) contactDetail?: ContactDetail

  @belongsTo('clever/clever-school-match', { async: false, inverse: 'school' })
  cleverSchoolMatch?: CleverSchoolMatch

  @hasMany('clever/teacher', { async: false, inverse: 'school' })
  teachers?: SyncHasMany<GravityTeacher>

  @hasMany('clever/student', { async: false, inverse: 'school' })
  students?: SyncHasMany<GravityStudent>

  get cleverSchool() {
    return this.cleverSchoolMatch?.cleverSchool
  }

  @hasMany('clever/school-subscription-utilisation', { async: false, inverse: 'school' })
  schoolSubscriptionUtilisations?: SyncHasMany<CleverSchoolSubscriptionUtilisation>

  utilisationFor(subscriptionType: SubscriptionType) {
    return this.schoolSubscriptionUtilisations?.find((utilisation) => utilisation.subscriptionType === subscriptionType)
  }
}
