import Model, { attr } from '@ember-data/model'

export default class StandardsSet extends Model {
  @attr('string') name!: string

  @attr('string') code!: string

  @attr('string') countryCode!: string
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'standards-set': StandardsSet
  }
}
