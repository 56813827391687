import type Owner from '@ember/owner'
import { setOwner } from '@ember/owner'
import Service, { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { isProduct } from 'district-ui-client/domain/product'
import { isSubscriptionType } from 'district-ui-client/domain/subscription-type'
import type ImpressionService from 'district-ui-client/services/impression'
import type ReportRegistryService from 'district-ui-client/services/report-registry'
import { hasNewReportForPath, isReportPath } from 'district-ui-client/services/report-registry'
import type SessionService from 'district-ui-client/services/session'
import type FeatureControlService from 'district-ui-client/services/feature-control'
import { FeatureAccess } from 'district-ui-client/services/feature-control'

/**
 * A feature notification with optional context to determine visibility.
 */
abstract class FeatureNotification {
  abstract isVisible(context?: Record<string, unknown>): boolean

  constructor(owner: Owner) {
    setOwner(this, owner)
  }
}

export enum FeatureNotificationId {
  SetAcademicStandards = 'set-academic-standards',
  NewReportForSubscriptionType = 'new-report-for-subscription-type',
  NewReportForProduct = 'new-report-for-product',
  NewReportForPath = 'new-report-for-path',
}

/**
 * A service for the conditional display of new feature notifications.
 *
 * Add a feature notification by writing a class that extends
 * `FeatureNotification`.
 *
 * - The class instance should be registered in this service's constructor.
 * - The class should have an `id` property from the FeatureNotificationId enum.
 * - The class should implement the `isVisible` method to determine visibility.
 *
 * Then query the service for notification visibility:
 *
 * @example
 * ```ts
 *   get showNotification() {
 *     return this.featureNotification.isVisible(FeatureNotificationId.YourNotificationId, { your: 'context' })
 *   }
 * ```
 *
 * or use the FeatureNotification helper:
 *
 * @example
 * ```gts
 * {{#if (FeatureNotification FeatureNotificationId.YourNotificationId your="context")}}
 *  <div>Your notification content</div>
 * {{/if}
 * ```
 */
export default class FeatureNotificationService extends Service {
  @tracked
  protected notifications: Record<FeatureNotificationId, FeatureNotification>

  constructor(owner: Owner) {
    super(owner)
    this.notifications = {
      [FeatureNotificationId.SetAcademicStandards]: new SetAcademicStandards(owner),
      [FeatureNotificationId.NewReportForSubscriptionType]: new NewReportForSubscriptionType(owner),
      [FeatureNotificationId.NewReportForProduct]: new NewReportForProduct(owner),
      [FeatureNotificationId.NewReportForPath]: new NewReportForPath(owner),
    }
  }

  isVisible(id: FeatureNotificationId, params?: Record<string, unknown>) {
    return this.notifications[id].isVisible(params)
  }
}

declare module '@ember/service' {
  interface Registry {
    'feature-notification': FeatureNotificationService
  }
}

class SetAcademicStandards extends FeatureNotification {
  @service session!: SessionService

  @service featureControl!: FeatureControlService

  isVisible() {
    return !this.session.currentDistrict.standardsSet && this.featureControl.canAccess(FeatureAccess.Standards)
  }
}

class NewReportForSubscriptionType extends FeatureNotification {
  @service reportRegistry!: ReportRegistryService

  @service impression!: ImpressionService

  isVisible({ reportSubscriptionType }: Record<string, unknown>) {
    if (isSubscriptionType(reportSubscriptionType)) {
      const newReports = this.reportRegistry.newReportsForSubscriptionType(reportSubscriptionType)
      return newReports.some((newReport) => this.impression.notMade(newReport))
    }
    return false
  }
}

class NewReportForProduct extends FeatureNotification {
  @service reportRegistry!: ReportRegistryService

  @service impression!: ImpressionService

  isVisible({ reportProduct }: Record<string, unknown>) {
    if (isProduct(reportProduct)) {
      const newReports = this.reportRegistry.newReportsForProduct(reportProduct)
      return newReports.some((newReport) => this.impression.notMade(newReport))
    }

    return false
  }
}

class NewReportForPath extends FeatureNotification {
  @service reportRegistry!: ReportRegistryService

  @service impression!: ImpressionService

  isVisible({ reportPath }: Record<string, unknown>) {
    if (typeof reportPath === 'string' && isReportPath(reportPath) && hasNewReportForPath(reportPath)) {
      return this.impression.notMade(reportPath)
    }
    return false
  }
}
