import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'
import { action } from '@ember/object'
import { isPresent, isEmpty } from '@ember/utils'
import { timeout, task } from 'ember-concurrency'
import { THEME_KEYS } from 'district-ui-client/utils/themes'
import { classNames as tooltipClassNames } from 'district-ui-client/components/tooltip'
import { tableColumnsToComparerKeyMapping, injectComparerKeys } from '@blakeelearning/data-tables/utils/sorting'
import { textFilterPredicate } from '@blakeelearning/data-tables/utils/filtering'

/**
 * @property {Boolean} useSyncedView if true, the table will be displayed in a 'simpler' mode, with unneeded columns and
 * actions hidden. Most data and actions in this table are only relevant to unsynced clever schools.
 */
export default class CleverTablesDashboardSchoolsTableComponent extends Component {
  @service intl

  @service store

  @service dataTransformer

  @tracked transformedItems = []

  get selectedTheme() {
    const themes = {
      [THEME_KEYS.blue]: { header: 'bg-oceany-blue-100' },
      [THEME_KEYS.green]: { header: 'bg-ms-green-100' },
      [THEME_KEYS.default]: { header: 'bg-dusty-black-100' },
    }
    const themeKey = this.args.theme || ''
    return themes[themeKey] || themes[THEME_KEYS.default]
  }

  get selectedIds() {
    return this.args.selectedIds || []
  }

  /**
   * This returns a subset of the selected ids array, where only ids for schools in the awaiting sync state are returned
   * It is the list of selected school ids that can have the sync action performed on them
   */
  get syncableSelectedIds() {
    const { cleverSchools } = this.args

    const schoolAwaitingSyncMap = cleverSchools.reduce((mapping, school) => {
      return { ...mapping, [school.id]: { isAwaitingSync: school.isAwaitingSync } }
    }, {})

    return this.selectedIds.filter((selectedId) => schoolAwaitingSyncMap[selectedId]?.isAwaitingSync)
  }

  get pipelineDescriptors() {
    return [this.rowDescriptor, this.sortingDescriptor, this.textFilterDescriptor]
  }

  /**
   * A descriptor for transforming the given array of data items, into the data needed for the table.
   * This way, the data in the rows can be transformed before the sort occurs, and those transformed data items can be
   * sortable.
   */
  get rowDescriptor() {
    const { subscriptionType } = this.args
    const transformer = async ({ items, ...rest }) => {
      return {
        ...rest,
        items: items.map((cleverSchool) => {
          const {
            id,
            name,
            cityStateJoined,
            blakeSchoolId,
            matchSchoolState,
            matchTeachersState,
            matchStudentsState,
            studentCounts,
            sectionCounts,
            isInitializing,
            isMatchingInProgress,
            isSyncInProgress,
            isAwaitingSync,
          } = cleverSchool
          // This is the number of students that have been shared via clever for this clever school record
          const studentCountForSubscriptionType = studentCounts?.[subscriptionType]
          // This is the number of sections (classes) that have been shared via clever for this clever school record
          const sectionCountForSubscriptionType = sectionCounts?.[subscriptionType]

          // Get the utilisation, used for the capacity column
          const school = isPresent(blakeSchoolId) ? this.store.peekRecord('clever/school', blakeSchoolId) : null
          const utilisation = school?.utilisationFor(subscriptionType)
          // Calculate the capacity diff for sorting.
          // If utilisation, use zero for the value, so that it shows up towards the middle when sorted
          let capacityDiff = 0
          if (utilisation) {
            capacityDiff = utilisation.isUnlimitedCapacity
              ? Number.POSITIVE_INFINITY
              : utilisation.licenceCount - utilisation.studentCount
          }

          return {
            id, // for checkbox selection
            name,
            cityStateJoined,
            matchSchoolState,
            matchTeachersState,
            matchStudentsState,
            studentCount: studentCountForSubscriptionType,
            sectionCount: sectionCountForSubscriptionType,
            capacityDiff,
            utilisation,
            isInitializing,
            isMatchingInProgress,
            isSyncInProgress,
            isAwaitingSync,
          }
        }),
      }
    }
    const descriptor = { type: 'custom', transformer }
    return descriptor
  }

  get sortingDescriptor() {
    const { sortingConfig } = this.args
    const tableColumns = this.columns
    if (!sortingConfig) return null

    const comparerKeyMapping = tableColumnsToComparerKeyMapping(tableColumns)
    const sortingConfigWithComparerKeys = injectComparerKeys(sortingConfig, comparerKeyMapping)
    const sortDescriptor = { type: 'sort', config: sortingConfigWithComparerKeys }

    return sortDescriptor
  }

  get dataKeysToFilterOn() {
    return ['name']
  }

  get textFilterDescriptor() {
    const { dataKeysToFilterOn } = this
    const { textFilter } = this.args

    // Only need to apply a text filter if there is text
    if (isEmpty(textFilter)) return null

    // Create an item filter function that keeps only selected items and items meeting the text filter query
    const selectedFilter = (item) => this.selectedIds?.includes(item.id)
    const searchFilter = textFilterPredicate(textFilter, dataKeysToFilterOn)
    const itemFilter = (item) => selectedFilter(item) || searchFilter(item)
    const filterConfig = { itemFilter }

    return { type: 'filter', config: filterConfig }
  }

  get disableActions() {
    return this.args.isSyncInProgress || this.args.isResetInProgress
  }

  @action
  performTransformItems(_element, [items, pipelineDescriptors]) {
    this.transformItems.perform(items, pipelineDescriptors)
  }

  transformItems = task({ restartable: true }, async (items = [], pipelineDescriptors = []) => {
    await timeout(200) // debounce

    const presentDescriptors = pipelineDescriptors.filter(isPresent)
    const transformedData = await this.dataTransformer.buildAndTransform(items, presentDescriptors)

    this.transformedItems = transformedData.items
  })

  get columns() {
    const { intl } = this
    const { useSyncedView } = this.args
    const translationPrefix = 'components.cleverUi.tables.dashboardSchoolsTable.headers'
    const tooltipPrefix = 'components.cleverUi.tables.dashboardSchoolsTable.tooltips'

    // Common column properties
    const defaultColumn = {
      classNames: 'table-box table-box-header bg-dusty-black-300 text-white',
      cellClassNames: 'table-box table-box-cell border-dusty-black-50',
    }
    const centeredColumn = {
      classNames: `${defaultColumn.classNames} text-center`,
      cellClassNames: `${defaultColumn.cellClassNames} text-center`,
    }

    // Column definitions
    const selectAllColumn = {
      // The classes for this cell are split up a bit, so that the padding classes are applied to the inner component
      // That way the entire cell can be clicked to set the checkbox.
      value: intl.t(`${translationPrefix}.selectAll`),
      classNames: 'table-box bg-dusty-black-300 text-white p-0 w-px', // 1px width stops the cell growing past its content width
      component: 'basic-cells/selectable',
      componentClassNames: 'table-box-header',
      cellClassNames: 'table-box border-dusty-black-50',
      cellComponent: 'basic-cells/selectable',
      cellComponentClassNames: 'table-box-cell',
    }

    const schoolNameColumn = {
      ...defaultColumn,
      isSchoolNameColumn: true,
      value: intl.t(`${translationPrefix}.schoolName`),
      valuePath: 'name',
      component: 'basic-cells/sortable',
    }

    const matchColumns = [
      {
        ...centeredColumn,
        value: intl.t(`${translationPrefix}.matchSchool`),
        component: 'basic-cells/sortable',
        isMatchStateColumn: true,
        valuePath: 'matchSchoolState',
      },
      {
        ...centeredColumn,
        value: intl.t(`${translationPrefix}.matchTeachers`),
        component: 'basic-cells/sortable',
        isMatchStateColumn: true,
        valuePath: 'matchTeachersState',
      },
      {
        ...centeredColumn,
        value: intl.t(`${translationPrefix}.matchStudents`),
        component: 'basic-cells/sortable',
        isMatchStateColumn: true,
        valuePath: 'matchStudentsState',
      },
    ]

    const cleverSharedColumns = [
      {
        ...centeredColumn,
        value: intl.t(`${translationPrefix}.sharedStudents`),
        component: 'basic-cells/sortable',
        tooltipValue: intl.t(`${tooltipPrefix}.sharedStudents`),
        tooltipClassNames,
        valuePath: 'studentCount',
      },
      {
        ...centeredColumn,
        value: intl.t(`${translationPrefix}.sharedSections`),
        component: 'basic-cells/sortable',
        tooltipValue: intl.t(`${tooltipPrefix}.sharedSections`),
        tooltipClassNames,
        valuePath: 'sectionCount',
      },
    ]

    const capacityColumn = {
      ...centeredColumn,
      value: intl.t(`${translationPrefix}.capacity`, { htmlSafe: true }),
      component: 'basic-cells/sortable',
      tooltipValue: intl.t(`${tooltipPrefix}.capacity`),
      tooltipClassNames,
      isCapacityColumn: true,
      // used for the sortable component, so that it sorts by capacity _issues_ (ie those going over, or close to it)
      valuePath: `capacityDiff`,
    }

    let columns = []

    if (useSyncedView) {
      columns = [
        selectAllColumn,
        schoolNameColumn,
        ...cleverSharedColumns,
        capacityColumn,
        {
          ...centeredColumn,
          classNames: `${centeredColumn.classNames} w-px`,
          isResetColumn: true,
        },
      ]
    } else {
      columns = [
        selectAllColumn,
        schoolNameColumn,
        ...matchColumns,
        ...cleverSharedColumns,
        capacityColumn,
        {
          ...centeredColumn,
          classNames: `${centeredColumn.classNames} w-px`,
          isSetupColumn: true,
        },
        {
          ...centeredColumn,
          classNames: `${centeredColumn.classNames} w-px`,
          isSyncColumn: true,
        },
      ]
    }

    return columns
  }

  get rows() {
    return this.transformedItems
  }

  get allSelectableIds() {
    return this.transformedItems.map((item) => item.id)
  }

  get isTransforming() {
    return this.transformItems.isRunning
  }

  get hasNoData() {
    return this.transformItems.isIdle && isEmpty(this.rows)
  }

  get noDataMessage() {
    return this.intl.t('components.cleverUi.tables.dashboardSchoolsTable.emptyTableMessage')
  }

  @action
  onTextInput(value) {
    return this.args.onTextInput?.({ value })
  }

  @action
  schoolSetupAction(...args) {
    return this.args.schoolSetupAction?.(...args)
  }

  @tracked resetConfirmModal = false

  @tracked syncConfirmModal = false

  @action
  syncSchoolsAction(...args) {
    this.showSyncModal(false)
    return this.args.syncSchoolsAction?.(...args)
  }

  @action
  resetSchoolsAction(...args) {
    this.showResetModal(false)
    return this.args.resetSchoolsAction?.(...args)
  }

  @action
  showResetModal(value) {
    this.resetConfirmModal = value
  }

  @action
  showSyncModal(value) {
    this.syncConfirmModal = value
  }
}
