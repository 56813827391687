import { template as template_ca4310ea28bd46b8add02b6ff2216226 } from "@ember/template-compiler";
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { SummaryTableSchools } from 'district-ui-client/components/summary-tables/schools';
import type ReportingReSummaryTableRoute from 'district-ui-client/routes/reporting/ui-scope/re/summary/table';
import RouteTemplate from 'ember-route-template';
import { not } from 'ember-truth-helpers';
interface Signature {
    Args: {
        model: ModelFor<ReportingReSummaryTableRoute>;
    };
}
export class ReportingReSummaryTableRouteTemplate extends Component<Signature> {
    get schools() {
        const uiScope = this.args.model.scope;
        if (uiScope.scope === 'district') return this.args.model.scopedData.schools;
        return this.args.model.scopedData.schools.filter((school)=>school.id === uiScope.id);
    }
    get isDataPresent() {
        return isPresent(this.args.model.simpleSummary?.simple_summary);
    }
    static{
        template_ca4310ea28bd46b8add02b6ff2216226(`
    <DetailedPageWrapper @viewMode="table" @empty={{not this.isDataPresent}}>
      <div
        class="overflow-hidden rounded-lg shadow-[0_4px_12px_0_rgba(18,17,16,0.1)] print:rounded-none print:shadow-none"
      >
        <SummaryTableSchools
          @product={{@model.product}}
          @schools={{this.schools}}
          @schoolsData={{@model.simpleSummary.simple_summary.schools}}
          @averagesAndTotalsData={{@model.simpleSummary.simple_summary.district}}
        />
      </div>
    </DetailedPageWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ReportingReSummaryTableRouteTemplate);
