import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'
import { action } from '@ember/object'
import { isPresent, isEmpty } from '@ember/utils'
import { timeout, task } from 'ember-concurrency'
import { tableColumnsToComparerKeyMapping, injectComparerKeys } from '@blakeelearning/data-tables/utils/sorting'

export default class CleverTablesMissingFieldsComponent extends Component {
  @service intl

  @service dataTransformer

  @tracked transformedItems = []

  get pipelineDescriptors() {
    return [this.sortingDescriptor]
  }

  get sortingDescriptor() {
    const { sortingConfig } = this.args
    const tableColumns = this.columns
    if (!sortingConfig) return null

    const comparerKeyMapping = tableColumnsToComparerKeyMapping(tableColumns)
    const sortingConfigWithComparerKeys = injectComparerKeys(sortingConfig, comparerKeyMapping)
    const sortDescriptor = { type: 'sort', config: sortingConfigWithComparerKeys }

    return sortDescriptor
  }

  @action
  performTransformItems(_element, [items, pipelineDescriptors]) {
    this.transformItems.perform(items, pipelineDescriptors)
  }

  transformItems = task({ restartable: true }, async (items = [], pipelineDescriptors = []) => {
    await timeout(200) // debounce

    const presentDescriptors = pipelineDescriptors.filter(isPresent)
    const transformedData = await this.dataTransformer.buildAndTransform(items, presentDescriptors)

    this.transformedItems = transformedData.items
  })

  get columns() {
    const { intl } = this
    const translationPrefix = 'components.cleverUi.tables.missingFields.headers'

    // Common column properties
    const defaultColumn = {
      classNames: 'table-box table-box-header bg-dusty-black-300 text-white',
      cellClassNames: 'table-box table-box-cell border-dusty-black-50',
      component: 'basic-cells/sortable',
    }

    return [
      {
        ...defaultColumn,
        value: intl.t(`${translationPrefix}.sisId`),
        valuePath: 'sisId',
      },
      {
        ...defaultColumn,
        value: intl.t(`${translationPrefix}.firstName`),
        valuePath: 'firstName',
      },
      {
        ...defaultColumn,
        value: intl.t(`${translationPrefix}.lastName`),
        valuePath: 'lastName',
      },
    ]
  }

  get rows() {
    return this.transformedItems
  }

  get isTransforming() {
    return this.transformItems.isRunning
  }

  get hasNoData() {
    return this.transformItems.isIdle && isEmpty(this.rows)
  }

  get noDataMessage() {
    return this.intl.t('components.cleverUi.tables.missingFields.emptyTableMessage')
  }
}
