import type RouteInfo from '@ember/routing/route-info'

export enum SubscriptionType {
  Reading = 'reading',
  Maths = 'maths',
  Writing = 'writing',
}

export function isSubscriptionType(value: unknown): value is SubscriptionType {
  const valid = Object.values<string>(SubscriptionType)
  return typeof value === 'string' && valid.includes(value)
}

export function subscriptionTypeOrDefault(value: unknown, defaultValue: SubscriptionType): SubscriptionType {
  return isSubscriptionType(value) ? value : defaultValue
}

export function extractSubscriptionTypeFromRouteInfo(routeInfo?: Nullable<RouteInfo>): SubscriptionType | undefined {
  // find() is always defined on RouteInfo, but allowed to be optional so that integration tests don't need to stub it
  const paramSubscriptionType = routeInfo?.find?.(({ name }) => name === 'subscription-type')?.params?.subscription_type
  if (isSubscriptionType(paramSubscriptionType)) return paramSubscriptionType
  return undefined
}
