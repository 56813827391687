import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type StudentImportService from 'district-ui-client/services/student-import'

export default class ImportStudentsAsyncRoute extends Route {
  @service studentImport!: StudentImportService

  deactivate() {
    this.studentImport.markAsCompleteIfNotRunning()
  }
}
