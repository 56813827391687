import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import type { BandKey } from 'district-ui-client/components/reporting/lesson-performance/overview'
import { queryParam } from 'district-ui-client/decorators/query-param'

export default class ReLessonPerformanceController extends Controller {
  /* Implemented via controller, rather than using reporting.studentGrade directly, so that default value &
   * deserialization occurs, and so that tracked updates only occur when it changes */
  @queryParam('reporting/ui-scope/re') studentGrade!: string

  @queryParam('reporting/ui-scope/re') contentLevel!: string

  queryParams = ['band', 'sort', 'pageNumber', 'perPage']

  @tracked band: 'all' | BandKey = 'all'

  @tracked sort = 'first_name'

  @tracked pageNumber = 1

  @tracked perPage = 25

  setBand = (band: 'all' | BandKey) => {
    this.band = band
    this.pageNumber = 1
  }
}

declare module '@ember/controller' {
  interface Registry {
    'reporting/ui-scope/re/lesson-performance': ReLessonPerformanceController
  }
}
