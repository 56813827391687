import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { service } from '@ember/service'
import {
  serialize as serializeSort,
  deserialize as deserializeSort,
  updateSortColumns,
} from '@blakeelearning/data-tables/utils/sorting'
import {
  serialize as serializeSelected,
  deserialize as deserializeSelected,
} from '@blakeelearning/data-tables/utils/selecting'

export default class CleverDashboardSyncedController extends Controller {
  @service clever

  @service router

  get syncedSchools() {
    return this.model.cleverSchools.filter((school) => school.hasCompletedSync)
  }

  queryParams = ['textFilter', 'sort', 'selected']

  @tracked
  textFilter = ''

  @tracked
  sort = 'capacityDiff,name'

  @tracked
  selected = ''

  get sortingConfig() {
    return deserializeSort(this.sort)
  }

  set sortingConfig(newSortConfig) {
    this.sort = serializeSort(newSortConfig)
  }

  get selectedIds() {
    const isAlphaNumeric = (str) => /^[a-z0-9]+$/i.test(str)
    return deserializeSelected(this.selected, String, { validationFunction: isAlphaNumeric })
  }

  set selectedIds(newSelectedIds) {
    this.selected = serializeSelected(newSelectedIds)
  }

  @action
  updateTextFilter({ value }) {
    this.textFilter = value
  }

  @action
  updateSorts({ sortKey }) {
    if (sortKey) {
      const newSortConfig = updateSortColumns(this.sortingConfig, sortKey)
      this.sortingConfig = newSortConfig
    }
  }

  @action
  updateSelectedIds(newSelectedIds) {
    this.selectedIds = newSelectedIds
  }

  // This action will look at the selected ids, and the current list of schools, then purge any ids that aren't present
  @action
  refreshSelectedIds() {
    const allIds = this.syncedSchools.map((school) => school.id)
    const newSelectedIds = this.selectedIds.filter((selectedId) => allIds.includes(selectedId))
    this.updateSelectedIds(newSelectedIds)
  }

  @action
  resetSchools({ ids }) {
    const { cleverDistrict, subscriptionType } = this.model
    // unselect any schools that have now been moved to the unsynced table
    const onUpdateCallback = () => this.refreshSelectedIds()
    this.clever.resetSchoolsTask.perform(cleverDistrict.id, ids, subscriptionType, { onUpdateCallback })
  }
}
