import { helper } from '@ember/component/helper'

interface Signature {
  Args: {
    Positional: [undefined | null | ((...args: any[]) => unknown)]
  }
  /**
   * Should be the same function given in positional args, or the noop function, but not sure how to type that.
   */
  Return: (...args: unknown[]) => unknown
}

/* Allows for the passed in function to not exist.
 * Useful in event modifier handlers where an action or function is a requirement.
 * For smaller components, it can often save needing a backing component file.
 * This helper takes a single optional argument, the function. It does not apply or bind additional arguments.
 *
 * Usage:
 * {{on "click" (optional @myAction)}}
 * {{on "click" (optional (if maybeTrue @myAction))}}
 * {{on "click" (fn (optional @myAction) someArgs)}}
 *
 * <MyComponent @onUpdate={{optional @doAnUpdate}} />
 */
export const optional = helper<Signature>(function ([fn]) {
  const noopFn = () => {}
  return typeof fn === 'function' ? fn : noopFn
})

export default optional
