import Service, { service } from '@ember/service'
import type { MessageOptions } from 'ember-cli-flash/services/flash-messages'
import type FlashMessageService from 'ember-cli-flash/services/flash-messages'
import type FlashObject from 'ember-cli-flash/flash/object'
import type { IntlService } from 'ember-intl'
import config from 'district-ui-client/config/environment'
import type { SafeString } from '@ember/template'
import type { IconName } from '@fortawesome/fontawesome-svg-core'

const isTest = config.environment === 'test'
const TIMEOUT = isTest ? 0 : 10000
const EXTENDED_TIMEOUT = isTest ? 0 : 500

export enum FlashType {
  Positive = 'positive',
  Critical = 'critical',
  Caution = 'caution',
  Info = 'info',
  Default = 'default',
}

interface FlashContent {
  title?: string | SafeString
  subtitle?: string | SafeString
}

interface CustomMessageInfo extends Partial<MessageOptions> {
  flashType: FlashType
  icon: IconName
  title: string | SafeString
  subtitle: string | SafeString
  preventDuplicates?: boolean
  showClose?: boolean
}

export type CustomFlashMessageObject = FlashObject & CustomMessageInfo

/**
 * This service handles the queue for flash messages for this application
 *
 * Currently using the service from ember-cli-flash, but we may want to write our own at some point
 * @see https://github.com/poteto/ember-cli-flash
 */
export default class FlashQueueService extends Service {
  @service intl!: IntlService

  @service flashMessages!: FlashMessageService

  // Default args for sticky flashes
  private get stickyArgs() {
    return {
      sticky: true,
      showClose: true,
      destroyOnClick: false,
      extendedTimeout: EXTENDED_TIMEOUT,
    }
  }

  private addUniqueMessage(options: CustomMessageInfo) {
    /* Need a dupe key for preventDuplicates to work. Currently this key is 'message', so set it to the combined text of
     * title/subtitle. Use toString() in case a htmlsafe string was given.
     */
    this.flashMessages.add({
      ...options,
      message: `${options.flashType || ''}:${options.title.toString()}${options.subtitle.toString()}`,
      preventDuplicates: true,
    })
  }

  get queue(): CustomFlashMessageObject[] {
    return this.flashMessages.queue as CustomFlashMessageObject[]
  }

  clearMessages() {
    this.flashMessages.clearMessages()
  }

  // Green theme, auto-dismiss
  addSuccess(content: FlashContent, customOptions: Partial<CustomMessageInfo> = {}) {
    return this.addPositive(content, customOptions)
  }

  addPositive({ title, subtitle }: FlashContent, customOptions: Partial<CustomMessageInfo> = {}) {
    this.addUniqueMessage({
      icon: 'circle-check',
      title: title ?? '',
      subtitle: subtitle ?? '',
      flashType: FlashType.Positive,
      sticky: false,
      showClose: true,
      destroyOnClick: true,
      extendedTimeout: EXTENDED_TIMEOUT,
      timeout: TIMEOUT,
      ...customOptions,
    })
  }

  // Red theme
  addFail(content: FlashContent, customOptions: Partial<CustomMessageInfo> = {}) {
    return this.addCritical(content, customOptions)
  }

  addCritical({ title, subtitle }: FlashContent, customOptions: Partial<CustomMessageInfo> = {}) {
    const subtitleDefault = this.intl.t('flashBox.criticalContactUsNoLink')
    this.addUniqueMessage({
      icon: 'diamond-exclamation',
      title: title ?? '',
      subtitle: subtitle ?? subtitleDefault,
      flashType: FlashType.Critical,
      ...this.stickyArgs,
      ...customOptions,
    })
  }

  // Orange theme
  addCaution({ title, subtitle }: FlashContent, customOptions: Partial<CustomMessageInfo> = {}) {
    this.addUniqueMessage({
      icon: 'triangle-exclamation',
      title: title ?? '',
      subtitle: subtitle ?? '',
      flashType: FlashType.Caution,
      ...this.stickyArgs,
      ...customOptions,
    })
  }

  // Blue theme
  addInfo({ title, subtitle }: FlashContent, customOptions: Partial<CustomMessageInfo> = {}) {
    this.addUniqueMessage({
      icon: 'circle-info',
      title: title ?? '',
      subtitle: subtitle ?? '',
      flashType: FlashType.Info,
      ...this.stickyArgs,
      ...customOptions,
    })
  }
}

declare module '@ember/service' {
  interface Registry {
    'flash-queue': FlashQueueService
  }
}
