import Route from '@ember/routing/route'

/**
 * Route which get activated when the opposite subscription type is going through the clever matching process
 */
export default class CleverIdMatchingInProgressRoute extends Route {
  model() {
    const { subscriptionType } = this.modelFor('subscription-type')
    return { subscriptionType }
  }
}
