import Service, { service } from '@ember/service'
import config from 'district-ui-client/config/environment'
import { join, joinQueryParams } from 'district-ui-client/utils/uri'

export default class EmailAllStudentsCsvService extends Service {
  @service flashQueue

  @service log

  @service router

  @service intl

  @service authToken

  buildEmailAllStudentsCSVEndpoint(districtId, product, period) {
    // RE Rails API still treats ReadingEggs as product: 'reading' and not 're'
    // This is a temporary modification till the RE Rails API is brought upto date
    let legacyProduct = product
    if (product === 're') {
      legacyProduct = 'reading'
    }
    const { start, end } = period
    const url = join(config.readingEggsV1Url, 'district_ui', 'reporting', 'students_summary')
    return joinQueryParams(url, {
      district_id: districtId,
      product: legacyProduct,
      from_date: start.toISOString(),
      to_date: end.toISOString(),
    })
  }

  async emailAllStudentsCSV(districtId, product, period) {
    const uri = this.buildEmailAllStudentsCSVEndpoint(districtId, product, period)

    const { flashQueue, log, router, intl } = this
    const contactUsUrl = router.urlFor('subscription-type.support')

    const response = await fetch(uri, {
      method: 'GET',
      headers: { Authorization: this.authToken.token, Accept: 'application/json' },
    })

    if (response.ok) {
      const json = await response.json()
      const title = json?.message ?? ''
      const subtitle = intl.t('flashBox.positiveContactUs', { contactUsUrl, htmlSafe: true })

      flashQueue.addSuccess({ title, subtitle })
    } else {
      const title = intl.t('reporting.emailCsv.errorFlash')
      const subtitle = intl.t('flashBox.criticalContactUs', { contactUsUrl, htmlSafe: true })

      flashQueue.addFail({ title, subtitle })
      log.error(title, { backendError: await response.text() })
    }
  }
}
