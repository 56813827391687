import Route from '@ember/routing/route'

export default class MsStandardsPerformanceRoute extends Route {
  queryParams = {
    sort: {
      replace: true,
    },
    pageNumber: {
      replace: true,
    },
    perPage: {
      replace: true,
    },
  }
}
