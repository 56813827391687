import { guidFor } from '@ember/object/internals'
import EmberObject from '@ember/object'
import type { LogArgument, LogResult } from 'rollbar'

export type { LogArgument, LogResult } from 'rollbar'

export type AdapterKeys =
  | 'critical'
  | 'error'
  | 'warning'
  | 'warn'
  | 'info'
  | 'debug'
  | 'log'

/**
 * An object identifying the logged-in user
 *
 * @see https://docs.rollbar.com/docs/rollbarjs-configuration-reference#payload-1
 */
export interface Person {
  id: string
  email?: string
  username?: string
}

type LogFunction = (arg: Error | string, ...args: LogArgument[]) => LogResult

export interface LogAdapter extends Record<AdapterKeys, LogFunction> {
  setPerson(person: Person): void

  willDestroy(): void

  destroy(): void
}

/**
 * Base Adapter for creating Log Adapters
 */
export default abstract class BaseAdapter
  extends EmberObject
  implements LogAdapter
{
  abstract setPerson(person: Person): void

  abstract critical(arg: Error | string, ...args: LogArgument[]): LogResult

  abstract error(arg: Error | string, ...args: LogArgument[]): LogResult

  abstract warning(arg: Error | string, ...args: LogArgument[]): LogResult

  abstract warn(arg: Error | string, ...args: LogArgument[]): LogResult

  abstract info(arg: Error | string, ...args: LogArgument[]): LogResult

  abstract debug(arg: Error | string, ...args: LogArgument[]): LogResult

  abstract log(arg: Error | string, ...args: LogArgument[]): LogResult

  override toString(): string {
    return `@blakeelearning/log@log-adapter:${guidFor(this)}`
  }
}

declare module '@blakeelearning/log' {
  interface LogAdapterRegistry {
    base: BaseAdapter
  }
}
