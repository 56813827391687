import Model, { belongsTo, attr } from '@ember-data/model'
import type CleverDistrictMatch from 'district-ui-client/models/clever/clever-district-match'
import type CleverStudent from 'district-ui-client/models/clever/clever-student'
import type GravityStudent from 'district-ui-client/models/clever/student'

/**
 * Model class for clever sisId and district id.
 *
 * @class CleverStudentMatchModel
 * @property {string} cleverId - clever id as a string
 * @property {boolean} missingGrade - boolean to indicate if the student has a missing grade
 * @property {string} sisId - unique id which relates to a clever record fo this type
 */
export default class CleverStudentMatch extends Model {
  @attr('string') cleverId!: string

  @attr('string') sisId!: string

  @attr('boolean', { defaultValue: false }) missingGrade!: boolean

  @belongsTo('clever/student', { async: false, inverse: 'cleverStudentMatch' })
  student?: GravityStudent

  @belongsTo('clever/clever-student', { async: false, inverse: 'cleverStudentMatch' })
  cleverStudent?: CleverStudent

  // Provided when creating a school match record
  @belongsTo('clever/clever-district-match', { async: false, inverse: null })
  cleverDistrictMatch?: CleverDistrictMatch
}
