import { template as template_3c383eb8b57e4605acc1820e49cda69b } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        compact?: boolean;
    };
}
/**
 * Intended for use in the reporting area to indicate there is no data.
 */ export const NoDataMessage: TOC<Signature> = template_3c383eb8b57e4605acc1820e49cda69b(`
  <div data-test-no-data-message class="text-center" ...attributes>
    <NoDataMessageImage />
    <div class="space-y-3">
      <h2 class="mt-0 text-xl font-medium" data-test-no-data-title>
        {{t "components.noDataMessage.title"}}
      </h2>
      <p class="text-base" data-test-no-data-body>
        {{#if @compact}}
          {{t "components.noDataMessage.bodyCompact"}}
        {{else}}
          {{t "components.noDataMessage.body" htmlSafe=true}}
        {{/if}}
      </p>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface ImageSignature {
    Element: SVGElement;
}
export const NoDataMessageImage: TOC<ImageSignature> = template_3c383eb8b57e4605acc1820e49cda69b(`
  {{! cloud-xmark (pro) }}
  <svg
    class="mx-auto fill-neutral-100"
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ...attributes
  >
    <path
      d="M54.1667 158.333C33.4636 158.333 16.6667 141.536 16.6667 120.833C16.6667 104.479 27.1354 90.5729 41.7188 85.4427C41.6927 84.7395 41.6667 84.0364 41.6667 83.3333C41.6667 60.3125 60.3125 41.6666 83.3334 41.6666C98.7761 41.6666 112.24 50.052 119.453 62.552C123.411 59.8958 128.203 58.3333 133.333 58.3333C147.135 58.3333 158.333 69.5312 158.333 83.3333C158.333 86.5104 157.734 89.5312 156.667 92.3437C171.875 95.4166 183.333 108.88 183.333 125C183.333 143.411 168.411 158.333 150 158.333H54.1667ZM78.9063 87.2395C76.4584 89.6875 76.4584 93.6458 78.9063 96.0677L91.1459 108.307L78.9063 120.547C76.4584 122.995 76.4584 126.953 78.9063 129.375C81.3542 131.797 85.3125 131.823 87.7344 129.375L99.974 117.135L112.214 129.375C114.661 131.823 118.62 131.823 121.042 129.375C123.464 126.927 123.49 122.969 121.042 120.547L108.802 108.307L121.042 96.0677C123.49 93.6197 123.49 89.6614 121.042 87.2395C118.594 84.8177 114.635 84.7916 112.214 87.2395L99.974 99.4791L87.7344 87.2395C85.2865 84.7916 81.3281 84.7916 78.9063 87.2395Z"
    />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default NoDataMessage;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        NoDataMessage: typeof NoDataMessage;
    }
}
