import ApplicationJSONAPIAdapter from 'district-ui-client/adapters/application'
import { service } from '@ember/service'
import config from 'district-ui-client/config/environment'
import { adapterHostAndNamespace } from 'district-ui-client/utils/uri'

const url = new URL(config.cleverV1Url)
export const { host, namespace } = adapterHostAndNamespace(url)

export default class CleverAdapter extends ApplicationJSONAPIAdapter {
  @service authToken

  host = host

  namespace = namespace

  get headers() {
    return {
      Authorization: this.authToken.token,
    }
  }
}
