import { addSeconds, formatDistance } from 'date-fns'
import { isNone } from '@ember/utils'

/*
 * Takes a number of seconds and returns moment duration's humanized format
 */

export function formattedHumanizedDuration(seconds?: number | null, replacement?: string | null): string {
  let humanizedDuration = replacement ?? ''

  if (isNone(seconds)) return humanizedDuration

  const baseDate = new Date()
  const laterDate = addSeconds(baseDate, seconds)
  humanizedDuration = formatDistance(baseDate, laterDate)

  return humanizedDuration
}
