import type { LogArgument, LogResult, Person } from '../log-adapters/base.ts'
import BaseAdapter from '../log-adapters/base.ts'

export default class ConsoleAdapter extends BaseAdapter {
  override setPerson(person: Person): void {
    console.log('setPerson', person)
  }

  override critical(arg: Error | string, ...args: LogArgument[]): LogResult {
    console.error(arg, ...args)
    return { uuid: 'critical' }
  }

  override error(arg: Error | string, ...args: LogArgument[]): LogResult {
    console.error(arg, ...args)
    return { uuid: 'error' }
  }

  override warning(arg: Error | string, ...args: LogArgument[]): LogResult {
    console.warn(arg, ...args)
    return { uuid: 'warning' }
  }

  override warn(arg: Error | string, ...args: LogArgument[]): LogResult {
    console.warn(arg, ...args)
    return { uuid: 'warn' }
  }

  override info(arg: Error | string, ...args: LogArgument[]): LogResult {
    console.info(arg, ...args)
    return { uuid: 'info' }
  }

  override debug(arg: Error | string, ...args: LogArgument[]): LogResult {
    console.debug(arg, ...args)
    return { uuid: 'debug' }
  }

  override log(arg: Error | string, ...args: LogArgument[]): LogResult {
    console.log(arg, ...args)
    return { uuid: 'log' }
  }

  override willDestroy(): void {}
}

declare module '@blakeelearning/log' {
  interface LogAdapterRegistry {
    console: ConsoleAdapter
  }
}
