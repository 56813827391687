import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'

export class MathsController extends Controller {
  queryParams = ['studentGrade', 'contentLevel']

  @tracked studentGrade = 'all'

  @tracked contentLevel = 'all'
}

export default MathsController

declare module '@ember/controller' {
  interface Registry {
    'reporting/ui-scope/maths': MathsController
  }
}
