import Model, { attr, belongsTo } from '@ember-data/model'
import type District from 'district-ui-client/models/district'
import { SubscriptionType } from 'district-ui-client/domain/subscription-type'

// order of preference of subscription-types
const subscriptionOrder = [SubscriptionType.Reading, SubscriptionType.Maths]

const blakeLocaleCountryToLanguageMap: Record<string, string> = {
  au: 'en-AU',
  ca: 'en-CA',
  cn: 'zh',
  in: 'en-GB',
  int: 'en',
  kr: 'ko',
  nz: 'en-NZ',
  th: 'th',
  uk: 'en-GB',
  gb: 'en-GB',
  us: 'en-US',
  za: 'en-ZA',
}

// copied from frontend monorepo site-copy addon
function languageFromBlakeData(blakeCountryCode?: string) {
  const countryCode = blakeCountryCode?.toLowerCase()

  const languageKey = countryCode || 'int'

  return blakeLocaleCountryToLanguageMap[languageKey] ?? 'en'
}

// Not an exhaustive list.
export const countryCodeNames: Record<string, string> = {
  AU: 'Australia',
  US: 'United States',
  CA: 'Canada',
  NZ: 'New Zealand',
  ZA: 'South Africa',
  GB: 'United Kingdom',
}

export default class DistrictCoordinator extends Model {
  @attr('string') login!: string

  @attr('string') firstName!: string

  @attr('string') lastName!: string

  @attr('string') email!: string

  /**
   * AU, GB, US, ... ISO_3166-1 alpha-2 codes https://en.wikipedia.org/wiki/ISO_3166-1
   */
  @attr('string') countryCode!: string

  /**
   * An array of the subscription types this disco is allowed to manage
   */
  @attr() private products!: SubscriptionType[]

  @belongsTo('district', { async: false, inverse: null }) district!: District

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get language() {
    return languageFromBlakeData(this.countryCode)
  }

  /**
   * Display name for a country code. May not have them all.
   */
  get countryName(): string | undefined {
    return countryCodeNames[this.countryCode]
  }

  get subscriptionTypes() {
    return [...this.products].sort((a, b) => subscriptionOrder.indexOf(a) - subscriptionOrder.indexOf(b))
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'district-coordinator': DistrictCoordinator
  }
}
