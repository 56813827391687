import Service from '@ember/service'
import config from 'district-ui-client/config/environment'

export default class LogoutService extends Service {
  logoutAndRedirect = () => {
    const url = config.loginUrl.replace('login', 'logout')
    window.location.href = url
  }
}

declare module '@ember/service' {
  interface Registry {
    logout: LogoutService
  }
}
