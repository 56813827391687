import Service, { inject as service } from '@ember/service'
import { set } from '@ember/object'
import { getOwner } from '@ember/application'
import type RouterService from '@ember/routing/router-service'
import { getConfig } from '@blakeelearning/get-config'
import type NetworkService from '@blakeelearning/device/device/network/service'
import ApplicationInstance from '@ember/application/instance'
import Route from '@ember/routing/route'

export type SafetyCheckerCallback = (
  toRoute: string,
  fromRoute?: string,
) => boolean

type Refreshable = Route & {
  isRefreshable(): boolean
}

/**
 * Manages tracking if refreshes are necessary, and actually performing them
 * if so.
 *
 * We log stack traces at the time a refresh is made to see where it happened,
 * and, if it was a scheduled refresh we store and then log stack traces of the
 * times a refresh was scheduled.
 */
export default class Refresher extends Service {
  @service() declare router: RouterService

  @service('device/network') declare network: NetworkService

  _needsRefresh = false

  _scheduledStacks: unknown[] = []

  _safetyCheckers: SafetyCheckerCallback[] = []

  constructor(properties?: Record<string, unknown>) {
    super(properties)

    this._resetSafetyCheckers()
  }

  /**
   * Call this to schedule a refresh the next time one is safe to perform
   */
  scheduleRefresh(): void {
    set(this, '_needsRefresh', true)
    this._storeScheduleStack(this._currentStack())
  }

  /**
   * Call this when it is safe to perform an app refresh. It will check if one
   * is necessary and will perform it if so.
   *
   * @param [location=window.location] - optional location object to use when redirecting, useful for testing
   * @returns true if a refresh will be occurring
   */
  refreshIfScheduled(location = window.location): boolean {
    const needsRefresh = this._needsRefresh
    if (needsRefresh) {
      this._logScheduledRefresh()
      this._refresh(location)
    }
    return needsRefresh
  }

  /**
   * Call this to add a safety checker function to the _safetyCheckers list.
   * Callback should return false if transition should not trigger a refresh
   *
   * @param callback the function to add to _safetyCheckers list
   */
  addSafetyChecker(callback: SafetyCheckerCallback): void {
    this._safetyCheckers.push(callback)
  }

  /**
   * Call this to determine if a refresh is safe to perform on the current route
   * 1. if all registered safetyCheckers have determined the route is safe
   * 2. if a safelist is defined, it will check that the route is on there
   * 3. if no list or the route is on the list, then defer to the individual routes' isRefreshable() function
   *
   * The safelist has been implemented as a safetyChecker in _isRouteSafelisted(),
   * and registered in the constructor() as a default for all applications to preserve existing behaviour
   *
   * The route specific check has been implemented in _isRefreshPermittedByRoute(),
   * and registered in the constructor() as a default for all applications to preserve existing behaviour
   *
   * @param currentRouteName the current route name, like some.route.here
   * @param fromRouteName optional previous route name
   * @returns true if refresh is allowed on the current route, or false if not
   */
  _isRefreshSafe(currentRouteName: string, fromRouteName?: string): boolean {
    const allSafetyChecksPassed = this._safetyCheckers.every((checker) =>
      checker(currentRouteName, fromRouteName),
    )

    return allSafetyChecksPassed
  }

  /**
   * Call this function to check if a refresh is safe to perform on the current route, if so, it will perform a refresh
   * if one is scheduled.
   *
   * @param currentRouteName the current route name, like some.route.here
   * @param [fromRouteName] optional previous route name
   * @param [location=window.location] - optional location object to use when redirecting, useful for testing
   * @returns true if a refresh will be occurring
   */
  refreshIfSafeAndScheduled(
    currentRouteName: string,
    fromRouteName?: string,
    location = window.location,
  ): boolean {
    const isRefreshSafe = this._isRefreshSafe(currentRouteName, fromRouteName)
    return isRefreshSafe ? this.refreshIfScheduled(location) : false
  }

  /**
   * Trigger a refresh immediately, regardless of if it's a safe spot or not.
   * @param [location=window.location] - optional location object to use when redirecting, useful for testing
   */
  hardRefresh(location = window.location): void {
    this._logHardRefresh()
    this._refresh(location)
  }

  get refresherSafelist(): string[] | undefined {
    try {
      return getConfig(this, 'appRefresher.safelistedRoutes')
    } catch {
      return undefined
    }
  }

  _refresh(location: Location): void {
    const { isOnline } = this.network.status
    // We should never refresh while offline
    if (isOnline) {
      location.reload()
    }
  }

  _currentStack(): string | undefined {
    return new Error().stack
  }

  _storeScheduleStack(stack: unknown): void {
    this._scheduledStacks.push(stack)
  }

  /**
   * A route is considered safelisted for refresh if:
   * 1. there is a safelist and the route is on it
   *
   * @returns true if route is safelisted, and false if it is not
   */
  _isRouteSafelisted(fromRouteName: string): boolean {
    const isSafelistedRoute = this.refresherSafelist?.includes(fromRouteName)
    return isSafelistedRoute ?? false
  }

  /**
   * A route is determined safe to refresh if:
   * 1. the route is not defined
   * 2. the app has not implemented isRefreshable() function on route
   * 3. the app has explicitly marked the route as refreshable in it's own isRefreshable() function
   *
   * @returns true if route is undefined, route.isRefreshable() is not defined, or route isRefreshable() is true
   * and false if no conditions match
   */
  _isRefreshPermittedByRoute(fromRouteName: string): boolean {
    const owner = getOwner(this)
    let route

    if (owner instanceof ApplicationInstance) {
      route = owner.lookup(`route:${fromRouteName}`)
    }

    if (
      route instanceof Route &&
      typeof (route as Refreshable).isRefreshable === 'function'
    ) {
      return (route as Refreshable).isRefreshable()
    }

    return true
  }

  /**
   * 1. Clears all safetyCheckers
   * 2. Adds default safetyCheckers
   */
  _resetSafetyCheckers(): void {
    this._safetyCheckers = []

    // no safelist means everything's valid, don't add safetyChecker
    if (this.refresherSafelist) {
      this.addSafetyChecker(this._isRouteSafelisted.bind(this))
    }

    this.addSafetyChecker(this._isRefreshPermittedByRoute.bind(this))
  }

  _logStack(stack: unknown): void {
    console.log(stack)
  }

  _logScheduledRefresh(): void {
    console.log('Refreshing because of a scheduled refresh.')
    this._logStack(this._currentStack())

    console.log('Stacks at the time of scheduling.')
    this._scheduledStacks.forEach((stack, index) => {
      console.log(`Stack ${index.toFixed()}:`)
      this._logStack(stack)
    })
  }

  _logScheduledLocationAssignment(url: string): void {
    console.log(`Changing location to ${url} as part of a scheduled refresh.`)
    this._logStack(this._currentStack())

    console.log('Stacks at the time of scheduling.')
    this._scheduledStacks.forEach((stack, index) => {
      console.log(`Stack ${index.toFixed()}:`)
      this._logStack(stack)
    })
  }

  _logHardRefresh(): void {
    console.log('Refreshing because of a hard refresh.')
    this._logStack(this._currentStack())
  }
}

declare module '@ember/service' {
  interface Registry {
    refresher: Refresher
  }
}
