import { get } from '@ember/object'
import colours from '@blakeelearning/blake-colours/colours'

const configName = Object.keys(window.requirejs.entries).filter((entry) => {
  return entry.match(/\/config\/environment$/)
})[0]

const config = window.require(configName).default

const defaultColour = colours.oceanyBlue300
const stadiumEventsColoursObject = {
  spelling: colours.oceanyBlue300,
  grammar: colours.oceanyBlue400,
  vocabulary: colours.juicyOrange300,
  usage: colours.purplyPink300,
}

const stadiumEventsColours = ['spelling', 'grammar', 'vocabulary', 'usage'].map(
  (colourKey) => stadiumEventsColoursObject[colourKey],
)

const colourMap = {
  fastPhonics: {
    totalUsageOverTime: {
      lessons: colours.oceanyBlue300,
      quizzes: colours.juicyOrange300,
      words: colours.stormBlue300,
      sounds: colours.purplyPink300,
      books: colours.grapeyGreen300,
    },
  },
  maths: {
    default: colours.msGreen300,
    lessons: colours.forestGreen300,
    workingAtGradeLevel: {
      below: colours.msGreen150,
      at: colours.msGreen300,
      above: colours.msGreen400,
    },
    grade: [colours.msGreen400, colours.msGreen300, colours.msGreen150],
    totalUsageOverTime: {
      lessons: colours.forestGreen300,
      assessments: colours.juicyOrange300,
      drivingTests: colours.oceanyBlue300,
      quizzes: colours.grapeyGreen300,
      mentalMinute: colours.midOrange300,
    },
    quizSeries: [colours.msGreen300, colours.yolkyYellow300, colours.watermelonyRed300],
    quizSingle: [colours.juicyOrange300],
    drivingTests: {
      number: colours.oceanyBlue300,
      operations: colours.forestGreen300,
      patterns: colours.purplyPink300,
      geometry: colours.yolkyYellow300,
      data: colours.watermelonyRed300,
      measurement: colours.stormBlue300,
    },
    drivingTestsPercentage: {
      notCompleted: colours.oceanyBlue300,
      completed: colours.oceanyBlue100,
    },
    drivingTestsCategories: [
      colours.oceanyBlue300,
      colours.forestGreen300,
      colours.purplyPink300,
      colours.yolkyYellow300,
      colours.watermelonyRed300,
      colours.oceanyBlue500,
    ],
    number: [colours.oceanyBlue300],
    operations: [colours.forestGreen300],
    patterns: [colours.purplyPink300],
    geometry: [colours.yolkyYellow300],
    data: [colours.watermelonyRed300],
    measurement: [colours.stormBlue500],
    certificatesEarned: [colours.medalBronze, colours.medalSilver, colours.medalGold],
    stadiumEvents: stadiumEventsColours,
  },
  // leaving old reading obj in here as protection
  reading: {
    default: [colours.oceanyBlue300],
    spelling: [colours.grapeyGreen300],
    bookLevelDistribution: [colours.purplyPink300],
    lexileGrade: [colours.oceanyBlue300],
    grade: [colours.oceanyBlue500, colours.oceanyBlue300, colours.oceanyBlue100],
  },
  re: {
    booksReadByGenrePie: {
      nonfiction: colours.purplyPink300,
      fiction: colours.purplyPink400,
    },
    reading: colours.oceanyBlue300,
    lessons: colours.oceanyBlue300,
    spelling: colours.grapeyGreen300,
    lexileGrade: colours.oceanyBlue300,
    storiesWritten: {
      byGrade: colours.oceanyBlue300,
      history: colours.oceanyBlue300,
    },
    storylandsLessons: {
      averages: colours.juicyOrange300,
      history: colours.juicyOrange300,
    },
    totalUsageOverTime: {
      lessons: colours.oceanyBlue300,
      assessments: colours.juicyOrange300,
      drivingTests: colours.stormBlue300,
      books: colours.purplyPink300,
      spelling: colours.grapeyGreen300,
    },
    workingAtGradeLevel: {
      below: colours.oceanyBlue100,
      at: colours.oceanyBlue300,
      above: colours.oceanyBlue400,
    },
    drivingTests: {
      sight_words: colours.oceanyBlue300,
      letters_and_sounds: colours.forestGreen300,
      content_words: colours.purplyPink300,
    },
    drivingTestsPercentage: {
      notCompleted: colours.oceanyBlue300,
      completed: colours.oceanyBlue100,
    },
    booksRead: {
      library: colours.purplyPink300,
      my_program: colours.juicyOrange300,
    },
  },
  rex: {
    default: colours.oceanyBlue300,
    quizSeries: [colours.grapeyGreen300, colours.yolkyYellow300, colours.watermelonyRed300],
    quizSeriesResults: colours.stormBlue300,
    quizScores: [colours.watermelonyRed300, colours.juicyOrange300, colours.msGreen300],
    bookLevelDistribution: colours.purplyPink300,
    booksReadByGenrePie: {
      nonfiction: colours.purplyPink300,
      fiction: colours.purplyPink400,
    },
    lexileGrade: colours.oceanyBlue300,
    grade: [colours.oceanyBlue500, colours.oceanyBlue300, colours.oceanyBlue100],
    stadiumEvents: stadiumEventsColours,
    totalUsageOverTime: {
      lessons: colours.oceanyBlue300,
      quizzes: colours.stormBlue300,
      assessments: colours.juicyOrange300,
      books: colours.purplyPink300,
      spelling: colours.grapeyGreen300,
    },
    lessons: colours.oceanyBlue300,
    quizzes: colours.stormBlue300,
    assessments: colours.juicyOrange300,
    books: colours.purplyPink300,
    spelling: colours.grapeyGreen300,
    my_lessons: colours.oceanyBlue300,
    reading: colours.oceanyBlue300,
    booksRead: {
      CompleteBook: colours.juicyOrange300,
      CompleteBookChapterQuiz: colours.purplyPink300,
    },
    workingAtGradeLevel: {
      below: colours.oceanyBlue100,
      at: colours.oceanyBlue300,
      above: colours.oceanyBlue400,
    },
  },
}

/**
 * Look up a colour by a dot-separated key.
 *
 * @example
 * getColour('rex.stadiumEventsTotals.spelling')
 *
 * @param  {String} key The key to look up
 * @return {String} The colour for the key
 */
export function getColour(key) {
  const colour = get(colourMap, key)
  if (colour) return colour

  if (config.environment === 'development') {
    throw new Error(`No colour defined for key: ${key}`)
  } else {
    return defaultColour
  }
}

export default function colourPicker(product, type) {
  return colourMap[product][type] || colourMap[product].default
}
