import { modifier } from 'ember-modifier'

function getColspanFromTheadRow(theadRow: Element) {
  return Array.from(theadRow.getElementsByTagName('th')).reduce((span, th) => {
    return span + Number.parseInt(th.getAttribute('colspan') ?? '1', 10)
  }, 0)
}

interface Signature {
  Args: {
    Named: Record<PropertyKey, unknown>
    Positional: unknown[]
  }
  Element: HTMLTableCellElement
}

/**
 * Applies a colspan to a td or th cell that represents the span of the table. The span to use is obtained from the
 * last row of the thead.
 *
 * Useful for anything in a table that we want to always span the width of the table. This includes; footers, empty
 * messages, loading spinners, expand & edit rows. Also helpful in situations where the number of table columns change
 * (for example - feature flags, subco vs teacher often have different cols available)
 *
 * Note that using a massive value like colspan="99" only works in some situations, and does not work well in print.
 *
 * Limitations:
 * - relies on there being a thead row available with a number of th cells
 * - this does not watch when colspan values themselves change; only when new th cells are added or removed to the row
 * - can't apply this modifier to a th cell in the last thead row
 * - if there are th cells with "hidden" they will still be included in the span calculation
 */
export const colspanMax = modifier<Signature>(function colspanMax(element, _positional, _named) {
  const tableElement = element.parentElement?.parentElement?.parentElement

  // Use the last thead row, as it will typically be the largest number of th cells.
  const lastTheadRow = tableElement?.querySelector('thead tr:last-of-type')
  if (!lastTheadRow) return

  const lastTheadRowColspan = getColspanFromTheadRow(lastTheadRow)

  element.setAttribute('colspan', `${lastTheadRowColspan}`)

  // now observe for changes to that head row

  const observer = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      if ((mutation.removedNodes || mutation.addedNodes) && lastTheadRow) {
        // apply the updated colspan to the cell element
        element.setAttribute('colspan', `${getColspanFromTheadRow(lastTheadRow)}`)
      }
    })
  })

  observer.observe(lastTheadRow, { childList: true })
  return () => {
    observer.disconnect()
  }
})

export default colspanMax
