import MatchRowComponent from 'district-ui-client/components/clever-ui/match-table-row/component'
import { service } from '@ember/service'
import { isPresent } from '@ember/utils'

export default class MatchTeachersTableRowComponent extends MatchRowComponent {
  @service clever

  @service intl

  userType = 'teacher'

  get searchFields() {
    return ['text', 'email']
  }

  get allOptions() {
    return [...this.actionOptions, ...this.args.blakeUserOptions]
  }

  get actionOptions() {
    const cleverTeacherEmail = this.cleverRecord.email

    // check the if the clever users email exists in the blake user list
    // if true we dont want to create another user
    const { blakeUserOptions } = this.args
    const emailExits = blakeUserOptions.filter(({ email }) => email === cleverTeacherEmail) || []
    if (isPresent(emailExits)) return []

    const { intl } = this
    const createNewTeacherText = intl.t('clever.createNewTeacherText')
    const createTeacherOption = { text: createNewTeacherText, value: 'create' }
    return [createTeacherOption]
  }
}
