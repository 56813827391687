import { template as template_9935caf6597447fcbe65f79d16f62edf } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import ModalDialog from 'ember-modal-dialog/components/modal-dialog';
interface Signature {
    Args: {
        close: () => void;
        containerClass?: string;
    };
    Blocks: {
        default: [];
    };
}
export const BaseModal: TOC<Signature> = template_9935caf6597447fcbe65f79d16f62edf(`
  <ModalDialog
    @onClose={{@close}}
    @translucentOverlay={{false}}
    @overlayClass="z-modal bg-modal-overlay"
    @containerClass="p-0 overflow-hidden z-auto {{@containerClass}} min-w-[32rem] max-w-[48rem] rounded-2xl shadow-modal"
  >
    {{yield}}
  </ModalDialog>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BaseModal;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        BaseModal: typeof BaseModal;
    }
}
