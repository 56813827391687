import {
  buildStudentsCsvApiValidateResultUrl,
  buildStudentsCsvApiSubmitResultUrl,
} from 'district-ui-client/student-import/urls'
import { Promise } from 'rsvp'
import toPairs from 'lodash/toPairs'
import { get } from '@ember/object'
import { isPresent } from '@ember/utils'
import { dasherize } from '@ember/string'
import { A, isArray } from '@ember/array'
import ErrorCodes from 'district-ui-client/student-import/error-codes'
import { format } from 'date-fns'

const { JOB_FAILED, CSV_INVALID } = ErrorCodes

const Utils = {
  /**
   * If the student import task is not running, mark it as complete.
   * @param {StudentImportTaskInstance} taskInstance
   */
  markAsCompleteIfNotRunning(taskInstance) {
    if (taskInstance?.isRunning === false) {
      taskInstance.markAsComplete()
    }
  },
  /**
   * Returns true if the task instance hasn't been cancelled or explicitly
   * marked as complete.
   * @returns {Boolean}
   */
  isTaskInstanceActive(taskInstance) {
    const isCanceled = taskInstance?.isCanceled
    const isMarkedComplete = taskInstance?.isMarkedComplete
    const isActive = taskInstance && !isCanceled && !isMarkedComplete
    return isActive ? taskInstance : undefined
  },

  /**
   * Build the function that will poll gravity for the status of the csv
   * validation job.
   */
  buildValidateCsvPollFn(store, { districtId, jobId, subscriptionType }) {
    const jobModelName = 'import-validation'
    const fn = async () =>
      store.findRecord(jobModelName, jobId, { reload: true, adapterOptions: { districtId, subscriptionType } })
    return fn
  },

  /**
   * Build the function that will request gravity for the result of the csv
   * validation job.
   */
  buildValidateCsvResultFn(headers, { districtId, jobId, subscriptionType }) {
    const validateResultUrl = buildStudentsCsvApiValidateResultUrl({ districtId, jobId, subscriptionType })
    const fn = async () =>
      fetch(validateResultUrl, {
        method: 'GET',
        headers,
      })
    return fn
  },

  /**
   * Build the function that will poll gravity for the status of the csv
   * submission job.
   */
  buildSubmitCsvPollFn(store, { districtId, jobId, subscriptionType }) {
    const jobModelName = 'import-schedule'
    const fn = async () =>
      store.findRecord(jobModelName, jobId, { reload: true, adapterOptions: { districtId, subscriptionType } })
    return fn
  },

  /**
   * Build the function that will request gravity for the result of the csv
   * submission job.
   */
  buildSubmitCsvResultFn(headers, { districtId, jobId, subscriptionType }) {
    const submitResultUrl = buildStudentsCsvApiSubmitResultUrl({ districtId, jobId, subscriptionType })
    const fn = async () =>
      fetch(submitResultUrl, {
        method: 'GET',
        headers,
      })
    return fn
  },

  /**
   * Converts the validate csv endpoint's response from a JSONAPI doc to an array of POJO objects
   * representing students (and their class and teacher) that will be created should the import be
   * confirmed.
   * @param {Object} payload
   * @returns {Array<Object>}
   */
  normalizeValidateCsvResponse(payload) {
    const { data: students } = payload

    const pojoStudents = students.map((student) => {
      const { attributes } = student
      const csvRow = parseInt(attributes['csv-row'], 10)
      const pojoStudent = {
        firstName: attributes['first-name'],
        lastName: attributes['last-name'],
        gradePosition: attributes['grade-position'],
        externalId: attributes['external-id'],
        csvRow,
        duplicateSource: attributes['duplicate-source'] || false,
        duplicateDestination: attributes['duplicate-destination'] || false,
        teacherEmail: attributes['teacher-email'],
        schoolClassName: attributes['school-class-name'],
        schoolCode: attributes['school-code'],
      }

      return pojoStudent
    })
    return A(pojoStudents).sortBy('csvRow')
  },

  /**
   * @returns {String}
   */
  generateImportTimestamp(intl) {
    return format(new Date(), intl.t('dateFormats.dateFns.d-MMM-yy HH:mm'))
  },

  getJobIdFromResponseBody(responseBody) {
    return responseBody?.data?.id ?? null
  },

  handleCsvValidationJobFailed() {
    return {
      ok: false,
      valid: false,
      code: JOB_FAILED,
    }
  },

  async handleCsvValidationJobComplete({ districtId, jobId, subscriptionType, timestamp }, headers) {
    const validateResultUrl = buildStudentsCsvApiValidateResultUrl({ districtId, jobId, subscriptionType })
    const response = await fetch(validateResultUrl, {
      method: 'GET',
      headers,
    })
    const responseBody = await response.json()

    if (response?.ok) {
      return Utils.handleCsvValid({ subscriptionType, responseBody, timestamp })
    } else if (response?.status === 422) {
      return Utils.handleCsvInvalid({ responseBody })
    } else {
      return Utils.handleCsvValidationJobFailed({ response, responseBody })
    }
  },

  handleCsvValid({ subscriptionType, responseBody, timestamp }) {
    return {
      ok: true,
      valid: true,
      subscriptionType,
      candidateStudents: Utils.normalizeValidateCsvResponse(responseBody),
      timestamp,
    }
  },

  handleCsvInvalid({ responseBody }) {
    return {
      ok: false,
      code: CSV_INVALID,
      isValid: false,
      validationErrors: responseBody?.errors || [],
    }
  },

  /**
   * Use this to upload a csv file to a url with the necessary headers
   */
  async uploadFileWithProgress(file, url, headers, formDataKeyValue, updateProgressFn) {
    const formData = new FormData()
    formData.append('file', file, file.name)
    toPairs(formDataKeyValue).forEach(([name, value]) => formData.append(name, value))

    return new Promise(function (resolve, _reject) {
      const DONE = 4
      const xhr = new XMLHttpRequest()
      const okStatusCodes = [200, 202]

      xhr.upload.addEventListener(
        'progress',
        (progressEvent) => {
          const { loaded, total } = progressEvent
          let percentComplete = 0
          if (loaded && total) {
            percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          }
          updateProgressFn(percentComplete)
        },
        false,
      )

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === DONE) {
          const { status } = this
          const ok = okStatusCodes.includes(status)
          // match the fetch api
          resolve({
            ok,
            status,
            json: async () => JSON.parse(this.responseText || '{}'),
          })
        }
      })

      xhr.open('POST', url, true)
      toPairs(headers).forEach(([name, value]) => xhr.setRequestHeader(name, value))
      xhr.send(formData)
    })
  },

  /**
   * Returns Headers which can be used for the student imports requests
   * @param {String} token
   */
  requestHeaders(token) {
    return {
      Authorization: token,
      Accept: ['application/vnd.api+json'].join(','),
    }
  },

  formatValidationErrors(responseBody, { intl }) {
    const errors = responseBody?.errors
    if (isArray(errors)) {
      return errors.map((error) => {
        return Utils.mapJsonApiErrorToRowError(error, responseBody, intl)
      })
    }
    return []
  },

  /**
   * @param {String} sourcePointer e.g. '/data/1/attributes/teacher-email'
   * @returns {Object} containing attr, value and label properties.
   * @example calling
   * getAttributeForPointer('/data/1/attributes/teacher-email', response.body, intl)
   * // would return
   * {
   *   attr: 'teacher-email',
   *   value: 'x@yields.com',
   *   label: 'Teacher email',
   * }
   */
  getAttributeForPointer(sourcePointer, responseBody, intl) {
    const sourceSegments = sourcePointer.split('/').filter(isPresent)
    const normalizedSourceSegments = sourceSegments.map(dasherize)
    const sourcePath = normalizedSourceSegments.join('.')
    const value = get(responseBody, sourcePath)
    const attr = normalizedSourceSegments.pop()
    // we might get back an attribute with a row number as the key
    // e.g. /attribute/4
    // rename the attribute to the candidate student attribute 'csv-row'
    const attrAsNumber = Number.parseInt(attr, 10)
    if (Number.isInteger(attrAsNumber))
      return {
        attr: 'csv-row',
        value: attrAsNumber,
        label: intl.t('manage.studentCsv.attribute.csv-row'),
      }
    const label = intl.t(`manage.studentCsv.attribute.${attr}`)
    return {
      attr,
      value,
      label,
    }
  },

  /**
   * Maps a JSONAPI error to a csv row error that the template can display as a row in the error table.
   * - Uses the "error.code" argument to find a translation under "manage.studentCsv.errors.*"
   * - Uses the "source.pointer" argument to find the csv column name.
   * - Falls back to the "manage.studentCsv.defaultRowError" translation.
   * - Passes "error.attr" and "error.value" arguments to the translation as "attr" and "value"
   *   in case they are needed to explain the error.
   * @param {Object} error the JSONAPI error object
   * @param {Object} responseBody the body of the 422 response from gravity
   * @param {IntlService} intl
   * @returns {Object}
   */
  mapJsonApiErrorToRowError(error, responseBody, intl) {
    error.csvRow = Utils.extractCsvRow(error)
    const sourcePointer = error.source?.pointer
    if (sourcePointer) {
      error.attribute = Utils.getAttributeForPointer(sourcePointer, responseBody, intl)
    }

    if (error.code === 'malformed_row' && error.detail) {
      // for malformed_row, gravity provides a better explanation
      // than the FE can provide, so hand the error over for display.
      return error
    }
    if (error.code) {
      if (intl.exists(`manage.studentCsv.errors.${error.code}`)) {
        error.detail = intl.t(`manage.studentCsv.errors.${error.code}`, {
          htmlSafe: true,
          attr: error.attribute?.label ?? intl.t('manage.studentCsv.errors.defaultAttributeName'),
          value: error.attribute?.value ?? error.meta?.value ?? '',
        })
      } else {
        error.detail = intl.t('manage.studentCsv.errors.default', {
          htmlSafe: true,
          attr: error.attribute?.label ?? intl.t('manage.studentCsv.errors.defaultAttributeName'),
          value: error.attribute?.value ?? error.meta?.value ?? '',
        })
      }
    }
    return error
  },

  /**
   * @param {Object} error
   * @returns {Number} the csv row starting at 1
   */
  extractCsvRow(error) {
    return error.meta?.csv_row
  },
}

export default Utils

export const { markAsCompleteIfNotRunning } = Utils
export const { isTaskInstanceActive } = Utils
export const { normalizeValidateCsvResponse } = Utils
export const { nextPollDelay } = Utils
export const { generateImportTimestamp } = Utils
export const { getJobIdFromResponseBody } = Utils
export const { handleCsvValidationJobFailed } = Utils
export const { handleCsvValidationJobComplete } = Utils
export const { handleCsvValid } = Utils
export const { handleCsvInvalid } = Utils
export const { uploadFileWithProgress } = Utils
export const { requestHeaders } = Utils
export const { pollJobUntilComplete } = Utils
export const { buildValidateCsvPollFn } = Utils
export const { buildValidateCsvResultFn } = Utils
export const { buildSubmitCsvPollFn } = Utils
export const { formatValidationErrors } = Utils
