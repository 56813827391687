import Controller from '@ember/controller'
import { action } from '@ember/object'
import { isEmpty } from '@ember/utils'
import { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import uniqBy from 'lodash/uniqBy'
import { task, didCancel } from 'ember-concurrency'

export default class NewController extends Controller {
  @service
  studentOperations

  @service
  alert

  @service
  store

  @service
  router

  @service
  activeRoute

  @service intl

  @service log

  @service gradeSets

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get school() {
    return this.model.school
  }

  get schoolClasses() {
    return this.model.schoolClasses
  }

  @tracked firstName = ''

  @tracked lastName = ''

  @tracked teacherId = ''

  @tracked gradePosition = ''

  // this generates a list of teachers from all the product school clases for the school
  get teachersForSchool() {
    const teachersFromSchoolClasses = this.schoolClasses.flatMap((schoolClass) => schoolClass.teachers)
    return uniqBy(teachersFromSchoolClasses, 'id')
  }

  get studentExists() {
    return (
      this.school.students.filter((student) => {
        const studentName = student.fullName.toLowerCase()
        const searchingFor = `${this.firstName} ${this.lastName}`.toLowerCase()
        return !student.isNew && studentName === searchingFor
      }).length > 0
    )
  }

  get disableSubmit() {
    return isEmpty(this.firstName) || isEmpty(this.lastName) || isEmpty(this.teacherId) || isEmpty(this.gradePosition)
  }

  @action
  cancel() {
    this._clearNewStudentFields()
    this._backToStudents(this.school.id)
  }

  @action
  setGradePosition(gradePosition) {
    const gradePos = Number.parseInt(gradePosition, 10)
    this.gradePosition = Number.isNaN(gradePos) ? '' : gradePos
  }

  @action
  setTeacherId(teacherId) {
    const foundTeacher = this.teachersForSchool.find((teacher) => teacher.id === teacherId)
    this.teacherId = foundTeacher ? teacherId : ''
  }

  createStudentTask = task({ drop: true }, async (newStudent) => {
    await newStudent.save()
  })

  @action
  async createStudent(event) {
    event.preventDefault()

    // just use teachers' first class for this subtype for now
    const teacher = this.teachersForSchool.find((teacher) => teacher.id === this.teacherId)
    const firstClass = teacher?.schoolClasses.find(
      (schoolClass) => schoolClass.subscriptionType === this.subscriptionType,
    )
    const schoolClasses = firstClass ? [firstClass] : []

    const newStudent = this.store.createRecord('student', {
      firstName: this.firstName,
      lastName: this.lastName,
      gradePosition: this.gradePosition,
      school: this.school,
      schoolClasses,
    })

    try {
      await this.createStudentTask.perform(newStudent)

      this._clearNewStudentFields()
      this._backToStudents(this.school.id)
      this.alert.show({ type: 'positive', message: 'Successfully created a student' })
    } catch (e) {
      newStudent.rollbackAttributes()
      if (didCancel(e)) return
      this.log.error('failed to create student', e)
      this.alert.showWithDismiss({
        type: 'critical',
        message: this.intl.t('errorMessages.createStudent'),
      })
    }
  }

  _clearNewStudentFields() {
    this.firstName = ''
    this.lastName = ''
    this.teacherId = ''
    this.gradePosition = ''
  }

  _backToStudents(schoolId) {
    this.router.transitionTo('subscription-type.manage.schools.students.index', schoolId)
  }
}
