import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'
import { action } from '@ember/object'
import {
  serialize as serializeSort,
  deserialize as deserializeSort,
  updateSortColumns,
} from '@blakeelearning/data-tables/utils/sorting'

export default class CleverMissingFieldsController extends Controller {
  @service clever

  @service fileDownload

  queryParams = ['sortTeacher', 'sortStudent']

  @tracked
  sortTeacher = ''

  @tracked
  sortStudent = ''

  get sortingConfigTeacher() {
    return deserializeSort(this.sortTeacher)
  }

  set sortingConfigTeacher(newSortConfig) {
    this.sortTeacher = serializeSort(newSortConfig)
  }

  get sortingConfigStudent() {
    return deserializeSort(this.sortStudent)
  }

  set sortingConfigStudent(newSortConfig) {
    this.sortStudent = serializeSort(newSortConfig)
  }

  @action
  updateSorts(sortTable, { sortKey }) {
    if (sortKey) {
      const isTeacherSort = sortTable === 'teacher'
      if (isTeacherSort) {
        const newSortConfig = updateSortColumns(this.sortingConfigTeacher, sortKey)
        this.sortingConfigTeacher = newSortConfig
      } else {
        const newSortConfig = updateSortColumns(this.sortingConfigStudent, sortKey)
        this.sortingConfigStudent = newSortConfig
      }
    }
  }

  get blakeTeachers() {
    return this.model.teacherMatches.map((match) => match.teacher)
  }

  get blakeStudents() {
    return this.model.studentMatches.map((match) => match.student)
  }

  @action
  downloadStudents() {
    const csvData = this.clever.blakeStudentsToCSVArray(this.blakeStudents, true)
    this.fileDownload.downloadAsCsv(csvData)
  }

  @action
  downloadTeachers() {
    const csvData = this.clever.blakeTeachersToCSVArray(this.blakeTeachers, true)
    this.fileDownload.downloadAsCsv(csvData)
  }
}
