import Controller from '@ember/controller'
import { action } from '@ember/object'
import { service } from '@ember/service'
import config from 'district-ui-client/config/environment'
import { didCancel } from 'ember-concurrency'
import { isTaskInstanceActive as isActive } from 'district-ui-client/student-import/utils'

const {
  csvImport: { redirectOnSuccessTimeout },
} = config

export default class ImportStudentsAsyncController extends Controller {
  @service activeRoute

  @service intl

  @service studentImport

  @service flashQueue

  @service router

  @service fileDownload

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get activeImportTaskInstance() {
    const taskInstance = this.studentImport.getLastForSubscriptionType(this.subscriptionType)
    return isActive(taskInstance) || null
  }

  get manageStudentsUrl() {
    return this.router.urlFor('subscription-type.manage.students', this.subscriptionType)
  }

  _showSuccessAlert() {
    const timeout = redirectOnSuccessTimeout
    const url = this.manageStudentsUrl
    const message = this.intl.t('manage.studentCsv.importSuccessful', { url, htmlSafe: true })

    // show alert until timeout or the flash is clicked
    this.flashQueue.addSuccess({ subtitle: message }, { timeout })
  }

  /**
   * Generates a sample student Import CSV array
   *
   * @return {string[][]} data array for csv
   */
  generateStudentSampleImportData() {
    const fieldPrefix = 'manage.students.sampleCsv.fields'

    const row = [
      this.intl.t(`${fieldPrefix}.firstName`),
      this.intl.t(`${fieldPrefix}.lastName`),
      this.intl.t(`${fieldPrefix}.grade`),
      this.intl.t(`${fieldPrefix}.teacherEmail`),
      this.intl.t(`${fieldPrefix}.schoolCode`),
      this.intl.t(`${fieldPrefix}.externalId`),
    ]

    return Array.from({ length: 4 }, () => row)
  }

  /**
   * @param {File} file
   */
  @action
  async uploadCsv(file) {
    try {
      const importTaskInstance = this.studentImport.perform(file)
      const result = await importTaskInstance
      if (result?.ok) {
        this._showSuccessAlert()
      }
    } catch (e) {
      if (!didCancel(e)) throw e // re-throw the non-cancelation error
    }
  }

  @action
  submitCsv() {
    this.activeImportTaskInstance?.submitCsv()
  }

  @action
  cancel() {
    this.activeImportTaskInstance?.cancelTask()
  }

  @action
  downloadSampleCsv() {
    const data = this.generateStudentSampleImportData()
    const filename = this.intl.t('manage.students.sampleCsv.filename')
    this.fileDownload.downloadAsCsv(data, { filename })
  }
}
