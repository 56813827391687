import Route from '@ember/routing/route'
import { service } from '@ember/service'

export default class NewRoute extends Route {
  @service store

  model(params) {
    return this.store.findRecord('school', params.school_id)
  }
}
